import React from "react";
import { Player, BigPlayButton, LoadingSpinner, ControlBar } from "video-react";
import { Container } from "./style";

const VideoPlayer = ({ url = "" }) => {
  return (
    <Container>
      <Player
        autoPlay={true}
        aspectRatio="4:3"
        isolation="auto"
        resize="x"
        isFullscreen={true}
        src={url}
      >
        <LoadingSpinner />
        <ControlBar />
        <BigPlayButton position="center" />
      </Player>
    </Container>
  );
};

export default VideoPlayer;
