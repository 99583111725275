import { DatePicker } from 'antd';
import React from 'react';
import './style.css';
import { useSelector, useDispatch } from 'react-redux/es/exports';
import moment from 'moment';
import { FiCalendar } from 'react-icons/fi';
import { setSingleDate } from '../../redux/modules/dates/actions';

export default ({ title, setPaginate, allowClear=true }) => {
  const { singleDate } = useSelector((state) => state.selectedDateReducer);
  const dispatch = useDispatch();

  const handleChangeDateInput = (_, dateString) => {
    if (dateString.length > 0) {
      const data = moment(dateString).format('YYYY-MM-DD');
      dispatch(setSingleDate(data));
    } else {
      dispatch(setSingleDate(''));
    }
    if (setPaginate) {
      setPaginate({
        limit: 12,
        page: 1,
      });
    }
  };

  return (
    <div className="d-picker">
      {title ? (
        <h4 className="black-text">{title}</h4>
      ) : (
        <h4 className="range-text">Sana</h4>
      )}
      <DatePicker
        placeholder="Sanani belgilang"
        allowClear={allowClear}
        defaultValue={singleDate && moment(singleDate)}
        format="DD MMM YYYY"
        suffixIcon={<FiCalendar />}
        onChange={handleChangeDateInput}
      />
    </div>
  );
};
