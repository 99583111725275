export const ratingsHeader = [
  {
    id: 1,
    Header: '№',
    accessor: 'id',
    show: true,
    width: 30,
  },
  {
    id: 2,
    Header: 'Familiya, Ism, Sharif',
    accessor: 'user_name',
    show: true,
    // width: 250,
    type: 'info',
  },
];

export const paymentsHeader = [
  {
    id: 1,
    Header: '№',
    accessor: 'id',
    show: true,
    width: 30,
  },
  {
    id: 2,
    Header: 'Tuman (Shahar)',
    accessor: 'region',
    show: true,
    align: 'center',
  },
  {
    id: 3,
    Header: 'MFY',
    accessor: 'district',
    show: true,
    // align: "center",
  },
  {
    id: 4,
    Header: 'Ism, Familiya',
    accessor: 'user_name',
    show: true,
    width: 300,
    // align: "center",
  },

  {
    id: 6,
    Header: 'Telefon raqami',
    accessor: 'phone',
    show: true,
  },
  {
    id: 5,
    Header: 'Status',
    accessor: 'status',
    show: true,
    type: 'payment',
    align: 'center',
  },
];
export const specialTasksHeader = [
  {
    id: 1,
    Header: '№',
    accessor: 'id',
    show: true,
    width: 20,
  },
  {
    id: 2,
    Header: 'Viloyat',
    accessor: 'province',
    align: 'left',
    show: true,
    width: 200,
    type: 'popover',
  },
  {
    id: 3,
    Header: 'Shahar tuman',
    accessor: 'region',
    align: 'left',
    show: true,
    width: 200,
    type: 'popover',
  },
  {
    id: 6,
    Header: 'Topshiriq nomi',
    textAlign: 'left',
    accessor: 'title',
    show: true,
    type: 'link',
    width: 500,
    // grow: 4,
  },
  {
    id: 7,
    Header: 'Jami hodimlar',
    accessor: 'totalUser',
    show: true,
    align: 'center',
    // width: 150,
  },
  {
    id: 8,
    Header: 'Bajarganlar soni',
    accessor: 'success',
    show: true,
    align: 'center',
    width: 150,
  },
  {
    id: 10,
    Header: 'Bajarish muddati',
    accessor: 'date',
    align: 'left',
    show: true,
    width: 120,
  },
];

export const specialTaskResultHeader = [
  {
    id: 1,
    Header: '№',
    accessor: 'id',
    show: true,
    width: 50,
  },
  {
    id: 2,
    Header: 'VILOYAT',
    accessor: 'province',
    align: 'left',
    show: true,
    width: 150,
  },
  {
    id: 3,
    Header: 'SHAHAR TUMAN',
    accessor: 'region',
    align: 'left',
    show: true,
    width: 150,
  },
  {
    id: 4,
    Header: 'MFY',
    accessor: 'district',
    align: 'left',
    show: true,
    width: 150,
  },
  {
    id: 5,
    Header: 'F.I.Sh',
    textAlign: 'center',
    accessor: 'user',
    show: true,
    width: 200,
    // grow: 4,
  },
  {
    id: 6,
    Header: 'Bajarish muddati',
    accessor: 'endTime',
    align: 'center',
    show: true,
    width: 130,
  },
  {
    id: 7,
    Header: 'Bajarilgan vaqti',
    accessor: 'uploadTime',
    align: 'center',
    show: true,
    width: 120,
  },
  {
    id: 8,
    Header: 'ISH HOLATI',
    accessor: 'status',
    align: 'center',
    show: true,
    width: 148,
    type: 'status',
  },
];

export const schedulesHeader = [
  {
    id: 1,
    Header: 'Familiya, Ism, Sharif',
    accessor: 'user_name',
    show: true,
    sort: true,
    width: 300,
  },
  {
    id: 6,
    Header: 'Tuman',
    accessor: 'region',
    show: true,
    width: 160,
    align: 'center',
  },
  {
    id: 7,
    Header: 'MFY',
    accessor: 'object',
    show: true,
    width: 150,
  },
  {
    id: 2,
    Header: 'Harakatlar',
    accessor: 'came',
    show: true,
    type: 'info',
    width: 100,
    // pl: 40,
    // pr: 20,
  },
  {
    id: 3,
    Header: 'Vaqti',
    accessor: 'comingTime',
    show: true,
    width: 160,
    // pl: 40,
    // pr: 20,
  },
  {
    id: 4,
    Header: 'Kech qoldi',
    accessor: 'lateTime',
    show: true,
    width: 100,
    sort: true,
    type: 'info',
    // pl: 40,
    // pr: 20,
  },
];

export const reportHeaderOrganization = [
  {
    Header: 'Т/р',
    width: 50,
    className: 'custom-sticky-td',
    columns: [
      {
        width: 50,
        Header: 'header_100',
        accessor: 'id',
        displayNone: true,
      },
    ],
    rowSpan: '2',
    show: true,
  },
  {
    Header: 'Ташкилот номи',
    className: 'custom-sticky-td',
    columns: [
      {
        width: 150,
        accessor: 'name',
        Header: 'header_2',
        displayNone: true,
      },
    ],
    rowSpan: '2',
    show: true,
  },
];

export const reportHeader = [
  {
    Header: 'T/r',
    width: 50,
    className: 'custom-sticky-td',
    columns: [
      {
        width: 50,
        Header: 'header_100',
        accessor: 'id',
        displayNone: true,
      },
    ],
    rowSpan: '2',
    show: true,
  },
  {
    Header: 'Shaxar, tumanlar nomi',
    className: 'custom-sticky-td',
    columns: [
      {
        width: 150,
        textAlign: 'left',
        accessor: 'name',
        Header: 'header_2',
        displayNone: true,
      },
    ],
    rowSpan: '2',
    show: true,
  },
];
export const reportUserHeader = [
  {
    Header: 'T/r',
    width: 50,
    className: 'custom-sticky-td',
    columns: [
      {
        width: 50,
        Header: 'header_100',
        accessor: 'id',
        displayNone: true,
      },
    ],
    rowSpan: '2',
    show: true,
  },
  {
    Header: 'MFY',
    className: 'custom-sticky-td',
    columns: [
      {
        // width: 200,
        accessor: 'district',
        Header: 'mfy_2',
        displayNone: true,
      },
    ],
    rowSpan: '2',
    show: false,
  },
  {
    Header: 'Nomi',
    className: 'custom-sticky-td',
    columns: [
      {
        width: 250,
        accessor: 'name',
        Header: 'header_2',
        displayNone: true,
      },
    ],
    rowSpan: '2',
    show: true,
  },
  {
    Header: 'Jami',
    columns: [
      {
        width: 50,
        Header: 'Reja',
        accessor: 'total_plan',
      },
      {
        width: 50,
        Header: 'Amalda',
        accessor: 'total_work',
      },
      {
        width: 50,
        Header: 'Foizi',
        accessor: 'total_percent',
      },
    ],
    show: true,
  },
];
export const reportSubCategoriesHeader = [
  {
    Header: 'T/r',
    className: 'custom-sticky-td',
    columns: [
      {
        Header: 'id',
        displayNone: true,
        columns: [
          {
            width: 50,
            Header: 'id',
            accessor: 'id',
            displayNone: true,
          },
        ],
      },
    ],
    rowSpan: 3,
    show: true,
  },
  {
    Header: 'MFY',
    className: 'custom-sticky-td',
    columns: [
      {
        Header: 'mfy',
        displayNone: true,
        columns: [
          {
            // width: 50,
            Header: 'mfy',
            accessor: 'district',
            displayNone: true,
          },
        ],
      },
    ],
    rowSpan: 3,
    show: false,
  },
  {
    Header: 'Nomi',
    className: 'custom-sticky-td',
    columns: [
      {
        Header: 'name',
        displayNone: true,
        columns: [
          {
            width: 250,
            Header: 'name',
            accessor: 'name',
            displayNone: true,
          },
        ],
      },
    ],
    rowSpan: 3,
    show: true,
  },
  {
    Header: 'Jami',
    columns: [
      {
        Header: 'Jami',
        displayNone: true,
        columns: [
          {
            width: 50,
            Header: 'Reja',
            accessor: 'total_plan',
            // displayNone: true,
          },
          {
            width: 50,
            Header: 'Amalda',
            accessor: 'total_work',
            // displayNone: true,
          },

          {
            width: 50,
            Header: 'Foizi',
            accessor: 'total_percent',
            // displayNone: true,
          },
        ],
      },
    ],
    rowSpan: 2,
    show: true,
  },
];
export const reportUserPlanHeader = [
  {
    Header: 'T/r',
    accessor: 'id',
    show: true,
    width: 50,
  },
  {
    Header: 'MFY',
    accessor: 'district',
    // width: 250,
    textAlign: 'left',
    show: false,
  },
  {
    Header: 'Nomi',
    accessor: 'name',
    width: 250,
    textAlign: 'left',
    show: true,
  },
  {
    Header: 'Jami',
    accessor: 'total',
    width: 50,
    textAlign: 'left',
    show: true,
  },
];

export const statisticsHeader = [
  {
    Header: 'T/r',
    show: true,
    height: 150,
    width: 50,
    accessor: 'id',
  },
  {
    Header: 'Худуд',
    show: true,
    height: 150,
    accessor: 'region',
  },
  {
    Header: 'Амалга оширилиши белгиланган ишлар сони',
    show: true,
    height: 150,

    accessor: 'plan_works',
  },
  {
    Header: 'Жами амалга оширилган ишлар сони	',
    show: true,
    height: 150,

    accessor: 'completed_works',
  },
  {
    Header: '%',
    show: true,
    height: 150,
    width: 100,
    accessor: 'percent',
  },
  {
    Header: 'Қуйилган бахо',
    show: true,
    height: 150,
    accessor: 'rating',
  },
];
