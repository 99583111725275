import React from 'react';
import { ChartCard, ChartCardHeader } from './style';
import { Pie } from '@ant-design/plots';
// import { Button } from 'antd';

const SpecialityChartCard = ({
  getStatisticChartData,
  statisticOfAppealsByCategory,
}) => {
  return (
    <ChartCard height="100%">
      <ChartCardHeader>
        <div>Ma’lumotlar sohalar kesimida</div>
      </ChartCardHeader>
      <div className="body__two__col">
        <div className="left__col">
          <ul className="items__list">
            {getStatisticChartData()?.map((item, index) => (
              <li key={`${index + 1}`}>
                <span
                  style={{
                    background: item?.color || '#000',
                  }}
                  className="item__marker"
                />
                <span className="text">{item?.title}</span>
                <span className="dashed__underline" />
                <span
                  style={{
                    color: '#323232',
                  }}
                >
                  {item?.count}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="right__col">
          <div className="right__col_chart">
            <Pie {...statisticOfAppealsByCategory} />
          </div>
          {/* <div>
            <Button>Batafsil</Button>
          </div> */}
        </div>
      </div>
    </ChartCard>
  );
};

export default React.memo(SpecialityChartCard);
