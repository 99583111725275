import styled from "styled-components";

export const Wrapper = styled.div`
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  font-size: 18px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  color: #282828;
`;
