import React, { useMemo, useState, useEffect } from 'react';
import Table from '../../../../components/Tables/ReportTable';
import { headerMaker } from '../../../../components/Tables/ExpandedTable/helper';
import reportService from 'services/reports';
import { useSelector } from 'react-redux';
import Spinner from 'components/Spinner';
import { Empty } from 'antd';
import { reportUserPlanHeader } from 'redux/modules/table/common';
import { NoData } from '../style';

export const createUserDataBySupport = (data = []) => {
  const rowData = data?.map((item, ind) => {
    let row = {
      id: ind + 1,
      name: item?.title.titleUZ
        ? item.title?.titleUZ
        : `${item.title?.lastName} ${item?.title?.firstName}`,
      total: item.data.reduce((acc, i) => acc + i?.total, 0),
      district: item?.district?.titleUZ ?? '-',
    };
    item?.data?.forEach((itm) => {
      row[itm?.support?._id] = itm?.total;
    });
    return row;
  });
  return rowData;
};

export default ({ active = '', selectedFilters, headerGetter }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newHeader, setNewHeader] = useState([]);
  const { selectedDate } = useSelector((state) => state.selectedDateReducer);

  useEffect(() => {
    setLoading(true);
    let data = {
      province: selectedFilters.province,
      region: selectedFilters.region,
    };
    if (
      !selectedDate.every((item) => item === '') &&
      selectedDate[0] !== 'Invalid date' &&
      selectedDate[1] !== 'Invalid date'
    ) {
      data['start'] = selectedDate[0];
      data['end'] = selectedDate[1];
    }
    if (!selectedDate.every((item) => item === '') && active !== '') {
      data = { ...data };
      reportService
        .getSupportReports(data)
        .then((resp) => {
          const rowData = createUserDataBySupport(resp);
          setRows(rowData);
          makeReportHeaders(resp);
        })
        .finally(() => setLoading(false))
        .catch((err) => console.log(err));
    }
  }, [active, selectedDate, selectedFilters]);

  useEffect(() => {
    if (newHeader?.length > 0) {
      if (selectedFilters?.region) {
        const heads = [...newHeader];
        heads[1].show = true;
        setNewHeader(heads);
      } else {
        const heads = [...newHeader];
        heads[1].show = false;
        setNewHeader(heads);
      }
    }
  }, [selectedFilters.region]);

  const makeReportHeaders = (supports = []) => {
    let result = [];
    supports?.forEach((item) =>
      item?.data?.map((subItem) => result.push(subItem?.support))
    );
    result = new Set([...result]);

    let heads = [...reportUserPlanHeader];
    if (selectedFilters?.region) {
      heads[1].show = true;
    } else {
      heads[1].show = false;
    }

    if (heads.length === 4) {
      result &&
        result.forEach((item) => {
          heads.push({
            Header: item?.titleUZ,
            show: true,
            textAlign: 'center',
            width: 120,
            accessor: item?._id,
          });
        });
    }
    setNewHeader(heads);
  };

  const headers = useMemo(() => headerMaker(newHeader), [newHeader]);
  useEffect(() => {
    headerGetter(headers);
  }, [headers]);

  if (loading) return <Spinner maxHeight="300px" />;
  return rows?.length > 0 ? (
    <Table
      data={rows}
      headers={headers}
      tableName="Yordam ko'rsatilganlar bo'yicha"
    />
  ) : (
    <NoData>
      <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
    </NoData>
  );
};
