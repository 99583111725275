import React, { useEffect, useState } from 'react';
import SupervisorCard from 'components/Cards/WorkCards/Supervisor';

import { SearchBox, WorkContent } from './style';
import { FiSearch, FiX } from 'react-icons/fi';
import { Empty, Pagination } from 'antd';
import works from 'services/works';
import Spinner from 'components/Spinner';
import { NoData } from '../style';
import { useSelector } from 'react-redux';

const Works = () => {
  const [worksTotal, setWorksTotal] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);
  const [worksData, setWorksData] = useState([]);
  const [search, setSearch] = useState('');
  const [bool, setBool] = useState(false);

  const { supervisorDate } = useSelector((state) => state.selectedDateReducer);

  const fetchWorks = () => {
    setLoading(true);
    const postData = {
      date: supervisorDate,
      page: pageNum,
      limit: 10,
      // status: 2,
    };
    if (search.length > 0) {
      postData['search'] = search;
    }
    works
      .getWorksRegSupervisor(postData, 'send')
      .then(({ docs, totalDocs }) => {
        setWorksTotal(totalDocs);
        setWorksData(docs);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchWorks();
  }, [supervisorDate, bool, pageNum]);

  const onSearch = (action) => {
    if (action === 'clear') {
      setPageNum(1);
      setSearch('');
      setBool((prev) => !prev);
    }
  };

  // if (loading) return <Spinner maxHeight={'100%'} />;

  return (
    <WorkContent>
      <div className="search">
        <SearchBox
          className="search-input"
          placeholder="Matn bo'yicha qidiruv..."
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="btn__group">
          {search.length > 0 && (
            <button className="clear__btn" onClick={() => onSearch('clear')}>
              <FiX size={24} />
            </button>
          )}
          <button className="search__btn" onClick={fetchWorks}>
            <FiSearch color="#fff" size={24} />
          </button>
        </div>
      </div>
      <div className="works__body">
        {loading ? (
          <Spinner maxHeight={'100%'} />
        ) : worksData.length > 0 ? (
          worksData.map((work, index) => (
            <div key={index} className="work">
              <SupervisorCard data={work} />
            </div>
          ))
        ) : (
          <NoData>
            <Empty
              description={
                <h1>Belgilangan kun uchun qilingan ishlar topilmadi!</h1>
              }
            />
          </NoData>
        )}
      </div>
      <div className="custom-pagination-wrapper">
        {worksTotal > 10 && (
          <Pagination
            defaultCurrent={pageNum}
            total={worksTotal}
            onChange={(page) => setPageNum(page)}
          />
        )}
      </div>
    </WorkContent>
  );
};

export default React.memo(Works);
