import React, { useEffect, useMemo, useState } from 'react';
import Table from '../../../components/Tables/ExpandedTable';
import { headerMaker } from '../../../components/Tables/ExpandedTable/helper';
import Spinner from '../../../components/Spinner';
// import { usePaginate } from 'hooks/usePaginate';
import { specialTasksHeader } from 'redux/modules/table/common';
import { Wrapper, PageHeader, FilterContainer, Button, NoData } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { useShowModal, useHideModal } from 'hooks/index';
import SpecialModal from './SpecialModal';
import Select from 'components/FormElements/Select';
import RangePicker from 'components/RangePicker';
import { useFilter } from 'views/useFilter';
import specialTask from 'services/specialTasks';
import moment from 'moment';
import { Empty, Pagination } from 'antd';
import { setModalLoading } from 'redux/modules/modals/actions';

export default () => {
  const { showBlured } = useShowModal();
  const { hideModal } = useHideModal();
  const dispatch = useDispatch();
  // const { data, handleOnTableChange, pageCount } = usePaginate();
  const [loading, setLoading] = useState(false);
  const { role } = useSelector((state) => state.authReducer);
  const [reportData, setReportData] = useState([]);
  const { selectedDate } = useSelector((state) => state.selectedDateReducer);
  const [allTotal, setAllTotal] = useState('');
  const [paginate, setPaginate] = useState({
    limit: 13,
    page: 1,
  });
  const { handleFilters, selectedFilters, provinces, regions } = useFilter({
    withObject: false,
  });

  const headers = useMemo(
    () =>
      headerMaker(
        role === 'proleader'
          ? specialTasksHeader?.filter((item) => item?.accessor !== 'province')
          : role === 'regleader'
          ? specialTasksHeader?.filter(
              (item) =>
                item?.accessor !== 'region' && item?.accessor !== 'province'
            )
          : specialTasksHeader
      ),
    [specialTasksHeader, role]
  );

  const fetchData = () => {
    let payload = { ...paginate };
    if (selectedFilters?.province) {
      payload['province'] = selectedFilters?.province;
    }
    if (selectedFilters?.region) {
      payload['region'] = selectedFilters?.region;
    }
    if (
      !selectedDate.every((item) => item === '') &&
      selectedDate[0] !== 'Invalid date' &&
      selectedDate[1] !== 'Invalid date'
    ) {
      payload['startDate'] = selectedDate[0];
      payload['endDate'] = selectedDate[1];
    }

    setLoading(true);
    specialTask
      .getAll(payload)
      .then((resp) => {
        setAllTotal(resp?.totalDocs);
        const finalData = resp?.docs?.map((item, idx) => ({
          id:
            Number(paginate?.page) * Number(paginate?.limit) -
            Number(paginate?.limit) +
            idx +
            1,
          title: {
            id: item?._id,
            value: item?.titleUZ,
          },
          totalUser: item?.totalUser,
          district:
            item?.district?.map((item) => item?.titleUZ)?.join(', ') || '-',
          province:
            item?.provinces?.map((item) => item?.titleUZ)?.join(', ') || '-',
          region:
            item?.regions?.map((item) => item?.titleUZ)?.join(', ') || '-',
          date: item?.date ? moment(item?.date).format('DD.MM.YYYY') : '-',
          forReference: item?.forReference,
          success: item?.success,
        }));
        setReportData(finalData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [selectedFilters, selectedDate, paginate]);

  const handleClickAddButton = () => {
    showBlured({
      title: 'Topshiriq ma’lumotlarini to’ldiring',
      maxWidth: '600px',
      maxHeight: '85vh',
      loading: false,
      withHeader: false,
      body: () => <SpecialModal handleClick={handleClickCreateTask} />,
    });
  };

  const handleClickCreateTask = (data) => {
    let payload = {};
    if (data?.forReference) {
      delete data['withText'];
      delete data['withVideo'];
      delete data['withPhoto'];
      delete data['telegramGroup'];
      payload = { ...data };
    } else {
      if (data?.withPhoto) {
        payload = {
          ...data,
        };
      } else {
        delete data['telegramGroup'];
        payload = {
          ...data,
        };
      }
    }

    const formData = new FormData();
    formData.append('titleUZ', payload?.titleUZ);
    formData.append('titleQQ', payload?.titleUZ);
    formData.append('titleRU', payload?.titleUZ);
    formData.append('desc', payload?.desc);
    formData.append('date', payload?.date);
    formData.append('role', payload?.role);
    payload?.forReference &&
      formData.append('forReference', payload?.forReference);
    payload?.withPhoto && formData.append('withPhoto', payload?.withPhoto);
    payload?.withText && formData.append('withText', payload?.withText);
    payload?.withVideo && formData.append('withVideo', payload?.withVideo);
    payload?.telegramGroup &&
      formData.append('telegramGroup', payload?.telegramGroup);
    payload?.file && formData.append('files', payload?.file);

    for (let i = 0; i < payload?.provinces?.length; i++) {
      formData.append(`provinces[${i}]`, payload?.provinces[i]);
    }
    if (payload?.role !== 'proleader') {
      for (let i = 0; i < payload?.regions?.length; i++) {
        formData.append(`regions[${i}]`, payload?.regions[i]);
      }
    }

    dispatch(setModalLoading(true));
    specialTask
      .create(formData)
      .then(() => {
        dispatch(setModalLoading(false));
        hideModal();
        fetchData();
      })
      .catch((err) => console.log(err.message))
      .finally(() => dispatch(setModalLoading(false)));
  };

  const onChange = (pageNumber, limit) => {
    setPaginate({
      limit: limit,
      page: pageNumber,
    });
  };

  const filterItems = [
    {
      name: 'province',
      label: 'Viloyat',
      options: provinces,
      permissions: ['leader'],
    },
    {
      name: 'region',
      label: 'Tuman',
      options: regions,
      permissions: ['leader', 'proleader'],
    },
  ];

  return (
    <Wrapper>
      <PageHeader>
        <FilterContainer>
          <div className="filters_wrap">
            {filterItems.map(
              (item) =>
                item.permissions.includes(role) && (
                  <Select
                    title={item.label}
                    key={item.name}
                    name={item.name}
                    id={item.name}
                    handleChange={(value) => handleFilters(item.name, value)}
                    value={selectedFilters?.[item.name]}
                    options={item.options || []}
                    placeholder="Hududni tanlang!"
                  />
                )
            )}
            <div className="ragne-picker">
              <RangePicker direction="column" />
            </div>
          </div>
          <Button
            className="add-btn"
            type="primary"
            onClick={handleClickAddButton}
          >
            Topshiriq yaratish
          </Button>
        </FilterContainer>
      </PageHeader>
      {loading ? (
        <Spinner maxHeight={'calc(100vh - 400px)'} />
      ) : (
        <>
          {reportData?.length ? (
            <Table headers={headers} data={reportData} onChange={() => {}} />
          ) : (
            <NoData>
              <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
            </NoData>
          )}
          {allTotal > 15 && (
            <div className="pagination-wrap">
              <Pagination
                defaultCurrent={paginate?.page}
                total={allTotal}
                defaultPageSize={paginate?.limit}
                pageSizeOptions={[13, 20, 25]}
                onChange={onChange}
              />
            </div>
          )}
        </>
      )}
    </Wrapper>
  );
};
