import { createPortal } from 'react-dom';
import {
  Body,
  CloseButton,
  CloseIcon,
  Container,
  Content,
  Header,
  MapContainer,
  Title,
} from './style';
import { useEffect, useRef, useState } from 'react';

import L from 'leaflet';
import 'leaflet-plugins/layer/tile/Yandex';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import MapLayer from 'components/FormElements/MapLayer';

export default function MapAreaModal({
  isOpen,
  onClose,
  maxWidth,
  title,
  maxHeight,
  data = [],
  mapLine,
}) {
  const mapRef = useRef(null);
  const [layer, setLayer] = useState('map');

  // delete L.Icon.Default.prototype._getIconUrl;
  // L.Icon.Default.mergeOptions({
  //   iconUrl: require('../../../assets/images/icon_map_marker.png'),
  //   iconSize: [25, 25],
  //   shadowSize: [0, 0],
  //   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  //   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // });
  // L.Icon.extend({
  //   options: {
  //     iconUrl: '../../../assets/images/icon_map_marker__active.png',
  //     iconSize: [36, 36],
  //     iconAnchor: [18, 18],
  //   },
  // });

  const marker = (time) => {
    return L.divIcon({
      className: 'custom-div-icon',
      html: `<div class='marker-pin'><i class='material-icons'>${time}</i></div>`,
      iconSize: [45, 57],
      iconAnchor: [15, 42],
    });
  };

  useEffect(() => {
    const container = L.DomUtil.get(mapRef?.current);
    let map = L.map(container, {
      attributionControl: false,
      fadeAnimation: true,
      minZoom: 6,
      maxZoom: 18,
    }).setView([40.3734, 71.7978], 10);
    const yndx = new L.Yandex({
      type: layer,
    });
    map.addLayer(yndx);

    // let markers = L.markerClusterGroup().on('clusterclick', function (e) {
    //   if (e.layer._zoom == 18 || e.layer._zoom > 14) {
    //     let ids = [];
    //     let markers = e.layer.getAllChildMarkers();
    //     markers.forEach(function (marker) {
    //       ids.push(marker.options.id);
    //     });
    //   } else {
    // markers.addLayer(markers);
    //     e.layer.zoomToBounds();
    //   }
    // });
    let markers = L.markerClusterGroup();
    mapLine?.map((item) => {
      const mark = new L.Marker([item?.lat, item?.lng], {
        icon: marker(item?.time),
      });
      markers.addLayer(mark);
    });
    map.addLayer(markers);

    if (data?.length > 0) {
      let polygon = L.polygon(data, { color: '#F69320' }).addTo(map);
      map.fitBounds(polygon.getBounds());
    }
    // if (mapLine?.length) {
    //   let polyline = L.polyline(mapLine, { color: 'red' }).addTo(map);
    //   map.fitBounds(polyline.getBounds());
    // }
    return () => {
      map.off();
      map.remove();
    };
  }, [layer]);

  return createPortal(
    <Container show={isOpen}>
      <Content maxWidth={maxWidth} show={isOpen}>
        <Header>
          <Title className="noselect">{title || ''}</Title>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <Body maxHeight={maxHeight}>
          <MapContainer ref={mapRef} id="map" />
          <div className="layer-wrapper">
            <MapLayer
              setValue={setLayer}
              value={layer}
              placement={'bottomRight'}
              special
            />
          </div>
        </Body>
      </Content>
    </Container>,
    document.getElementById('map-area')
  );
}
