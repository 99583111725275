import { Popover } from 'antd';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const PopoverContainer = styled.div`
  .rate {
    border-radius: 10px;
    border: 1px solid #ffbd00;
    background: rgba(255, 189, 0, 0.12);
    display: inline-flex;
    padding: 12px 11px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: #ffbd00;
    font-family: Segoe UI;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 12px */
  }
`;

const PopoverBody = styled.div`
  color: #080936;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  border-radius: 8px 8px 0px 0px;
  .body {
    width: 100%;
    max-width: 400px;
    padding: 12px 16px;
  }
  .rate__responsible {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 12px 16px;
    /* background: rgba(76, 77, 220, 0.12); */
    .label {
      margin: 0;
      font-weight: 400;
    }
    .value {
      font-weight: 600;
    }
  }
`;

const Body = ({ data }) => {
  const { role } = useSelector((state) => state.authReducer);
  return (
    <PopoverBody>
      <PopoverTitle>
        <div className="value">
          {moment(data?.rateTime).format('DD.MM.YYYY')}
        </div>
        {!['regsupervisor', 'prosupervisor', 'supervisor'].includes(role) ? (
          <div className="supervisor-title">
            <div className="label">Moderator:</div>
            <div className="value">
              {data?.supervisor?.firstName &&
                `${data?.supervisor?.lastName ?? ''}. ${
                  data?.supervisor?.firstName[0]?.toUpperCase() ?? ''
                } `}
            </div>
          </div>
        ) : null}
      </PopoverTitle>
      <div className="body">{data?.rateComment}</div>
    </PopoverBody>
  );
};

const PopoverTitle = styled.div`
  background: rgba(76, 77, 220, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #080936;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  height: 34px;
  flex-shrink: 0;
  padding: 12px 16px;
  border-radius: 8px 8px 0px 0px !important;

  .supervisor-title {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .label {
    font-weight: 400;
    line-height: 100%; /* 14px */
    padding: 0;
    margin: 0;
    opacity: 0.6;
  }
  .value {
    font-weight: 600;
    line-height: 100%; /* 14px */
  }
`;

const CustomPopover = ({ data = {} }) => {
  return (
    <PopoverContainer>
      <Popover
        content={<Body data={data} />}
        trigger="hover"
        className="popover"
      >
        <div className="rate">{data?.rate ?? 0}</div>
      </Popover>
    </PopoverContainer>
  );
};

export default CustomPopover;
