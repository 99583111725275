import React, { useEffect, useState } from 'react';
import { Header, NoData, TaskBody, TaskWrapper } from './style';
import { buttons } from './helper';
import ReportTab from 'components/Tabs/ReportTab';
import NewTaskCard from 'components/Cards/NewTaskCard';
import { useNavigate } from 'react-router-dom';
import specialTask from 'services/specialTasks';
import Spinner from 'components/Spinner';
import { Empty, Pagination } from 'antd';

export default () => {
  const [activeTab, setActiveTab] = useState('new');
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [paginate, setPaginate] = useState({
    limit: 12,
    page: 1,
  });

  const handleActive = ({ value }) => {
    setPaginate({
      limit: 12,
      page: 1,
    });
    setActiveTab(value);
  };

  useEffect(() => {
    setLoading(true);
    const sentData = {
      ...paginate,
      done: activeTab === 'new' ? false : true,
    };

    specialTask
      .getAllTasks(sentData)
      .then((res) => {
        setTotalDocs(res?.totalDocs);
        setTasks(res?.docs);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [activeTab]);

  const handleClickNewCard = (data) => {
    navigate('/tasks/send', { state: { value: 'Topshiriqlar', data: data } });
  };

  const onChange = (pageNumber, limit) => {
    setPaginate({
      limit: limit,
      page: pageNumber,
    });
  };

  return (
    <TaskWrapper>
      <Header>
        <ReportTab
          list={buttons}
          active={activeTab}
          handleActive={handleActive}
        />
      </Header>
      <TaskBody>
        {loading ? (
          <Spinner maxHeight={'calc(100vh - 400px)'} />
        ) : tasks?.length ? (
          <>
            {tasks?.map((task, index) => (
              <React.Fragment key={index}>
                {activeTab === 'new' ? (
                  <NewTaskCard task={task} handleClick={handleClickNewCard} />
                ) : (
                  <NewTaskCard task={task} noClick />
                )}
              </React.Fragment>
            ))}
            {totalDocs > 12 ? (
              <div className="pagination-wrap">
                <Pagination
                  defaultCurrent={paginate?.page}
                  total={totalDocs}
                  defaultPageSize={paginate?.limit}
                  pageSizeOptions={[12, 20, 25]}
                  onChange={onChange}
                />
              </div>
            ) : null}
          </>
        ) : (
          <NoData>
            <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
          </NoData>
        )}
      </TaskBody>
    </TaskWrapper>
  );
};
