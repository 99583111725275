export const buttons = [
  {
    id: 1,
    title: 'Viloyatlar',
    value: 'province',
  },
  {
    id: 2,
    title: 'Tumanlar',
    value: 'region',
  },
];

export const formProvinceData = {
  title: "Viloyat qo'shish",
  data: [
    {
      type: 'text',
      label: 'Nomi UZ',
      name: 'titleUZ',
      value: '',
      size: 24,
    },
    {
      type: 'text',
      label: 'Nomi RU',
      name: 'titleRU',
      value: '',
      size: 24,
    },
    {
      type: 'text',
      label: 'Nomi QQ',
      name: 'titleQQ',
      value: '',
      size: 24,
    },
    { type: 'textarea', label: 'Izoh', name: 'desc', value: '', size: 24 },
  ],
};
export const formRegionData = {
  title: "Tuman qo'shish",

  data: [
    {
      type: 'text',
      label: 'Nomi UZ',
      name: 'titleUZ',
      value: '',
      size: 24,
    },
    {
      type: 'text',
      label: 'Nomi RU',
      name: 'titleRU',
      value: '',
      size: 24,
    },
    {
      type: 'text',
      label: 'Nomi QQ',
      name: 'titleQQ',
      value: '',
      size: 24,
    },
    { type: 'textarea', label: 'Izoh', name: 'desc', value: '', size: 24 },
    {
      type: 'select',
      label: 'Viloyat',
      name: 'province',
      value: '',
      size: 24,
    },
  ],
};
