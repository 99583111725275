import { Button, Col, DatePicker, Input, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { Span } from '../../style';
import { DefaultWrap } from '../style';

export default ({ id, handleRep, data }) => {
  const [date, setDate] = useState(data?.date);
  const [repetition, setRepetition] = useState(data?.repetition);
  const [newCount, setNewCount] = useState(data?.count);

  return (
    <DefaultWrap>
      <Row justify="center" align="middle">
        <Col span={12}>
          <Span>Sana:</Span>
        </Col>
        <Col span={12}>
          <DatePicker
            placeholder=""
            value={date != null ? moment(date) : null}
            onChange={(e) => setDate(moment(e).format('YYYY-MM-DD'))}
            allowClear={false}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Span>Takroriyligi:</Span>
        </Col>
        <Col span={12}>
          <Input
            className="custom-input"
            type="number"
            value={repetition}
            onChange={(e) => setRepetition(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Span>Rejasi:</Span>
        </Col>
        <Col span={12}>
          <Input
            className="custom-input"
            type="number"
            value={newCount}
            onChange={(e) => setNewCount(e.target.value)}
          />
        </Col>
      </Row>
      <Row justify="end">
        <Col span={3}>
          <Button
            onClick={() =>
              handleRep(id, {
                date,
                repetition: repetition * 1,
                count: newCount * 1,
                type: 'repet',
              })
            }
            type="primary"
          >
            Ok
          </Button>
        </Col>
      </Row>
    </DefaultWrap>
  );
};
