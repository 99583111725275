import { service } from '.';

export default {
  getAll: (data) => service.post('/districts/paginate', data),
  getAllObjectByRegions: (data) => service.post('/districts/search', data),
  // get all parent objects without paginate
  // get all parent objects without paginate
  // get sub ojects list with pagination
  getOne: (id) => service.get(`/districts/${id}`),
  create: (data) => service.post(`/districts`, data),
  update: (id, data) => service.put(`/districts/${id}`, data),
  delete: (id) => service.delete(`/districts/${id}`),
};
