/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { InfoBox } from './style';
import MapButton from 'components/Map';
import CustomPopover from '../Tooltip';
import { useSelector } from 'react-redux';
import AudioPlayer from 'components/AudioPlayer';

const InfoSection = ({
  data,
  handleShowModal,
  size,
  unrated = false,
  showLocation = false,
}) => {
  const { lang } = useSelector((state) => state.appReducer);

  const actions = [
    {
      id: 0,
      label: 'Viloyat:',
      value: data.province?.[`title${lang}`] ?? '-',
      show: true,
    },
    {
      id: 1,
      label: 'Shahar-tuman:',
      value: data.region?.[`title${lang}`] ?? '-',
      show: true,
    },
    {
      id: 2,
      label: 'MFY:',
      value: data.district?.[`title${lang}`] ?? '-',
      show: true,
    },
    {
      id: 4,
      label: '#ID:',
      value: data.workId,
      show: true,
    },
    {
      id: 5,
      label: 'Ishtirokchilar soni:',
      value: data.public > 0 ? data.public : 1,
      show: true,
    },
    // {
    //   id: 5,
    //   label: "Baholagan mas'ul:",
    //   value:
    //     data?.supervisor?.firstName &&
    //     `${data?.supervisor?.lastName ?? ''} ${
    //       data?.supervisor?.firstName ?? ''
    //     } `,
    //   show:
    //     !unrated && ['leader', 'proleader', 'regleader', 'advanced'].includes(role) && true,
    //   // show: !unrated && role !== 'regsupervisor' && true, //old version
    // },
  ];

  return (
    <>
      <InfoBox size={size}>
        {actions.map(
          (action, index) =>
            action.show && (
              <div key={`${index + 1}`} className="section">
                <span className="text">{action.label}</span>
                <span className="value">{action.value}</span>
              </div>
            )
        )}

        <div className="section">
          {!unrated && (
            <>
              <div className="text">
                <div className="rate__action">
                  <span className="text">Baho</span>
                  <CustomPopover data={data} />
                </div>
              </div>
              <div className="value">
                <MapButton noTitle smaller handleShowModal={handleShowModal} />
              </div>
            </>
          )}
          {showLocation && (
            <div className="value">
              <MapButton noTitle smaller handleShowModal={handleShowModal} />
            </div>
          )}
        </div>
        {data?.sound ? (
          <AudioPlayer url={`data:audio/ogg;base64,${data?.sound}`} />
        ) : null}
      </InfoBox>
    </>
  );
};

export default InfoSection;
