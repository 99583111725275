import { Input } from 'antd';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  width: 100%;
  height: 44px;
  border-radius: 8px;
  & .ant-input {
    background: none;
    border: none;
    outline: none;
    color: white;
  }
  & .ant-input:hover {
    background: none;
    border: none;
    outline: none;
    color: white;
  }
  & .ant-input:focus {
    background: none;
    border: none;
    outline: none;
    color: white;
  }
`;
