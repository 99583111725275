import React, { useEffect, useMemo, useState } from 'react';
import { Empty } from 'antd';
import Wrapper from '../../../components/Wrapper';
import { CategoryCardV2 } from '../../../components/Cards';
import reports from 'services/reports';
import Spinner from '../../../components/Spinner';
import { useSelector } from 'react-redux';
import {
  makeReportAllCategory,
  makeReportAllgroups,
} from '../../../utils/makeReport';
import RangePicker from 'components/RangePicker';
import { FilterGroups, NoData, ReportCardsBody } from './style';
import Select from 'components/FormElements/Select';
import { useFilter } from 'views/useFilter';
import { useNavigate } from 'react-router-dom';
import DynamicFilters from 'components/DynamicFilters';
import { useWindowSize } from 'hooks/useDevice';

/* eslint react/no-unescaped-entities: off */
export default () => {
  const { selectedDate } = useSelector((state) => state.selectedDateReducer);
  const { width } = useWindowSize();
  const { handleFilters, selectedFilters, provinces, regions, objects } =
    useFilter({
      withObject: true,
    });

  const navigate = useNavigate();
  const { role } = useSelector((state) => state.authReducer);
  // const dateQuery = useMemo(
  //   () => selectedDate.every((item) => item !== '') && selectedDate,
  //   [selectedDate]
  // );

  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);

  const fetchAllReport = async () => {
    try {
      setLoading(true);
      const data = {};
      if (
        selectedFilters?.province ||
        selectedFilters?.region ||
        selectedFilters?.district
      ) {
        data['province'] = selectedFilters?.province;
        data['region'] = selectedFilters?.region;
        data['district'] = selectedFilters?.district;
      }
      if (
        !selectedDate.every((item) => item === '') &&
        selectedDate[0] !== 'Invalid date' &&
        selectedDate[1] !== 'Invalid date'
      ) {
        data['startDate'] = selectedDate[0];
        data['endDate'] = selectedDate[1];
      }
      if (role === 'advanced') {
        const reportsData = await reports.getAllAdvancedReport(data);
        const report = makeReportAllCategory(reportsData);
        setReportData(report);
      } else {
        const reportsData = await reports.getAllNewSpecialtyReport(data);
        const report = makeReportAllgroups(reportsData);
        setReportData(report);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllReport();
  }, [selectedFilters, selectedDate]);

  const handleCategory = (id, value) => {
    navigate(`/specialities/detail/${id}`, {
      state: {
        value,
        provinceId: selectedFilters?.province,
        regionId: selectedFilters?.region,
        districtId: selectedFilters?.district,
      },
    });
  };

  const filterItems = [
    {
      name: 'province',
      label: 'Viloyat',
      type: 'select',
      placeholder: 'Viloyatni tanlang...',
      options: provinces,
      permissions: ['leader'],
      span: 12,
    },
    {
      name: 'region',
      label: 'Tuman',
      placeholder: 'Tuman(Shahar) tanlang...',
      type: 'select',
      options: regions,
      permissions: ['leader', 'proleader'],
      span: 12,
    },
    {
      name: 'district',
      label: 'MFY',
      placeholder: 'MFY tanlang...',
      type: 'select',
      options: objects,
      permissions: ['leader', 'proleader', 'regleader'],
      span: 12,
    },
    {
      label: 'Sana',
      name: 'date',
      type: 'datepicker',
      permissions: ['leader', 'proleader', 'regleader', 'advanced'],
      span: 12,
    },
  ];
  const filteredByRelevance = useMemo(() => {
    return filterItems.filter((item) => item.permissions?.includes(role));
  }, [filterItems, role]);

  return (
    <Wrapper>
      <FilterGroups>
        {width < 1069 ? (
          <DynamicFilters
            height={'150px'}
            selectedFilters={selectedFilters} // valueState
            handleFilters={handleFilters} // filter handler
            filters={filteredByRelevance} // schema filters
          />
        ) : (
          <>
            {filteredByRelevance.map((item) => (
              <div className="filter__item" key={item.name}>
                {item?.type === 'select' ? (
                  <Select
                    name={item.name}
                    label={item.label}
                    placeholder={item.placeholder}
                    options={item.options}
                    onChange={(value) => handleFilters(item.name, value)}
                    title={item.label}
                    value={selectedFilters[item.name]}
                  />
                ) : (
                  <RangePicker direction={'column'} />
                )}
              </div>
            ))}
          </>
        )}
      </FilterGroups>
      {loading ? (
        <Spinner maxHeight={'calc(100vh - 300px)'} />
      ) : reportData?.length ? (
        <ReportCardsBody>
          {reportData?.map((item, indx) => (
            <CategoryCardV2
              key={indx}
              pageType="main"
              item={item}
              handleClick={handleCategory}
            />
          ))}
        </ReportCardsBody>
      ) : (
        <NoData>
          <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
        </NoData>
      )}
    </Wrapper>
  );
};
