// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SET_TOKEN: 'SET_TOKEN',
  SET_ROLE: 'SET_ROLE',
  SET_USERNAME: 'SET_USERNAME',
  CLEAR_TOKEN: 'CLEAR_TOKEN',
  SET_FULLNAME: 'SET_FULLNAME',
  SET_REGION_ID: 'SET_REGION_ID',
  SET_group_ID: 'SET_group_ID',
  SET_PROVINCE_ID: 'SET_PROVINCE_ID',
  SET_USER_ID: 'SET_USER_ID',
  SET_PROVINCE_TITLE: 'SET_PROVINCE_TITLE',
  SET_REGION_TITLE: 'SET_REGION_TITLE',
};
