import { Pie, measureTextWidth } from '@ant-design/plots';
import React, { memo } from 'react';
import { PieCard } from './style';
import { checkWorkAndPlan } from 'utils/makeReport';

const PieChart = ({ data: pieData }) => {
  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px; height:${20}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };">${text}</div>`;
  }

  const data = [
    {
      type: 'plans',
      label: 'Belgilandi',
      value: pieData?.plans,
    },
    {
      type: 'works',
      label: 'Bajarilgan',
      value: pieData?.works,
    },
    {
      type: 'notDone',
      label: 'Bajarilmagan',
      value: pieData?.plans - pieData?.works,
    },
  ];

  const dataPie = [
    {
      type: 'plans',
      label: 'Bajarilmagan',
      value: pieData?.plans - pieData?.works,
    },
    {
      type: 'works',
      label: 'Bajarilgan',
      value: pieData?.works,
    },
  ];

  const config = {
    appendPadding: 10,
    data: dataPie,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `${v} %`,
      },
    },
    legend: false,
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '{value}',
    },
    statistic: {
      // title: false,
      title: {
        offsetY: -4,
        style: {
          fontSize: '16px',
        },
        customHtml: (container, _, datum) => {
          const { width } = container.getBoundingClientRect();
          const text = datum ? `${datum?.label}` : '';
          return renderStatistic(width, text, {
            fontSize: 18,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: '18px',
        },

        customHtml: (container, view, datum) => {
          const { width } = container.getBoundingClientRect();
          const { plans, works } = pieData;
          const text =
            datum?.value && datum?.type === 'works'
              ? `${checkWorkAndPlan(works, plans)}%`
              : datum?.type === 'plans'
              ? `${checkWorkAndPlan(plans - works, plans)}%`
              : '100%';
          return renderStatistic(width, text, {
            fontSize: 18,
          });
        },
      },
    },
    color: ({ type }) => {
      if (type === 'plans') {
        return '#FF6060';
      }
      return '#2DCE99';
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  };
  return (
    <PieCard>
      <div className="card__title">{pieData.mainTitle}</div>
      <Pie
        {...config}
        style={{
          maxHeight: '180px',
        }}
      />
      <div className="pie__bottom">
        {data.map((item, index) => (
          <div
            key={item.type}
            className={`${
              index === 0 ? 'plan' : index === 2 ? 'notDone' : 'work'
            } pie__bottom__info`}
          >
            <p>{item.label}</p>
            <p>{item.value} </p>
          </div>
        ))}
      </div>
    </PieCard>
  );
};

export default memo(PieChart); //PieChart
