import React from 'react';
import { DuplicateWrapper } from './style';
import { Button } from 'antd';
import { useHideModal, useShowAlert } from 'hooks';
import quiz from 'services/quiz';
import { useDispatch } from 'react-redux';
import { setModalLoading } from 'redux/modules/modals/actions';

export default ({ testId, fetchData = () => {} }) => {
  const { hideModal } = useHideModal();
  const dispatch = useDispatch();
  const { error } = useShowAlert();

  const handleSubmit = () => {
    dispatch(setModalLoading(true));
    const sentData = {
      status: 3,
    };
    quiz
      .completed(testId, sentData)
      .then(() => {
        hideModal();
        fetchData();
      })
      .catch(() => {
        error('Xatolik yuz berdi');
      })
      .finally(() => {
        dispatch(setModalLoading(false));
      });
  };

  return (
    <DuplicateWrapper>
      <div className="title">Haqiqatdan ushbu testni yakunlamoqchimisiz?</div>
      <div className="button-wrapper">
        <Button onClick={hideModal}>Bekor qilish</Button>
        <Button onClick={handleSubmit}>Yakunlash</Button>
      </div>
    </DuplicateWrapper>
  );
};
