import React, { memo, useEffect, useState } from 'react';
import { CardBody } from './style';
import ModalPlayer from 'components/ModalPlayer';
import ImageCarusel from './ImageCarusel';
import InfoSection from './InfoSection';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useShowModal } from 'hooks';
import MapY from 'components/Map/MapModal';
import Text from 'assets/taskCard/text.svg';

const SpeciaTaskCard = ({
  size = 'small',
  data,
  unrated = false,
  showLocation = false,
}) => {
  const { lang } = useSelector((state) => state.appReducer);
  const [modalOpen, setModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [mediaData, setMediaData] = useState([]);
  const [expandedText, setExpandedText] = useState(false);
  const [geoLocation, setGeoLocation] = useState([]);
  const { showBlured } = useShowModal();

  const descTextSlicer = (text) => {
    const result =
      !expandedText && text?.length > 200 ? text.slice(0, 200) + '...' : text;
    return result;
  };

  useEffect(() => {
    if (
      data?.media !== undefined &&
      data?.media !== null &&
      data?.media?.length > 0
    ) {
      const latLng = [data?.media[0]?.lat, data?.media[0]?.lng];
      if (latLng !== undefined && latLng !== null) {
        setGeoLocation([data?.media[0]?.lat, data?.media[0]?.lng]);
      }
    } else {
      const latLng = [data?.lat, data?.lng];
      if (latLng !== undefined && latLng !== null) {
        setGeoLocation([data?.lat, data?.lng]);
      }
    }
  }, [data]);

  const handleShowModal = () => {
    showBlured({
      title: '',
      maxWidth: '1200px',
      height: '600px',
      withHeader: false,
      body: () => <MapY latLang={geoLocation} />,
    });
  };

  const showPlayer = (url) => {
    setVideoUrl(url);
    setModalOpen(!modalOpen);
  };
  useEffect(() => {
    if (
      data?.media !== undefined &&
      data?.media !== null &&
      data?.media?.length > 0
    ) {
      const media = data?.media.map((item) => ({ ...item, isImage: true }));
      setMediaData(media);
    }
    if (data?.video.length > 0) {
      const video = data?.video.map((item) => ({
        ...item,
        videoUrl: item ?? '',
        isImage: false,
      }));
      setMediaData((prev) => [...prev, ...video]);
    }
    // setMediaData(() => [
    //   {
    //     image: Text,
    //     isImage: true,
    //   },
    // ]);
  }, []);
  return (
    <>
      {modalOpen && (
        <ModalPlayer
          url={videoUrl}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
      <CardBody
        expanded={expandedText}
        size={size}
        className={expandedText ? 'expanded' : ''}
      >
        <div className="image__block item-1">
          {mediaData.length > 0 ? (
            <ImageCarusel
              data={mediaData}
              showPlayer={showPlayer}
              size={size}
            />
          ) : (
            <div className="image-wrapper">
              <img src={Text} alt="" />
            </div>
          )}
        </div>
        <div className="description item-2">
          <p className="title">{data?.specialTask?.['title' + lang]}</p>
          <div className="desc__text">
            {descTextSlicer(data?.desc)}
            {data?.desc?.length > 200 && (
              <button
                className="show__more"
                onClick={() => setExpandedText(!expandedText)}
              >
                {expandedText ? 'Yopish' : 'Ko’proq o’qish'}
              </button>
            )}
          </div>
          <div className="executor__info">
            <div className="date">
              {moment(data?.uploadTime).format('DD.MM.YYYY')}
              <span className="time">
                {moment(data?.uploadTime).format('HH:mm:ss')}
              </span>
            </div>
            <p className="executor">
              {`${data?.user?.lastName} ${data?.user?.firstName} ${data?.user?.middleName}`}
            </p>
          </div>
        </div>
        <div className="info item-3">
          <InfoSection
            unrated={unrated}
            data={data}
            size={size}
            handleShowModal={handleShowModal}
            showLocation={showLocation}
          />
        </div>
      </CardBody>
    </>
  );
};

export default memo(SpeciaTaskCard);
