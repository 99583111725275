import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../../components/Spinner';
import { NoData, WorkPageWrapper } from '../style';
import YouthLeaderCard from 'components/Cards/WorkCards/YouthLeader';
import { HiOutlineTrash } from 'react-icons/hi2';
import works from 'services/works';
import { useShowAlert } from 'hooks';
import { Empty } from 'antd';
import moment from 'moment';
import MultiDatePicker from 'components/FormElements/MultiDatePicker';
import { setSingleDate } from 'redux/modules/dates/actions';

const buttons = [
  {
    id: 2,
    title: 'O’chirish',
    value: 'del',
    icon: HiOutlineTrash,
  },
  {
    id: 1,
    title: 'Yuborish',
    value: 'add',
  },
];

export default () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [cardType, setCardType] = useState('large');
  const [desc, setDesc] = useState('');
  const [worksData, setWorksData] = useState([]);
  const { error, success } = useShowAlert();
  const { singleDate } = useSelector((state) => state.selectedDateReducer);
  const [determinedDays, setDeterminedDays] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const screenChecker = (innerWidth) => {
    if (innerWidth >= 1806) {
      setCardType('large');
    } else {
      setCardType('small');
    }
  };

  const fetWorkDates = () => {
    const determinedData = {
      date: moment().format('YYYY-MM-DD'),
      // status: 2,
    };
    works
      .getAdvancedWorkDays(determinedData)
      .then((res) => {
        setDeterminedDays(res);
      })
      .catch((err) => {
        setDeterminedDays([]);
        console.error(err);
      });
  };

  useEffect(() => {
    fetWorkDates();
    screenChecker(window.innerWidth);
    const handleResize = () => screenChecker(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleClick = (type) => {
    if (type === 'add') {
      handleSend(worksData[0]?._id);
    }
    if (type === 'del') {
      handleDelete(worksData[0]?._id);
    }
  };
  const handleDelete = (id) => {
    setLoading(true);
    works
      .deleteNewWork(id)
      .then(() => {
        success('Muvofaqqiyatli o’chirildi', 2, 'bottomCenter');
        fetchWork();
        fetWorkDates();
      })
      .catch((err) => {
        console.log(err);
        error('Xatolik', 2, 'bottomCenter');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSend = (id) => {
    setLoading(true);
    works
      .updateNewWork(id, { desc })
      .then(() => {
        success('Yuborildi', 2, 'bottomCenter');
        setDesc('');
        fetchWork();
        fetWorkDates();
      })
      .catch((err) => {
        console.log(err);
        error('Xatolik', 2, 'bottomCenter');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchWork = () => {
    setLoading(true);
    const reqBody = {
      page: 1,
      limit: 1,
      // status: 1
    };
    if (singleDate?.length) {
      reqBody['date'] = singleDate;
    }
    works
      .getNewWorksAdvanced(reqBody)
      .then(({ docs }) => {
        setWorksData(docs);
        setDesc(docs[0]?.desc ?? '');
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleChangeDateInput = (dateString) => {
    setStartDate(dateString);
    const data = moment(dateString).format('YYYY-MM-DD');
    if (data) {
      dispatch(setSingleDate(data));
    } else {
      dispatch(setSingleDate(''));
    }
  };

  useEffect(() => {
    fetchWork();
  }, [startDate, singleDate]);

  if (loading) return <Spinner maxHeight="100%" />;
  return (
    <WorkPageWrapper>
      <div className="filter__date" style={{ padding: ' 20px 0' }}>
        {/* <DatePicker /> */}
        <span className="label">Sana</span>
        <MultiDatePicker
          dates={determinedDays}
          onChange={handleChangeDateInput}
          startDate={startDate}
        />
      </div>
      {worksData?.length > 0 ? (
        <div className="works">
          <div className="work">
            <YouthLeaderCard
              size={cardType}
              data={worksData[0]}
              handleChange={(value) => {
                setDesc(value);
              }}
              desc={desc}
              showLocation={true}
            />
          </div>
          <div className="button__group">
            {buttons.map(({ id, title, value, icon: Icon }) => (
              <button
                key={id}
                disabled={value === 'add' && desc?.length < 70 && true}
                className={value}
                onClick={() => handleClick(value)}
              >
                {Icon && <Icon size={20} />}
                {title}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <NoData>
          <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
        </NoData>
      )}
    </WorkPageWrapper>
  );
};
