import styled, { css } from 'styled-components';

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1262px;
  ${flexCenter}
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  padding: 40px 20px;
  box-sizing: border-box;

  /* max-width: 1440px; */
  .supervisorCard {
    width: 100%;
    min-height: 570px;
    padding: 30px;
    background-color: #fff !important;
    border-radius: 12px;
  }
  .similar__works {
    width: 100%;
    ${flexCenter}
    flex-direction: column;
    gap: 30px;
    .work {
      width: 100%;
      padding: 30px;
      border-radius: 12px !important;
      background-color: #fff;
    }
  }
  .bottom__actions {
    width: 100%;
    ${flexCenter}
    justify-content: space-between;
    gap: 30px;
    .work__total {
      border-radius: 12px;
      background: #fff;
      display: inline-flex;
      height: 56px;
      width: 100%;
      max-width: 200px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      font-family: Segoe UI;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
      :disabled {
        cursor: not-allowed;
        background: #e2e8f0;
        color: #a0aec0;
      }
      .title {
        color: #a0aec0;
        font-size: 16px;
      }
      .count {
        color: #00826C;
        font-size: 20px;
      }
    }
  }
`;

export const TaskDescription = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 22px;
  border-radius: 12px;
  box-sizing: border-box;
  .search {
    border-radius: 12px;
    width: 100%;
    max-width: 1071px;
    height: 100%;
    background: #fff;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .clear__btn {
      background: transparent;
      transition: all 0.3s;
      :hover {
        color: #6652da;
        transition: all 0.3s;
      }
      :active {
        transform: scale(0.9);
        transition: all 0.3s;
      }
    }
    .search__btn {
      border-radius: 12px;
      background: #00826C;
      transition: all 0.3s;
      :hover {
        background: #6652da;
        transition: all 0.3s;
      }
      :active {
        transform: scale(0.9);
        transition: all 0.3s;
      }
    }
    button {
      border: none;
      outline: none;
      display: flex;
      width: 58px;
      height: 48px;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
  .search__result__info {
    border-radius: 12px;
    background: #fff;
    display: inline-flex;
    height: 56px;
    width: 100%;
    max-width: 200px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    :disabled {
      cursor: not-allowed;
      background: #e2e8f0;
      color: #a0aec0;
    }
    .title {
      color: #a0aec0;
      font-size: 16px;
    }
    .count {
      color: #00826C;
      font-size: 20px;
    }
  }
`;

export const SaveBtn = styled.button`
  outline: none;
  border: none;
  border-radius: 12px;
  background: #00826C;
  display: flex;
  width: 180px;
  height: 44px;
  padding: 15px 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  :hover {
    background: #6652da;
    transition: all 0.3s;
  }
  :active {
    transform: scale(0.9);
    transition: all 0.3s;
  }
  /* &:hover {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.57);
  }
  &:disabled {
    cursor: not-allowed;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  } */
`;

export const RecentWorks = styled.div`
  margin: 20px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  & .found-works {
    display: flex;
    justify-content: start;
    gap: 20px;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #3d3d3d;
    & .total {
      font-weight: 500;
      font-size: 28px;
      line-height: 33px;
      color: #171717;
    }
  }
`;

export const RateComment = styled.div`
  width: 100%;
  border: 1px solid #dadada;
  padding: 10px 30px;
  border-radius: 10px;
  & ul {
    width: 100%;
    height: 160px;
    padding: 5px;
    & li {
      font-weight: 500;
      font-size: 16px;
    }
  }
`;
export const EmptySpace = styled.div`
  padding: 30px;
`;

export const SearchBox = styled.input`
  width: -webkit-fill-available;
  max-width: 900px;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  padding: 0px 20px;
  box-sizing: border-box;
`;
export const NoData = styled.div`
  width: 100%;
  height: calc(100vh - 300px);
  max-height: 100%;
  margin: 0 auto;
  ${flexCenter}
`;
export const btnStyle = css`
  background: none;
  border: none;
  margin: 0;
  color: #c2c2c2;
`;

export const ActionButton = styled.div`
  & .edit-btn {
    ${btnStyle}
  }
  & .edit-btn:hover {
    color: var(--custom-primary);
  }
  & .edit-btn:disabled {
    color: #c2c2c2;
  }
`;

export const UsersWrapper = styled.div`
  width: 100%;
  /* max-width: 1262px; */
  /* height: 100%; */
  ${flexCenter}
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  padding: 40px 20px;
  box-sizing: border-box;
  .top__actions {
    width: 100%;
    max-width: 1044px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 22px;
    .add-btn {
      ${flexCenter}
      gap: 10px;
      height: 44px;
      padding: 13px;
      gap: 10px;
      border-radius: 10px;
      color: #fff;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      background: #00c4fa;
      cursor: pointer;
      :hover {
        background: #00c4fa99;
      }
    }
  }
  & .pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    .ant-pagination-options-size-changer.ant-select {
      .ant-select-selector {
        height: 32px !important;
        min-height: 34px !important;
      }
    }
  }
`;
