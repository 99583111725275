import styled from 'styled-components';

export const Wrapper = styled.button`
  border: none;
  outline: none;
  display: flex;
  /* flex: 1; */
  justify-content: left;
  align-items: center;
  cursor: pointer;
  transition: all 100ms linear;
  background: none;
  gap: 10px;
  color: #00826C;

  /* padding: 10px; */
`;
