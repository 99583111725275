import React from 'react';
import { ModalWrapper } from '../style';
import UserImage from 'assets/avatar.png';
import { DotCard } from 'components/Cards/ScheduleCard/style';

export default ({ data }) => {
  const getDistrict = () => {
    const rawData = data?.district?.titleUZ?.split('MFY');
    return rawData?.join(' ');
  };

  return (
    <ModalWrapper>
      <div className="user__info">
        <div className="left-section">
          <div className="user-image">
            <img src={data?.user?.avatar ?? UserImage} alt="" />
          </div>
          <div className="left-section-text">
            <div className="username">
              {[
                data?.user?.firstName,
                data?.user?.lastName,
                data?.user?.middleName,
              ].join(' ')}
            </div>
            <div className="region-card">
              <div className="region-text">
                <div className="region-title">
                  Viloyat: <DotCard width={'68px'}></DotCard>
                </div>
                <div className="region-value">
                  {data?.province?.titleUZ?.split(' ')?.[0]}{' '}
                  {data?.province?.titleUZ?.split(' ')?.[1][0]}.
                </div>
              </div>
              <div className="region-text">
                <div className="region-title">
                  Tuman: <DotCard width={'69px'}></DotCard>
                </div>
                <div className="region-value">
                  {data?.region?.titleUZ?.split(' ')?.[0]}{' '}
                  {data?.region?.titleUZ?.split(' ')?.[1][0]}.
                </div>
              </div>
              <div className="region-text">
                <div className="region-title">
                  MFY:<DotCard width={'83px'}></DotCard>
                </div>
                <div className="region-value">{getDistrict()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-text">{data?.text}</div>
    </ModalWrapper>
  );
};
