import styled from 'styled-components';

export const ChartCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 5px 14px 0px rgba(126, 126, 126, 0.05);
  flex-direction: column;
  .body__two__col {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media (max-width: 1200px) {
      justify-content: start;
    }
    .left__col {
      width: 60%;
      .items__list {
        list-style-type: none;
        width: 100%;
        li {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #9ea0a4;
          padding: 10px 0px;
          font-weight: 600;
          font-size: 13px;
          line-height: 100%;
          .text {
            width: max-content;
            margin-right: 5px;
          }
          .item__marker {
            width: 12px;
            height: 12px;
            padding: 3px 5px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 10px;
          }
          .dashed__underline {
            width: 100%;
            margin-top: 5px;
            border-bottom: 1px dashed #9ea0a4;
            margin-right: 5px;
          }
        }
      }
      .status__container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        .title__total__apeals {
          padding-top: 30px;
          width: 100%;
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 15px;
          color: #080936;
          font-family: Segoe UI;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%; /* 14px */
          .title__total__apeals_count {
            color: #080936;
            font-family: Segoe UI;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
          }
          @media (max-width: 1366px) {
            & {
              font-size: 13px;
              b {
                font-size: 13px;
              }
            }
          }
        }
        .apeals__status__list {
          padding-top: 18px;
          width: 100%;
          display: flex;
          gap: 60px;
          justify-content: flex-start;
          align-items: center;
          @media (max-width: 1700px) {
            gap: 30px;
          }

          .apeals__status {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            font-weight: 600;
            .status__label {
              font-size: 14px;
            }
            .percent {
              font-size: 36px;
            }
            .count {
              font-size: 14px;
            }
            @media (max-width: 1366px) {
              & {
                justify-content: baseline;
                align-items: center;

                min-height: 110px;
                .status__label {
                  font-size: 13px;
                  text-align: center;
                  height: 42px;
                }
                .percent {
                  font-size: 20px;
                }
                .count {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }

    .monthly__diagram {
      font-size: 24px;
      overflow: hidden;
    }
    .right__col {
      width: 210px;
      height: 195px;

      @media (max-width: 1366px) {
        width: 150px;
        .g2-html-annotation {
          font-size: 15px !important;
        }
      }
      @media (max-width: 1200px) {
        width: 210px;
      }
    }
  }
`;

export const ChartCardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin: 10px 0px 30px; */
  font-size: 18px;
  font-weight: 500;
  .card__title {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 24px;
    & .card__title_text {
      color: #080936;
      font-family: Segoe UI;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
  }
  @media (max-width: 1366px) {
    .card__title {
      font-size: 13px;
    }
  }
`;
