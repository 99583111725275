import React from "react";
import { Wrapper, Badge } from "./style";

export default ({ value, color, bg, border }) => {
  return (
    <>
      <Wrapper>
        <Badge bg={bg} color={color} border={border}>
          {value}
        </Badge>
      </Wrapper>
    </>
  );
};
