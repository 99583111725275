import { Col, Row } from 'antd';
import Diagramma from 'components/Diagramma';
import RangePicker from 'components/RangePicker';
import SelectCategory from 'components/SelectCategory';
import React, { useCallback, useEffect, useState } from 'react';
import PieChart from './PieCart';
import { useSelector } from 'react-redux';
import { makeReportOneCategory } from 'utils/makeReport';
import reports from 'services/reports';
import Spinner from 'components/Spinner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Wrapper } from './style';

const SpecialitiesDetail = () => {
  const { id: catId } = useParams();
  const navigate = useNavigate();
  const { selectedDate } = useSelector((state) => state.selectedDateReducer);
  const { categories } = useSelector((state) => state.appReducer);
  const [groupReport, setgroupReport] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const parentCategories = categories.filter((item) => item.parent == null);
  const { provinceId, regionId, districtId } = useLocation().state;

  const fetchReportCategorygroups = useCallback(
    async (catID) => {
      const reqBody = {};
      if (provinceId || regionId || districtId) {
        reqBody['province'] = provinceId;
        reqBody['region'] = regionId;
        reqBody['district'] = districtId;
      }
      if (
        !selectedDate.every((item) => item === '') &&
        selectedDate[0] !== 'Invalid date' &&
        selectedDate[1] !== 'Invalid date'
      ) {
        reqBody['startDate'] = selectedDate[0];
        reqBody['endDate'] = selectedDate[1];
      }
      try {
        if (catID && category === null) {
          reqBody['category'] = catID;
        } else {
          reqBody['category'] = category;
        }
        setLoading(true);
        const data = await reports.getOneNewSpecialtyReport(reqBody);
        const { card, diagramm } = makeReportOneCategory(data);
        setChartData(card);
        setgroupReport(diagramm);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [category, selectedDate]
  );

  useEffect(() => {
    setCategory(catId);
  }, []);

  useEffect(() => {
    fetchReportCategorygroups(catId);
  }, [category, selectedDate]);

  const handleCategory = (id, value) => {
    setCategory(id);
    navigate(`/specialities/detail/${id}`, {
      state: {
        value,
        provinceId: provinceId,
        regionId: regionId,
        districtId: districtId,
      },
    });
  };

  if (loading) return <Spinner maxHeight={'100%'} />;

  return (
    <Wrapper>
      <Row gutter={[20, 16]}>
        <Col span={24}>
          <Row align="bottom" gutter={[20, 16]}>
            <Col span={24} sm={24} md={15} lg={24} xl={15} xxl={18}>
              {parentCategories.length !== 0 && (
                <SelectCategory
                  active={category}
                  options={parentCategories}
                  handleChange={handleCategory}
                />
              )}
            </Col>
            <Col span={24} sm={24} md={9} lg={24} xl={9} xxl={6}>
              <span
                style={{
                  color: '#fff',
                  fontFamily: 'Segoe UI',
                  fontSize: ' 15px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '100%',
                }}
              >
                Sana
              </span>
              <RangePicker titleHide direction="column" />{' '}
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          order={2}
          md={{
            order: 2,
            span: 24,
          }}
          lg={{
            order: 2,
            span: 24,
          }}
          xl={{ span: 15, order: 1 }}
          xxl={{ span: 18, order: 1 }}
        >
          <Diagramma data={groupReport} />
        </Col>
        <Col
          span={24}
          order={1}
          md={{
            order: 1,
            span: 24,
          }}
          lg={{
            order: 1,
            span: 24,
          }}
          xl={{ span: 9, order: 1 }}
          xxl={{ span: 6, order: 1 }}
        >
          {chartData &&
            chartData?.map((item, indx) => (
              <PieChart data={item} key={`${indx + 1}`} />
            ))}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default SpecialitiesDetail;
