import styled from 'styled-components';

export const Button = styled.button`
  width: auto;
  padding: 5px 15px;
  cursor: pointer;
  background: var(--custom-primary);
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  align-self: flex-end;
  transition: all 0.3ms linear;
  :hover {
    background: #00a388;
  }
`;
