import moment from 'moment';

export const timeFormatter = (minutes) => {
  let response = '';
  if (minutes === 0) return minutes;
  if (minutes !== null && minutes > 0) {
    const durations = moment.duration(minutes, 'minutes');
    const days = durations.days();
    const hours = durations.hours();
    const remainingMinutes = durations.minutes();
    if (days !== 0) {
      response = `${days}k-${hours < 10 ? '0' + hours : hours}s : ${
        remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes
      }m `;
    } else {
      hours !== 0
        ? (response = `${hours}s :${
            remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes
          }m `)
        : (response = `${
            remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes
          }m `);
    }
    // const hours = Math.floor(minutes / 60);
    // const remainingMinutes = minutes % 60;
  } else {
    response = '-';
  }
  return response;
};

export const scheduleTimeFormatter = (minutes) => {
  let response = '';
  if (minutes > 9) {
    if (minutes === 0) return minutes;
    if (minutes !== null && minutes > 0) {
      const durations = moment.duration(minutes, 'minutes');
      const days = durations.days();
      const hours = durations.hours();
      const remainingMinutes = durations.minutes();
      if (days !== 0) {
        response = `${days}k : ${hours < 10 ? '0' + hours : hours}s : ${
          remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes
        }m `;
      } else {
        hours !== 0
          ? (response = `${hours}s : ${
              remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes
            }m `)
          : (response = `${
              remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes
            }m `);
      }
      // const hours = Math.floor(minutes / 60);
      // const remainingMinutes = minutes % 60;
    } else {
      response = '-';
    }
  } else {
    response = minutes + 'm';
  }
  return response;
};
