import { Container, Wrapper } from './style';
import Rais from '../../../assets/rais.svg';
import PremiumSoft from '../../../assets/premium-soft.png';

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <img src={Rais} alt="O'zbekiston mahallalar uyushmasi" height="40px" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="234"
          height="2"
          viewBox="0 0 234 2"
          fill="none"
        >
          <path d="M0 1H234" stroke="url(#paint0_linear_56_2740)" />
          <defs>
            <linearGradient
              id="paint0_linear_56_2740"
              x1="0"
              y1="1"
              x2="231.743"
              y2="1"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#E0E1E2" stopOpacity="0" />
              <stop offset="0.5" stopColor="#E0E1E2" />
              <stop offset="1" stopColor="#E0E1E2" stopOpacity="0.15625" />
            </linearGradient>
          </defs>
        </svg>
        <img src={PremiumSoft} alt="PremiumSoft" height="35px" />
      </Container>
    </Wrapper>
  );
};

export default Footer;
