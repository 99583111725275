import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  h4 {
    color: #080936;
    font-family: 'Segoe UI';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;
  }
`;

export const IconContainer = styled.div`
  background: rgb(233, 233, 250);
  border-radius: 6px;
  padding: 7.5px;
  display: flex;
  align-items: center;
`;
