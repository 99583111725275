import styled from 'styled-components';

export const PieCard = styled.div`
  width: 100%;
  min-height: 434px;
  height: 100%;
  max-width: 523px;
  max-height: 434px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  box-sizing: border-box;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 5px 14px 0px rgba(126, 126, 126, 0.05);
  padding: 24px;

  @media (max-width: 1200px) {
    max-width: 100%;
  }

  .card__title {
    width: 100%;
    color: #080936;
    font-family: Segoe UI;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
  }

  & canvas {
    width: 100% !important;
  }
  .pie__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: nowrap;

    @media (max-width: 1800px) and (min-width: 1600px) {
      flex-wrap: wrap;
      gap: 25px;
    }

    @media (max-width: 1300px) {
      flex-wrap: wrap;
    }

    @media (max-width: 1200px) {
      gap: 45px;
    }

    .plan {
      color: #00826C;
    }
    .work {
      color: #2dce99;
    }
    .notDone {
      color: #ff6060;
    }
    .pie__bottom__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      p {
        padding: 0;
        margin: 0;
        &:nth-child(1) {
          text-align: center;
          font-family: Segoe UI;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%; /* 14px */
          text-transform: uppercase;
        }
        &:nth-child(2) {
          font-family: Segoe UI;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%; /* 36px */

          @media (max-width: 1500px) {
            font-size: 22px;
          }
        }
      }
    }
  }
`;
