import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 12px;
  overflow: hidden;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  padding: 16px;
  background: rgba(76, 77, 220, 0.04);
   & img:first-child{
    margin-left: 10px;
   }
`;
