import Table from 'components/Tables/ExpandedTable';
import React, { useEffect, useState } from 'react';
import { NoData, TableHead, TableWrapper } from './style';
import { ReactComponent as CompletedIcon } from '../../../../assets/icons/completed.svg';
import { ReactComponent as NotCompletedIcon } from '../../../../assets/icons/notCompleted.svg';
import { LinkWrapper } from '../style';
import { CiShare1 } from 'react-icons/ci';
import { useLocation, useNavigate } from 'react-router-dom';
import quiz from 'services/quiz';
import { useShowAlert } from 'hooks';
import { Empty, Pagination } from 'antd';
import Spinner from 'components/Spinner';

export default () => {
  const navigate = useNavigate();
  const location = useLocation().state;
  const { error } = useShowAlert();
  const [totalDocs, setTotalDocs] = useState(0);
  const [paginate, setPaginate] = useState({
    limit: 10,
    page: 1,
  });
  const [loading, setLoading] = useState([]);
  const [data, setData] = useState([]);

  const mainHeadCols = [
    {
      id: 1,
      Header: '№',
      accessor: 'id',
      show: true,
      width: 30,
    },
    {
      id: 3,
      Header: 'SHAHAR/TUMAN',
      accessor: 'region',
      type: 'name',
      width: 500,
      Cell: ({ cell }) => {
        return (
          <LinkWrapper
            onClick={() =>
              handleClickRow(
                cell?.row?.original?.regionId,
                cell?.row?.original?.region
              )
            }
          >
            {cell?.row?.original?.region} <CiShare1 size={16} />
          </LinkWrapper>
        );
      },
    },
    {
      id: 4,
      Header: 'JAMI MAHALLAR RAISLARI',
      type: 'name',
      width: 200,
      align: 'center',
      accessor: 'users',
    },
    {
      id: 5,
      Header: () => (
        <TableHead>
          <CompletedIcon />
          BAJARDI
        </TableHead>
      ),
      accessor: 'completed',
      type: 'name',
      width: 200,
      align: 'center',
    },
    {
      id: 2,
      Header: () => (
        <TableHead>
          <NotCompletedIcon />
          BAJARMADI
        </TableHead>
      ),
      accessor: 'notCompleted',
      show: true,
      align: 'center',
      width: 200,
    },
    {
      id: 9,
      Header: 'BAJARILGANDAGI %',
      accessor: 'percent',
      show: true,
      align: 'center',
      width: 200,
    },
  ];

  const handleClickRow = (id, text) => {
    navigate('/tests/get-one/region', {
      state: {
        value: location.value + ' > ' + text,
        quizId: location?.quizId,
        regionId: id,
      },
    });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const query = `limit=${paginate?.limit}&page=${paginate?.page}&quiz=${location?.quizId}`;
      const resData = await quiz.getOneTest(query);
      setTotalDocs(resData?.totalDocs);
      const finallyData = resData?.docs?.map((itm, index) => ({
        id: paginate?.limit * paginate?.page - paginate?.limit + index + 1,
        region: itm?.title?.titleUZ,
        regionId: itm?.title?._id,
        completed: itm?.solvedUsers,
        notCompleted: itm?.unSolvedUsers,
        percent: itm?.trueAnswerPercent,
        users: itm?.totalUsers,
      }));
      setData(finallyData);
    } catch (err) {
      console.log(err);
      error('Xatolik yuz berdi');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location?.quizId, paginate]);

  const handlePaginate = (page, limit) => {
    setPaginate({
      page: page,
      limit: limit,
    });
  };

  if (loading) {
    return <Spinner maxHeight={'calc(100% - 100px)'} />;
  }
  return (
    <TableWrapper>
      {data?.length ? (
        <>
          <Table headers={mainHeadCols} data={data} />
          {totalDocs > 10 && (
            <div className="pagination custom-pagination-wrapper">
              <Pagination
                current={paginate?.page}
                total={totalDocs}
                onChange={(page, limit) => handlePaginate(page, limit)}
                defaultPageSize={paginate?.limit}
                showSizeChanger
              />
            </div>
          )}
        </>
      ) : (
        <NoData>
          <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
        </NoData>
      )}
    </TableWrapper>
  );
};
