import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
const service = axios.create({
  baseURL,
});

service.interceptors.response.use(
  (res) => res.data,
  (error) => {
    if (error.response.status === 403) {
      localStorage.removeItem('agency_access_token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

service.interceptors.request.use((config) => {
  const token = localStorage.getItem('agency_access_token');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${token}`;
  // if (lan) {
  //   config.params = {
  //     lan: 'uz',
  //   };
  // }
  return config;
});

export { service };
