import React, { useMemo, useState, useEffect } from 'react';
import Table from '../../../../components/Tables/ReportTable';
import { headerMaker } from '../../../../components/Tables/ExpandedTable/helper';
import reportService from 'services/reports';
import { useSelector } from 'react-redux';
import { checkWorkAndPlan } from '../../../../utils/makeReport';
import { reportSubCategoriesHeader } from 'redux/modules/table/common';
import Spinner from 'components/Spinner';
import { Empty } from 'antd';
import { NoData } from '../style';

export const createUserDataBySubcategory = (data) => {
  const rowData = data?.map((item, ind) => {
    const tPlan = item.data.reduce((acc, i) => acc + i?.reja, 0);
    const tWork = item.data.reduce((acc, i) => acc + i?.amalda, 0);
    const tPercent = checkWorkAndPlan(tWork, tPlan);
    let row = {
      id: ind + 1,
      name: item?.title.titleUZ
        ? item.title.titleUZ
        : `${item.title.lastName} ${item.title.firstName}`,
      district: item.title?.district?.titleUZ ?? '-',
      total_plan: tPlan,
      total_work: tWork,
      total_percent: tPercent + '%',
      percent: (tWork * 100) / tPlan,
    };
    item.data.forEach((itm) => {
      row[itm.subCategory + 'plan'] = itm.reja;
      row[itm.subCategory + 'work'] = itm.amalda;
      row[itm.subCategory + 'percent'] =
        checkWorkAndPlan(itm.amalda, itm.reja) + '%';
    });
    return row;
  });
  rowData.sort((a, b) => b.percent - a.percent);
  return rowData.map((item, ind) => ({ ...item, id: ind + 1 }));
};

export default ({
  active,
  selectedFilters,
  headerGetter,
  getDataForExcellTable,
  fetchExcellData,
}) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newHeader, setNewHeader] = useState([]);
  const { selectedDate } = useSelector((state) => state.selectedDateReducer);
  // const { role } = useSelector((state) => state.authReducer);
  // const { data, handleOnTableChange, pageCount } = usePaginate();

  const { categories } = useSelector((state) => state.appReducer);

  const dateQuery = useMemo(
    () => selectedDate.every((item) => item !== '') && selectedDate,
    [selectedDate]
  );

  const fetchData = (forExcel) => {
    setLoading(true);
    let data = {
      province: selectedFilters.province,
      region: selectedFilters.region,
    };
    if (
      !selectedDate.every((item) => item === '') &&
      selectedDate[0] !== 'Invalid date' &&
      selectedDate[1] !== 'Invalid date'
    ) {
      data['start'] = selectedDate[0];
      data['end'] = selectedDate[1];
    }
    if (!selectedDate.every((item) => item === '') && active !== '') {
      data = { ...data };
      reportService
        .getAllReportBySubCategories(data)
        .then((resp) => {
          const rowData = createUserDataBySubcategory(resp);
          setRows(rowData);
          makeReportHeaders(resp);
        })
        .finally(() => setLoading(false))
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    fetchData();
  }, [active, dateQuery, selectedFilters]);

  const makeReportHeaders = (data = []) => {
    const hechCategories = new Map();
    const parentCatgs = categories.filter((item) => !item.parent);
    categories.forEach((item) => hechCategories.set(item._id, item));
    const headerCats =
      data[0] &&
      data[0].data.map((item) => hechCategories.get(item.subCategory));
    let subHeaders = [...reportSubCategoriesHeader];
    if (selectedFilters?.region) {
      subHeaders[1].show = true;
      subHeaders[2].className = '';
    } else {
      subHeaders[1].show = false;
      subHeaders[2].className = 'custom-sticky-td';
    }
    if (subHeaders?.length === 4) {
      headerCats &&
        parentCatgs.forEach((item) => {
          item &&
            subHeaders.push({
              Header: item?.title,
              show: true,
              columns: headerCats
                .filter((i) => i?.parent === item?._id)
                .map((e) => ({
                  Header: e?.title,
                  show: true,
                  columns: [
                    {
                      width: 50,
                      Header: 'Reja',
                      accessor: e?._id + 'plan',
                    },
                    {
                      width: 50,
                      Header: 'Amalda',
                      accessor: e?._id + 'work',
                    },
                    {
                      width: 50,
                      Header: 'Foizi',
                      accessor: e?._id + 'percent',
                    },
                  ],
                })),
            });
        });
    }
    setNewHeader(subHeaders);
  };

  const headers = useMemo(() => headerMaker(newHeader), [newHeader]);
  useEffect(() => {
    headerGetter(headers);
  }, [headers]);

  if (loading) return <Spinner maxHeight="300px" />;
  return rows?.length > 0 ? (
    <Table
      data={rows}
      headers={headers}
      // onChange={handleOnTableChange}
      // total={rows.length}
      // reportsData={rows}
      // page={pageCount}
      tableName="Subkategoriyalar bo'yicha"
    />
  ) : (
    <NoData>
      <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
    </NoData>
  );
};
