import React, { useEffect } from 'react';
import Header from './Header';
import Body from './Body';
import { BodyContainer } from './style';
import ContainerLayout from './Wrapper';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import * as devices from '../../constants/devices';
import { useWindowSize } from 'hooks/useDevice';
import {
  setBadgeNotificationCount,
  setDesktopCollapsed,
} from 'redux/modules/application/actions';
import complaints from 'services/complaints';

export default () => {
  const collapsed = useSelector(({ appReducer }) => appReducer.collapsed);
  const role = useSelector((state) => state.authReducer.role);

  const { width, device } = useWindowSize();
  const dispatch = useDispatch();

  const desktopCollapsed = useSelector(
    ({ appReducer }) => appReducer.desktopCollapsed
  );

  useEffect(() => {
    if (width < 1360) {
      if (!desktopCollapsed) {
        dispatch(setDesktopCollapsed(true));
      }
    } else {
      if (desktopCollapsed) {
        dispatch(setDesktopCollapsed(false));
      }
    }
  }, [width]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (role === 'proleader') {
        complaints.getSideBarNotificationCount().then((res) => {
          dispatch(
            setBadgeNotificationCount({
              complaints: res?.count,
            })
          );
        });
      }
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <ContainerLayout collapsed={collapsed}>
      <Header />
      <BodyContainer>
        <Body />
      </BodyContainer>
      {device === devices.DESKTOP ? (
        !desktopCollapsed && <Footer />
      ) : (
        <Footer />
      )}
    </ContainerLayout>
  );
};
