import { ScheduleCard } from 'components/Cards';
import React, { useState } from 'react';
import { ButtonMap, ModalBodyWrapper, TableWrapper } from '../style';
import { Table } from 'antd';
import {
  DaysName,
  areaBorderColumn,
  areaBorderTimeColumn,
  didNotComeColumn,
  didNotComeColumnBecauseOf,
  lateComeColumn,
} from '../helper';
import moment from 'moment';
import MapAreaModal from 'components/Map/MapAreaModal';
import MapSvg from '../../../../assets/icons/sidebar/map.svg';
import schedule from 'services/schedule';
import Spinner from 'components/Spinner';
import { useSelector } from 'react-redux';

export default ({ active, item, polygon = [], tabActive }) => {
  const [isOpen, setOpen] = useState(false);
  const [mapLine, setMapLine] = useState([]);
  const [loading, setLoading] = useState(false);

  const { singleDate } = useSelector((state) => state.selectedDateReducer);

  const columns = {
    1: lateComeColumn,
    2: tabActive === 7 ? didNotComeColumnBecauseOf : didNotComeColumn,
    4: [
      ...areaBorderColumn,
      {
        title: 'Xaritada',
        key: 'cameTime',
        width: '100px',
        render: (value) => {
          return (
            <ButtonMap onClick={() => handleMap(value?.sentDate)}>
              <img src={MapSvg} alt="" />
            </ButtonMap>
          );
        },
      },
    ],
    8: areaBorderTimeColumn,
  };

  // const handleData = () => {
  //   const newData = item?.data;
  //   const newArr = [];
  //   let startDate = '';

  //   for (let i = 0; i < newData?.length; i++) {
  //     if (newData[i]?.deviceOff) {
  //       if (!startDate) {
  //         startDate = newData[i]?.date;
  //       } else {
  //         if (i === newData?.length - 1) {
  //           let finallyMinutes = moment
  //             ?.duration(
  //               moment(newData[i]?.date, 'YYYY-MM-DD HH:mm').diff(
  //                 moment(startDate, 'YYYY-MM-DD HH:mm')
  //               )
  //             )
  //             .asMinutes();
  //           newArr.push({
  //             startDate: startDate,
  //             minute: finallyMinutes,
  //           });
  //           startDate = '';
  //         }
  //       }
  //     } else {
  //       if (startDate) {
  //         let finallyMinutes = moment
  //           ?.duration(
  //             moment(newData[i - 1]?.date, 'YYYY-MM-DD HH:mm').diff(
  //               moment(startDate, 'YYYY-MM-DD HH:mm')
  //             )
  //           )
  //           .asMinutes();

  //         newArr.push({
  //           startDate: startDate,
  //           minute: finallyMinutes,
  //         });
  //         startDate = '';
  //       }
  //     }
  //   }

  //   return newArr ?? [];
  // };

  const handleMap = (date) => {
    const sentData = {
      date: moment(date).format('YYYY-MM-DD'),
      user: item?.user?._id,
    };
    setLoading(true);
    schedule
      .getOneAreaLine(sentData)
      .then((res) => {
        const newMapLine = res?.data
          ?.filter((item) => item?.offside)
          ?.map((itm) => {
            return {
              lat: itm?.lat,
              lng: itm?.lng,
              time: itm?.date ? moment(itm?.date).format('HH:mm') : '-',
            };
          });
        setMapLine(newMapLine);
        setOpen(true);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const getTime = (late) => {
    if (Math.floor(late / 60) > 0) {
      if (late % 60 > 0) {
        return (
          Math.floor(late / 60) + ' soat ' + Math.round(late % 60) + ' min'
        );
      } else {
        return Math.floor(late / 60) + ' soat';
      }
    } else {
      return Math.round(late % 60) + ' min';
    }
  };

  const lateComeData = item?.data?.map((itm, index) => ({
    key: `${index + 1}`,
    day: DaysName[moment(itm?.date).isoWeekday() - 1],
    date: moment(itm?.date).format('DD.MM.YYYY'),
    cameTime: moment(itm?.date).format('HH:mm'),
    lateTime: getTime(itm?.late),
  }));

  const didNotComeData = item?.data?.map((itm, index) => ({
    key: `${index + 1}`,
    day: DaysName[moment(itm?.date).isoWeekday() - 1],
    date: moment(itm?.date).format('DD.MM.YYYY'),
    active: itm?.active,
    cause: itm?.cause,
  }));

  const areaBorderData = item?.data?.map((itm, index) => ({
    key: `${index + 1}`,
    day: DaysName[moment(itm?.date).isoWeekday() - 1],
    date: moment(itm?.date).format('DD.MM.YYYY'),
    cameTime: itm?.time > 0 ? getTime(itm?.time ? itm?.time : 0) : '1-marta',
    sentDate: itm?.date,
  }));

  const areaBorderTimeData = item?.periods?.map((itm, idx) => ({
    ...itm,
    key: `${idx + 1}`,
    indx: idx + 1,
    minute: `${
      itm?.duration?.hours ? itm?.duration?.hours?.toString() + ' soat' : ''
    } ${
      itm?.duration?.minutes ? itm?.duration?.minutes?.toString() + ' min' : ''
    }`,
    // minute: itm?.minute > 0 ? getTime(itm?.minute ? itm?.minute : 0) : '',
  }));

  const data = {
    1: lateComeData,
    2: didNotComeData,
    4: areaBorderData,
    8: areaBorderTimeData,
  };

  const title = {
    1:
      item?.data?.length == 1
        ? '  kun kesimida kech qolganlik holatlari'
        : item?.data?.length > 1
        ? '  kunlar kesimida kech qolganlik holatlari'
        : '',
    2:
      item?.data?.length == 1
        ? '  kun kesimida kelmaganlik holatlari'
        : item?.data?.length > 1
        ? '  kunlar kesimida kelmaganlik holatlari'
        : '',
    4:
      item?.data?.length == 1
        ? '  kun kesimida hududni tark etganlik holatlari'
        : item?.data?.length > 1
        ? '  kunlar kesimida hududni tark etganlik holatlari'
        : '',
    8: singleDate + '  kun kesimida aloqa uzilganlik holatlari',
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) return <Spinner />;
  return (
    <>
      {active === 4 && (
        <MapAreaModal
          isOpen={isOpen}
          onClose={handleClose}
          maxWidth={'900px'}
          maxHeight={'75vh'}
          title={'Map'}
          data={polygon}
          mapLine={mapLine}
        />
      )}
      <ModalBodyWrapper>
        <ScheduleCard
          notClick
          active={active}
          background={'rgba(76, 77, 220, 0.08)'}
          data={item}
          tabActive={tabActive}
        />
        <div className="table-title">
          {active !== 8 && (
            <>
              {item?.data?.length === 1
                ? moment(item?.data[0]?.date).format('DD.MM.YYYY')
                : item?.data?.length > 1
                ? moment(item?.data[0]?.date).format('DD.MM.YYYY') +
                  '-' +
                  moment(item?.data[item?.data?.length - 1]?.date).format(
                    'DD.MM.YYYY'
                  )
                : ''}
            </>
          )}
          {title[active]}
        </div>
        <TableWrapper>
          <Table
            columns={columns[active]}
            dataSource={data[active]}
            pagination={data[active]?.length > 10 ? true : false}
          />
        </TableWrapper>
      </ModalBodyWrapper>
    </>
  );
};
