import styled from 'styled-components';

export const InfoBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  & .section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 15px;
    font-size: ${({ size }) => (size === 'small' ? '12px' : '16px')};
    line-height: 100%; /* 12px */
    font-family: Segoe UI;
    font-style: normal;
  }
  & .text {
    width: 60%;
    color: rgba(8, 9, 54, 0.5);
    font-weight: 400;
    display: flex;
    align-items: center;
    /* text color/9D */
    color: #a8a8a8;
  }
  & .value {
    width: 50%;
    align-self: flex-start;
    color: var(--textcolor-01, #080936);
    font-weight: 600;
    text-align: left;
  }
  .rate__action {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 14px;
  }
`;
