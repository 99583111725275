import styled from 'styled-components';

export const FilterGroups = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
  box-sizing: border-box;
  transition: height 0.3s ease-in-out;
  .main__form {
    display: flex;
    min-height: 50px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 10px;
    label {
      color: #fff !important;
    }
  }

  .filter__item {
    .range-datepicker {
      max-width: 100% !important;
      min-width: 100% !important;
    }
    .filter__label {
      color: #fff;
      font-family: Segoe UI;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 15px */
      padding: 0;
      margin: 0;
    }
    span {
      /* background-color: transparent; */
    }
  }
`;
export const ReportCardsBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  flex-wrap: wrap;
  gap: 58px;

  @media (max-width: 1116px) {
    gap: 25px;
    justify-content: center;
  }
  @media (max-width: 960px) {
    gap: 25px;
    justify-content: flex-start;
  }
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  height: calc(100vh - 400px);
`;
