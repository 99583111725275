import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

const UploadWrap = styled.div`
  width: 100%;
  height: 100%;
  .ant-btn:hover,
  .ant-btn:focus {
    color: #9a9aeb !important;
    border-color: #9a9aeb !important;
  }
`;
const ImageBlock = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #c2c2c240;
  border-radius: 6px;
  img {
    border-radius: 6px;
    width: 100px;
    height: 100px;
    border-radius: 6px;
  }
`;

export default ({ handleUpload, value, postData, setPostData, name, accept }) => {
  const [file, setFile] = useState(null);
  const props = {
    name,
    imageUrl: value,
    beforeUpload: (file) => {
      if (file) {
        handleUpload(file);
        const image = URL.createObjectURL(image);
        setFile(file);
      }
      return false;
    },
  };
  const handleDelete = () => {
    const newPostData = { ...postData };
    delete newPostData['file'];
    setPostData({ ...newPostData });
  };

  return (
    <UploadWrap>
      {value ? (
        <ImageBlock>
          <img src={typeof value === 'string' ? value : file} alt={value} />{' '}
          <Button onClick={handleDelete} icon={<CloseOutlined />} />
        </ImageBlock>
      ) : (
        <Upload {...props} file={value} maxCount={1} accept={accept}>
          <Button icon={<UploadOutlined />}>Fayl yuklash</Button>
        </Upload>
      )}
    </UploadWrap>
  );
};
