import React from 'react';
import './style.css';
import { Col, Input, Row, Switch } from 'antd';
import Select from '../../FormElements/Select';
import FileUpload from 'components/FormElements/FileUpload';
import Upload from 'components/FormElements/Upload';

export default ({ item, formik, selectOptions, deleteImage,id }) => {
  switch (item.type) {
    case 'text':
      return (
        <Input
          onChange={formik.handleChange}
          name={item.name}
          value={formik.values[item.name]}
        />
      );
    case 'textarea':
      return (
        <Input.TextArea
          onChange={formik.handleChange}
          name={item.name}
          value={formik.values[item.name]}
        />
      );
    case 'password':
      return (
        <Input
          onChange={formik.handleChange}
          name={item.name}
          value={formik.values[item.name]}
        />
      );
    case 'select':
      return (
        <Select
          handleChange={(e) => formik.setFieldValue(item.name, e)}
          options={selectOptions[item.name]}
          title=""
          allowClear={!item.multiple && true}
          multiple={
            // formik.values.role &&
            (formik.values.role === 'groupleader' ||
              formik.values.role === 'supervisor' ||
              formik.values.role === 'editor') &&
            item.multiple
          }
          defaultVal={formik.values[item.name]}
        />
      );
    case 'searchSelect':
      return (
        <Select
          objSelect
          handleChange={(e) => formik.setFieldValue(item.name, e)}
          options={selectOptions[item.name]}
          title=""
          allowClear={!item.multiple && true}
          multiple={
            // formik.values.role &&
            (formik.values.role === 'groupleader' ||
              formik.values.role === 'supervisor' ||
              formik.values.role === 'editor') &&
            item.multiple
          }
          defaultVal={formik.values[item.name]}
        />
      );
    case 'switch':
      return (
        <Switch
          checked={formik.values[item.name]}
          onChange={(e) => formik.setFieldValue(item.name, e)}
        />
        // <Select
        //   handleChange={(e) => formik.setFieldValue(item.name, e)}
        //   options={selectOptions[item.name]}
        //   title=""
        //   defaultVal={formik.values.province}
        // />
      );
    case 'tel':
      return (
        <Row gutter={[0, 16]} align="middle">
          <Col span={5}>
            <div className="num">+998</div>
          </Col>
          <Col span={19}>
            <Input
              placeholder="911324567"
              maxLength={9}
              minLength={9}
              type="text"
              onChange={formik.handleChange}
              name={item.name}
              value={formik.values[item.name]}
            />
          </Col>
        </Row>
      );
    case 'file':
      return (
        <FileUpload
          handleUpload={(file) => formik.setFieldValue(item.name, file)}
          name={item.name}
          formik={formik}
          value={formik.values[item.name]}
          deleteImage={deleteImage}
          userId={id}
        />
      );

    case 'coordinates':
      return (
        <Upload
          name={
            formik.values?.coordinates?.length > 0 && formik.values?.titleUZ
          }
          onChange={(files) => formik.setFieldValue(item?.name, files)}
        />
      );
    default:
      break;
  }
};
