import styled from 'styled-components';

export const SendTaskWrapper = styled.div`
  padding: 28px 0px 14px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const HeaderCardWrap = styled.div`
  width: 100%;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
  padding: 24px;
  display: flex;
  justify-content: space-between;
  gap: 30px;

  & .left-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 700px;
    & .section-title {
      color: #080936;
      font-family: 'Segoe UI';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
    & .section-desc {
      color: rgba(8, 9, 54, 0.7);
      font-family: 'Segoe UI';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }

  & .right-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    & .image-wrapper {
      width: 85px;
      height: 85px;
      border-radius: 10px;
      background: #eeeefc;
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.04);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .term-section {
      display: flex;
      align-items: center;
      gap: 20px;
      & .term-text {
        color: rgba(8, 9, 54, 0.6);
        font-family: 'Segoe UI';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }
      & .term-day {
        width: auto;
        padding: 4px 10px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        background: rgba(76, 77, 220, 0.1);
        color: #00826C;
        font-family: 'Segoe UI';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
      }
    }
  }
`;

export const BodyWrapper = styled.div`
  padding: 24px;
  width: 493px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .body-title {
    color: #080936;
    font-family: 'Segoe UI';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
  & .image-upload-wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
    & .image-upload {
      cursor: pointer;
      width: 100%;
      height: 165px;
      border-radius: 16px;
      border: 1px dashed #00826C;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      & .image-upload-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        color: #00826C;
        font-family: 'Segoe UI';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
      }
    }
    & input {
      display: none;
    }
  }
  & .description {
    display: flex;
    flex-direction: column;
    gap: 8px;
    & .desc-title {
      color: #080936;
      font-family: 'Segoe UI';
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
    & .textarea-wrap {
      & textarea {
        width: 100%;
        min-height: 158px !important;
        border-radius: 16px !important;
        border: 1px solid rgba(8, 9, 54, 0.1);
        :hover,
        :focus {
          border: 1px solid #9a9aeb !important;
        }
      }
    }
  }
`;

export const UploadImage = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  & .card {
    width: 98px;
    height: 98px;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    & .ant-image {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    & .delete-image {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 2;
      width: 20px;
      height: 20px;
      background: rgb(76, 77, 220);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 46px;
  padding-top: 26px;

  & .submit-button {
    width: 124px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: #00826C;
    color: #fff;
    font-family: 'Segoe UI';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    outline: none;
    border: none;
    cursor: pointer;
  }
  & .cancel-button {
    color: #080936;
    font-family: 'Segoe UI';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    background: none;
    outline: none;
    cursor: pointer;
    border: none;
  }
  & .submit-button[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
    cursor: no-drop;
  }
`;

export const ButtonViewWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;

  & .submit-button {
    width: 124px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: rgb(0, 196, 250);
    color: #fff;
    font-family: 'Segoe UI';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    outline: none;
    border: none;
    cursor: pointer;
  }
  & .submit-button[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
    cursor: no-drop;
  }
`;

export const VideoFrame = styled.div`
  width: 98px;
  height: 98px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  border: 1px solid #eee;
  &:hover {
    & svg {
      color: #00826C;
      transition-duration: 1.2s;
      transform: scale(1.5);
    }
  }
  & img {
    background-origin: center;
    background-size: cover;
    border-radius: 10px;
    object-fit: cover;
  }
  & .effect__blur {
    filter: blur(5px);
  }
  & svg {
    position: absolute;
    left: 40%;
    top: 41%;
    color: #fff;
    font-size: 20px;
    transition-duration: 1s;
  }
`;
