import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  background: #fff;
  .accardion__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 42px;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    outline: none;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
  }
  .filters__body {
    max-height: ${({ open }) => (open ? '800px' : '0px')};
    transition: max-height 0.3s ease-in-out;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 10px;

    color: #00c4fa;
    font-family: Segoe UI;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    .filters__row {
      width: 100%;
      padding: 25px;
      padding-top: 0px;
      gap: 0;
      /* visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
      transition: visiblity 5s ease-out; */
      .black-text {
        margin: 0px !important;
      }
      .d-picker > .ant-picker {
        width: 100% !important;
      }
    }
    .btn-primary {
      background-color: 00C4FA;
    }
    .action__btn {
      display: flex;
      height: 44px;
      width: 100%;
      padding: 13px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 10px;
      color: #fff;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 16px */
    }
  }
  .clear__btn {
    border: 1px solid #00826C !important;
    color: #00826C !important;
    background: #fff;
  }
  .switch__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 12px 0 12px 0;
    label {
      display: flex;
      align-items: center;
      justify-content: flex-start !important;
      gap: 10px;
      color: #080936 !important;
      cursor: pointer;
      font-family: Segoe UI;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */
      text-transform: uppercase;
    }
  }
`;

export const SearchInput = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  min-width: 280px;
  & .ant-input-affix-wrapper input.ant-input {
    min-width: 150px !important;
    width: 100%;
  }
`;
