import React from 'react';
import { StatusCard } from '../style';

export default ({ color, bgColor, data }) => {
  return (
    <StatusCard bgcolor={bgColor ? 'true' : 'false'} color={color}>
      <div className="late-came">
        <div className="late-came-text">Hududni tark etgan</div>
        <div className="late-came-count">{data?.count} marta</div>
      </div>
    </StatusCard>
  );
};
