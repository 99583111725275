import React from 'react';

export default ({ headers, data, id }) => {
  const tableHeaders = headers.map((item) => (
    <th key={item?.accessor}>{item?.Header}</th>
  ));

  const tableRows = data.map((item) => {
    const tableCells = headers.map((header) => {
      let cellValue = item[header?.accessor];
      if (header?.accessor === 'total') {
        cellValue = `${cellValue}.`;
      } else if (typeof cellValue === 'object') {
        cellValue = cellValue?.categoryValue;
      }
      return <td key={header?.accessor}>{cellValue}</td>;
    });

    return <tr key={item.id}>{tableCells}</tr>;
  });
  return (
    <table id={id}>
      <thead>
        <tr>{tableHeaders}</tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};
