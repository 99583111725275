import React from 'react';
import { DotCard, ScheduleCardWrap } from './style';
import UserImage from '../../../assets/avatar.png';
import LateCome from './LateCome';
import DidNotCome from './DidNotCome';
import Vacant from './Vacant';
import AreaBorder from './AreaBorder';
import { scheduleColor } from 'constants/colors';
import Came from './Came';
import AllDidNotCome from './AllDidNotCome';
import AllLateCome from './AllLateCome';
// import AreaBorderTime from './AreaBorderTime';

export default ({
  data,
  handleCard,
  active,
  background,
  cursor,
  notClick,
  tabActive,
}) => {
  const activeCard = {
    1: <LateCome bgColor={notClick} color={scheduleColor[1]} data={data} />,
    2: (
      <DidNotCome
        bgColor={notClick}
        color={scheduleColor[2]}
        data={data}
        tabActive={tabActive}
      />
    ),
    3: <Vacant color={scheduleColor[3]} />,
    4: <AreaBorder data={data} bgColor={notClick} color={scheduleColor[4]} />,
    5: (
      <Came
        color={scheduleColor[3]}
        date={tabActive === 5 ? data?.data && data?.data[0]?.date : data?.date}
        late={data?.late}
      />
    ),
    6: <AllDidNotCome bgColor={notClick} color={scheduleColor[2]} />,
    7: (
      <AllLateCome
        bgColor={notClick}
        color={scheduleColor[1]}
        date={data?.date}
        late={data?.late}
      />
    ),
    // 8: (
    //   <AreaBorderTime data={data} bgColor={notClick} color={scheduleColor[6]} />
    // ),
  };

  const getDistrict = () => {
    if (data?.user?.district) {
      // Ohiridagi MFY degan so'z har doim katta harfda bo'lishi kerak !
      const rawData = data?.user?.district?.titleUZ?.split('MFY');

      return rawData?.join(' ');
    }
    const rawData = data.user?.titleUZ?.split(' ');
    rawData?.pop();
    return rawData?.join(' ');
  };
  return (
    <ScheduleCardWrap
      background={background}
      cursor={
        cursor && active !== 3 && active !== 5 && active !== 6 && active !== 7
          ? 'true'
          : 'false'
      }
      onClick={() =>
        !notClick &&
        active !== 3 &&
        active !== 5 &&
        active !== 6 &&
        active !== 7 &&
        handleCard(data?.user?._id, active)
      }
    >
      <div className="left-section">
        <div className="user-image">
          <img src={data?.user?.avatar ?? UserImage} alt="" />
        </div>
        <div className="left-section-text">
          <div className="username">
            {active !== 3
              ? data?.user?.firstName +
                ' ' +
                data?.user?.lastName +
                ' ' +
                data?.user?.middleName
              : ''}
          </div>
          <div className="region-card">
            <div className="region-text">
              <div className="region-title">
                Viloyat: <DotCard width={'68px'}></DotCard>
              </div>
              <div className="region-value">
                {data?.user?.province?.titleUZ.split(' ')?.[0]}{' '}
                {data?.user?.province?.titleUZ.split(' ')?.[1][0]}.
              </div>
            </div>
            <div className="region-text">
              <div className="region-title">
                Tuman: <DotCard width={'69px'}></DotCard>
              </div>
              <div className="region-value">
                {data?.user?.region?.titleUZ.split(' ')?.[0]}{' '}
                {data?.user?.region?.titleUZ.split(' ')?.[1][0]}.
              </div>
            </div>
            <div className="region-text">
              <div className="region-title">
                MFY:<DotCard width={'83px'}></DotCard>
              </div>
              <div className="region-value">{getDistrict()}</div>
            </div>
          </div>
        </div>
      </div>
      {activeCard[active]}
    </ScheduleCardWrap>
  );
};
