import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 32px;
    padding: 6px 11px;
    background-color: #f2cf3f;
  }

  & .ant-select-selection-search > input {
    height: 100% !important;
  }
`;
export const Title = styled.span`
  color: #fff;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
`;
