import React, { useState } from 'react';
import FiltersForm from './Form';
import { Wrapper } from './style';
import { Col, Row } from 'antd';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const Filters = ({
  filters = [],
  selectedFilters,
  handleFilters,
  height,
  handleChangeRangePicker,
  setPaginate,
  allowClear,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Wrapper open={open} height={height}>
      <button
        onClick={() => {
          setOpen((open) => !open);
        }}
        className="accardion__header"
      >
        <span>Filterlar</span>
        <span className="filter__btn" type="text">
          {open ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
        </span>
      </button>
      <div className="filters__body">
        <Row
          gutter={[10, 10]}
          // justify="space-between"
          className="filters__row"
          align="stretch"
        >
          {filters.map((item, ind) => (
            <Col
              key={ind}
              order={item?.order ?? ind}
              style={{ width: '100%' }}
              span={item.type === 'button' ? 5 : 12}
              md={item.type === 'button' ? 5 : 12}
              lg={item.type === 'button' ? 5 : 10}
              xl={item.type === 'button' ? 4 : 8}
              xxl={item.type === 'button' ? 3 : 5}
            >
              <FiltersForm
                allowClear={allowClear}
                handleFilter={handleFilters}
                selectedFilters={selectedFilters}
                item={item}
                handleChangeRangePicker={handleChangeRangePicker}
                setPaginate={setPaginate}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Wrapper>
  );
};

export default React.memo(Filters);
