import styled from 'styled-components';

export const ModalBodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px;
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;

  & .ant-select {
    max-width: 100% !important;
    & .ant-select-selector {
      min-height: 44px;
    }

    & .ant-select-clear {
      right: 10px;
    }
    & .ant-select-selection-overflow {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid rgba(140, 151, 171, 0.3);
  }

  & .file-upload {
    width: 100%;
    height: 44px;
    border-radius: 12px;
    border: 1px solid rgba(140, 151, 171, 0.3);
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    transition: all 0.3s;
    :hover {
      border-color: #9a9aeb;
      outline: 0;
      box-shadow: 0 0 0 2px #e9e9fa;
    }
    & label {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      color: #8c97ab;
      font-family: 'Segoe UI';
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      cursor: pointer;
      & img {
        width: 20px;
        height: 20px;
      }
    }
    & input {
      display: none;
    }
  }

  & .ant-input {
    border-radius: 12px;
    height: 44px;
    :focus,
    :hover {
      border-color: #9a9aeb;
      outline: 0;
      box-shadow: 0 0 0 2px #e9e9fa;
    }
  }
`;
export const Label = styled.div`
  color: #8c97ab;
  font-family: 'Segoe UI';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`;

export const ImageUploadWrap = styled.div`
  display: flex;
  gap: 16px;

  & .image-upload {
    width: 96px;
    height: 96px;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #eee;

    & .ant-image {
      width: 100%;
      height: 100%;
      & img {
        width: 100%;
        height: 100%;
      }
    }

    & label {
      width: 100%;
      height: 100%;
      cursor: pointer;
      border: 1px solid rgba(140, 151, 171, 0.3);
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      object-fit: cover;
    }

    & label img {
      cursor: pointer;
      width: 32px;
      height: 32px;
      object-fit: cover;
    }
    & input {
      display: none;
    }
  }

  & .right-section {
    height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & .right-section-label {
      color: #8c97ab;
      font-family: 'Segoe UI';
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
    & .right-action {
      display: flex;
      align-items: center;
      gap: 40px;
    }
    & .editButton {
      color: #00826C;
    }
    & .deleteButton {
      color: #ff6060;
    }
    & .action {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      & img {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 46px;
  padding-top: 5px;

  & .submit-button {
    width: 124px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: #00826C;
    color: #fff;
    font-family: 'Segoe UI';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    outline: none;
    border: none;
    cursor: pointer;
  }
  & .cancel-button {
    color: #080936;
    font-family: 'Segoe UI';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    background: none;
    outline: none;
    cursor: pointer;
    border: none;
  }
  & .submit-button[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
    cursor: no-drop;
  }
`;
