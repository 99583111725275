import React from 'react';
import { Header, SideBarButton, TitleContainer } from './style';
import Logo from '../../Logo';
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { setDesktopCollapsed } from 'redux/modules/application/actions';
import { useWindowSize } from 'hooks/useDevice';
import * as devices from '../../../constants/devices';

const SidebarHeader = () => {
  const desktopCollapsed = useSelector(
    ({ appReducer }) => appReducer.desktopCollapsed
  );
  const dispatch = useDispatch();
  const { device } = useWindowSize();

  const handleClick = () => {
    dispatch(setDesktopCollapsed(!desktopCollapsed));
  };

  return (
    <Header>
      <TitleContainer>
        <Logo
          onClick={() => {
            console.log('Click logo');
          }}
        />
      </TitleContainer>

      <div className="header-line">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="234"
          height="2"
          viewBox="0 0 234 2"
          fill="none"
        >
          <path d="M0 1H234" stroke="url(#paint0_linear_21_4808)" />
          <defs>
            <linearGradient
              id="paint0_linear_21_4808"
              x1="0"
              y1="1"
              x2="231.743"
              y2="1"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#E0E1E2" stopOpacity="0" />
              <stop offset="0.5" stopColor="#E0E1E2" />
              <stop offset="1" stopColor="#E0E1E2" stopOpacity="0.15625" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      {device === devices.DESKTOP && (
        <SideBarButton onClick={handleClick}>
          {desktopCollapsed ? (
            <MdKeyboardDoubleArrowRight size={18} />
          ) : (
            <MdKeyboardDoubleArrowLeft size={18} />
          )}
        </SideBarButton>
      )}
    </Header>
  );
};

export default SidebarHeader;
