import React from 'react';
import { TabButton } from './style';
import { scheduleColor } from 'constants/colors';

export default ({ data, active, setTabActive }) => {
  return (
    <TabButton
      onClick={() => setTabActive(data?.status)}
      color={scheduleColor[data?.status]}
      active={active}
    >
      {data?.title}
    </TabButton>
  );
};
