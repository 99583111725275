import { Popover, Segmented } from 'antd';
import { Button, MapLayerWrap } from './style';
import MapLayerIcon from '../../../assets/mapLayer.svg';
import MapLayerIconSpecial from '../../../assets/mapLayerSpecial.svg';

export default ({ value, setValue, placement, special }) => {
  const content = (
    <MapLayerWrap>
      <Segmented
        options={[
          { label: 'Sxema', value: 'map' },
          { label: "Suniy yo'ldosh", value: 'satellite' },
          { label: 'Gibrid', value: 'hybrid' },
        ]}
        value={value}
        onChange={setValue}
      />
    </MapLayerWrap>
  );

  return (
    <>
      <Popover
        content={content}
        trigger="click"
        overlayClassName="custom-popover-wrap"
        placement={placement ? placement : 'bottom'}
      >
        <Button $special={special}>
          <img src={special ? MapLayerIconSpecial : MapLayerIcon} alt="" />
        </Button>
      </Popover>
    </>
  );
};
