import React from 'react';
import { getColor } from '../../../../utils/getColor';
import { Wrapper, Badge, BadgeContainer } from './style';

export default ({ value, userRank,  column = false }) => {
  return (
    <>
      {userRank || userRank === 0 ? (
        <Wrapper column={column}>
          {/* <BadgeContainer>
            <span
              style={{
                fontStyle: "italic",
                fontSize: "12px",
              }}
            >
              Рейтинг:
            </span>
            <Badge value={getColor(userRank)}>{userRateType}</Badge>
          </BadgeContainer> */}
          <span
            style={{
              fontFamily: 'Segoe UI',
              fontSize: '15px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              letterSpacing: '0.3px',
            }}
          >
            {value}
          </span>
        </Wrapper>
      ) : (
        <span
          style={{
            fontFamily: 'Segoe UI',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '0.3px',
          }}
        >
          {value}
        </span>
      )}
    </>
  );
};
