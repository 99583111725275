import React, { useEffect, useState } from 'react';
import { Answers, Label, TestBlock, TestCreate } from './style';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import TextArea from 'components/FormElements/TextArea';
import { Input, Radio } from 'antd';
import { Button } from '../style';
import { FiPlus } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from 'components/Spinner';
import { useShowAlert } from 'hooks';
import book from 'services/book';
import Select from 'components/FormElements/Select';
import { MdKeyboardBackspace } from 'react-icons/md';

export default () => {
  const [sectionId, setSectionId] = useState('');
  const [quizCount, setQuizCount] = useState('');
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const { error } = useShowAlert();
  const { bookId } = useLocation().state;
  const [value, setValue] = useState([
    {
      title: '',
      answerOptions: [
        {
          title: '',
          isCorrect: false,
        },
        {
          title: '',
          isCorrect: false,
        },
        {
          title: '',
          isCorrect: false,
        },
      ],
    },
  ]);

  const [status, setStatus] = useState(false);

  const handleAddTest = () => {
    const newValue = {
      title: '',
      answerOptions: [
        {
          title: '',
          isCorrect: false,
        },
        {
          title: '',
          isCorrect: false,
        },
        {
          title: '',
          isCorrect: false,
        },
      ],
    };
    setValue((prev) => [...prev, newValue]);
  };

  const handleDeleteTest = (testId) => {
    const newValue = value?.filter((_, index) => index !== testId);
    setValue(() => newValue);
  };

  const handleTestTitle = (testId, e) => {
    const newValue = [...value];
    newValue[testId].title = e?.target?.value;
    setValue(() => newValue);
  };

  const handleAnswerTitle = (testId, answerId, e) => {
    const newValue = [...value];
    newValue[testId].answerOptions[answerId].title = e.target?.value;
    setValue(() => newValue);
  };

  const handleAnswerIsCorrect = (testId, answerId) => {
    const newValue = [...value];
    const newAnswers = newValue[testId].answerOptions?.map((itm, index) => ({
      ...itm,
      isCorrect: index === answerId ? true : false,
    }));
    newValue[testId].answerOptions = [...newAnswers];
    setValue(() => newValue);
  };

  const handleSubmit = () => {
    const validateTitle = value?.filter((itm) => itm?.title?.length <= 2);
    const validateAnswer = value?.map((itm) =>
      itm?.answerOptions.filter((val) => val?.title?.length <= 2)
    );
    const validateAnswerCheck = value?.map((itm) =>
      itm?.answerOptions.filter((val) => val?.isCorrect)
    );

    if (
      sectionId?.length >= 3 &&
      Number(quizCount) &&
      !validateTitle?.length &&
      !validateAnswer?.flat(1)?.length &&
      validateAnswerCheck?.flat(1)?.length === value?.length
    ) {
      const sentData = {
        questions: [...value],
        quizCount: Number(quizCount),
      };

      setLoading(true);
      sectionId &&
        book
          .createTestForSection(sectionId, sentData)
          .then(() => {
            navigate('/books');
          })
          .catch((err) => {
            error('Xatolik yuz berdi');
            console.log(err);
          })
          .finally(() => setLoading(false));
    } else {
      setStatus(true);
    }
  };

  useEffect(() => {
    if (bookId) {
      setLoading(true);
      book
        .getAllBookQuiz({ book: bookId })
        .then((res) => {
          setSections(res);
        })
        .catch((err) => {
          console.log(err);
          error('Xatolik yuz berdi');
        })
        .finally(() => setLoading(false));
    }
  }, [bookId]);

  useEffect(() => {
    if (sectionId) {
      setLoading(true);
      book
        .getOneBookQuiz(sectionId)
        .then((res) => {
          res?.questions?.length && setValue(() => res?.questions);
          setSectionId(res?._id);
          setQuizCount(res?.quizCount ?? '');
        })
        .catch((err) => {
          console.log(err);
          error('Xatolik yuz berdi');
        })
        .finally(() => setLoading(false));
    }
  }, [sectionId]);

  const handleSectionId = (e) => {
    setValue(() => [
      {
        title: '',
        answerOptions: [
          {
            title: '',
            isCorrect: false,
          },
          {
            title: '',
            isCorrect: false,
          },
          {
            title: '',
            isCorrect: false,
          },
        ],
      },
    ]);
    setQuizCount('');
    setSectionId(e);
  };

  if (loading) {
    return <Spinner maxHeight={'100%'} />;
  }
  return (
    <TestCreate>
      <Button className="back-button" onClick={() => navigate(-1)}>
        <MdKeyboardBackspace size={25} color="white" />{' '}
      </Button>
      <div className="header">
        <div className="header-section">
          <div className="select-wrap">
            <Label>Kitob bobini tanlang</Label>
            <Select
              options={sections}
              onChange={(e) => handleSectionId(e)}
              value={sectionId}
              status={!sectionId?.length && status ? 'error' : undefined}
            />
          </div>
          <div className="input-wrap">
            <Label>Testlar soni</Label>
            <Input
              onChange={(e) =>
                (Number(e?.target.value) > 0 || e.target.value === '') &&
                setQuizCount(e.target.value)
              }
              value={quizCount}
              status={!Number(quizCount) && status ? 'error' : undefined}
            />
          </div>
        </div>
        <Button className="add-btn" onClick={handleSubmit}>
          Saqlash
        </Button>
      </div>
      <div className="blocks">
        {value?.map((item, index) => (
          <TestBlock key={index}>
            <div className="test-header">
              <div className="title-number">Test {index + 1}</div>
              {value?.length > 1 ? (
                <div
                  className="delete-icon"
                  onClick={() => handleDeleteTest(index)}
                  aria-hidden
                >
                  <DeleteIcon />
                </div>
              ) : null}
            </div>
            <div className="test-body">
              <TextArea
                descriptionValue={item?.title}
                handleDescription={(e) => handleTestTitle(index, e)}
                placeholder={'Savol'}
                status={item?.title?.length <= 2 && status}
              />
              <Radio.Group
                className="answers"
                value={
                  item?.answerOptions.findIndex((val) => val?.isCorrect) ?? ''
                }
              >
                {item?.answerOptions?.map((itm, idx) => (
                  <Answers
                    key={idx}
                    status={
                      status &&
                      item?.answerOptions.findIndex((val) => val?.isCorrect) ===
                        -1
                    }
                  >
                    <Radio
                      id={`radio${idx + 1}`}
                      value={idx}
                      onChange={() => handleAnswerIsCorrect(index, idx)}
                      status="error"
                    />
                    <div className="answer-input">
                      <Input
                        placeholder={`Variant ${idx + 1}`}
                        value={itm?.title}
                        onChange={(e) => handleAnswerTitle(index, idx, e)}
                        status={
                          status && itm?.title?.length <= 2
                            ? 'error'
                            : undefined
                        }
                      />
                    </div>
                  </Answers>
                ))}
              </Radio.Group>
            </div>
          </TestBlock>
        ))}
        <div>
          <Button className="add-test" onClick={handleAddTest}>
            <FiPlus size={20} color="white" /> <span>Savol qo’shish</span>
          </Button>
        </div>
      </div>
    </TestCreate>
  );
};
