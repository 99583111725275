import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Empty, Input, Pagination, Row, Switch } from 'antd';
import {
  ResultsContent,
  FilterContainer,
  SearchInput,
  ResultInfo,
  NoData,
  SearchPageWrapper,
} from './style';
import works from '../../../services/works';
import { useSelector } from 'react-redux';
import Spinner from '../../../components/Spinner';
import { useShowAlert } from '../../../hooks';
import { FiSearch, FiX } from 'react-icons/fi';
import WorkCard from 'components/Cards/WorkCard';
import { useFilter } from 'views/useFilter';
import DynamicFilters from 'components/DynamicFilters';
import Select from 'components/FormElements/Select';
import RangePicker from 'components/RangePicker';
import moment from 'moment';
import { Title } from 'components/FormElements/Select/style';

export default () => {
  const { role } = useSelector((state) => state.authReducer);
  const { selectedDate } = useSelector((state) => state.selectedDateReducer);

  const {
    handleFilters,
    selectedFilters,
    setSelectedFilters,
    categories,
    provinces,
    regions,
    objects,
    supportTypes,
    works: worksList,
  } = useFilter({ withObject: true });

  const { error } = useShowAlert();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [worksData, setWorksData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [search, setSearch] = useState('');
  const [cardType, setCardType] = useState('small');
  const [bool, setBool] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);

  const [reportCount, setReportCount] = useState({
    worksTotal: 0,
    participant: 0,
    helpedCount: 0,
  });

  const screenChecker = (innerWidth) => {
    setScreenWidth(innerWidth);
    if (
      (innerWidth >= 2200 && innerWidth <= 2900) ||
      (innerWidth < 1709 && innerWidth >= 1336)
    ) {
      setCardType('large');
    } else {
      setCardType('small');
    }
  };

  useEffect(() => {
    screenChecker(window.innerWidth);
    const handleResize = () => screenChecker(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const categoryAll = categories.filter((c) => c.parent === null);
    setCategoryList(categoryAll);
  }, [categories]);

  useEffect(() => {
    if (selectedFilters?.onlineMonitoring) {
      fetchWorksMonitoring();
      setPageNum(() => 1);
    } else {
      fetchWorks(pageNum, pageSize);
    }
  }, [pageNum, pageSize, bool, selectedFilters?.onlineMonitoring]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const onShowSizeChange = (_, pageSize) => {
    setPageSize(pageSize);
  };

  const handleChangeRangePicker = (_, value) => {
    selectedFilters.startDate = value[0];
    selectedFilters.endDate = value[1];
  };
  const fetchWorks = useCallback(
    (page, limit) => {
      if (search.length > 0 || Object.keys(selectedFilters).length > 0) {
        setLoading(true);
        const { categories, works: subCategory, ...rest } = selectedFilters;
        const data = {
          page: page,
          limit: limit,
          status: 3,
          ...rest,
          category: categories,
          subCategory: subCategory,
        };
        delete data['onlineMonitoring'];

        if (
          !selectedDate.every((item) => item === '') &&
          selectedDate[0] !== 'Invalid date' &&
          selectedDate[1] !== 'Invalid date'
        ) {
          data['startDate'] = selectedDate[0];
          data['endDate'] = selectedDate[1];
        }
        if (search?.length > 0) {
          data['search'] = search;
        }
        works
          .getAll(data)
          .then(({ docs, totalDocs, participants, helped }) => {
            setReportCount((prev) => ({
              ...prev,
              helpedCount: helped,
              participant: participants,
              worksTotal: totalDocs,
            }));
            setWorksData(docs);
          })
          .finally(() => setLoading(false))
          .catch((err) => console.error(err));
      } else {
        error(
          "Iltimos qidiruv uchun avval maydonlarni to'ldirilganligini tekshiring",
          2,
          'topRight'
        );
      }
    },
    [pageNum, pageSize, search, selectedFilters, selectedDate]
  );

  const fetchWorksMonitoring = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { categories, works: subCategory, support } = selectedFilters;
      const data = {
        distance: 100,
        support,
      };
      if (categories || subCategory) {
        data['category'] = categories;
        data['subCategory'] = subCategory;
      }
      if (
        !selectedDate.every((item) => item === '') &&
        selectedDate[0] !== 'Invalid date' &&
        selectedDate[1] !== 'Invalid date'
      ) {
        data['startDate'] = selectedDate[0];
        data['endDate'] = selectedDate[1];
      }
      if (search?.length > 0) {
        data['search'] = search;
      }
      if (position.coords?.latitude || position.coords?.longitude) {
        data['lat'] = parseFloat(position.coords?.latitude);
        data['lng'] = parseFloat(position.coords?.longitude);
      }
      setLoading(true);
      works
        .getAllNearDistance(data)
        .then(({ works, participants, helped }) => {
          setReportCount((prev) => ({
            ...prev,
            helpedCount: helped,
            participant: participants,
            worksTotal: works?.length ?? 0,
          }));

          setWorksData(works);
        })
        .catch((err) => {
          error('Xatolik yuz berdi', 2, 'topRight');
          console.error(err);
        })
        .finally(() => setLoading(false));
    });
  };

  const handleSearchFilter = () => {
    setPageNum(() => 1);
    fetchWorks(1, pageSize);
  };

  const filters = [
    {
      label: 'Viloyat',
      name: 'province',
      placeholder: 'Viloyatni tanlang...',
      type: 'select',
      options: provinces,
      permission: ['leader'],
      span: 12,
      hide: selectedFilters?.onlineMonitoring,
    },
    {
      label: 'Shahar-tuman',
      placeholder: 'Tuman(Shahar) tanlang...',
      name: 'region',
      type: 'select',
      options: regions,
      permission: ['leader', 'proleader'],
      span: 12,
      hide: selectedFilters?.onlineMonitoring,
    },
    {
      label: 'MFY',
      name: 'district',
      placeholder: 'MFYni tanlang...',
      type: 'select',
      options: objects,
      permission: ['leader', 'proleader', 'regleader'],
      span: 12,
      hide: selectedFilters?.onlineMonitoring,
    },
    {
      label: 'Sana',
      name: 'date',
      type: 'datepicker',
      permission: ['leader', 'proleader', 'regleader', 'advanced'],
      span: 12,
    },
    {
      label: 'Onlayn monitoring',
      name: 'onlineMonitoring',
      type: 'switch',
      permission: ['leader', 'proleader', 'regleader', 'advanced'],
      // span: 12,
      order: selectedFilters?.onlineMonitoring
        ? 6
        : role === 'proleader'
        ? 8
        : role === 'regleader'
        ? 7
        : 9,
    },
    {
      label: 'Sohalar',
      name: 'categories',
      placeholder: 'Sohani tanlang...',
      type: 'select',
      options: categoryList,
      permission: ['leader', 'proleader', 'regleader', 'advanced'],
      span: 12,
    },
    {
      label: 'Ishlar',
      placeholder: 'Ishni tanlang...',
      name: 'works',
      type: 'select',
      options: worksList,
      permission: ['leader', 'proleader', 'regleader', 'advanced'],
      span: 12,
    },
    {
      label: 'Yordam',
      name: 'support',
      placeholder: 'Yordam turini tanlang...',
      type: 'select',
      options: supportTypes,
      permission: ['leader', 'proleader', 'regleader', 'advanced'],
      span: 12,
    },

    {
      label: 'Qidirish',
      name: 'search',
      placeholder: 'Matn kiriting...',
      type: 'search',
      permission: ['leader', 'proleader', 'regleader', 'advanced'],
      span: 12,
      inMobile: true,
    },
    {
      label: 'Tozalash',
      icon: FiX,
      name: 'button',
      type: 'button',
      className: 'clear__btn',
      onClick: () => handleClearFilters(),
      permission: ['leader', 'proleader', 'regleader', 'advanced'],
      span: 6,
      inMobile: true,
    },
    {
      label: 'Qidirish',
      icon: FiSearch,
      name: 'button',
      type: 'button',
      className: 'btn-primary',
      onClick: () =>
        selectedFilters?.onlineMonitoring
          ? fetchWorksMonitoring()
          : handleSearchFilter(),
      permission: ['leader', 'proleader', 'regleader', 'advanced'],
      span: 6,
      inMobile: true,
    },
  ];

  const searchRsult = {
    commonResult: {
      title: 'Qidiruv natijalari',
      value: reportCount?.worksTotal?.toLocaleString(),
    },
    participants: {
      title: 'Barcha qatnashchilar',
      value: reportCount?.participant?.toLocaleString(),
    },
    supportCount: {
      title: 'Yordam ko’rsatilganlar soni',
      value: reportCount?.helpedCount?.toLocaleString(),
    },
  };

  const handleClearFilters = () => {
    setPageNum(() => 1);
    const { endDate, startDate, ...rest } = selectedFilters;
    setSelectedFilters({ endDate, startDate });
    setSearch('');
    if (Object.keys(rest).length !== 0 || search.length !== 0) {
      setBool((prev) => !prev);
    }
  };
  const filteredByRelevance = useMemo(() => {
    return filters.filter((item) => item.permission?.includes(role));
  }, [filters, role]);

  return (
    <SearchPageWrapper>
      <FilterContainer>
        {screenWidth < 1762 ? (
          <div className="mobile__filters">
            <DynamicFilters
              height={'500px'}
              selectedFilters={selectedFilters} // valueState
              handleFilters={handleFilters} // filter handler
              filters={filters?.filter(
                (item) => !item?.hide && item?.permission?.includes(role)
              )} // schema filters
              handleChangeRangePicker={handleChangeRangePicker} // range picker uchun
            />
          </div>
        ) : (
          <Row gutter={[20, 20]} align="bottom">
            {filteredByRelevance.map(
              (item) =>
                item.permission.includes(role) &&
                !item.hide &&
                !item.inMobile && (
                  <Col
                    flex={'260px'}
                    key={item.name}
                    style={{ width: '100%' }}
                    className="select-container"
                  >
                    {item.type === 'select' ? (
                      <Select
                        // status={mustBeSelect ? 'error' : ''}
                        handleChange={(value) => {
                          handleFilters(item?.name, value);
                        }}
                        options={item?.options}
                        name={item?.name}
                        defaultVal={selectedFilters?.[item?.name]}
                        title={item?.label}
                        placeholder={item.placeholder}
                      />
                    ) : item.type === 'datepicker' ? (
                      <RangePicker
                        allowClear={false}
                        style={{ width: '100%' }}
                        direction={'column'}
                        onChange={handleChangeRangePicker}
                        defaultValue={[
                          moment(selectedFilters?.startDate),
                          moment(selectedFilters?.endDate),
                        ]}
                      />
                    ) : item.type === 'switch' ? (
                      <div className="switch__item">
                        <label htmlFor="switch">
                          <Switch
                            id="switch"
                            checked={selectedFilters[item?.name]}
                            onChange={(e) => handleFilters(item.name, e)}
                          />
                          {item.label}
                        </label>
                      </div>
                    ) : null}
                  </Col>
                )
            )}
            <Col
              span={8}
              style={{
                display: 'flex',
                gap: '20px',
                alignItems: 'flex-end',
              }}
            >
              <SearchInput>
                <Title level={5}>Qidiruv</Title>
                <Input
                  placeholder="Matn bo'yicha qidiruv..."
                  allowClear
                  prefix={<FiSearch color="#CCCCCC" size={12} />}
                  className="search"
                  style={{
                    background: '#FFFFFF',
                    border: '1px solid rgba(0, 0, 0, 0.07)',
                    borderRadius: '10px',
                    height: '42px',
                    fontSize: '14px',
                    minWidth: '160px',
                  }}
                  onChange={handleSearch}
                  value={search}
                />
              </SearchInput>
              <Button
                className="search__btn action__btn"
                type="primary"
                onClick={() =>
                  selectedFilters?.onlineMonitoring
                    ? fetchWorksMonitoring()
                    : handleSearchFilter()
                }
              >
                <FiSearch color="#FFFFFF" size={20} />
                Qidirish
              </Button>
              <Button
                className="clear__btn action__btn"
                type="primary"
                onClick={handleClearFilters}
              >
                <FiX color="#FFFFFF" size={20} />
                Tozalash
              </Button>
            </Col>
          </Row>
        )}
      </FilterContainer>
      <ResultInfo>
        {Object.keys(searchRsult).map((key) => (
          <div key={key} className="result__item">
            <p className="title">{searchRsult[key].title}:</p>
            <p className="value">{searchRsult[key].value}</p>
          </div>
        ))}
      </ResultInfo>
      <ResultsContent noBorder={worksData?.length > 0 && true}>
        {loading ? (
          <Spinner maxHeight="calc(100vh - 400px)" />
        ) : worksData?.length > 0 ? (
          <>
            <div className="result__body">
              {worksData?.map((work, indx) => (
                <WorkCard key={`${indx + 1}`} data={work} size={cardType} />
              ))}
            </div>
          </>
        ) : (
          <NoData>
            <Empty
              description={<h1>Qidiruv bo`yicha natijalar topilmadi!</h1>}
            />
          </NoData>
        )}
      </ResultsContent>
      {!selectedFilters?.onlineMonitoring &&
        reportCount?.worksTotal > 10 &&
        reportCount?.worksTotal >= pageSize && (
          <div className="custom-pagination-wrapper pagination-footer">
            <Pagination
              current={pageNum}
              pageSize={pageSize}
              onShowSizeChange={onShowSizeChange}
              total={reportCount?.worksTotal}
              onChange={(page) => setPageNum(page)}
            />
          </div>
        )}
    </SearchPageWrapper>
  );
};
