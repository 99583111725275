import React, { useEffect, useState } from 'react';
import Table from '../../../components/Tables/ExpandedTable';
import { headerMaker } from '../../../components/Tables/ExpandedTable/helper';
import { useDispatch, useSelector } from 'react-redux';
import reports from '../../../services/reports';
import Spinner from '../../../components/Spinner';

import { setReportSubData } from '../../../redux/modules/application/actions';
import { makeAdvancedReport, makeReport } from '../../../utils/makeReport';
import { categoryHeaders } from '../../../utils/categoryHeader';
import { useShowModal } from '../../../hooks';
import works from '../../../services/works';
import { Col, Pagination, Row, Empty } from 'antd';
import { setModalLoading } from '../../../redux/modules/modals/actions';
import DatePicker from '../../../components/DatePicker';
import Select from '../../../components/FormElements/Select';
import { useFilter } from 'views/useFilter';
import WorkCard from 'components/Cards/WorkCard';
import { NoData } from './style';

export default () => {
  const { showBlured } = useShowModal();
  // const { page, modalWork } = useSelector((state) => state.modalWork);
  const dispatch = useDispatch();
  const { handleFilters, selectedFilters, categories, provinces, regions } =
    useFilter({ withObject: false });

  const { singleDate } = useSelector((state) => state.selectedDateReducer);
  const { role } = useSelector((state) => state.authReducer);
  const [reportsData, setReportsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [newHeader, setNewHeader] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [sort, setSort] = useState(true);

  const mainHeadCols = [
    {
      id: 1,
      Header: '№',
      accessor: 'id',
      show: true,
      width: 30,
    },
    {
      id: 3,
      Header: 'Viloyat',
      accessor: 'province',
      type: 'name',
      width: 200,
      show: ['leader'].includes(role) ? true : false,
      // ...(role === 'leader' && { type: 'name', width: 200 }),
    },
    {
      id: 4,
      Header: 'Tuman (Shahar)',
      type: 'name',
      width: 200,
      accessor: 'region',
      show: ['leader', 'proleader'].includes(role) ? true : false,
      // width: 250,
      // ...(role === 'proleader' && { type: 'name', width: 200 }),
    },
    {
      id: 5,
      Header: 'MFY',
      accessor: 'district',
      type: 'name',
      width: 200,
      show: ['leader', 'proleader', 'regleader'].includes(role) ? true : false,
      // ...(role === 'regleader' && { type: 'name', width: 200 }),
    },
    {
      id: 2,
      Header: 'Ism, Familiya',
      accessor: 'user_name',
      show: true,
      // width: 250,
      type: 'info',
    },
    {
      id: 6,
      Header: 'Jami',
      accessor: 'total',
      type: 'info',
      width: 50,
      show: true,
      ...(role !== 'advanced' && { sort: true }),
      align: 'center',
    },
  ];

  useEffect(() => {
    let newHeads = mainHeadCols;
    if (selectedFilters?.province) {
      newHeads = mainHeadCols.filter((item) => item.accessor !== 'province');
    }
    if (selectedFilters?.region) {
      newHeads = mainHeadCols.filter(
        (item) => item.accessor !== 'province' && item.accessor !== 'region'
      );
    }
    newHeads = newHeads.filter((item) => item.show);
    newHeads[1].type = 'name_rating';
    newHeads[1].width = 200;
    const foo = headerMaker(newHeads.concat(categoryHeaders(newHeader)));
    setHeaders(foo);
  }, [newHeader, selectedFilters]);

  const fetchRateReports = async () => {
    try {
      setLoading((prev) => !prev);
      const data = {
        province: selectedFilters.province,
        region: selectedFilters.region,
        date: singleDate,
        page: pageIndex,
        limit: pageSize,
        sort: sort ? -1 : 1,
      };
      // const pCats = categories.filter((i) => !i.parent);
      const reportData = await reports.getAllDaiyReport(data);
      const pCats =
        reportData &&
        reportData?.docs[0]?.categorys.map((i) => ({
          ...i.category,
          title: i?.category?.titleUZ,
        }));
      // console.log('Cats', pCats);
      const sortedData = pCats?.sort((a, b) => {
        return a._id.localeCompare(b._id);
      });
      setNewHeader(sortedData);
      setTotal(reportData?.totalDocs);
      const report = makeReport(
        reportData.docs,
        pCats,
        reportData.page * reportData.limit - pageSize,
        role
      );
      setReportsData(report.sort((a, b) => b.total.value - a.total.value));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const fetchAdvancedRateReports = async () => {
    try {
      setLoading(true);
      const data = {
        date: singleDate,
      };
      const reportData = await reports.getAdvancedReportsByDaily(data);
      const pCats = categories.filter((i) => !i.parent);
      setNewHeader(pCats);
      const report = makeAdvancedReport(reportData, pCats);
      setReportsData(report.sort((a, b) => b.total.value - a.total.value));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (singleDate && role !== 'advanced') {
      fetchRateReports();
    }
    if (singleDate && role === 'advanced') {
      fetchAdvancedRateReports();
    }
  }, [singleDate, selectedFilters, pageSize, pageIndex, sort]);

  const handleSelectCategory = async (id, userId) => {
    try {
      setLoadingSub(true);
      const data = {
        category: id,
        user: userId,
      };
      let resp;
      role === 'advanced'
        ? (resp = await reports.getAdvancedReportsByDailySub({
            ...data,
            date: singleDate,
          }))
        : (resp = await reports.getAllSubReport({
            ...data,
            startDate: singleDate,
            endDate: singleDate,
          }));

      const subCats = categories.filter((item) => item.parent === id);
      const hechSubCats = new Map();
      subCats.forEach((item) => hechSubCats.set(item._id, item.title));
      const report = resp?.map((item, idx) => {
        // const subCat = resp.find((itm) => itm.subCategory === item._id);
        return {
          id: idx + 1,
          name: hechSubCats.get(item?.subCategory) ?? '-',
          subCategoryId: item?.subCategory,
          total: item?.rate
            ? Math.min(Math.round(item?.rate), 100)
            : item?.rate,
          categoryId: id,
          user: userId,
          works: item?.works,
          groupId: Math.trunc(Math.random() * 100000 + Math.random() * 5000),
        };
      });

      dispatch(setReportSubData(report ? report : []));
      setLoadingSub(false);
    } catch (error) {
      setLoadingSub(false);
      console.log(error);
    }
  };

  const setWorksModal = async (data) => {
    dispatch(setModalLoading(true));
    try {
      const reqData = {
        works: data?.works,
      };
      // const common = { categories, regions };
      const resp = await works.getAllSubWorks(reqData);
      console.log(resp);
      // const workerData = constructorMethod(resp.docs, common);
      dispatch(setModalLoading(false));
      showBlured({
        title: 'Batafsil',
        withHeader: false,
        body: () => (
          <div style={{ padding: '20px', textAlign: 'center' }}>
            {resp?.length > 0 ? (
              <>
                <WorkCard data={resp[0]} />
                {/* <RatedWork
                  data={workerData[0]}
                  medium
                  borderLess
                  objectsList={[]}
                /> */}
                {/* <Pagination
                  current={page === 0 ? 1 : page}
                  pageSize={1}
                  onChange={onChangePage}
                  total={resp?.totalPages}
                  // size="large"
                /> */}
              </>
            ) : null}
          </div>
        ),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const filterItems = [
    {
      name: 'province',
      label: 'Viloyat',
      permissions: ['leader'],
      options: provinces,
    },
    {
      name: 'region',
      label: 'Tuman',
      options: regions,
      permissions: ['leader', 'proleader'],
    },
  ];

  const onShowSizeChange = (_, pageSize) => {
    setPageSize(pageSize);
  };

  if (loading) return <Spinner maxHeight={'100%'} />;
  return (
    <>
      <Row
        align="middle"
        justify="start"
        style={{ padding: '0px 0px 20px' }}
        gutter={20}
      >
        {filterItems.map(
          (item) =>
            item.permissions.includes(role) && (
              <Col key={item.name}>
                <Select
                  title={item.label}
                  name={item.name}
                  id={item.name}
                  handleChange={(value) => handleFilters(item.name, value)}
                  value={selectedFilters?.[item.name]}
                  options={item.options || []}
                  placeholder="Hududni tanlang!"
                />
              </Col>
            )
        )}
        <Col>
          <div>
            <DatePicker />
          </div>
        </Col>
      </Row>
      {reportsData?.length ? (
        <>
          <Table
            headers={headers}
            selectCategory={handleSelectCategory}
            loading={loading}
            subLoading={loadingSub}
            setWorksModal={setWorksModal}
            data={reportsData}
            setSort={setSort}
            sort={sort}
          />
          {total > 10 && (
            <div className="custom-pagination-wrapper">
              <Pagination
                current={pageIndex}
                pageSize={pageSize}
                onShowSizeChange={onShowSizeChange}
                total={total}
                onChange={(page) => setPageIndex(page)}
              />
            </div>
          )}
        </>
      ) : (
        <NoData>
          <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
        </NoData>
      )}
    </>
  );
};
