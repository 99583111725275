import React from 'react';
import { ChartCard, ChartCardHeader } from './style';
import Dropdown from 'components/FormElements/Dropdown';
import { dashboardPieMonthColors } from 'constants/colors';
import { Pie } from '@ant-design/plots';

const ChartForMonthCard = ({
  value,
  monthList,
  currentMonth,
  getMonthlyAnalyst,
  monthlyAnalysis,
  monthlyReportData,
  statisticOfAppealsByMonth,
}) => {
  const listData = [
    {
      type: 'Umumiy rejalar',
      value: monthlyAnalysis?.not > 0 || monthlyAnalysis?.done > 0 ? 100 : 0,
      status: 3,
      color: dashboardPieMonthColors[1],
      count: monthlyAnalysis?.total,
    },
    ...monthlyReportData,
  ];

  return (
    <ChartCard>
      <ChartCardHeader>
        <div className="card__title">
          <Dropdown
            options={monthList()}
            defaultValue={value ? String(value) : String(currentMonth)}
            handleMonth={getMonthlyAnalyst}
          />
          <div className="card__title_text">Oyidagi ish rejalar</div>
        </div>
      </ChartCardHeader>
      <div className="body__two__col">
        <div className="left__col">
          <div className="status__container">
            <div className="title__total__apeals">
              Umumiy rejalar soni:
              <div className="title__total__apeals_count">
                {monthlyAnalysis?.total ?? 0} ta
              </div>
            </div>
            <div className="apeals__status__list">
              {listData?.map((item, index) => (
                <div
                  style={{
                    color: item?.color,
                  }}
                  className="apeals__status"
                  key={`${index + 1}`}
                >
                  <span className="status__label">{item?.type}</span>
                  <span className="percent">{item.value}%</span>
                  <span className="count">{item.count} ta</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        {(monthlyAnalysis?.not > 0 || monthlyAnalysis?.done > 0) && (
          <div className="right__col monthly__diagram">
            <Pie {...statisticOfAppealsByMonth} />
          </div>
        )}
      </div>
    </ChartCard>
  );
};

export default React.memo(ChartForMonthCard);
