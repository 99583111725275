import styled from 'styled-components';

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  height: calc(100vh - 400px);
`;
