import React from 'react';
import { Button, Checkbox, Switch } from 'antd';
import moment from 'moment';
import Select from 'components/FormElements/Select';
import TextInput from 'components/FormElements/TextInput';
import RangePicker from 'components/RangePicker';
import './styles.css';
import DatePicker from 'components/DatePicker';
import RangePickerSchedule from 'components/FormElements/RangePickerSchedule';

const DynamicForm = ({
  item,
  selectedFilters,
  handleFilter,
  handleChangeRangePicker,
  setPaginate,
  allowClear,
}) => {
  const { icon: Icon } = item;
  switch (item?.type) {
    case 'text':
      return (
        <div className="form__group">
          <label className="form__title" htmlFor={item.name}>
            {item.label}
          </label>
          <TextInput
            name={item.name}
            id={item.name}
            placeholder={item.placeholder}
            onChange={(e) => handleFilter(item.name, e.target.value)}
            value={selectedFilters[item.name]}
          />
        </div>
      );
    case 'select':
      return (
        <div className="form__group">
          <label className="form__title" htmlFor={item.name}>
            {item.label}
          </label>
          <Select
            name={item.name}
            id={item.name}
            placeholder={item.placeholder}
            onChange={(value) => handleFilter(item?.name, value)}
            value={selectedFilters[item.name]}
            options={item.options}
          />
        </div>
      );
    case 'date':
      return (
        <div className="form__group">
          {/* <label className="form__title" htmlFor={item.name}>
            {item.label}
          </label> */}
          <div className="date-picker">
            <DatePicker
              title={item.label}
              allowClear={allowClear}
              placeholder="Sanani belgilang"
              onChange={(_, dateString) =>
                handleFilter(item.name, moment(dateString).format('YYYY-MM-DD'))
              }
              format="YYYY-MM-DD"
              defaultValue={
                selectedFilters[item.name]
                  ? moment(selectedFilters[item.name]).format('YYYY-MM-DD')
                  : moment()
              }
              setPaginate={setPaginate}
            />
          </div>
        </div>
      );
    case 'datepicker':
      return (
        <div className="form__group">
          <label className="form__title" htmlFor={item.name}>
            {item.label}
          </label>
          <RangePicker
            titleHide={true}
            direction={'column'}
            onChange={handleChangeRangePicker}
            defaultValue={[
              moment(selectedFilters?.startDate),
              moment(selectedFilters?.endDate),
            ]}
            id={item.name}
            placeholder={item.placeholder}
            setPaginate={setPaginate}
          />
        </div>
      );
    case 'newDatepicker':
      return (
        <div className="form__group">
          <label className="form__title" htmlFor={item.name}>
            {item.label}
          </label>
          <RangePickerSchedule
            titleHide={true}
            direction={'column'}
            onChange={handleChangeRangePicker}
            defaultValue={[
              moment(selectedFilters?.startDate),
              moment(selectedFilters?.endDate),
            ]}
            id={item.name}
            placeholder={item.placeholder}
            setPaginate={setPaginate}
          />
        </div>
      );
    case 'switch':
      return (
        <div className="form__group switch__item">
          <Switch
            id="switch"
            checked={selectedFilters[item?.name]}
            name={item.name}
            onChange={(value) => handleFilter(item?.name, value)}
          />
          <label className="form__title" htmlFor="switch">
            {item.label}
          </label>
        </div>
      );
    case 'checkbox':
      return (
        <div className="form__group">
          <label htmlFor="checkbox" className="form__title">
            <Checkbox
              id="checkbox"
              defaultChecked={selectedFilters[item.name]}
              name={item.name}
              onChange={handleFilter}
            />
            {item.label}
          </label>
        </div>
      );
    case 'search':
      return (
        <div className="form__group search__item">
          <label className="form__title" htmlFor={item.name}>
            {item.label}
          </label>
          <TextInput
            type={item.type}
            name={item.name}
            id={item.name}
            placeholder={item.placeholder}
            onChange={(e) => handleFilter(item.name, e.target?.value)}
            value={selectedFilters[item.name]}
          />
        </div>
      );
    case 'button':
      return (
        <Button
          className={`${item?.className} action__btn`}
          type="primary"
          onClick={item?.onClick}
        >
          {Icon && <Icon size={20} />}
          {item?.label}
        </Button>
      );

    default:
      break;
  }
};

export default DynamicForm;
