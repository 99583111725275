import React, { useEffect, useState } from 'react';
import { UserAnswer, UserBlock } from './style';
import { Image } from 'antd';
import { useLocation } from 'react-router-dom';
import { useShowAlert } from 'hooks';
import quiz from 'services/quiz';
import Spinner from 'components/Spinner';
import Correct from './Answers/Correct';
import InCorrect from './Answers/InCorrect';
import Avatar from '../../../../assets/avatar.png';

export default () => {
  const location = useLocation().state;
  const { error } = useShowAlert();

  const [loading, setLoading] = useState([]);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const query = `quiz=${location?.quizId}&user=${location?.userId}`;
      const resData = await quiz.getUserAnswer(query);
      setData(resData);
    } catch (err) {
      console.log(err);
      error('Xatolik yuz berdi');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location?.quzId]);

  if (loading) {
    return <Spinner maxHeight={'calc(100% - 50px)'} />;
  }
  return (
    <UserBlock>
      <UserAnswer>
        <div className="block-header">
          <div className="user-image">
            <Image
              src={data?.user?.avatar ?? Avatar}
              alt={data?.user?.avatar}
            />
          </div>
          <div className="username">
            {data?.user?.firstName ?? ''} {data?.user?.lastName ?? ''}{' '}
            {data?.user?.middleName ?? ''}
          </div>
          <div className="quiz-title">
            Mavzu bo’yicha test savollari natijasi
          </div>
          <div className="answer-percent">{data?.trueAnswersPercent}%</div>
        </div>
        <div className="block-body">
          <div className="result-count">
            {data?.totalQuestions}/<span>{data?.trueAnswers}</span>
          </div>
          <div className="answers">
            {data?.questions?.map((itm, index) => (
              <React.Fragment key={index}>
                {itm?.isCorrect ? (
                  <Correct item={itm} id={index + 1} />
                ) : (
                  <InCorrect item={itm} id={index + 1} />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </UserAnswer>
    </UserBlock>
  );
};
