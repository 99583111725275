import React, { useState } from 'react';
import WithDays from './WithDays';
import Default from './Default';
import { BtnWrap, ButtonWrapper } from './style';
import { Button } from 'antd';
import WithWeekDay from './WithWeekDay';

export default ({ id, data, handleRep }) => {
  const [activeTab, setActiveTab] = useState(data?.type ? data?.type : 'repet');

  const activeContent = {
    repet: (
      <Default
        id={id}
        handleRep={handleRep}
        data={data?.type === 'repet' ? data : {}}
      />
    ),
    week: (
      <WithWeekDay
        id={id}
        handleRep={handleRep}
        data={data?.type === 'week' ? data : {}}
      />
    ),
    days: (
      <WithDays
        id={id}
        handleRep={handleRep}
        data={data?.type === 'days' ? data : {}}
      />
    ),
  };

  const buttonList = [
    {
      key: 'repet',
      title: 'Takroriylik',
    },
    {
      key: 'week',
      title: 'Hafta kunlari',
    },
    {
      key: 'days',
      title: 'Oy kunlari',
    },
  ];

  return (
    <div>
      <ButtonWrapper>
        {buttonList?.map((item, index) => (
          <BtnWrap
            key={index}
            background={activeTab == item?.key ? 'true' : 'false'}
          >
            <Button type="primary" onClick={() => setActiveTab(item?.key)}>
              {item?.title}
            </Button>
          </BtnWrap>
        ))}
      </ButtonWrapper>
      <div>{activeContent[activeTab]}</div>
    </div>
  );
};
