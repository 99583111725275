import styled from 'styled-components';
import { Button as BTN } from 'antd';

export const BooksWrapper = styled.div`
  width: 100%;
  padding-top: 30px;

  & .pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 40px 10px 0px;

    & .ant-pagination-item {
      border-radius: 6px;
      :hover {
        border-color: #00826C !important;
        & a {
          color: #00826C !important;
        }
      }
    }
    & .ant-pagination-item-active {
      border: 1px solid #00826C;
      & a {
        color: #00826C !important;
      }
    }
    .ant-pagination-prev button {
      border-radius: 6px !important;
      :hover {
        border-color: #00826C !important;
        & svg {
          color: #00826C !important;
        }
      }
    }
    .ant-pagination-next button {
      border-radius: 6px !important;
      :hover {
        border-color: #00826C !important;
        & svg {
          color: #00826C !important;
        }
      }
    }
    .ant-pagination-disabled button {
      :hover {
        border-color: #d9d9d9 !important;
        & svg {
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }
    }

    & .ant-select {
      width: 125px !important;
      & .ant-select-selector {
        min-width: 100% !important;
        min-height: 32px !important;
        border-radius: 6px !important;
        height: 100%;
        color: #080936;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        .ant-select-selection-item {
          margin-right: 0px !important;
        }
        & .ant-select-arrow {
          margin-top: 12px !important;
        }
      }
    }
  }
`;

export const AddContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-bottom: 20px;
`;

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  height: calc(100vh - 400px);
`;

export const DeleteWrapper = styled.div`
  padding: 20px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  & .delete-title {
    color: #080936;
    text-align: center;
    font-family: 'Segoe UI';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-align: center;
  }
  & .delete-text {
    color: rgba(8, 9, 54, 1);
    font-family: 'Segoe UI';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-align: center;
  }

  & .buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 17px;
    padding-top: 16px;
    & .button-submit {
      border-radius: 8px;
      background: #fc6666;
    }
    & .cancel-button {
      border-radius: 8px;
      background: #00826C;
    }
    & button {
      width: 67px;
      height: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: 'Segoe UI';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
`;

export const Button = styled(BTN)`
  height: 44px;
  width: ${({ width }) => width && width};
  background: ${({ background }) => (background ? background : '#00c4fa')};
  border-radius: 12px;
  padding: 0 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4.5px;
  font-size: 15px;
  :hover {
    background: #33d3ff;
  }
`;
