import { Button } from 'antd';
import { ActionButton } from './style';
import * as Yup from 'yup';
import { FiEdit } from 'react-icons/fi';
import moment from 'moment';

export const buttons = [
  {
    id: 1,
    title: 'Баҳоланмаган',
    value: 2,
  },
  {
    id: 2,
    title: 'Баҳоланган',
    value: 3,
  },
];

export const validationSchema = Yup.object().shape({
  date: Yup.array().min(1, 'Тўлдириш мажбурий'),
  cause: Yup.string()
    .required('Тўлдириш мажбурий')
    .min(10, 'Минимум 10 таҳлиф берилди')
    .max(100, 'Максимум 100 таҳлиф берилди'),
});

export const getColumn = ({ handleClick }) => {
  const userTableHeader = [
    {
      id: 0,
      Header: '№',
      accessor: 'indx',
      show: true,
      width: 50,
    },
    {
      id: 1,
      Header: 'F.I.SH',
      accessor: 'fullname',
      show: true,
      width: 250,
    },
    {
      id: 2,
      Header: 'TUMAN',
      accessor: 'regionName',
      align: 'center',
      show: true,
      width: 300,
    },
    {
      id: 3,
      Header: 'MFY',
      accessor: 'districtName',
      show: true,
      width: 300,
    },
    {
      id: 4,
      Header: 'XOLATI',
      accessor: 'inActive',
      show: true,
      width: 80,
    },
    {
      id: 5,
      Header: 'TATIL KUNLARI',
      accessor: 'date',
      show: true,
      width: 200,
      align: 'center',
    },
    {
      id: 6,
      Header: 'SABABI',
      accessor: 'cause',
      show: true,
      width: 250,
      align: 'center',
    },
    {
      id: 7,
      Header: 'XARAKATLAR',
      show: true,
      width: 100,
      Cell: ({ row: { original } }) => {
        return (
          <ActionButton>
            <Button
              className="edit-btn"
              onClick={() => handleClick(original._id)}
              shape="circle"
              icon={<FiEdit size={25} />}
            ></Button>
          </ActionButton>
        );
      },
    },
  ];
  return userTableHeader;
};
const isDateValid = (date) => {
  const today = moment().startOf('day');
  const inputDate = moment(date).startOf('day');

  return inputDate.isSameOrAfter(today);
};
export const getEventsColumn = ({ handleClick }) => {
  const userTableHeader = [
    {
      id: 0,
      Header: '№',
      accessor: 'indx',
      show: true,
      width: 50,
    },
    {
      id: 1,
      Header: 'Tadbir nomi',
      accessor: 'title',
      show: true,
      width: 300,
    },
    {
      id: 2,
      Header: 'Sana',
      accessor: 'date',
      align: 'center',
      show: true,
    },
    {
      id: 3,
      Header: 'Boshlanish vaqti',
      accessor: 'start',
      show: true,
    },
    {
      id: 4,
      Header: 'Tugash vaqti',
      accessor: 'end',
      show: true,
    },
    {
      id: 5,
      Header: 'XARAKATLAR',
      show: true,
      width: 100,
      Cell: ({ row: { original } }) => {
        return (
          isDateValid(original?.date) && (
            <ActionButton>
              <Button
                className="edit-btn"
                onClick={() => handleClick(original?._id)}
                shape="circle"
                icon={<FiEdit size={25} />}
              ></Button>
            </ActionButton>
          )
        );
      },
    },
  ];
  return userTableHeader;
};
export const checkListArr = Array.from({ length: 10 }, (_, i) => ({
  id: i + 1,
  titleRU: `Check ${i + 1}`,
  titleUZ: `Check ${i + 1}`,
  titleEN: `Check ${i + 1}`,
  checked: false,
  rate: Math.floor(Math.random() * 100) + 1,
}));

export const dataModalForWork = {};

export const MOCK_UserList = Array.from({ length: 10 }, (_, i) => ({
  fullname: 'Umarov Sherzod Davronovich',
  indx: i + 1,
  id: i + 1,
  regionName: 'Quva tumani',
  districtName: 'Kandabuloq MFY',
  region: {
    _id: Math.floor(Math.random() * 10000) + 1,
    titleUZ: 'Quva tumani',
    titleRU: 'Quva tumani',
    titleQQ: 'Quva tumani',
  },
  object: {
    _id: Math.floor(Math.random() * 10000) + 1,
    titleUZ: 'Kandabuloq MFY',
    titleRU: 'Kandabuloq MFY',
    titleQQ: 'Kandabuloq MFY',
  },
  inActive: 'faol',
}));
