import React from 'react';
import { TaskCardWrap } from './style';
import ImageUrl from '../../../assets/taskCard/image.svg';
import moment from 'moment';
import Video from '../../../assets/taskCard/video.svg';
import Text from '../../../assets/taskCard/text.svg';

const iconRender = (value) =>
  value?.withPhoto
    ? ImageUrl
    : value?.withVideo
    ? Video
    : value?.withText
    ? Text
    : Text;

export default ({ handleClick, task, noClick }) => {
  return (
    <TaskCardWrap
      onClick={() => !noClick && handleClick(task)}
      noclick={noClick ? 'true' : 'false'}
    >
      <div className="card-header">
        <div className="image-wrapper">
          <img src={iconRender(task)} alt="" />
        </div>
        <div className="card-text-wrap">
          <div className="card-title">{task?.titleUZ}</div>
          <div className="card-text-list">
            <div className="list-item">Berilgan sana</div>
            <div className="list-item-date">
              {moment(task?.createdAt).format('DD.MM.YYYY')}
            </div>
          </div>
          {/* <div className="card-text-list">
            <div className="list-item">Muddat</div>
            <div className="list-item-day">3-kun</div>
          </div> */}
        </div>
      </div>
      <div className="card-footer">{task?.desc.slice(0, 90) + '...'}</div>
    </TaskCardWrap>
  );
};
