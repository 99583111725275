import { service } from '.';

export default {
  getAllPaginate: (data) => service.post('/regions/paginate', data),
  getAll: (data) => service.post('/regions/search', data),
  getOne: (id) => service.get(`/regions/${id}`),
  create: (data) => service.post(`/regions`, data),
  update: (id, data) => service.put(`/regions/${id}`, data),
  delete: (id) => service.delete(`/regions/${id}`),
};
