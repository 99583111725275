import React, { useEffect, useState } from 'react';
import { ModalContainer, Item, Label, Button, SwitchContainer } from '../style';
import Select from 'components/FormElements/Select';
import { Col, DatePicker, Input, Row, Switch } from 'antd';
import { useSelector } from 'react-redux';
import { leaderRoles, proleaderRoles, regleaderRoles } from '../helper';
import FileUploadNew from 'components/FormElements/FileUploadNew';
import moment from 'moment';
import Spinner from 'components/Spinner';

const { TextArea } = Input;

const SpecialModal = ({ handleClick }) => {
  const { provinces, regions } = useSelector((state) => state.appReducer);
  const { loading } = useSelector((state) => state.modalReducer);
  const [regionOptions, setRegionOptions] = useState(regions);

  const { role, provinceId, regionId } = useSelector(
    (state) => state.authReducer
  );
  const [postData, setPostData] = useState({ forReference: true });

  useEffect(() => {
    if (role === 'proleader') {
      setPostData({ ...postData, provinces: [provinceId] });
    }
    if (role === 'regleader') {
      setPostData({
        ...postData,
        provinces: [provinceId],
        regions: [regionId],
      });
    }
  }, []);

  const handleClickCreateTask = () => {
    if (
      postData?.titleUZ?.length > 3 ||
      postData.provinces?.length ||
      postData.date?.length ||
      postData.role?.length ||
      (postData.role !== 'proleader' && postData.regions?.length)
    ) {
      if (postData?.forReference) {
        handleClick({ ...postData });
      } else {
        if (postData?.withPhoto || postData?.withVideo || postData?.withText) {
          handleClick({ ...postData });
        }
      }
    }
  };

  const roleOptions = {
    leader: leaderRoles,
    proleader: proleaderRoles,
    regleader: regleaderRoles,
  };

  const validateForm = () => {
    if (postData?.forReference) {
      return true;
    } else {
      return postData?.withPhoto || postData?.withVideo || postData?.withText;
    }
  };

  const handleSelectProvince = (value) => {
    setPostData({ ...postData, ['provinces']: value });
    if (value.length > 0) {
      const filteredRegions = regions.filter((item) =>
        value.includes(item?.province?._id)
      );
      setRegionOptions(filteredRegions);
    } else {
      setRegionOptions(regions);
    }
  };
  return (
    <ModalContainer>
      {loading ? (
        <Spinner maxHeight="100%" />
      ) : (
        <>
          <Row gutter={[16, 24]}>
            <Col span={24}>
              <Label>
                Topshiriq nomi<span>*</span>
              </Label>
              <Item>
                <Input
                  onChange={(e) => {
                    setPostData({ ...postData, ['titleUZ']: e.target.value });
                  }}
                  value={postData?.titleUZ}
                />
              </Item>
            </Col>
            <Col span={24}>
              <Label>Topshiriq matni</Label>
              <Item>
                <TextArea
                  rows={4}
                  onChange={(e) => {
                    setPostData({ ...postData, ['desc']: e.target.value });
                  }}
                  value={postData?.desc}
                />
              </Item>
            </Col>
            <Col span={24} className="ant-clear-icon">
              <Label>
                Viloyat<span>*</span>
              </Label>
              <Select
                name="provinces"
                defaultVal={postData?.provinces}
                options={[{ _id: 'all', title: 'Barchasi' }, ...provinces]}
                handleChange={handleSelectProvince}
                multiple
                disabled={role === 'proleader' || role === 'regleader'}
              />
            </Col>
            {postData?.role !== 'proleader' && (
              <Col span={24} className="ant-clear-icon">
                <Label>Shahar-tuman{role !== 'leader' && <span>*</span>}</Label>
                <Select
                  name="regions"
                  options={[
                    { _id: 'all', title: 'Barchasi' },
                    ...regionOptions,
                  ]}
                  defaultVal={postData?.regions}
                  handleChange={(value) => {
                    setPostData({ ...postData, ['regions']: value });
                  }}
                  multiple
                  disabled={role === 'regleader'}
                />
              </Col>
            )}
            <Col span={12}>
              <Label>
                Kimlar uchun<span>*</span>
              </Label>
              <Select
                name="roles"
                defaultVal={postData.roles}
                options={roleOptions[role]}
                handleChange={(value) => {
                  setPostData({ ...postData, ['role']: value });
                }}
              />
            </Col>
            <Col span={12}>
              <Label>
                Sana <span>*</span>
              </Label>
              <div className="date-picker">
                <DatePicker
                  placeholder="Sanani belgilang"
                  onChange={(_, dateString) =>
                    setPostData({
                      ...postData,
                      ['date']: moment(dateString).format('YYYY-MM-DD'),
                    })
                  }
                />
              </div>
            </Col>
            <Col span={12}>
              <span>
                <FileUploadNew
                  handleUpload={(file) =>
                    setPostData({ ...postData, ['file']: file })
                  }
                  setPostData={setPostData}
                  postData={postData}
                  accept={
                    'image/jpeg, image/png, image/jpg, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  }
                />
              </span>
            </Col>
            <Col span={12}>
              <Switch
                checked={postData.forReference}
                onChange={(e) =>
                  setPostData({ ...postData, ['forReference']: e })
                }
              />
              <span style={{ marginLeft: '16px' }}>Ma’lumot uchun</span>
            </Col>
          </Row>
          {!postData.forReference && (
            <>
              <Label>Topshiriq javobini holati</Label>
              <Row gutter={[16, 24]}>
                <Col span={12}>
                  <SwitchContainer>
                    <Switch
                      checked={postData?.withText}
                      onChange={(e) =>
                        setPostData({ ...postData, ['withText']: e })
                      }
                    />
                    <span>Matn</span>
                  </SwitchContainer>
                  <SwitchContainer>
                    <Switch
                      checked={postData?.withPhoto}
                      onChange={(e) =>
                        setPostData({
                          ...postData,
                          ['withPhoto']: postData?.withVideo
                            ? !postData?.withVideo
                            : e,
                        })
                      }
                    />
                    <span>Rasm</span>
                  </SwitchContainer>
                  <SwitchContainer>
                    <Switch
                      checked={postData?.withVideo}
                      onChange={(e) =>
                        setPostData({
                          ...postData,
                          ['withVideo']: postData?.withPhoto
                            ? !postData?.withPhoto
                            : e,
                        })
                      }
                    />
                    <span>Video</span>
                  </SwitchContainer>
                </Col>
                {postData?.withPhoto && (
                  <Col>
                    <SwitchContainer>
                      <Switch
                        checked={postData?.telegram}
                        onChange={(e) =>
                          setPostData({ ...postData, ['telegramGroup']: e })
                        }
                      />
                      <span>Telegram guruhga tushishi</span>
                    </SwitchContainer>
                  </Col>
                )}
              </Row>
            </>
          )}
          <Row gutter={[16, 24]} justify="center">
            <Col>
              <Button
                type="primary"
                disabled={
                  !postData?.titleUZ?.length > 3 ||
                  !postData.provinces?.length ||
                  !postData.role?.length ||
                  (postData.role !== 'proleader' &&
                    !postData.regions?.length) ||
                  !postData.date?.length ||
                  !validateForm()
                }
                onClick={handleClickCreateTask}
                background="#00826C"
                width="250px"
              >
                Yuborish
              </Button>
            </Col>
          </Row>
        </>
      )}
    </ModalContainer>
  );
};

export default SpecialModal;
