import { Button, Modal } from 'antd';
import VideoPlayer from 'components/VideoPlayBack';
import React from 'react';
import './modal.css';

import { createPortal } from 'react-dom';
import { CloseButton, Wrapper } from './style';
import { GrClose } from 'react-icons/gr';
import { CloseOutlined } from '@ant-design/icons';

export default ({ url, setModalOpen, modalOpen }) =>
  createPortal(
    <Wrapper>
      <Modal
        title="Video"
        centered
        className="modal__body"
        width={1000}
        closable={false}
        open={modalOpen}
        footer={null}
      >
        <VideoPlayer url={url} />
        <CloseButton onClick={() => setModalOpen(!modalOpen)}>
          <CloseOutlined size={40} />
        </CloseButton>
      </Modal>
    </Wrapper>,
    document.getElementById('videoPlayback-root')
  );
