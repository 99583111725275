import { service } from '.';

export default {
  getAllForMap: (data) => service.post('/web/works/maps', data),
  getAll: (data) => service.post('/web/works/searchWorks', data),
  getSimilarWorksSearch: (data) =>
    service.post('/web/works/regsupervisor/search', data),

  getAllSpecialWorks: () => service.get('/specialWorks'),
  getSupervisorLeaderWorks: (data) => service.post('/showpublics/search', data),
  getAllOrganazations: () => service.get('/users/search'),
  getNewWorksAdvanced: (data) => service.post('/web/works/advanced/new', data),
  getSentWorksAdvanced: (data) =>
    service.post('/web/works/advanced/send', data),
  getWorksRegSupervisor: (data, type) =>
    service.post(`/web/works/regsupervisor/${type}`, data),
  updateNewWork: (id, data) =>
    service.put(`/web/works/advanced/update/${id}`, data),
  deleteNewWork: (id) => service.delete(`/web/works/advanced/delete/${id}`),

  getTotal: (data) => service.post('/mobilereports/sendnew', data),
  getWorkDays: (data) => service.post('/web/works/date', data),
  getAdvancedWorkDays: (data) => service.post('/web/works/advanced/date', data),
  getWorkDaysForSupervisorLeader: (data) =>
    service.post('/works/showpublicdate', data),
  getOne: (id) => service.get(`/works/${id}`),
  create: (data) => service.post(`/works`, data),
  update: (id, data) => service.put(`/web/works/${id}`, data),
  updateWorkRegSupervisor: (id, data) =>
    service.put(`/web/works/regsupervisor/update/${id}`, data),
  delete: (data) => service.post('/works/backup', data),
  deleteWork: (id) => service.delete(`/works/${id}`),
  deleteSiteWork: (id) => service.delete(`/showpublics/${id}`),
  deleteImage: (data) => service.post('/deleteFiles', data),
  deleteOldImage: (data) => service.post('/deleteFiles/old', data),
  createTag: (data) => service.post('/tags', data),

  // New API
  getAllSubWorks: (data) => service.post('/web/works/subsWorks', data),
  getOneWork: (id) => service.get(`/web/works/findOneWork/${id}`),

  // near distance

  getAllNearDistance: (data) => service.post('/web/works/nearDistance', data),
};
