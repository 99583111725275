import React from 'react';
import { StatusCard } from '../style';

export default ({ color, bgColor, data, tabActive }) => {
  return (
    <StatusCard color={color} bgcolor={bgColor ? 'true' : 'false'}>
      <div className="late-came">
        <div className="late-came-text">
          {tabActive === 7 ? 'Sababli kelmagan' : 'Kelmagan'}
        </div>
        <div className="late-came-count">{data?.count} marta</div>
      </div>
    </StatusCard>
  );
};
