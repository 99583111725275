import styled from 'styled-components';

export const DuplicateWrapper = styled.div`
  padding: 24px;
  .title {
    font-size: 24px;
    text-align: center;
  }
  .button-wrapper {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    align-items: center;
    padding-top: 20px;
    button {
      height: 44px;
      border-radius: 12px;
      background: #00826C;
      border: 1px solid #00826C;
      color: white;
      font-weight: 600;
      font-size: 15px;
      :first-child {
        background-color: #e4d5d5;
        border-color: #e4d5d5;
      }
    }
  }
`;
