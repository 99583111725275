import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

export default ({ color, children }) => (
  <Title
    style={{
      textAlign: 'center',
      fontSize: '20px',
      fontFamily: 'Segoe UI',
      fontWeight: '600',
      fontStyle: 'normal',
      color: color ? color : '#fff',
    }}
  >
    {children}
  </Title>
);
