import styled from 'styled-components';

export const PublishingWrapper = styled.div`
  padding: 24px;
  .checkbox-item {
    width: 100%;
    color: #080936;
    font-size: 16px;
    line-height: 22px;
    label {
      font-size: 16px;
      line-height: 22px;
    }
  }
  .button-wrapper {
    padding-top: 40px;
    display: flex;
    justify-content: flex-end;
    button {
      border-radius: 12px;
      background: #00826C;
      border: 1px solid #00826C;
      color: white;
      width: 113px;
      height: 45px;
      font-weight: 600;
      font-size: 15px;
    }
  }

  .regionLeader-wrapper {
    .title {
      font-size: 22px;
    }
    .button-wrapper {
      display: flex;
      gap: 15px;
      justify-content: flex-end;
      align-items: center;
      padding-top: 20px;
      button {
        height: 44px;
        border-radius: 12px;
        background: #00826C;
        border: 1px solid #00826C;
        color: white;
        font-weight: 600;
        font-size: 15px;
        :first-child {
          background-color: #e4d5d5;
          border-color: #e4d5d5;
        }
      }
    }
  }
`;
