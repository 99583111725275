import { Input } from 'antd';
import React from 'react';
import { Description } from '../style';

export default ({ title, onChange, value, workId, errorHelper }) => {
  return (
    <Description>
      <div className="descriptions">
        <div className="title">{title}</div>
        <div className="desc__text">
          <div className="remember">
            <p>
              Izoh:{''}
              <span>(viloyat, tuman, ism-familiya yozish shart emas)</span>
            </p>
            <p>{value.length}/700</p>
          </div>
          <textarea
            className={`${errorHelper ? 'inputError' : ''} input_body`}
            value={value}
            onChange={(e) => onChange(e.target.value, workId)}
            placeholder="Ma'lumot yozing..."
            maxLength={700}
            rows={5}
            style={{
              resize: 'none',
            }}
          />
        </div>
      </div>
    </Description>
  );
};
