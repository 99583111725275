import { BrowserRouter as Router } from 'react-router-dom';
import React from 'react';
import { Provider } from 'react-redux';
import App from '../containers/App';
import store from '../redux/store';
import Modal from '../components/Modal';
import WorksMap from 'views/WorksMap';
import { ViewportProvider } from 'hooks/useDevice';

export default () => {
  return (
    <Provider store={store}>
      <Router>
        <ViewportProvider>
          <WorksMap />
          <App />
          <Modal />
        </ViewportProvider>
      </Router>
    </Provider>
  );
};
