import { TimePicker } from 'antd';
import styled from 'styled-components';

export const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  height: 44px;
  border-radius: 8px;
  color: white;

  & .react-time-picker__clear-button {
    display: none;
  }
  & .react-time-picker__wrapper {
    background: none;
  }
  & .react-time-picker__inputGroup__input {
    background: none;
    border: none;
    outline: none;
    color: white;
  }
`;
