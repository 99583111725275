import React, { useEffect, useState } from 'react';
import users from 'services/users';
import { useHideModal, useShowModal } from 'hooks';
import ModalBody from './ModalBody';
import { setModalLoading } from 'redux/modules/modals/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Empty, Pagination } from 'antd';
import { EmptySpace, NoData } from '../style';
import { getColumn } from '../helper';
import ExpandedTable from 'components/Tables/ExpandedTable';
import Spinner from 'components/Spinner/FullScreen';

const UserInActive = () => {
  const { lang } = useSelector((state) => state.appReducer);
  const { showBlured } = useShowModal();
  const { hideModal } = useHideModal();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [userLimit, setUserLimit] = useState(10);
  const [data, setData] = useState([]);

  const fetchWorks = () => {
    setLoading(true);
    const postData = {
      page: page,
      limit: userLimit,
    };
    users
      .getUsersAdvancedAll(postData)
      .then(({ docs, totalDocs }) => {
        const maddepData = docs.map((item, idx) => ({
          ...item,
          indx: page * userLimit + idx + 1 - userLimit,
          fullname: `${item?.firstName} ${item?.lastName} ${item?.middleName}`,
          regionName: item?.region?.[`title${lang}`],
          districtName: item?.district?.[`title${lang}`] ?? '-',
          cause: item?.inActive?.cause ?? '-',
          inActive: item?.active ? (
            <span style={{ color: '#11B521', fontWeight: 'bold' }}>Faol</span>
          ) : (
            <span style={{ color: '#EE1111', fontWeight: 'bold' }}>
              Faol emas
            </span>
          ),
          date:
            item?.inActive !== null
              ? item?.inActive?.start + ' / ' + item?.inActive?.end
              : '-',
        }));
        setTotal(totalDocs);
        setData(maddepData);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchWorks();
  }, [page, userLimit]);

  const handleClick = (id) => {
    showBlured({
      title: 'Holatni o’zgartirish',
      maxWidth: '384px',
      height: '240px',
      withHeader: false,
      body: () => <ModalBody id={id} handleSubmit={handleSubmit} />,
    });
  };

  const handleSubmit = (id, value) => {
    dispatch(setModalLoading(true));
    const newData = {
      start: value?.date[0],
      end: value?.date[1],
      cause: value?.cause,
    };
    users
      .userInActive(id, newData)
      .then(() => {
        hideModal();
        fetchWorks();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(setModalLoading(false));
      });
  };

  const tableHeader = getColumn({ handleClick });

  const onShowSizeChange = (_, pageSize) => {
    setUserLimit(pageSize);
  };

  if (loading) return <Spinner />;

  return data?.length ? (
    <>
      <EmptySpace />
      <ExpandedTable data={data} headers={tableHeader} onChange={() => {}} />
      {total > 10 && (
        <div className="pagination custom-pagination-wrapper">
          <Pagination
            current={page}
            total={total}
            onChange={(current, newSize) => {
              setPage(newSize !== userLimit ? 1 : current);
            }}
            onShowSizeChange={onShowSizeChange}
            defaultPageSize={userLimit}
            showSizeChanger
          />
        </div>
      )}
    </>
  ) : (
    <NoData>
      <Empty description={<h1>Ma`lumot topilmadi!</h1>} />
    </NoData>
  );
};

export default React.memo(UserInActive);
