import styled from 'styled-components';

export const ModalBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 35px;
  padding: 20px;
  .form__group {
    width: 100%;
    height: 100%;

    & .form__title {
      display: flex;
      font-size: 14px;
      color: #000;
      margin: 2px;
      font-weight: 500;
    }
  }
  .date__picker {
    width: 100%;
    transition: all 0.3s ease-in-out;

    .ant-picker {
      border-radius: 10px;
      border: 1px solid #d9d9d9;
      height: 44px;
      width: 100%;
    }
    .ant-picker-input > input {
      font-weight: 700;
      width: 80px;
      text-align: left;
    }
    .ant-picker-dropdown
      .ant-picker-dropdown-range
      .ant-picker-dropdown-placement-bottomRight {
      padding: 15px;
      background: transparent;
    }
    .ant-picker-panel-container {
      border-radius: 20px;
    }
    .ant-picker-date-panel {
      padding: 5px;
    }
  }

  & .action-button {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    transition: all 0.3s ease-in-out;
    button {
      border-radius: 12px;
      display: flex;
      width: 154px;
      height: 44px;
      padding: 15px 3px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border: none;
      outline: none;
      cursor: pointer;
    }
    .submit-btn {
      background: #00826C;
      color: #fff;
    }
    .cancel-btn {
      border: 1px solid #00826C;
      color: #00826C;
      background: #fff;
    }
  }

  & .error-message {
    position: absolute;
    color: #f64e60;
  }
`;
