// Render Prop
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import FormElement from './FormElement';
import { Card, Box } from './style';
import { Col, Row, Button, Space } from 'antd';
import ojectService from '../../services/objects';
import regions from 'services/regions';
import { useDispatch, useSelector } from 'react-redux';
import { setModalLoading } from 'redux/modules/modals/actions';

export default ({
  formData,
  cancel,
  sendData,
  selectOptions,
  id,
  initialVal,
  fetchRegion = true,
  fetchObject = true,
  deleteImage,
}) => {
  const { lang } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [optionsData, setOptionsData] = useState({});
  const [objects, setObjects] = useState([]);
  const formik = useFormik({
    initialValues: initialVal,
    onSubmit: (values) => {
      id ? sendData(id, values) : sendData(values);
    },
  });

  useEffect(() => {
    setOptionsData(selectOptions);
  }, []);

  useEffect(() => {
    if (fetchRegion && formik.values.province) {
      regions
        .getAll({
          province: formik.values.province?._id ?? formik.values.province,
        })
        .then((resp) => {
          setOptionsData((prev) => ({
            ...prev,
            region: resp.map((item) => ({
              ...item,
              title: item['title' + lang],
            })),
          }));
        })
        .catch((err) => console.log(err));
    }
  }, [formik.values.province]);

  useEffect(() => {
    if (fetchObject && formik.values.region) {
      const payload = {
        region: formik.values.region?._id ?? formik.values.region,
      };
      ojectService
        .getAllObjectByRegions(payload)
        .then((resp) => {
          setObjects(resp);
        })
        .catch((err) => console.log(err));
    }
  }, [formik.values.region]);

  useEffect(() => {
    if (!formik?.values?.parent) {
      const filteredData = formData?.data?.filter((item) =>
        item?.isShow?.includes('parent')
      );
      setOptions(filteredData);
    }
    if (formik?.values?.parent) {
      const filteredData = formData?.data?.filter((item) =>
        item?.isShow?.includes('child')
      );
      setOptions(filteredData);
    }
  }, [formik?.values?.parent]);

  useEffect(() => {
    if (formik.values?.role && formik.values?.role !== 'editor') {
      const filteredData = formData.data.filter((item) =>
        item?.permission?.includes(formik?.values?.role)
      );
      setOptions(filteredData);
    }
  }, [formik.values.role]);

  const handleCancel = () => {
    cancel();
  };
  const checkOptions = () => {
    return options.length > 0 ? options : formData.data;
  };

  return (
    <Box>
      <Card>
        <form onSubmit={formik.handleSubmit}>
          <Row align="left" gutter={[16, 8]}>
            {checkOptions().map((item, ind) => (
              <Col span={item.size} key={ind + 1} align="left">
                <div className="label">{item.label}</div>
                <FormElement
                  formik={formik}
                  item={item}
                  deleteImage={deleteImage}
                  selectOptions={{
                    ...optionsData,
                    district: objects,
                  }}
                  id={id}
                />
              </Col>
            ))}
          </Row>
          <Space
            style={{
              marginTop: '20px',
              display: 'block',
              align: 'right',
            }}
          >
            <Row justify="end" gutter={[8, 8]}>
              <Col>
                <Button
                  style={{
                    borderRadius: '6px',
                  }}
                  onClick={() => handleCancel()}
                >
                  Bekor qilish
                </Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  {id ? 'Yangilash' : 'Saqlash'}
                </Button>
              </Col>
            </Row>
          </Space>
        </form>
      </Card>
    </Box>
  );
};
