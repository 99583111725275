import React from 'react';
import { CardWrapper } from './style';

const HeaderCard = ({ data, minWidth, headerData }) => {
  return (
    <CardWrapper color={data?.color} minwidth={minWidth}>
      <div className="card-left">
        <div className="card-title">{data?.title}</div>
        <div className="card-count">{headerData[data?.key] ?? 0}</div>
      </div>
      <div className="card-icon">
        <img src={data?.img} alt="" />
      </div>
    </CardWrapper>
  );
};
export default React.memo(HeaderCard);
