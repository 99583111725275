export const formData = {
  title: "Foydalanuvchi qo'shish",

  data: [
    {
      type: 'select',
      label: 'Role',
      name: 'role',
      multiple: false,
      value: '',
      size: 12,
      permission: [
        'admin',
        'leader',
        'proleader',
        'regleader',
        'regsupervisor',
        'prosupervisor',
        'supervisor',
        'advanced',
      ],
    },

    {
      type: 'text',
      label: 'Familiyasi',
      name: 'lastName',
      value: '',
      size: 12,
      permission: [
        'admin',
        'leader',
        'proleader',
        'regleader',
        'regsupervisor',
        'prosupervisor',
        'supervisor',
        'advanced',
      ],
    },
    {
      type: 'text',
      label: 'Ismi',
      name: 'firstName',
      value: '',
      size: 12,
      permission: [
        'admin',
        'leader',
        'proleader',
        'regleader',
        'regsupervisor',
        'prosupervisor',
        'supervisor',
        'advanced',
      ],
    },
    {
      type: 'text',
      label: 'Sharifi',
      name: 'middleName',
      value: '',
      size: 12,
      permission: [
        'admin',
        'leader',
        'proleader',
        'regleader',
        'regsupervisor',
        'prosupervisor',
        'supervisor',
        'advanced',
      ],
    },
    {
      type: 'password',
      label: 'Parol',
      name: 'password',
      value: '',
      size: 12,
      permission: [
        'admin',
        'leader',
        'proleader',
        'regleader',
        'regsupervisor',
        'prosupervisor',
        'supervisor',
        'advanced',
      ],
    },
    {
      type: 'select',
      label: 'Viloyat',
      name: 'province',
      value: '',
      multiple: false,
      size: 12,
      permission: [
        'proleader',
        'regleader',
        'regsupervisor',
        'advanced',
        'prosupervisor',
      ],
    },
    {
      type: 'select',
      label: 'Tuman',
      name: 'region',
      value: '',
      multiple: false,
      size: 12,
      permission: ['regleader', 'regsupervisor', 'advanced'],
    },

    {
      type: 'searchSelect',
      label: 'MFY',
      name: 'district',
      value: '',
      multiple: false,
      size: 12,
      permission: ['advanced'],
    },
    {
      type: 'file',
      label: 'Rasmi',
      name: 'avatar',
      value: '',
      size: 12,
      permission: [
        'leader',
        'proleader',
        'regleader',
        'regsupervisor',
        'prosupervisor',
        'supervisor',
        'advanced',
      ],
    },
    {
      type: 'tel',
      label: 'Telefon raqami',
      name: 'phone',
      value: '',
      size: 12,
      permission: [
        'admin',
        'leader',
        'proleader',
        'regleader',
        'regsupervisor',
        'prosupervisor',
        'supervisor',
        'advanced',
      ],
    },

    {
      type: 'switch',
      label: 'Faolligi',
      name: 'active',
      value: true,
      size: 12,
      permission: [
        'admin',
        'leader',
        'proleader',
        'regleader',
        'regsupervisor',
        'prosupervisor',
        'supervisor',
        'advanced',
      ],
    },
  ],
};

export const initialValuesAdd = {
  firstName: '',
  lastName: '',
  middleName: '',
  password: '',
  province: '',
  region: '',
  regions: [],
  district: '',
  role: '',
  phone: '',
  active: true,
  avatar: '',
};
