import { Button } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  /* margin-left: 35px; */
`;

export const CustomButton = styled(Button)`
  color: #fff;
  min-height: 42px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(5px);
  :hover {
    background-color: #00c4fa;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
`;
