import { service } from '.';

export default {
  getAll: () => service.post('/users/filter'),
  getUsersByFilter: (data) => service.post('/users/filter', data),
  getOne: (id) => service.get(`/users/${id}`),
  create: (data) => service.post('/users', data),
  update: (id, data) => service.put(`/users/${id}`, data),
  delete: (id) => service.delete(`/users/${id}`),
  userInActive: (id, data) => service.put(`/web/users/inactive/${id}`, data),
  getUsersAdvancedAll: (data) => service.post(`/web/users`, data),
  getUserInformation: (id) => service.get(`/web/users/${id}`),
  deleteAvatarImg: (id) => service.delete(`/users/avatar/${id}`),
  deleteMacAddress: (id) => service.put(`/users/macAddress/${id}`),
  findAdvancedLocation: (payload) =>
    service.post('/web/coordinate/pin/', payload),
  getAdvancedLocation: (district, date) =>
    service.get(`/web/coordinate/coordinates?district=${district}&date=${date}`),
};
