import styled from 'styled-components';

export const ScheduleWrapper = styled.div`
  width: 100%;
  padding-top: 24px;
  transition: height 0.4s ease-in-out;

  & .select-wrap {
    width: 260px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    & .d-picker {
      .range-text {
        color: #fff;
      }
    }
    & .date-title {
      color: #fff;
      font-family: Segoe UI;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      padding-top: 2px;
    }

    & .range-text {
      margin-top: -1px;
      padding-bottom: 3px;
    }
  }
  .lateTotal {
    min-width: 90px;
  }
  & .result-select-wrap {
    display: flex;
    flex-direction: column;
    gap: 9px;
    /* min-width: 60px; */
    & .result-title {
      color: #fff;
      font-family: Segoe UI;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      padding-top: 2px;
    }
  }

  & .pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0px;

    & .ant-pagination-item {
      border-radius: 6px;
      :hover {
        border-color: #00826C !important;
        & a {
          color: #00826C !important;
        }
      }
    }
    & .ant-pagination-item-active {
      border: 1px solid #00826C;
      & a {
        color: #00826C !important;
      }
    }
    .ant-pagination-prev button {
      border-radius: 6px !important;
      :hover {
        border-color: #00826C !important;
        & svg {
          color: #00826C !important;
        }
      }
    }
    .ant-pagination-next button {
      border-radius: 6px !important;
      :hover {
        border-color: #00826C !important;
        & svg {
          color: #00826C !important;
        }
      }
    }
    .ant-pagination-disabled button {
      :hover {
        border-color: #d9d9d9 !important;
        & svg {
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }
    }

    & .ant-select {
      width: 125px !important;
      & .ant-select-selector {
        min-width: 100% !important;
        min-height: 32px !important;
        border-radius: 6px !important;
        height: 100%;
        color: #080936;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        .ant-select-selection-item {
          margin-right: 0px !important;
        }
        & .ant-select-arrow {
          margin-top: 12px !important;
        }
      }
    }
  }
`;

export const TabStatus = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 16px;
  padding-bottom: 24px;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  /* @media (max-width: 1169px) { */
  padding-bottom: 5px;
  margin-bottom: 21px;
  /* } */
`;

export const SelectsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 24px;
  gap: 16px;
  flex-wrap: wrap;
`;

export const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: ${({ active }) => (active === 'true' ? 'wrap' : 'nowrap')};
  align-items: flex-end;
  .filter__menu {
    margin-top: ${({ active }) => (active === 'true' ? 0 : '26px')};
    width: 100%;
    order: ${({ active }) => (active === 'true' ? 2 : 0)};
    box-shadow: ${({ active }) =>
      active === 'true' ? 'rgba(0, 0, 0, 0.04) 0px 3px 12px 0px' : 'none'};
  }
  gap: 16px;
  .excell__btn {
    padding-top: 24px;
    margin-left: auto;
  }
  @media (max-width: 1067) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const ResultCount = styled.div`
  max-width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  padding: 15px 24px;
  border-radius: 8px;
  background: ${({ color }) => color && color};
  transition: all 0.4s ease-in-out;
  color: #fff;
  font-family: Segoe UI;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
`;

export const ExcelContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & .for-button {
    gap: 8px !important;
    width: 167px;
    height: 42px;
    background-color: #00c4fa;
    border: none;
    color: white;
    font-weight: 550;
    border-radius: 12px;
    cursor: pointer;
  }
`;

export const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  gap: 14px;
  /* max-height: calc(100vh - 365px); */
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
`;

export const ModalBodyWrapper = styled.div`
  width: 100%;
  padding: 20px 40px 40px;

  & .table-title {
    width: 100%;
    text-align: center;
    color: #080936;
    text-align: center;
    font-family: 'Segoe UI';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    padding: 24px 0px 16px;
  }
`;

export const TableWrapper = styled.div`
  & .ant-table-thead > tr > th {
    color: #080936;
    font-family: Segoe UI;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    padding: 8px 12px !important;
    border: none;
    :last-child {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px;
    }
    :first-child {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px;
    }
  }
  & .ant-table-cell {
    ::before {
      display: none !important;
    }
  }
  & .ant-table-tbody > tr > td {
    padding: 20px 12px !important;
    color: #080936;
    font-family: Segoe UI;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  /* .ant-pagination-options {
    display: none;
  } */

  & .ant-pagination-item {
    border-radius: 6px;
    :hover {
      border-color: #00826C !important;
      & a {
        color: #00826C !important;
      }
    }
  }
  & .ant-pagination-item-active {
    border: 1px solid #00826C;
    & a {
      color: #00826C !important;
    }
  }
  .ant-pagination-prev button {
    border-radius: 6px !important;
    :hover {
      border-color: #00826C !important;
      & svg {
        color: #00826C !important;
      }
    }
  }
  .ant-pagination-next button {
    border-radius: 6px !important;
    :hover {
      border-color: #00826C !important;
      & svg {
        color: #00826C !important;
      }
    }
  }
  .ant-pagination-disabled button {
    :hover {
      border-color: #d9d9d9 !important;
      & svg {
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }
  }

  & .ant-select {
    width: 125px !important;
    & .ant-select-selector {
      min-width: 100% !important;
      min-height: 32px !important;
      border-radius: 6px !important;
      height: 100%;
      color: #080936;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      .ant-select-selection-item {
        margin-right: 0px !important;
      }
      & .ant-select-arrow {
        margin-top: 12px !important;
      }
    }
  }
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  height: calc(100vh - 400px);
`;

export const ButtonMap = styled.button`
  background: var(--sidebar-item-active);
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
`;

export const Status = styled.div`
  width: auto;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 13px;
  color: white;
  text-align: center;
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 500;
  background: #2dce99;
  /* background: rgb(255, 96, 96); */
`;
