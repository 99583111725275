import React from 'react';
import { StatusCard } from '../style';
import { scheduleTimeFormatter } from 'utils/timeFormatter';

export default ({ color, bgColor, data }) => {
  const allLateMinutes = data?.data.reduce((a, b) => a + b.late, 0);
  return (
    <StatusCard color={color} bgcolor={bgColor ? 'true' : 'false'}>
      <div className="late-came">
        <div className="late-came-text">Kech qolgan</div>
        <div className="late-came-count">{data?.count} marta</div>
      </div>
      <div>
        {/* <div className="came-time-text">Kelgan vaqti</div> */}
        <div className="came-date">{scheduleTimeFormatter(allLateMinutes)}</div>
      </div>
    </StatusCard>
  );
};
