import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as NewWorksIcon } from 'assets/icons/sidebar/menu.svg';
import { setCollapsed } from 'redux/modules/application/actions';

const Menu = () => {
  const collapsed = useSelector(({ appReducer }) => appReducer.collapsed);
  const dispatch = useDispatch();
  const handleClickMenu = () => {
    dispatch(setCollapsed(!collapsed));
  };

  return (
    <span
      style={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <NewWorksIcon onClick={handleClickMenu} />
    </span>
  );
};

export default Menu;
