import React, { useEffect, useMemo, useState } from 'react';
import Table from '../../../components/Tables/ExpandedTable';
import { headerMaker } from '../../../components/Tables/ExpandedTable/helper';
import Spinner from '../../../components/Spinner';
import { usePaginate } from 'hooks/usePaginate';
import { paymentsHeader } from 'redux/modules/table/common';
import { Empty, Pagination } from 'antd';
import service from 'services/reports';
import { FilterContainer, FilterItem, NoData, ResultCount } from './style';
import Select from 'components/FormElements/Select';
import { useSelector } from 'react-redux';
import { scheduleColor } from 'constants/colors';
import DownloadToExcell from 'components/DownloadToExcell';
import { useShowAlert } from 'hooks';

export default () => {
  const [loading, setLoading] = useState(false);
  const { regions } = useSelector((state) => state.appReducer);
  const [reportsData, setReportsData] = useState([]);
  const headers = useMemo(() => headerMaker(paymentsHeader), [paymentsHeader]);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [region, setRegion] = useState();
  const [excelData, setExcellData] = useState([]);
  const [statistics, setStatistics] = useState({ paid: 0, unpaid: 0 });
  const { success, error, warning } = useShowAlert();

  const fetchReport = async (withPaginate) => {
    try {
      let data = {};
      if (withPaginate) {
        setLoading(true);
        data = {
          ...data,
          limit: pageSize,
          page: pageIndex,
        };
      }
      if (region) data['region'] = region;
      const resp = await service.getPaymentReports(data);
      if (withPaginate) {
        setTotal(resp?.totalDocs);
        setStatistics(() => ({
          paid: resp?.paid ?? 0,
          unpaid: resp?.unpaid ?? 0,
        }));
        const result = resp?.docs.map((item, idx) => ({
          id: pageIndex * pageSize - pageSize + idx + 1,
          user_name: `${item.lastName} ${item.firstName} ${item.middleName}`,
          region: item?.region?.titleUZ,
          district: item?.district?.titleUZ ?? '-',
          phone: item?.phone,
          status: {
            value: item?.payment ? item.endDate : "To'lanmagan",
            bg: 'trasnparent',
            color: item?.payment ? '#00826C' : '#FF6060',
          },
        }));
        setReportsData(result ?? []);
      } else {
        const result = resp?.map((item, idx) => ({
          id: idx + 1,
          user_name: `${item.lastName} ${item.firstName} ${item.middleName}`,
          region: item?.region?.titleUZ,
          district: item?.district?.titleUZ ?? '-',
          phone: item?.phone ?? '-',
          status: {
            value: item?.payment ? item.endDate : "To'lanmagan",
            bg: 'trasnparent',
            color: item?.payment ? '#00826C' : '#FF6060',
          },
        }));
        setExcellData(result);
      }
      setLoading(false);
    } catch (err) {
      setReportsData([]);
      setLoading(false);
      error("Ma'lumotlarni yuklashda xatolik");
      console.log(err);
    }
  };
  useEffect(() => {
    fetchReport('withpaginate');
  }, [pageIndex, pageSize, region]);

  const onShowSizeChange = (_, pageSize) => {
    setPageSize(pageSize);
  };
  const handleFetchExcellData = () => {
    fetchReport();
    success("Ma'lumotlar tayyorlanmoqda.", 1);
  };
  return (
    <>
      <FilterContainer>
        <FilterItem>
          <Select
            name="region"
            label="Tuman (Shahar)"
            placeholder="Hududni tanlang"
            options={regions}
            onChange={(value) => {
              setRegion(value);
              setPageIndex(() => 1);
            }}
            title="Hudud"
            value={region}
          />
        </FilterItem>
        <FilterItem>
          <div className="excell__btn">
            <DownloadToExcell
              handleFetchData={handleFetchExcellData}
              fileName="To'lov holati"
              tableName={'payments'}
              tableHeader={headers}
              tableData={excelData}
              setExcellData={setExcellData}
            />
          </div>
        </FilterItem>
      </FilterContainer>
      <FilterContainer>
        <FilterItem>
          <ResultCount color={scheduleColor[0]}>{statistics.paid}</ResultCount>
          <ResultCount color={scheduleColor[2]}>
            {statistics.unpaid}
          </ResultCount>
        </FilterItem>
      </FilterContainer>
      {loading ? (
        <Spinner maxHeight={'500px'} />
      ) : reportsData?.length ? (
        <>
          <Table headers={headers} data={reportsData} />
          {total > 10 && (
            <div className="custom-pagination-wrapper">
              <Pagination
                current={pageIndex}
                pageSize={pageSize}
                onShowSizeChange={onShowSizeChange}
                total={total}
                onChange={(page) => setPageIndex(page)}
              />
            </div>
          )}
        </>
      ) : (
        <NoData>
          <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
        </NoData>
      )}
    </>
  );
};
