import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import { FiChevronDown } from 'react-icons/fi';

export default ({ options, handleChange, active }) => {
  const [hideOptions, setHideOptions] = useState(false);

  const handleHide = () => {
    setHideOptions(!hideOptions);
  };
  const handleItem = (id, titleObj) => {
    handleChange(id, titleObj);
    setHideOptions(false);
  };
  const changeName = () => {
    let cat = options.find((item) => item._id == active);
    return cat?.title || '';
  };
  return (
    <div className="component__wrapper">
      <div className="app-container">
        <button onClick={handleHide} className="category-button">
          {' '}
          <div>{changeName()}</div>{' '}
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FiChevronDown size={24} color="#00826C" />
          </div>
        </button>
      </div>
      {hideOptions && (
        <div className="optionsContainer">
          {options?.map((item, ind) => (
            <button
              key={ind + 1}
              className={`item-button ${active == item._id ? 'active' : ''}`}
              onClick={() => handleItem(item._id, item.title)}
            >
              {item.title}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
