import React, { useMemo, useState, useEffect } from 'react';
import Table from '../../../../components/Tables/ReportTable';
import { headerMaker } from '../../../../components/Tables/ExpandedTable/helper';
import reportService from 'services/reports';
import { useSelector } from 'react-redux';
import { checkWorkAndPlan } from '../../../../utils/makeReport';
import Spinner from 'components/Spinner';
import { Empty } from 'antd';
import { reportUserHeader } from 'redux/modules/table/common';
import { NoData } from '../style';

export const createUserDataByCategory = (data) => {
  const rowData = data?.map((item, ind) => {
    const tPlan = item.data.reduce((acc, i) => acc + i?.reja, 0);
    const tWork = item.data.reduce((acc, i) => acc + i?.amalda, 0);
    const tPercent = checkWorkAndPlan(tWork, tPlan);
    let row = {
      id: ind + 1,
      name: item.title.titleUZ
        ? item.title?.titleUZ
        : `${item.title.lastName} ${item.title.firstName}`,
      district: item?.title?.district?.titleUZ ?? '-',
      total_plan: tPlan,
      total_work: tWork,
      total_percent: tPercent + '%',
      percent: (tWork * 100) / tPlan,
    };
    item.data &&
      item.data.forEach((itm) => {
        row[itm.category + 'plan'] = itm.reja;
        row[itm.category + 'work'] = itm.amalda;
        row[itm.category + 'percent'] =
          checkWorkAndPlan(itm.amalda, itm.reja) + '%';
      });
    return row;
  });
  rowData.sort((a, b) => b.percent - a.percent);
  return rowData.map((item, ind) => ({ ...item, id: ind + 1 }));
};

export default ({ active, selectedFilters, headerGetter }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newHeader, setNewHeader] = useState([]);
  const { selectedDate } = useSelector((state) => state.selectedDateReducer);
  const { categories } = useSelector((state) => state.appReducer);

  useEffect(() => {
    setLoading(true);
    let data = {
      province: selectedFilters.province,
      region: selectedFilters.region,
    };
    if (
      !selectedDate.every((item) => item === '') &&
      selectedDate[0] !== 'Invalid date' &&
      selectedDate[1] !== 'Invalid date'
    ) {
      data['start'] = selectedDate[0];
      data['end'] = selectedDate[1];
    }
    if (!selectedDate.every((item) => item === '') && active !== '') {
      data = { ...data };
      reportService
        .getAllReportByCategories(data)
        .then((resp) => {
          const rowData = createUserDataByCategory(resp);
          setRows(rowData);
          makeReportHeaders(resp);
        })
        .finally(() => setLoading(false))
        .catch((err) => console.log(err));
    }
  }, [active, selectedDate, selectedFilters]);

  const makeReportHeaders = (data = []) => {
    const hechCategories = new Map();
    const pCats = categories.filter((item) => !item.parent);

    pCats.forEach((item) => hechCategories.set(item._id, item));
    const headerCats =
      data[0] && data[0].data.map((item) => hechCategories.get(item.category));
    let heads = [...reportUserHeader];
    if (selectedFilters?.region) {
      heads[1].show = true;
      heads[2].className = '';
    } else {
      heads[1].show = false;
      heads[2].className = 'custom-sticky-td';
    }
    if (heads.length === 4) {
      headerCats &&
        headerCats.forEach(
          (item) =>
            item &&
            heads.push({
              Header: item?.title,
              show: true,
              columns: [
                {
                  width: 50,
                  Header: 'Reja',
                  accessor: item?._id + 'plan',
                },
                {
                  width: 50,
                  Header: 'Amalda',
                  accessor: item?._id + 'work',
                },
                {
                  width: 50,
                  Header: 'Foizi',
                  accessor: item?._id + 'percent',
                },
              ],
            })
        );
    }
    setNewHeader(heads);
  };

  const headers = useMemo(() => headerMaker(newHeader), [newHeader]);
  useEffect(() => {
    headerGetter(headers);
  }, [headers]);

  if (loading) return <Spinner maxHeight="300px" />;

  return rows?.length > 0 ? (
    <Table
      data={rows}
      headers={headers}
      // onChange={handleOnTableChange}
      // total={rows.length}
      // reportsData={rows}
      // page={pageCount}
      tableName="Kategoryalar boyicha"
    />
  ) : (
    <NoData>
      <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
    </NoData>
  );
};
