import styled from 'styled-components';

export const DropDownContainer = styled('div')`
  width: 137px;
  height: 38px;
  border-radius: 8px;
  background: linear-gradient(39.27deg, #00826c 12.35%, #00cca9 95.72%);
  position: relative;
  top: 0;
  left: 0;
  color: #fff;
`;

export const DropDownHeader = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const DropDownListContainer = styled('div')`
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;

  box-shadow: 0px 11px 16px rgba(0, 0, 0, 0.05);
`;

export const DropDownList = styled('ul')`
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 6px;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;

  &:first-child {
    padding-top: 0.8em;
  }
`;

export const ListItem = styled('li')`
  cursor: pointer;
  list-style: none;
  color: #00826c;
  width: 100%;
  padding: 5px 10px;
  &:hover {
    border-radius: 8px;
    background: linear-gradient(39.27deg, #00826c 12.35%, #00cca9 95.72%);
    color: #fff;
  }
`;
