import React, { useEffect, useState } from 'react';
import { Wrapper, TaskDescription, SearchBox, NoData, SaveBtn } from './style';
import SupervisorCard from 'components/Cards/WorkCards/Supervisor';
import MapY from 'components/Map/MapModal';
import { Empty, Pagination } from 'antd';
import works from 'services/works';
import Spinner from 'components/Spinner/FullScreen';
import { RatingBox } from 'components/Cards/WorkCards/Supervisor/style';
import WorkRating from 'components/Cards/WorkCards/Contents/WorkRating';
import { useShowAlert, useShowModal } from 'hooks';
import { FiSearch, FiX } from 'react-icons/fi';
import rating from 'services/rating';
import { useSelector } from 'react-redux';

const COMMON_RATE = 100;
const calcCount = (page, total, limit) => {
  let reslut = 0;
  if (total > 10) {
    reslut = total - page * limit;
  }
  return reslut;
};

export default () => {
  const [bal, setBal] = useState(0);
  const { lang } = useSelector((state) => state.appReducer);
  const { supervisorDate } = useSelector((state) => state.selectedDateReducer);
  const { showBlured } = useShowModal();
  const { success, error } = useShowAlert();

  const [loading, setLoading] = useState(false);
  const [rateComment, setRateComment] = useState([]);
  const [worksData, setWorksData] = useState([]);
  const [worksTotal, setWorksTotal] = useState(0);
  const [selectedRates, setSelectedRates] = useState([]);
  const [checkList, setCheckList] = useState([]);
  //  similar works
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [similarLoading, setSimilarLoading] = useState(false);
  const [similarWorkPageNum, setSimilarWorkPageNum] = useState(1);
  const [similarWorks, setSimilarWorks] = useState([]);
  const [similarWorksCount, setSimilarWorksCount] = useState(0);
  const [bool, setBool] = useState(false);

  // // get one work
  const fetchWorks = () => {
    setLoading(true);
    const postData = {
      date: supervisorDate,
      page: 1,
      limit: 1,
      status: 2,
    };
    works
      .getWorksRegSupervisor(postData, 'new')
      .then(({ docs, totalDocs }) => {
        setWorksTotal(totalDocs);
        setWorksData(docs);
      })
      .catch((err) => {
        console.error(err);
        error("Ma'lumot yuklashda xatolik yuz berdi", 2, 'bottomCenter');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchWorks();
    setSelectedRates([]);
  }, [supervisorDate]);

  // // get checklist by group id
  useEffect(() => {
    rating
      .getAll({})
      .then((resp) => setCheckList(resp))
      .catch((err) => console.error(err));
  }, []);

  // // change rate and rate comment in rated work data
  useEffect(() => {
    if (worksData.length > 0) {
      if (worksData[0]?.rate) {
        setBal(() => worksData[0]?.rate * 1);
        setRateComment(worksData[0]?.rateComment?.split('/'));
      } else {
        setBal(COMMON_RATE);
      }
    }
  }, [worksData]);

  const fetchSimilarWorks = () => {
    if (worksData?.length > 0) {
      setSimilarLoading(true);
      let postWork = {
        limit: limit,
        page: similarWorkPageNum,
        subCategory: worksData[0]?.subCategory._id,
        date: supervisorDate,
        region: worksData[0]?.region?._id,
      };

      if (search.length > 0) {
        postWork['search'] = search;
      }
      works
        .getSimilarWorksSearch(postWork)
        .then(({ docs, totalDocs }) => {
          setSimilarWorksCount(totalDocs);
          setSimilarWorks(docs);
        })
        .catch((err) => console.error(err))
        .finally(() => setSimilarLoading(false));
    }
  };
  // get rated work
  useEffect(() => {
    if (worksData.length > 0) {
      fetchSimilarWorks();
    }
  }, [similarWorkPageNum, bool, worksData]);

  const onSearch = (action) => {
    if (action === 'clear') {
      setSimilarWorkPageNum(1);
      setSearch('');
      setBool((prev) => !prev);
    }
  };

  const handleSaveWork = () => {
    // if (selectedRates.length > 0) {
    setLoading(true);
    const id = worksData[0]?._id;
    const putData = {
      checklists: selectedRates,
      rateComment: rateComment.join('/'),
      rate: bal * 1,
    };
    if (putData.checklists.length === 0) {
      delete putData.checklists;
    }
    works
      .updateWorkRegSupervisor(id, putData)
      .then(() => {
        setWorksData(() => []);
        setSimilarWorksCount(0);
        setSimilarWorks([]);
        setSelectedRates([]);
        fetchWorks();
        success("Ma'lumot saqlandi", 2, 'bottomCenter');
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);

        error("Ma'lumot saqlashda xatolik yuz berdi", 2, 'bottomCenter');
      });
  };

  useEffect(() => {
    if (selectedRates.length > 0) {
      let descText = [];
      checkList.forEach((item) => {
        if (selectedRates.includes(item._id)) {
          descText.push(item?.[`title${lang}`]);
        }
      });
      setRateComment(descText);
      let sum = 0;
      checkList.forEach((item) => {
        if (selectedRates.includes(item._id)) {
          sum += item.rate;
        }
      });
      sum = COMMON_RATE - sum;
      setBal(sum);
    } else {
      setRateComment([
        'Yuborilgan ish ijrochi tomonidan qoniqarli darajada bajarilgan!',
      ]);
      setBal(COMMON_RATE);
    }
  }, [selectedRates]);

  const handleShowMapModal = () => {
    const latLng = [worksData[0]?.media[0]?.lat, worksData[0]?.media[0]?.lng];

    showBlured({
      title: 'Tadbir o`tkazilgan joy!',
      maxWidth: '1200px',
      height: '600px',
      withHeader: false,
      body: () => <MapY latLang={latLng ?? '40.5 71.25'} />,
    });
  };

  const handleChecklist = (workId, rateId) => {
    if (selectedRates.includes(rateId)) {
      setSelectedRates(selectedRates.filter((rate) => rate !== rateId));
    } else {
      setSelectedRates((prev) => [...prev, rateId]);
    }
  };

  if (loading) return <Spinner maxHeight={'100%'} />;
  return (
    <Wrapper>
      {worksData !== undefined && worksData.length > 0 ? (
        <div className="supervisorCard">
          <SupervisorCard data={worksData[0]} rate={selectedRates} unrated />
          <RatingBox>
            <WorkRating
              data={checkList}
              bal={bal}
              selectedRates={selectedRates}
              disabled={worksData[0]?.rate && true}
              onChange={handleChecklist}
              handleShowModal={handleShowMapModal}
              workId={worksData[0]?._id}
              currentWork={worksData[0]}
            />
          </RatingBox>
          <div className="bottom__actions">
            <SaveBtn
              // disabled={selectedRates.length === 0 && true}
              onClick={handleSaveWork}
            >
              Saqlash
            </SaveBtn>
            <div className="work__total">
              <div className="title">Yana:</div>
              <div className="count">{worksTotal - 1}</div>
            </div>
          </div>
        </div>
      ) : (
        <NoData>
          <Empty
            description={
              <h1>Belgilangan kun uchun qilingan ishlar topilmadi!</h1>
            }
          />
        </NoData>
      )}

      {similarWorks.length > 0 && (
        <TaskDescription>
          <div className="search">
            <SearchBox
              className="search-input"
              placeholder="Matn bo'yicha qidiruv..."
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {search.length > 0 && (
              <button className="clear__btn" onClick={() => onSearch('clear')}>
                <FiX size={24} />
              </button>
            )}
            <button className="search__btn" onClick={fetchSimilarWorks}>
              <FiSearch color="#fff" size={24} />
            </button>
          </div>

          <div className="search__result__info">
            {/* <div className="title">yana:</div> */}
            <div className="count">{similarWorksCount ?? 0}</div>
          </div>
        </TaskDescription>
      )}
      <div className="similar__works custom-pagination-wrapper">
        {similarLoading ? (
          <Spinner />
        ) : (
          <>
            {similarWorks.map((work, index) => (
              <div key={index} className="work">
                <SupervisorCard data={work} rate={selectedRates} />
              </div>
            ))}
            {similarWorksCount > 10 && (
              <Pagination
                defaultCurrent={similarWorkPageNum}
                total={similarWorksCount}
                onChange={(page) => setSimilarWorkPageNum(page)}
              />
            )}
          </>
        )}
      </div>
    </Wrapper>
  );
};
