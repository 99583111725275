import { getTime } from 'components/Cards/ScheduleCard/Came';
import moment from 'moment';
import React from 'react';

export default ({ headers, data, id }) => {
  const tableHeaders = headers.map((item) => (
    <th key={item?.title}>{item?.title}</th>
  ));

  const tableRows = data.map((item) => {
    const tableCells = headers.map((header) => {
      let cellValue = item[header?.dataIndex];
      if (header?.dataIndex === 'userName') {
        cellValue = `${item?.user?.firstName} ${item?.user?.lastName} ${item?.user?.middleName}`;
      }
      if (header?.dataIndex === 'province') {
        cellValue = item?.user?.province?.titleUZ;
      }
      if (header?.dataIndex === 'region') {
        cellValue = item?.user?.region?.titleUZ;
      }
      if (header?.dataIndex === 'district') {
        cellValue = item?.user?.district?.titleUZ;
      }
      if (header?.dataIndex === 'came') {
        cellValue =
          item.late === 0 ? moment(item.date).format('DD.MM.YY HH:mm') : '-';
      }
      if (header.dataIndex === 'late') {
        cellValue = cellValue > 0 ? getTime(cellValue) : '-';
      }

      return <td key={header?.dataIndex}>{cellValue}</td>;
    });

    return <tr key={item?.indx}>{tableCells}</tr>;
  });
  return (
    <table id={id}>
      <thead>
        <tr>{tableHeaders}</tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};
