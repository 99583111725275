import React, { useCallback, useEffect, useState } from 'react';
import {
  BodyCardWrapper,
  DashboardWrapper,
  HeaderCardWrapper,
} from './style.js';
import {
  HeaderCard,
  AreaChartCard,
  PieChartCard,
  SpecialityChartCard,
  ChartForMonthCard,
} from 'components/DashboardCard';
import { getHour, getMonthName, headerCardProLeader } from '../helper.js';
import {
  categoryColors,
  dashboardPieColors,
  dashboardPieMonthColors,
} from 'constants/colors.js';
import { Col, Empty, Row } from 'antd';
import moment from 'moment';
import Diagramma from 'components/Diagramma';
import dashboard from 'services/dashboard.js';
import Spinner from 'components/Spinner';

export default () => {
  const [time, setTime] = useState({ day: moment().format('YYYY-MM-DD') });
  const [month, setMonth] = useState('');
  const [loading, setLoading] = useState({
    headerCard: false,
    pieChart: false,
    areaChart: false,
    barChart: false,
    categoryChart: false,
    monthChart: false,
  });
  const [headerCards, setHeaderCards] = useState({});
  const [pieChartData, setPieChartData] = useState({});
  const [areaChartData, setAreaChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [categoryChartData, setCategoryChartData] = useState([]);
  const [monthlyChartData, setMonthlyChartData] = useState({});

  const getPercentage = (count, total) => {
    let percent;
    if (count && total) percent = Math.round((count * 100) / total);
    return percent > 0 ? percent : 0;
  };

  // =============================  Card 1(Header)  =======================================

  const fetchHeaderData = async () => {
    setLoading({ ...loading, headerCard: true });
    try {
      const resHead = await dashboard.getAllHeader();
      setHeaderCards(resHead);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading({ ...loading, headerCard: false });
    }
  };

  useEffect(() => {
    fetchHeaderData();
  }, []);

  // =============================  Card 2  =======================================

  const fetchPieChartData = async () => {
    setLoading({ ...loading, pieChart: true });
    try {
      const resPieChart = await dashboard.getPieChart();
      const allTotal = Object.values(resPieChart)?.reduce((a, b) => a + b);
      const newWork =
        resPieChart?.newWork !== 0
          ? Math.round((resPieChart?.newWork * 100) / allTotal)
          : 0;
      const editWork =
        resPieChart?.editWork !== 0
          ? Math.round((resPieChart?.editWork * 100) / allTotal)
          : 0;
      const sendWork =
        editWork > 0 || newWork > 0 ? 100 - (newWork + editWork) : 0;

      const finalData =
        newWork > 0 || editWork > 0
          ? [
              {
                type: 'Baholangan',
                value: sendWork,
                status: 3,
              },
              {
                type: 'Tahrirlashda',
                value: editWork,
                status: 2,
              },
              {
                type: 'Yangi',
                value: newWork,
                status: 1,
              },
            ]
          : [];
      setPieChartData(finalData);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading({ ...loading, pieChart: false });
    }
  };

  useEffect(() => {
    fetchPieChartData();
  }, []);

  const analysisOfAppeals = {
    appendPadding: 10,
    data: pieChartData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.64,
    legend: false,
    label: {
      style: {
        fontSize: 14,
      },
      formatter: function formatter(v, q) {
        return q ? q?._origin?.type + ' ' + v?.value + ' %' : v?.value + ' %';
      },
    },
    color: ({ type }) => {
      const status = pieChartData?.find((item) => item?.type === type)?.status;
      return dashboardPieColors[status];
    },
    statistic: {
      title: false,
      content: {
        style: {
          fontSize: 24,
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#080936',
          fontFamily: 'Segoe UI',
          fontStyle: 'normal',
        },
        formatter: function formatter(v) {
          return v !== undefined ? `${v?.value} %` : '100 %';
        },
      },
      pieStyle: {
        lineWidth: 0,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  };

  // =============================  Card 3  =======================================

  const fetchAreaChartData = async () => {
    setLoading({ ...loading, areaChart: true });
    try {
      const resAreaChart = await dashboard.getAreaChart(time);
      time && makeData(Object?.keys(time)[0], resAreaChart);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading({ ...loading, areaChart: false });
    }
  };

  useEffect(() => {
    fetchAreaChartData();
  }, [time]);

  const makeData = (key, data) => {
    if (key == 'day') {
      let days = Array.from({ length: 24 }).map((_, ind) => ({
        time: ind,
        value: 0,
      }));
      let dat = days.map((item) => {
        let foo = data?.filter((itm) => itm?.time == item?.time);

        if (foo?.length == 0) {
          return { time: getHour(item?.time), value: item?.value };
        } else {
          return { time: getHour(item?.time), value: foo[0]?.value };
        }
      });
      setAreaChartData(dat);
    } else if (key == 'month') {
      let count = moment().daysInMonth();
      let months = Array.from({ length: count }).map((_, ind) => ({
        time: ind + 1,
        value: 0,
      }));
      let mon = months.map((item) => {
        let foo = data?.filter((itm) => itm?.time == item?.time);
        if (foo?.length == 0) {
          return item;
        } else {
          return { time: item?.time, value: foo[0]?.value };
        }
      });
      setAreaChartData(mon);
    } else {
      let years = Array.from({ length: 13 }).map((_, ind) => ({
        time: ind,
        value: 0,
      }));
      let yer = years.map((item) => {
        let foo = data?.filter((itm) => itm?.time == item?.time);
        if (foo?.length == 0) {
          return { time: getMonthName(item?.time), value: item?.value };
        } else {
          return { time: getMonthName(item?.time), value: foo[0]?.value };
        }
      });
      setAreaChartData(yer);
    }
  };

  const areaConfig = {
    data: areaChartData,
    xField: 'time',
    yField: 'value',
    color: '#00826C',
    areaStyle: () => {
      return {
        fill: 'l(270) 0:rgba(76, 77, 220, 0) 1:rgba(76, 77, 220, 1) 100%',
      };
    },
    xAxis: {
      label: {
        rotate: -Math.PI / 4,
        offset: 18,
        autoHide: false,
      },
    },
  };

  const onChangeTime = (item) => {
    let t = moment().format('YYYY-MM-DD');
    if (item == 'Kun') {
      setTime({ day: t });
    } else if (item == 'Oy') {
      setTime({ month: t });
    } else {
      setTime({ year: t });
    }
  };

  // =============================  Card 4  =======================================

  const fetchBarChartData = async () => {
    setLoading({ ...loading, barChart: true });
    try {
      const resBarChart = await dashboard.getBarChart();
      const finalResBarChart = resBarChart
        .map((itm) => ({
          regionName: itm?._id?.titleUZ,
          percent:
            itm?.totalValue > itm?.totalPlan
              ? 100
              : getPercentage(
                  itm?.totalPlan - itm?.totalValue,
                  itm?.totalPlan
                ) ?? 0,
        }))
        .sort((a, b) => b?.percent - a?.percent);
      setBarChartData(finalResBarChart);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading({ ...loading, barChart: false });
    }
  };

  useEffect(() => {
    fetchBarChartData();
  }, []);

  // =============================  Card 5  =======================================

  const fetchCategoryCardData = async () => {
    setLoading({ ...loading, categoryChart: true });
    try {
      const resCategoryChart = await dashboard.getCategoryChart();
      setCategoryChartData(resCategoryChart);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading({ ...loading, categoryChart: false });
    }
  };

  useEffect(() => {
    fetchCategoryCardData();
  }, []);

  const getStatisticChartData = () => {
    const total = categoryChartData?.reduce((r, d) => r + d?.count, 0);
    const data = categoryChartData?.map((item, idx) => ({
      ...item,
      title: item?._id?.titleUZ,
      type: idx,
      color: categoryColors[idx],
      value: getPercentage(item?.count, total),
    }));
    return data;
  };

  const statisticOfAppealsByCategory = {
    appendPadding: 5,
    data: getStatisticChartData(),
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.64,
    height: 450,
    meta: {
      value: {
        formatter: (v) => `${v} %`,
      },
    },
    color: ({ type }) => {
      return categoryColors[type];
    },
    legend: false,
    label: false,
    statistic: {
      title: false,
      content: {
        style: {
          fontSize: 24,
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#080936',
          fontFamily: 'Segoe UI',
          fontStyle: 'normal',
        },
        formatter: function formatter(v) {
          return v !== undefined ? `${v?.value} %` : '100 %';
        },
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  };

  // =============================  Card 6  =======================================

  const currentYear = moment(new Date()).format('YYYY');
  const currentMonth =
    moment().month() + 1 < 10
      ? `0${moment().month() + 1}`
      : moment().month() + 1;

  const fetchMonthCardData = async (body) => {
    setLoading({ ...loading, monthChart: true });
    const reqBody = body ?? {
      month: `${currentYear}-${currentMonth}`,
    };
    try {
      const resMonthChart = await dashboard.getMonthChart(reqBody);
      setMonthlyChartData(resMonthChart);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading({ ...loading, monthChart: false });
    }
  };

  useEffect(() => {
    fetchMonthCardData();
  }, []);

  const monthlyReportData = [
    {
      type: 'Bajarilgan',
      value:
        monthlyChartData?.not > 0
          ? 100 - getPercentage(monthlyChartData?.not, monthlyChartData?.total)
          : monthlyChartData?.done > 0
          ? getPercentage(monthlyChartData?.done, monthlyChartData?.total)
          : 0,
      color: dashboardPieMonthColors[2],
      status: 2,
      count: monthlyChartData?.done,
    },
    {
      type: 'Bajarilmagan',
      value: getPercentage(monthlyChartData?.not, monthlyChartData?.total) ?? 0,
      color: dashboardPieMonthColors[3],
      status: 3,
      count: monthlyChartData?.not,
    },
  ];

  const monthList = useCallback(() => {
    const dataList = Array.from({ length: 12 }).map((_, i) => ({
      key: i + 1,
      label: getMonthName(i + 1),
    }));
    return dataList;
  }, []);

  const getMonthlyAnalyst = (val) => {
    setMonth(val);
    let month;
    if (val < 10) {
      month = `0${val}`;
    } else {
      month = val;
    }
    if (!val) {
      month = moment(new Date()).format('MMMM');
    }
    const reqBody = {
      month: `${currentYear}-${month}`,
    };
    fetchMonthCardData(reqBody);
  };

  const statisticOfAppealsByMonth = {
    appendPadding: 10,
    data: monthlyReportData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.64,
    legend: false,
    label: false,
    color: ({ type }) => {
      const status = monthlyReportData?.find(
        (item) => item?.type === type
      )?.status;
      return dashboardPieMonthColors[status];
    },
    statistic: {
      title: false,
      content: {
        style: {
          fontSize: 24,
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#080936',
          fontFamily: 'Segoe UI',
          fontStyle: 'normal',
        },
        formatter: function formatter(v) {
          return v !== undefined ? `${v?.value} %` : '100 %';
        },
      },
      pieStyle: {
        lineWidth: 0,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  };

  return (
    <DashboardWrapper>
      <HeaderCardWrapper>
        {headerCardProLeader?.map((item, index) => (
          <HeaderCard
            key={index}
            data={item}
            minWidth={'247px'}
            headerData={headerCards}
          />
        ))}
      </HeaderCardWrapper>
      <BodyCardWrapper>
        <Row gutter={[20, 20]} className="pie-chart-card-row">
          <Col
            span={24}
            md={{
              span: 24,
            }}
            lg={{
              span: 24,
            }}
            xl={{
              span: 10,
            }}
            xxl={{
              span: 10,
            }}
            className="pie-chart-card"
          >
            {loading?.pieChart ? (
              <div className="no-data">
                <Spinner />
              </div>
            ) : pieChartData?.length ? (
              <PieChartCard
                defaultData={pieChartData}
                analysisOfAppeals={analysisOfAppeals}
              />
            ) : (
              <div className="no-data">
                <Empty description={false} />
                {"Ma'lumot topilmadi!"}
              </div>
            )}
          </Col>
          <Col
            span={24}
            md={{
              span: 24,
            }}
            lg={{
              span: 24,
            }}
            xl={{
              span: 14,
            }}
            xxl={{
              span: 14,
            }}
            className="area-chart-card"
          >
            {loading?.areaChart ? (
              <div className="no-data area-height">
                <Spinner />
              </div>
            ) : (
              <AreaChartCard
                areaConfig={areaConfig}
                onChangeTime={onChangeTime}
                defaultValue={Object.keys(time)[0]}
              />
            )}
          </Col>
          <Col
            order={6}
            md={{
              span: 24,
              order: 6,
            }}
            lg={{
              span: 24,
              order: 6,
            }}
            xl={{
              span: 24,
              order: 6,
            }}
            xxl={{
              span: 12,
              order: 2,
            }}
            className="diagram-card-col"
          >
            {loading?.barChart ? (
              <div className="no-data">
                <Spinner />
              </div>
            ) : barChartData?.length ? (
              <>
                <div className="diagram-card-col-title">
                  Shahar-tuman bo’yicha rejalar bajarilishi
                </div>
                <Diagramma data={barChartData} />
              </>
            ) : (
              <div className="no-data">
                <Empty description={false} />
                {"Ma'lumot topilmadi!"}
              </div>
            )}
          </Col>
          <Col
            order={3}
            md={{
              span: 24,
              order: 3,
            }}
            lg={{
              span: 24,
              order: 3,
            }}
            xl={{
              span: 24,
              order: 3,
            }}
            xxl={{
              span: 12,
              order: 4,
            }}
            className="speciality-card"
          >
            <Row gutter={[20, 20]}>
              <Col
                span={24}
                md={{
                  span: 24,
                }}
                lg={{
                  span: 24,
                }}
                xl={{
                  span: 12,
                }}
                xxl={{
                  span: 24,
                }}
              >
                {loading?.categoryChart ? (
                  <div className="no-data category-chart">
                    <Spinner />
                  </div>
                ) : getStatisticChartData()?.length ? (
                  <SpecialityChartCard
                    getStatisticChartData={getStatisticChartData}
                    statisticOfAppealsByCategory={statisticOfAppealsByCategory}
                  />
                ) : (
                  <div className="no-data category-chart">
                    <Empty description={false} />
                    {"Ma'lumot tompilmadi!"}
                  </div>
                )}
              </Col>
              <Col
                span={24}
                md={{
                  span: 24,
                }}
                lg={{
                  span: 24,
                }}
                xl={{
                  span: 12,
                }}
                xxl={{
                  span: 24,
                }}
              >
                {loading?.monthChart ? (
                  <div className="no-data monthly-card">
                    <Spinner />
                  </div>
                ) : (
                  <ChartForMonthCard
                    value={month}
                    currentMonth={currentMonth}
                    getMonthlyAnalyst={getMonthlyAnalyst}
                    monthList={monthList}
                    monthlyAnalysis={monthlyChartData}
                    monthlyReportData={monthlyReportData}
                    statisticOfAppealsByMonth={statisticOfAppealsByMonth}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </BodyCardWrapper>
    </DashboardWrapper>
  );
};
