import styled from 'styled-components';

export const CardWrapper = styled.div`
  min-width: ${({ minwidth }) => (minwidth ? minwidth : '210px')};
  max-width: ${({ minwidth }) => (minwidth ? minwidth : '210px')};
  height: 93px;
  background: #fff;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;

  & .card-left {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & .card-title {
      color: #080936;
      font-family: Segoe UI;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
    & .card-count {
      color: ${({ color }) => (color ? color : '#00826C')};
      font-family: Segoe UI;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
    }
  }
  & .card-icon {
    width: 48px;
    height: 48px;
  }
`;
