import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../../components/Spinner';
import { NoData, WorkPageWrapper } from '../style';
import DatePicker from 'components/DatePicker';
import Supervisor from 'components/Cards/WorkCards/Supervisor';
import { DownloadOutlined } from '@ant-design/icons';
import works from 'services/works';
import { useShowAlert } from 'hooks';
import { Empty } from 'antd';

export default () => {
  const [loading, setLoading] = useState(false);
  const { role } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [cardType, setCardType] = useState('large');
  const [worksData, setWorksData] = useState([]);
  const { error, success } = useShowAlert();
  const { singleDate } = useSelector((state) => state.selectedDateReducer);

  const screenChecker = (innerWidth) => {
    if (innerWidth >= 1606) {
      setCardType('large');
    } else {
      setCardType('small');
    }
  };

  useEffect(() => {
    screenChecker(window.innerWidth);
    const handleResize = () => screenChecker(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const fetchWork = () => {
    setLoading(true);
    const reqBody = {
      page: 1,
      limit: 10,
      // status: 1
    };
    if (singleDate?.length) {
      reqBody['date'] = singleDate;
    }
    works
      .getSentWorksAdvanced(reqBody)
      .then(({ docs }) => {
        setWorksData(docs);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchWork();
  }, [singleDate]);

  // if (loading) return <Spinner maxHeight="100%" />;
  return (
    <WorkPageWrapper>
      <div className="filter__date" style={{ padding: ' 20px 0' }}>
        <DatePicker />
      </div>

      <div className="sent__works">
        {loading ? (
          <Spinner maxHeight="100%" />
        ) : worksData?.length > 0 ? (
          worksData?.map((work, i) => (
            <div className="work" key={i}>
              <Supervisor
                size={cardType}
                data={work}
                unrated={true}
                showLocation
              />
              <button className="download__btn">
                <DownloadOutlined size={24} />
              </button>
            </div>
          ))
        ) : (
          <NoData>
            <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
          </NoData>
        )}
      </div>
    </WorkPageWrapper>
  );
};
