import styled from 'styled-components';
import { MenuItem } from '../style';

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  .close__outside__btn {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: transparent;
    border: none;
    outline: none;
  }
`;

export const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const DropdownMenu = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 10px;
  position: absolute;
  top: 45px;
  left: -17px;
  min-width: 300px;
  max-width: 300px;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
`;

export const HamburgerMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
  cursor: pointer;
`;
export const ListItem = styled(MenuItem)`
  width: 100%;
  justify-content: flex-start;
`;
