import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 50px;
  th > div {
    color: rgb(8, 9, 54, 0.5);
  }
  th:last-child {
    padding: 0;
  }
  td:first-child {
    padding: 0 30px 0 10px;
  }
`;

export const TableHead = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  height: calc(100% - 150px);
`;
