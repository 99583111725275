import React, { useEffect, useState } from 'react';
import { Answers, Label, TestBlock, TestCreate } from './style';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import TextArea from 'components/FormElements/TextArea';
import { Input, Radio } from 'antd';
import { Button } from '../style';
import { FiPlus } from 'react-icons/fi';
import TextInput from 'components/FormElements/TextInput';
import quiz from 'services/quiz';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from 'components/Spinner';
import { useShowAlert } from 'hooks';

export default () => {
  const [questionTitle, setQuestionTitle] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { error } = useShowAlert();
  const { id } = useLocation().state;
  const [value, setValue] = useState([
    {
      title: '',
      answerOptions: [
        {
          title: '',
          isCorrect: false,
        },
        {
          title: '',
          isCorrect: false,
        },
        {
          title: '',
          isCorrect: false,
        },
      ],
    },
  ]);

  const [status, setStatus] = useState(false);

  const handleAddTest = () => {
    const newValue = {
      title: '',
      answerOptions: [
        {
          title: '',
          isCorrect: false,
        },
        {
          title: '',
          isCorrect: false,
        },
        {
          title: '',
          isCorrect: false,
        },
      ],
    };
    setValue((prev) => [...prev, newValue]);
  };

  const handleDeleteTest = (testId) => {
    const newValue = value?.filter((_, index) => index !== testId);
    setValue(() => newValue);
  };

  const handleTestTitle = (testId, e) => {
    const newValue = [...value];
    newValue[testId].title = e?.target?.value;
    setValue(() => newValue);
  };

  const handleAnswerTitle = (testId, answerId, e) => {
    const newValue = [...value];
    newValue[testId].answerOptions[answerId].title = e.target?.value;
    setValue(() => newValue);
  };

  const handleAnswerIsCorrect = (testId, answerId) => {
    const newValue = [...value];
    const newAnswers = newValue[testId].answerOptions?.map((itm, index) => ({
      ...itm,
      isCorrect: index === answerId ? true : false,
    }));
    newValue[testId].answerOptions = [...newAnswers];
    setValue(() => newValue);
  };

  const handleSubmit = () => {
    const validateTitle = value?.filter((itm) => !itm?.title?.length);
    const validateAnswer = value?.map((itm) =>
      itm?.answerOptions.filter((val) => !val?.title?.length)
    );
    const validateAnswerCheck = value?.map((itm) =>
      itm?.answerOptions.filter((val) => val?.isCorrect)
    );

    if (
      questionTitle?.length >= 3 &&
      !validateTitle?.length &&
      !validateAnswer?.flat(1)?.length &&
      validateAnswerCheck?.flat(1)?.length === value?.length
    ) {
      const sentData = {
        title: questionTitle,
        questions: [...value],
      };

      setLoading(true);
      id
        ? quiz
            .update(id, sentData)
            .then(() => {
              navigate(-1);
            })
            .catch((err) => {
              error("Test o'zgartirishda xatolik yuz berdi");
              console.log(err);
            })
            .finally(() => setLoading(false))
        : quiz
            .create(sentData)
            .then(() => {
              navigate(-1);
            })
            .catch((err) => {
              error('Test yaratishda xatolik yuz berdi');
              console.log(err);
            })
            .finally(() => setLoading(false));
    } else {
      setStatus(true);
    }
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      quiz
        .getOne(id)
        .then((res) => {
          setQuestionTitle(() => res?.title);
          setValue(() => [...res.questions]);
        })
        .catch((err) => {
          console.log(err);
          error('Xatolik yuz berdi');
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  if (loading) {
    return <Spinner maxHeight={'100%'} />;
  }
  return (
    <TestCreate>
      <div className="header">
        <div className="select-wrap">
          <Label>Savollar turini yozing</Label>
          <TextInput
            onChange={(e) => setQuestionTitle(e.target?.value)}
            value={questionTitle}
            status={questionTitle?.length <= 2 && status}
          />
        </div>
        <Button className="add-btn" onClick={handleSubmit}>
          Saqlash
        </Button>
      </div>
      <div className="blocks">
        {value?.map((item, index) => (
          <TestBlock key={index}>
            <div className="test-header">
              <div className="title-number">Test {index + 1}</div>
              {value?.length > 1 ? (
                <div
                  className="delete-icon"
                  onClick={() => handleDeleteTest(index)}
                  aria-hidden
                >
                  <DeleteIcon />
                </div>
              ) : null}
            </div>
            <div className="test-body">
              <TextArea
                descriptionValue={item?.title}
                handleDescription={(e) => handleTestTitle(index, e)}
                placeholder={'Savol'}
                status={!item?.title?.length && status}
              />
              <Radio.Group
                className="answers"
                value={
                  item?.answerOptions.findIndex((val) => val?.isCorrect) ?? ''
                }
              >
                {item?.answerOptions?.map((itm, idx) => (
                  <Answers
                    key={idx}
                    status={
                      status &&
                      item?.answerOptions.findIndex((val) => val?.isCorrect) ===
                        -1
                    }
                  >
                    <Radio
                      id={`radio${idx + 1}`}
                      value={idx}
                      onChange={() => handleAnswerIsCorrect(index, idx)}
                      status="error"
                    />
                    <div className="answer-input">
                      <Input
                        placeholder={`Variant ${idx + 1}`}
                        value={itm?.title}
                        onChange={(e) => handleAnswerTitle(index, idx, e)}
                        status={
                          status && !itm?.title?.length ? 'error' : undefined
                        }
                      />
                    </div>
                  </Answers>
                ))}
              </Radio.Group>
            </div>
          </TestBlock>
        ))}
        <div>
          <Button className="add-test" onClick={handleAddTest}>
            <FiPlus size={20} color="white" /> <span>Savol qo’shish</span>
          </Button>
        </div>
      </div>
    </TestCreate>
  );
};
