import React from 'react';
import NameCell from './CellName';
import NameCellWithIcon from './CellNameWithIcon';
import InfoCell from './CellInfo';
import LinkCell from './CellLink';
import CellShowWork from './CellShowWork';
import CellPopover from './CellPopover';
import PaymentCell from './CellPayment';

export const headerMaker = (data) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  data
    .filter(({ show }) => show)
    .map(({ type, ...rest }) => {
      if (type === 'name') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <NameCell {...value} />,
        };
      }
      if (type === 'name_rating') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <NameCellWithIcon {...value} />,
        };
      }
      if (type === 'info') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <InfoCell {...value} {...rest} />,
        };
      }
      if (type === 'link') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value, row } }) => (
            <LinkCell {...value} data={row?.original} />
          ),
        };
      }
      if (type === 'status') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value, row } }) => (
            <CellShowWork id={value} data={row?.original} />
          ),
        };
      }
      if (type === 'payment') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value, row } }) => (
            <PaymentCell {...value} data={row?.original} />
          ),
        };
      }
      if (type === 'popover') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value, row } }) => <CellPopover data={value} />,
        };
      }
      return rest;
    });

export const subData = Array.from({ length: 7 }).map((_, ind) => ({
  id: ind + 1,
  name: '“Маҳаллабай” ишлаш тизими бўйича амалга оширилаётган ишлар ҳолати, мавжуд муаммолар ва уларнинг ҳал этиш даражаси',
  count: '18 марта',
  total: '100',
}));

export const LibraryModalColumns = [
  {
    id: 1,
    Header: 'Kitob',
    accessor: 'name',
    show: true,
    width: 150,
  },
  {
    id: 2,
    Header: 'BV',
    accessor: 'startDate',
    show: true,
    width: 100,
    align: 'center',
    popover: 'Boshlagan vaqti',
  },
  {
    id: 3,
    Header: 'OBIV',
    accessor: 'lastDate',
    show: true,
    width: 100,
    align: 'center',
    popover: "Oxirgi bo'limni ishlagan vaqti",
  },
  {
    id: 4,
    Header: 'Umumiy',
    accessor: 'total',
    show: true,
    width: 70,
    align: 'center',
  },
  {
    id: 5,
    Header: 'Foizi',
    accessor: 'percent',
    show: true,
    width: 70,
    align: 'center',
  },
];
