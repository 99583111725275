import React, { useMemo, useState } from 'react';
import { useRef, useEffect } from 'react';
import { FilterGroups, MapContainer } from './style';
import { Button } from 'antd';
import L from 'leaflet';
import 'leaflet-plugins/layer/tile/Yandex';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import Select from 'components/FormElements/Select';
import { useSelector } from 'react-redux';
import { useFilter } from 'views/useFilter';
import './style.css';
import Spinner from 'components/Spinner/FullScreen';
import { useShowAlert } from 'hooks';
import uzbekistanPolygon from '../../constants/uzbekistanPolygon.json';
import './helper';
import { FiSearch } from 'react-icons/fi';
import usersSerivce from 'services/users';
import moment from 'moment';
import MapLayer from 'components/FormElements/MapLayer';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: require('../../assets/images/icon_map_marker.png'),
  iconSize: [25, 25],
  shadowSize: [0, 0],
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
});
L.Icon.extend({
  options: {
    iconUrl: '../../assets/images/icon_map_marker__active.png',
    iconSize: [36, 36],
    iconAnchor: [18, 18],
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
const LocationMap = () => {
  const mapRef = useRef(null);
  const { objects, handleFilters, regions, selectedFilters } = useFilter({
    withObject: true,
  });
  const [loading, setLoading] = useState(false);
  const { error } = useShowAlert();
  const [reload, setReload] = useState(false);
  const [advancedCoordinates, setAdvancedCoordinates] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const role = useSelector((state) => state.authReducer.role);
  const [layer, setLayer] = useState('map');

  const polyStyle = useMemo(
    () => ({
      fillColor: 'transparent',
      color: '#F69320',
    }),
    []
  );
  const objectCenter = () => {
    let sumLon = 0;
    let sumLat = 0;

    for (const coordinate of coordinates) {
      sumLon += coordinate[0];
      sumLat += coordinate[1];
    }
    const averageLon = sumLon / coordinates.length;
    const averageLat = sumLat / coordinates.length;
    const centerCoordinate = [averageLon, averageLat];
    return centerCoordinate;
  };

  useEffect(() => {
    const container = L.DomUtil.get(mapRef?.current);
    let map = L.map(container, {
      attributionControl: false,
      fadeAnimation: true,
      minZoom: 6,
      maxZoom: 18,
    }).setView(
      coordinates?.length > 0 ? objectCenter() : [40.3734, 71.7978],
      coordinates?.length > 0 ? 15 : 6
    );
    const yndx = new L.Yandex({
      type: layer,
    });
    map.addLayer(yndx);
    L.geoJson(uzbekistanPolygon, polyStyle).addTo(map);
    L.polygon(coordinates, {
      fillColor: 'transparent',
      color: '#F69320',
    }).addTo(map);
    let markers = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      zoomToBoundsOnClick: false,
      iconCreateFunction: function (cluster) {
        return L.divIcon({
          html:
            '<div class="mycluster"><div class="mycluster_count">' +
            cluster.getChildCount() +
            '</div><div class="mycluster_circle"></div></div>',
        });
      },
    }).on('clusterclick', function (e) {
      if (e.layer._zoom == 18 || e.layer._zoom > 14) {
        let ids = [];
        let markers = e.layer.getAllChildMarkers();
        markers.forEach(function (marker) {
          ids.push(marker.options.id);
        });
      } else {
        e.layer.zoomToBounds();
      }
    });
    advancedCoordinates
      .filter(
        (item) =>
          item?.location?.lat &&
          item?.location?.lng &&
          item?.location?.lat !== null &&
          item?.location?.lng !== null
      )
      .map((item) => {
        const divElement = document.createElement('div');
        // const assignBtn = document.createElement('div');
        const innerHtmlContent = document.createElement('div');
        const customPopupContent = document.createElement('div');

        const imageBox = document.createElement('div');
        imageBox.classList.add('custom-tooltip-image');
        imageBox.innerHTML = `<img src=${item?.user?.avatar} alt="" />`;
        const title = document.createElement('h3');
        title.textContent = `${item.user.lastName} ${item.user.firstName} ${item.user.middleName}`;

        const firstTitleWithIcons = document.createElement('div');
        firstTitleWithIcons.classList.add('custom-title-with-icons');

        firstTitleWithIcons.innerHTML = `
       
        <span style="color:#323232">+998 ${item?.user.phone}</span>
        `;

        const secondTitleWithIcons = document.createElement('div');
        secondTitleWithIcons.classList.add('custom-title-with-icons');

        secondTitleWithIcons.innerHTML = `
       
        <span style="color:#323232">${item?.date}</span>
        `;
        innerHtmlContent.appendChild(imageBox);
        innerHtmlContent.appendChild(title);
        // innerHtmlContent.appendChild(desc)

        customPopupContent.appendChild(firstTitleWithIcons);
        customPopupContent.appendChild(secondTitleWithIcons);

        divElement.classList.add('container');

        innerHtmlContent.appendChild(customPopupContent);
        divElement.appendChild(innerHtmlContent);
        const customPopup = divElement;
        const mark = new L.Marker([item?.location?.lat, item?.location?.lng]);
        mark.addTo(map);
        mark.bindPopup(customPopup).openPopup();
        markers.addLayer(mark);
      });

    map.addLayer(markers);
    return () => {
      map.off();
      map.remove();
    };
  }, [mapRef, coordinates, advancedCoordinates, reload]);

  useEffect(() => {
    if (selectedFilters?.district) {
      const findOne = objects.find(
        (item) => item._id === selectedFilters.district
      );

      setCoordinates(() => findOne?.coordinates ?? []);
    } else {
      setCoordinates(() => []);
    }
  }, [selectedFilters.district, layer]);

  const filterItems = [
    {
      name: 'region',
      label: 'Tuman',
      placeholder: 'Tuman(Shahar) tanlang...',
      type: 'select',
      options: regions,
      permissions: ['proleader'],
      span: 12,
    },
    {
      name: 'district',
      label: 'MFY',
      placeholder: 'MFY tanlang...',
      type: 'select',
      options: objects,
      permissions: ['proleader', 'regleader'],
      span: 12,
    },
  ];

  const getAdvancedLocation = (id) => {
    const date = moment().format('YYYY-MM-DD');
    usersSerivce
      .getAdvancedLocation(id, date)
      .then((resp) => {
        setAdvancedCoordinates(() => [resp]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setReload((prev) => !prev);
        setAdvancedCoordinates(() => []);
        error("Ma'lumotlarni yuklashda xatolik yuz berdi!");
      });
  };
  const handleSearchButton = () => {
    setLoading(true);
    const payload = {
      district: selectedFilters.district,
    };
    usersSerivce
      .findAdvancedLocation(payload)
      .then(() => getAdvancedLocation(selectedFilters?.district))
      .catch((err) => {
        setLoading(false);
        setReload((prev) => !prev);
        error("Ma'lumotlarni yuklashda xatolik yuz berdi!");
        console.log(err);
      });
  };

  if (loading) return <Spinner />;
  return (
    <>
      <FilterGroups>
        <>
          {filterItems.map(
            (item) =>
              item.permissions.includes(role) && (
                <div className="filter__item" key={item.name}>
                  <Select
                    name={item.name}
                    label={item.label}
                    placeholder={item.placeholder}
                    options={item.options}
                    onChange={(value) => handleFilters(item.name, value)}
                    title={item.label}
                    value={selectedFilters[item.name]}
                  />
                </div>
              )
          )}
        </>
        <Button
          className="search__btn action__btn"
          type="primary"
          disabled={!selectedFilters?.district}
          onClick={handleSearchButton}
        >
          <FiSearch color="#FFFFFF" size={20} />
          Joylashuvni aniqlash
        </Button>
      </FilterGroups>
      <MapContainer ref={(el) => (mapRef.current = el)} id="map">
        <div className="map-layer-wrap">
          <MapLayer
            placement={'bottomRight'}
            setValue={setLayer}
            value={layer}
            special
          />
        </div>
      </MapContainer>
    </>
  );
};

export default LocationMap;
