import React from 'react';
import { Wrapper, DownIcon, UpIcon } from './style';
// import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

export default ({ handleExpand, value, active }) => (
  <Wrapper onClick={handleExpand} active={active}>
    {value ? (
      <FaChevronUp size={14} color={active ? '#fff' : '#00826C'} />
    ) : (
      <FaChevronDown size={14} color="#70B9AC" />
    )}
  </Wrapper>
);
