import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  width: 100%;
  padding: 13px 0px;
  display: flex;
  flex-direction: column;
  gap: 27px;
`;

export const HeaderCardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 12px;
  padding-bottom: 3px;
`;

export const BodyCardWrapper = styled.div`
  width: 100%;
  & .pie-chart-card-row {
    margin: 0px !important;
    & .no-data {
      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #080936;
      font-family: 'Segoe UI';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
    & .pie-chart-card {
      padding: 24px !important;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 5px 14px 0px rgba(126, 126, 126, 0.05);
      & .chart-name {
        color: #080936;
        font-family: Segoe UI;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        padding-bottom: 69px;
      }
      & .pie-chart-container {
        width: 100%;
        display: flex;
        justify-content: center;
        & .pie-chart-wrap {
          width: 100%;
          /* width: 208px; */
          height: 208px;
        }
      }
      & .pie-chart-card-footer {
        padding-top: 32px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 40px;
        @media (max-width: 1466px) {
          gap: 20px;
        }
        & .pie-footer-card {
          display: flex;
          padding: 6px 12px;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          border-radius: 48px;
          border: 1px solid rgba(8, 9, 54, 0.1);
          background: #fff;
          & .pie-footer-title {
            color: #080936;
            font-family: Segoe UI;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }
  & .area-chart-card {
    padding: 0px 0px 0px 20px !important;
    @media (max-width: 1199.9px) {
      padding: 0px !important;
    }
    & .area-height {
      height: 445px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 5px 14px 0px rgba(126, 126, 126, 0.05);
    }
  }

  & .speciality-card {
    padding: 0px !important;
    & .category-chart {
      height: 393px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 5px 14px 0px rgba(126, 126, 126, 0.05);
    }
    & .monthly-card {
      height: 281px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 5px 14px 0px rgba(126, 126, 126, 0.05);
    }
    @media (max-width: 1599.99px) {
      padding: 0px !important;
    }
  }
`;

export const DotFooterCard = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  border: none;
`;

export const ChartCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 5px 14px 0px rgba(126, 126, 126, 0.05);
  flex-direction: column;
  height: ${({ height }) => (height ? height : '486px')};
  .body__two__col {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    .left__col {
      width: 60%;
      .items__list {
        list-style-type: none;
        width: 100%;
        li {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #9ea0a4;
          padding: 10px 0px;
          font-weight: 600;
          font-size: 13px;
          line-height: 100%;
          .text {
            width: max-content;
            margin-right: 5px;
          }
          .item__marker {
            width: 12px;
            height: 12px;
            padding: 3px 5px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 10px;
          }
          .dashed__underline {
            width: 100%;
            margin-top: 5px;
            border-bottom: 1px dashed #9ea0a4;
            margin-right: 5px;
          }
        }
      }
      .status__container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        .title__total__apeals {
          width: 100%;
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 15px;
          font-size: 14px;
          font-weight: 600;
          color: #9ea0a4;
          b {
            font-weight: 600;
            font-size: 20px;
            color: #323232;
          }
          @media (max-width: 1366px) {
            & {
              font-size: 13px;
              b {
                font-size: 13px;
              }
            }
          }
        }
        .apeals__status__list {
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .apeals__status {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            font-weight: 600;
            .status__label {
              font-size: 14px;
            }
            .percent {
              font-size: 36px;
            }
            .count {
              font-size: 14px;
            }
            @media (max-width: 1366px) {
              & {
                justify-content: baseline;
                align-items: center;

                min-height: 110px;
                .status__label {
                  font-size: 13px;
                  text-align: center;
                  height: 42px;
                }
                .percent {
                  font-size: 20px;
                }
                .count {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }

    .monthly__diagram {
      font-size: 24px;
    }
    .right__col {
      width: 40%;
      @media (max-width: 1366px) {
        .g2-html-annotation {
          font-size: 15px !important;
        }
      }
    }
  }
`;

export const ChartCardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0 30px 0;
  font-size: 18px;
  font-weight: 500;
  .card__title {
    /* width: 100%; */
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
  }
  @media (max-width: 1366px) {
    .card__title {
      font-size: 13px;
    }
  }
`;
