import types from '../../../constants/action-types';

export const setToken = (payload) => ({
  type: types.SET_TOKEN,
  payload,
});
export const setRole = (payload) => ({
  type: types.SET_ROLE,
  payload,
});
export const setUserId = (payload) => ({
  type: types.SET_USER_ID,
  payload,
});
export const setUsername = (payload) => ({
  type: types.SET_USERNAME,
  payload,
});
export const setFullname = (payload) => ({
  type: types.SET_FULLNAME,
  payload,
});
export const setRegionId = (payload) => ({
  type: types.SET_REGION_ID,
  payload,
});
export const setProvinceId = (payload) => ({
  type: types.SET_PROVINCE_ID,
  payload,
});
export const setRegionTitle = (payload) => ({
  type: types.SET_REGION_TITLE,
  payload,
});
export const setProvinceTitle = (payload) => ({
  type: types.SET_PROVINCE_TITLE,
  payload,
});
export const setgroupId = (payload) => ({
  type: types.SET_group_ID,
  payload,
});
export const clearToken = () => {
  localStorage.removeItem('agency_access_token');
  localStorage.removeItem('role');
  localStorage.removeItem('username');
  localStorage.removeItem('fullname');
  localStorage.removeItem('provinceId');
  localStorage.removeItem('regionId');
  localStorage.removeItem('user_id');
  localStorage.removeItem('provinceTitle');
  localStorage.removeItem('regionTitle');
  return {
    type: types.CLEAR_TOKEN,
  };
};
