import styled, { css } from 'styled-components';
export const centeredFlex = css`
  display: flex;
  justify-content: start;
  align-items: center;
`;
export const WorkContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 22px;
  overflow: hidden;
  box-sizing: border-box;
  .search {
    border-radius: 12px;
    width: 100%;
    height: 100%;
    max-width: 1262px;
    height: 56px;
    flex-shrink: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    .btn__group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .clear__btn {
        background: transparent;
        transition: all 0.3s;
        :hover {
          color: #6652da;
          transition: all 0.3s;
        }
        :active {
          transform: scale(0.9);
          transition: all 0.3s;
        }
      }
      .search__btn {
        border-radius: 12px;
        background: #00826C;
        transition: all 0.3s;
        :hover {
          background: #6652da;
          transition: all 0.3s;
        }
        :active {
          transform: scale(0.9);
          transition: all 0.3s;
        }
      }
      button {
        border: none;
        outline: none;
        display: flex;
        width: 58px;
        height: 48px;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        cursor: pointer;
      }
    }
  }
  .works__body {
    width: 100%;
    max-width: 1262px;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 22px;
    overflow-y: auto;
    padding: 20px 0px;
    box-sizing: border-box;

    .work {
      width: 100%;
      max-width: 1262px;
      padding: 30px;
      background-color: #fff;
      border-radius: 12px;
    }
  }
`;

export const SearchBox = styled.input`
  border-radius: 12px;
  width: 100%;
  max-width: 900px;
  height: 100%;
  flex-shrink: 0;
  background: transparent;
  border: none;
  outline: none;
  padding: 0px 20px;
  box-sizing: border-box;
`;
