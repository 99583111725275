import styled from 'styled-components';

export const AudioWrap = styled.div`
  border-radius: 10px;
  background: white;
  padding: 8px 12px;
  position: relative;
  width: 100%;
  background-color: rgb(0, 130, 108, 0.3);

  .voice-content {
    display: flex;
    align-items: center;
    gap: 13px;
    button {
      background-color: rgb(224, 240, 237);
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      outline: none;
      border: none;
      cursor: pointer;
    }

    #waveform {
      width: calc(100% - 33px);
    }
  }
`;
