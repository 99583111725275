import React, { useEffect, useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Text,
  Content,
  RightContent,
  LeftContent,
  Menu,
  MenuItem,
} from './style';
import { Button } from 'antd';
import logo from '../../../assets/logo.png';
import { clearToken } from '../../../redux/modules/auth/actions';

import { useNavigate } from 'react-router-dom';
import {
  clearSelectedDate,
  clearSelectedDateSchedule,
} from '../../../redux/modules/dates/actions';
import Logo from '../../../components/Logo';

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isActive, setIsActive] = useState(1);
  const navigate = useNavigate();

  const data = [
    { key: 1, title: "Bo'limlar", href: '/' },
    { key: 2, title: 'Kategoriya', href: '/category', path: 'category' },
    { key: 3, title: 'MFY', href: '/object', path: 'object' },
    { key: 4, title: 'Foydalanuvchilar', href: '/users', path: 'users' },
    { key: 5, title: 'Sozlamalar', href: '/setting', path: 'setting' },
    // { key: 6, title: "Статистика", href: "/statistica" },
    { key: 7, title: 'Kutubxona', href: '/books', path: 'books' },
  ];

  const handlePage = (key, href) => {
    setIsActive(key);
    navigate(href);
  };

  useEffect(() => {
    setIsActive(data?.find((item) => item.href === location.pathname)?.key);
  }, [location]);

  const handleLogOut = () => {
    dispatch(clearToken());
    dispatch(clearSelectedDate());
    dispatch(clearSelectedDateSchedule());
  };

  return (
    <Content>
      <LeftContent>
        <Logo />
        {/* <Text>АДМИН ПАНЕЛ</Text> */}
        <Menu>
          {data.map((item, index) => (
            <MenuItem
              key={index + 1}
              // isActive={isActive === item.key}
              isActive={
                location?.pathname?.split('/')?.includes(item?.path) ||
                isActive === item.key
              }
              onClick={() => {
                handlePage(item.key, item.href);
              }}
            >
              {item.title}
            </MenuItem>
          ))}
        </Menu>
      </LeftContent>
      <RightContent>
        {' '}
        <Button
          onClick={() => handleLogOut()}
          style={{ background: 'none', border: 'none' }}
          shape="circle"
          icon={<FiLogOut color="#00826C" size="25" />}
        />
      </RightContent>
    </Content>
  );
};
