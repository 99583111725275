export const evalutionRoles = [
  {
    _id: 'supervisor',
    title: 'Supervisor',
  },
  {
    _id: 'prosupervisor',
    title: 'Province supervisor',
  },
  {
    _id: 'regsupervisor',
    title: 'Region supervisor',
  },
];

export const initialValuesAdd = {
  titleUZ: '',
  titleRU: '',
  titleEN: '',
  desc: '',
  parent: '',
  evalutionRole: '',
  meet: false,
  support: false,
  webEdit: false,
  withVideo: false,
  // role: 'editor',
  // group: '',
  // public: false,
  // special: false,
  // regions: [],
  // telGroupId: '',
  // workStatus: 0,
};

export const workStatusList = [
  { _id: 0, title: 'Viloyat tekshiradi' },
  { _id: 1, title: 'Tuman tekshiradi' },
];
export const formData = {
  title: "Kategoriya qo'shish",
  data: [
    {
      type: 'text',
      label: 'Nomi(Uz)',
      name: 'titleUZ',
      value: '',
      size: 12,
      permission: ['editor'],
      isShow: ['parent', 'child'],
    },
    {
      type: 'text',
      label: 'Nomi (Ru)',
      name: 'titleRU',
      value: '',
      size: 12,
      permission: ['editor'],
      isShow: ['parent', 'child'],
    },
    {
      type: 'text',
      label: 'Nomi (Qq)',
      name: 'titleQQ',
      value: '',
      size: 12,
      permission: ['editor'],
      isShow: ['parent', 'child'],
    },

    {
      type: 'select',
      label: 'Qaysi kategoriyaga tegishli',
      name: 'parent',
      value: '',
      size: 12,
      permission: ['editor'],
      isShow: ['child'],
    },
    {
      type: 'select',
      label: 'Ishning baholanishi',
      name: 'evalutionRole',
      value: '',
      size: 24,
      permission: ['editor'],
      isShow: ['child'],
    },
    {
      type: 'textarea',
      label: 'Izoh',
      name: 'desc',
      value: '',
      size: 24,
      permission: ['editor'],
      isShow: ['parent', 'child'],
    },
    {
      type: 'switch',
      label: "Tadbir turini ko'rsatish",
      name: 'meet',
      value: '',
      size: 12,
      permission: ['editor'],
      isShow: ['child'],
    },
    {
      type: 'switch',
      label: 'Video bilan',
      name: 'withVideo',
      value: '',
      size: 12,
      permission: ['editor'],
      isShow: ['child'],
    },
    {
      type: 'switch',
      label: "Yordam ko'rsatish",
      name: 'support',
      value: '',
      size: 12,
      permission: ['editor'],
      isShow: ['child'],
    },
    {
      type: 'switch',
      label: 'Webda tahrirlash',
      name: 'webEdit',
      value: '',
      size: 12,
      permission: ['editor'],
      isShow: ['child'],
    },
  ],
};

export const WeekDays = [
  {
    title: 'Dushanba',
    _id: 1,
  },
  {
    title: 'Seshanba',
    _id: 2,
  },
  {
    title: 'Chorshanba',
    _id: 3,
  },
  {
    title: 'Payshanba',
    _id: 4,
  },
  {
    title: 'Juma',
    _id: 5,
  },
  {
    title: 'Shanba',
    _id: 6,
  },
];
