import React, { useEffect, useRef } from 'react';
import { useTable, usePagination } from 'react-table';

import {
  Cell,
  Container,
  TBody,
  TD,
  TH,
  THead,
  TR,
  Wrapper,
  Table,
  ExcelContainer,
} from './style';
import Pagination from '../ExpandedTable/Pagination';
import DownloadToExcell from 'components/DownloadToExcell';

export default ({
  headers,
  data = [],
  reportsData = [],
  height,
  withExcel,
  total,
  page,
  onChange,
  tableName = '',
  id = '',
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: headers,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: page,
    },
    usePagination
  );

  useEffect(() => {
    const sortedData = reportsData.sort((a, b) => b.length - a.length);
    if (onChange) onChange(sortedData, { pageIndex, pageSize });
  }, [onChange, pageIndex, pageSize]);

  return (
    <Container>
      {withExcel && (
        <ExcelContainer>
          <DownloadToExcell
            fileName={tableName}
            tableName={tableName}
            tableHeader={headers}
            tableData={reportsData}
          />
        </ExcelContainer>
      )}
      <Wrapper height={height}>
        <Table {...getTableProps()} id={id}>
          <THead>
            {headerGroups.map((headerGroup, i) => (
              <TR {...headerGroup.getHeaderGroupProps()} key={`${i + 1}`}>
                {headerGroup.headers.map((header, ind) => {
                  return (
                    !header.displayNone && (
                      <TH
                        {...header}
                        key={`${ind + 1}`}
                        {...header.getHeaderProps()}
                        rowSpan={header.rowSpan ? header.rowSpan : 1}
                        style={header.displayNone ? { display: 'none' } : {}}
                        className={header?.className}
                      >
                        <Cell {...header}>{header.render('Header')}</Cell>
                      </TH>
                    )
                  );
                })}
              </TR>
            ))}
          </THead>
          <TBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TR {...row.getRowProps()} key={`${i + 1}`}>
                  {row.cells.map((cell, idx) => {
                    const { column } = cell;
                    return (
                      <TD
                        {...column}
                        key={`${idx + 1}`}
                        {...cell.getCellProps()}
                      >
                        <Cell {...column}>{cell.render('Cell')}</Cell>
                      </TD>
                    );
                  })}
                </TR>
              );
            })}
          </TBody>
        </Table>
      </Wrapper>
      {total > 10 ? (
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageIndex={pageIndex}
          previousPage={previousPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageCount={pageCount}
        />
      ) : null}
    </Container>
  );
};
