import React, { useEffect } from 'react';
import { useTable, usePagination } from 'react-table';
import {
  Cell,
  Container,
  Wrapper,
  Table,
  TR,
  TBody,
  TD,
  THead,
  TREmpty,
  TH,
  PopoverItem,
} from './style';
import Spinner from '../../../components/Spinner';
import { Popover } from 'antd';

export default React.memo(
  ({
    data = [],
    headers,
    height,
    notCheckable,
    loading,
    onChange,
    page,
    subLoading,
    id,
  }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns: headers,
        data,
        manualPagination: true,
        initialState: {
          pageIndex: 0,
          pageSize: 10,
        },
        pageCount: page,
      },
      usePagination
    );

    useEffect(() => {
      if (onChange) onChange({ pageIndex, pageSize });
    }, [pageIndex, pageSize]);

    return (
      <>
        <Container>
          {loading ? (
            <Spinner maxHeight="600px" />
          ) : (
            <Wrapper height={height}>
              <>
                <Table {...getTableProps()} id={id}>
                  <THead>
                    {headerGroups.map((headerGroup, idx) => (
                      <TR
                        {...headerGroup.getHeaderGroupProps()}
                        notCheckable={notCheckable}
                        key={`${idx + 1}`}
                      >
                        {headerGroup.headers.map((header, index) => (
                          <TH key={`${index + 1}`} {...header}>
                            {header?.popover ? (
                              <>
                                <Popover
                                  content={
                                    <PopoverItem>{header?.popover}</PopoverItem>
                                  }
                                  trigger="hover"
                                >
                                  <Cell {...header}>
                                    {header.render('Header')}
                                  </Cell>
                                </Popover>
                              </>
                            ) : (
                              <Cell {...header}>{header.render('Header')}</Cell>
                            )}
                          </TH>
                        ))}
                      </TR>
                    ))}
                  </THead>

                  <TBody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                      prepareRow(row);
                      return (
                        <React.Fragment key={`${index + 1}`}>
                          <TREmpty />
                          <TR $loading={subLoading}>
                            {row.cells.map((cell, ind) => {
                              const { column } = cell;
                              return (
                                <React.Fragment key={`${ind + 1}`}>
                                  <TD
                                    {...column}
                                    className={data.length > 6 && 'body-cell'}
                                  >
                                    <Cell {...column}>
                                      {cell.render('Cell')}
                                    </Cell>
                                  </TD>
                                </React.Fragment>
                              );
                            })}
                          </TR>
                        </React.Fragment>
                      );
                    })}
                  </TBody>
                </Table>
              </>
            </Wrapper>
          )}
        </Container>
      </>
    );
  }
);
