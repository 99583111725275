import styled from 'styled-components';

export const Button = styled.button`
  outline: none;
  padding: 12px;
  box-sizing: border-box;
  box-shadow: 0 var(--shadow-y) var(--shadow-blur) 0 var(--shadow-color);
  transition: box-shadow 0.1s ease-out, background-color 0.1s ease-out;
  border-radius: 10px;
  background-color: ${({ $special }) =>
    $special ? '#fff' : 'var(--map-filter-color)'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  gap: 10px;
  z-index: 1201;
  border: 1px solid
    ${({ $special }) => ($special ? '#CCC' : 'var(--map-filter-color)')};
  font-style: normal;
  cursor: pointer;
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  img {
    width: 24px;
    height: 24px;
  }
`;

export const MapLayerWrap = styled.div`
  padding: 10px;

  .ant-segmented {
    border-radius: 10px;
    .ant-segmented-thumb {
      border-radius: 10px;
    }

    .ant-segmented-item {
      border-radius: 10px;
      height: 44px;
      overflow: hidden;
      .ant-segmented-item-label {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 550;
        border-radius: 10px;
        user-select: none;
      }
    }
  }
`;
