import React, { useEffect, useState } from 'react';
import { CardContent } from './style';
import InfoSection from 'components/Cards/WorkCard/InfoSection';
import Desc from './Desc';
import { useSelector } from 'react-redux';
import ImagesBlock from '../Contents/ImageGallery';
import { useShowModal } from 'hooks';
import MapY from 'components/Map/MapModal';

const YouthLeaderCard = ({
  data,
  handleChange,
  canDeleteImage,
  handleDeleteImage,
  size = 'large',
  desc = '',
  showLocation = false,
}) => {
  const [geoLocation, setGeoLocation] = useState([]);
  const [mainImage, setMainImage] = useState({});
  const { lang } = useSelector((state) => state.appReducer);
  const [expandedText, setExpandedText] = useState(false);
  const [mediaData, setMediaData] = useState([]);

  const { showBlured } = useShowModal();
  useEffect(() => {
    if (
      data?.media !== undefined &&
      data?.media !== null &&
      data?.media?.length > 0
    ) {
      const media = data?.media.map((item) => ({ ...item, isImage: true }));
      setMediaData(media);
      if (data.video.length > 0) {
        const video = data?.video.map((item) => ({
          ...item,
          videoUrl: item ?? '',
          isImage: false,
        }));
        setMediaData((prev) => [...prev, ...video]);
      }
      const latLng = [data?.media[0]?.lat, data?.media[0]?.lng];
      if (latLng !== undefined && latLng !== null) {
        setGeoLocation(latLng);
        setMainImage(data?.media && data?.media[0]);
      }
    } else {
      setGeoLocation('40.5 71.25');
    }
  }, [data]);

  const checkActive = (image) => {
    if (image === mainImage?.image) return true;
  };

  const handleMainImage = (image) => {
    const latLng = [image?.lat, image?.lng];
    setGeoLocation(latLng);
    setMainImage(image);
  };
  const onChange = (value, workId) => {
    handleChange(value, workId);
  };

  const handleShowModal = () => {
    showBlured({
      title: '',
      maxWidth: '1200px',
      height: '600px',
      withHeader: false,
      body: () => <MapY latLang={geoLocation} />,
    });
  };
  return (
    <CardContent size={size} expanded={expandedText}>
      <div className="description">
        <Desc
          title={data.subCategory?.['title' + lang]}
          value={desc}
          onChange={onChange}
        />
      </div>
      <div className="info">
        <InfoSection
          data={data}
          size={size}
          unrated
          handleShowModal={handleShowModal}
          showLocation={showLocation}
        />
      </div>
      <div className="image__gallery">
        <ImagesBlock
          imageData={mediaData}
          checkActive={checkActive}
          handleMainImage={handleMainImage}
          mainImage={mainImage}
          canDeleteImage={canDeleteImage}
          deleteImage={handleDeleteImage}
        />
      </div>
    </CardContent>
  );
};

export default YouthLeaderCard;
