import { service } from '.';

export default {
  getAll: () => service.get('/web/quiz'),
  getAllWithPaginate: (data) => service.post('/web/quiz/paginate', data),
  getOne: (id) => service.get(`/web/quiz/${id}`),
  create: (data) => service.post(`/web/quiz`, data),
  update: (id, data) => service.put(`/web/quiz/${id}`, data),
  delete: (id) => service.delete(`/web/quiz/${id}`),

  duplicate: (id) => service.get(`/web/quiz/duplicate/${id}`),
  completed: (id, data) => service.put(`/web/quiz/update/${id}`, data),
  getOneTest: (query) =>
    service.get(`/web/webReport/quizReportLeader?${query}`),

  getUserAnswer: (query) =>
    service.get(`/web/webReport/quizReportOneUser?${query}`),
};
