import React from 'react';
import { ChartCard, ChartCardHeader } from './style';
import { Segmented } from 'antd';
import { Area } from '@ant-design/plots';

const AreaChartCard = ({ areaConfig, onChangeTime, defaultValue }) => {
  const value = {
    day: 'Kun',
    month: 'Oy',
    year: 'Yil',
  };

  return (
    <ChartCard height={'100%'}>
      <ChartCardHeader>
        <div>Ma’lumotlarni kelib tushish statistikasi</div>
        <Segmented
          options={['Kun', 'Oy', 'Yil']}
          onChange={(e) => onChangeTime(e)}
          defaultValue={value[defaultValue]}
        />
      </ChartCardHeader>
      <div className="area-chart-container">
        <Area {...areaConfig} />
      </div>
    </ChartCard>
  );
};

export default React.memo(AreaChartCard);
