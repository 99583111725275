import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Row } from 'antd';
import './style.js';
import moment from 'moment';
import { ModalBody } from './style.js';
import { useHideModal } from 'hooks/modal.js';
import { useFormik } from 'formik';
import TimePicker from 'components/FormElements/TimePicker';
import TextInput from 'components/FormElements/TextInput/index.jsx';
import events from 'services/events.js';
import * as Yup from 'yup';

const formValidationSchema = Yup.object().shape({
  title: Yup.string().required('Tadbir nomi kiritilmadi'),
  date: Yup.string().required('Sana kiritilmadi'),
  start: Yup.string().required('Boshlanish vaqti kiritilmadi'),
  end: Yup.string()
    .required('Tugash vaqti kiritilmadi')
    .test(
      'is-greater',
      "Tugash vaqti kattaroq bo'lishi lozim",
      function (value) {
        const { start } = this.parent;
        return moment(value, 'HH:mm').isSameOrAfter(moment(start, 'HH:mm'));
      }
    ),
});

export default ({ id, handleSubmit }) => {
  const { hideModal } = useHideModal();

  const formik = useFormik({
    validationSchema: formValidationSchema,
    initialValues: {
      date: moment(new Date()).format('YYYY-MM-DD'),
      title: '',
      start: '',
      end: '',
    },
    onSubmit: (values) => {
      // handleSubmit(id, values);
      console.log('values', values);
    },
  });

  useEffect(() => {
    if (id) {
      //get one logic
      events.getOne(id).then((res) => {
        formik.setValues({
          date: moment(res.date).format('YYYY-MM-DD'),
          title: res.title,
          start: res.start,
          end: res.end,
        });
      });
    }
  }, [id]);

  const formBody = [
    {
      name: 'title',
      label: 'Tadbir nomi',
      type: 'text',
      placeholder: 'Tadbir nomi',
    },
    {
      name: 'date',
      label: 'Sana',
      type: 'date',
      placeholder: 'Sana',
    },
    {
      name: 'start',
      label: 'Boshlanish vaqti',
      type: 'time',
      placeholder: 'Boshlanish vaqti',
    },
    {
      name: 'end',
      label: 'Tugash vaqti',
      type: 'time',
      placeholder: 'Tugash vaqti',
    },
  ];

  const handleDateChange = (dateString, name) => {
    const formattedDate = dateString
      ? moment(dateString).format('YYYY-MM-DD')
      : null;
    formik.setFieldValue(name, formattedDate);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalBody>
        <Row gutter={[16, 18]} justify="start" align="top">
          {formBody.map((item, index) => (
            <Col span={item.type === 'time' ? 12 : 24} key={index}>
              {item.type === 'text' ? (
                <div className="form__group">
                  <label className="form__title" htmlFor={item.name}>
                    {item.label}
                  </label>
                  <TextInput
                    type={item.type}
                    name={item.name}
                    id={item.name}
                    placeholder={item.placeholder}
                    onChange={formik.handleChange}
                    value={formik?.values[item.name]}
                  />
                  {formik.errors[item.name] && formik.touched[item.name] && (
                    <div className="error-message">
                      {formik.errors[item.name]}
                    </div>
                  )}
                </div>
              ) : item.type === 'time' ? (
                <div className="form__group">
                  <label className="form__title" htmlFor={item.name}>
                    {item.label}
                  </label>
                  <TimePicker
                    type={item.type}
                    name={item.name}
                    id={item.name}
                    placeholder={item.placeholder}
                    onChange={(time) => formik.setFieldValue(item.name, time)}
                    value={formik?.values[item.name]}
                  />
                  {formik.errors[item.name] && formik.touched[item.name] && (
                    <div className="error-message">
                      {formik.errors[item.name]}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="form__group date__picker"
                  style={
                    formik.touched['date'] && formik.errors['date']
                      ? { marginBottom: '50px' }
                      : { marginBottom: 0 }
                  }
                >
                  <label className="form__title" htmlFor={item.name}>
                    {item.label}
                  </label>

                  <div className="date-picker">
                    <DatePicker
                      placeholder="Sanani belgilang"
                      onChange={(_, dateString) =>
                        handleDateChange(dateString, item.name)
                      }
                      format="YYYY-MM-DD"
                      value={
                        formik.values[item.name]
                          ? moment(formik.values[item.name])
                          : null
                      }
                    />
                  </div>
                  {formik.errors[item.name] && formik.touched[item.name] && (
                    <div className="error-message">
                      {formik.errors[item.name]}
                    </div>
                  )}
                </div>
              )}
            </Col>
          ))}
        </Row>
        <div
          className="action-button"
          style={
            (formik.touched['start'] && formik.errors['start']) ||
            (formik.touched['end'] && formik.errors['end'])
              ? { marginTop: '30px' }
              : { marginTop: 0 }
          }
        >
          <button
            type="button"
            size="large"
            onClick={() => hideModal()}
            className="cancel-btn"
          >
            Bekor qilish
          </button>
          <button type="submit" className="submit-btn" size="large">
            {id ? 'Yangilash' : 'Yaratish'}
          </button>
        </div>
      </ModalBody>
    </form>
  );
};
