import Layout from '../containers/Home';
import Auth from '../containers/Auth';
import Login from '../views/Auth';
import { Navigate } from 'react-router-dom';
import {
  DailyRating,
  Reports,
  Specialities,
  TimeInterval,
  Search,
  Statistics,
  Tasks,
  SpecialTaskDetail,
  SpecialTasks,
  SpecialitiesDetail,
  LibraryReports,
  OnlineLibrary,
  // IbratFarzandlari,
  Payments,
  Complaints,
  Tests,
} from '../views/Web';

import Supervisor from 'views/Web/Supervisor';
import RatedWorks from 'views/Web/Supervisor/SimilarWorks';
import UserInActive from 'views/Web/Supervisor/UserInActive';

import Category from '../views/Admin/Category';
import Object from '../views/Admin/Object';

import Sections from '../views/Admin/Sections';
import Users from '../views/Admin/Users';
import FormCreator from '../components/FormCreator';
import Setting from '../views/Admin/Setting';
import Dashboard from 'views/Dashboard';
import Schedule from 'views/Web/Schedule';
import SendTasks from 'views/Web/Tasks/SendTasks';
import { NewWorks } from 'views/Web/Works';
import { SentWorks } from 'views/Web/Works';
import Events from 'views/Web/Supervisor/Events';
import Books from 'views/Admin/Books';
import CreateTest from 'views/Web/Tests/CreateTest';
import GetOneTest from 'views/Web/Tests/GetOneTest';
import ByRegion from 'views/Web/Tests/ByRegion';
import ByUser from 'views/Web/Tests/ByUser';
import CreateBookTest from 'views/Admin/Books/CreateBookTest';
import LocationMap from 'views/LocationMap';

export const leadersRoutes = [
  {
    element: <Layout />,
    children: [
      { path: '/', element: <Dashboard /> },
      { path: '/specialities', element: <Specialities /> },
      { path: '/specialities/detail/:id', element: <SpecialitiesDetail /> },
      { path: '/detailed', element: <Specialities /> },
      { path: '/daily-rating', element: <DailyRating /> },
      { path: '/time-interval', element: <TimeInterval /> },
      { path: '/reports', element: <Reports /> },
      { path: '/search', element: <Search /> },
      { path: '/statistics', element: <Statistics /> },
      { path: '/schedule', element: <Schedule /> },
      { path: '/special-tasks', element: <SpecialTasks /> },
      { path: '/online-library', element: <OnlineLibrary /> },
      {
        path: '/library-report',
        element: <LibraryReports />,
      },
      // {
      //   path: '/external-app',
      //   element: <IbratFarzandlari />,
      // },
      { path: '/special-tasks/:id', element: <SpecialTaskDetail /> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
  { path: '/login', index: true, element: <Login /> },
];

export const provinceLeadersRoutes = [
  {
    element: <Layout />,
    children: [
      { path: '/', element: <Dashboard /> },
      { path: '/specialities', element: <Specialities /> },
      { path: '/specialities/detail/:id', element: <SpecialitiesDetail /> },
      { path: '/detailed', element: <Specialities /> },
      { path: '/daily-rating', element: <DailyRating /> },
      { path: '/time-interval', element: <TimeInterval /> },
      { path: '/reports', element: <Reports /> },
      { path: '/search', element: <Search /> },
      { path: '/statistics', element: <Statistics /> },
      { path: '/schedule', element: <Schedule /> },
      { path: '/location', element: <LocationMap /> },
      {
        path: '/tasks',
        element: <Tasks />,
      },
      { path: '/tasks/send', element: <SendTasks /> },
      { path: '/special-tasks', element: <SpecialTasks /> },
      { path: '/special-tasks/:id', element: <SpecialTaskDetail /> },
      { path: '/online-library', element: <OnlineLibrary /> },
      {
        path: '/library-report',
        element: <LibraryReports />,
      },
      // { path: '/external-app', element: <IbratFarzandlari /> },
      { path: '/complaints', element: <Complaints /> },
      { path: '/payments', element: <Payments /> },
      { path: '/tests', element: <Tests /> },
      { path: '/tests/create', element: <CreateTest /> },
      { path: '/tests/get-one', element: <GetOneTest /> },
      { path: '/tests/get-one/region', element: <ByRegion /> },
      { path: '/tests/get-one/region/user', element: <ByUser /> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
  { path: '/login', index: true, element: <Login /> },
];

export const regionLeadersRoutes = [
  {
    element: <Layout />,
    children: [
      { path: '/', element: <Dashboard /> },
      { path: '/specialities', element: <Specialities /> },
      { path: '/specialities/detail/:id', element: <SpecialitiesDetail /> },
      { path: '/detailed', element: <Specialities /> },
      { path: '/daily-rating', element: <DailyRating /> },
      { path: '/time-interval', element: <TimeInterval /> },
      { path: '/reports', element: <Reports /> },
      { path: '/search', element: <Search /> },
      { path: '/statistics', element: <Statistics /> },
      { path: '/schedule', element: <Schedule /> },
      { path: '/location', element: <LocationMap /> },
      {
        path: '/tasks',
        element: <Tasks />,
      },
      { path: '/tasks/send', element: <SendTasks /> },
      { path: '/special-tasks', element: <SpecialTasks /> },
      { path: '/special-tasks/:id', element: <SpecialTaskDetail /> },
      { path: '/online-library', element: <OnlineLibrary /> },
      {
        path: '/library-report',
        element: <LibraryReports />,
      },
      // { path: '/external-app', element: <IbratFarzandlari /> },
      { path: '/tests', element: <Tests /> },
      { path: '/tests/create', element: <CreateTest /> },
      { path: '/tests/get-one', element: <GetOneTest /> },
      { path: '/tests/get-one/region', element: <ByRegion /> },
      { path: '/tests/get-one/region/user', element: <ByUser /> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
  { path: '/login', index: true, element: <Login /> },
];

export const advancedRoutes = [
  {
    element: <Layout />,
    children: [
      { path: '/', element: <Specialities /> },
      { path: '/detailed', element: <Specialities /> },
      { path: '/daily-rating', element: <DailyRating /> },
      { path: '/time-interval', element: <TimeInterval /> },
      { path: '/new-works', element: <NewWorks /> },
      { path: '/works', element: <SentWorks /> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
  { path: '/login', index: true, element: <Login /> },
];

export const adminRoutes = [
  {
    element: <Layout />,
    children: [
      { path: '/', element: <Sections /> },
      { path: '/category', element: <Category /> },
      { path: '/object', element: <Object /> },
      { path: '/setting', element: <Setting /> },
      { path: '/add', element: <FormCreator /> },
      { path: '/users', element: <Users /> },
      { path: '/books', element: <Books /> },
      { path: '/books/add', element: <CreateBookTest /> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
  { path: '/login', index: true, element: <Login /> },
];
export const supervisorRoutes = [
  {
    element: <Layout />,
    children: [
      { path: '/', element: <Supervisor /> },
      { path: '/rated-works', element: <RatedWorks /> },
      { path: '/users', element: <UserInActive /> },
      // { path: '/events', element: <Events /> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
  { path: '/login', index: true, element: <Login /> },
];

export const publicRoutes = [
  {
    element: <Auth />,
    children: [
      { path: '/login', index: true, element: <Login /> },
      { path: '*', element: <Navigate to="/login" /> },
    ],
  },
];
