import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

const ImageBlock = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #c2c2c240;
  border-radius: 6px;
  img {
    border-radius: 6px;
    width: 100px;
    height: 100px;
    border-radius: 6px;
  }
`;

export default ({ handleUpload, value, formik, name, deleteImage, userId }) => {
  const [file, setFile] = useState(null);
  const props = {
    name,
    imageUrl: value,
    beforeUpload: (file) => {
      if (file) {
        handleUpload(file);
        const image = URL.createObjectURL(file);
        setFile(image);
      }
      return false;
    },
  };
  const handleDelete = () => {
    if (typeof value !== 'string') {
      setFile(null);
      formik.setFieldValue(name, '');
    } else {
      deleteImage(userId, formik, name);
    }
  };

  return (
    <>
      {value ? (
        <ImageBlock>
          <img src={typeof value === 'string' ? value : file} alt={value} />{' '}
          <Button onClick={handleDelete} icon={<CloseOutlined />} />
        </ImageBlock>
      ) : (
        <Upload {...props} file={value} maxCount={1}>
          <Button icon={<UploadOutlined />}>Расм юклаш</Button>
        </Upload>
      )}
    </>
  );
};
