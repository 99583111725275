const regions = [
  "Фарғона шаҳар",
  "Марғилон шаҳар",
  "Қўқон шаҳар",
  "Қувасой шаҳар",
  "Олтиариқ туман",
  "Қуштепа туман",
  "Риштон туман",
  "Тошлоқ туман",
  "Ёзёвон туман",
  "Қува туман",
  "Фарғона туман",
  "Учкўприк туман",
  "Бувайда туман",
  "Бешариқ туман",
  "Ўзбекистон туман",
  "Данғара туман",
  "Фурқат туман",
  "Сўҳ тумани",
  "Боғдод тумани",
];
export const mockData = Array.from({ length: 20 }).map((_, idx) => ({
  id: idx + 1,
  region: regions[Math.floor(Math.random() * 19)],
  plan_works: Math.floor(Math.random() * 30),
  completed_works: Math.floor(Math.random() * 30),
  percent: Math.floor(Math.random() * 30),
  rating: Math.floor(Math.random() * 100),
}));
