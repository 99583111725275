import React from 'react';
import {
  MainImage,
  ImageList,
  ImageThumbnail,
  ImagesBlock,
  VideoThumbnail,
} from './style';
import NO_Image from 'assets/images/no_photo.png';
import VideoThumb from 'assets/images/effect_blur.jpg';
import Image from '../Image';
import { useState } from 'react';
import ModalPlayer from 'components/ModalPlayer';
import { PlayCircleOutlined } from '@ant-design/icons';

const ImagesContainer = ({
  imageData = [],
  mainImage,
  handleMainImage,
  checkActive,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const showPlayer = (url) => {
    setVideoUrl(url);
    setModalOpen(!modalOpen);
  };

  return (
    <>
      {modalOpen && (
        <ModalPlayer
          url={videoUrl}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}

      <ImagesBlock>
        <MainImage>
          {Object.keys(mainImage).length > 0 ? (
            <>
              <Image
                image={`${mainImage?.image}`}
                alt={`image ${mainImage?.image}`}
                images={imageData}
              />
            </>
          ) : imageData?.length > 0 ? (
            <VideoThumbnail
              width="100%"
              height="100%"
              onClick={() => showPlayer(imageData[0]?.videoUrl)}
            >
              <img
                width="100%"
                height="100%"
                className="effect__blur"
                src={VideoThumb}
                alt="video frame"
              />
              <PlayCircleOutlined className="icon play__icon" />
            </VideoThumbnail>
          ) : (
            <>
              <img
                style={{ width: '100%', height: '100%' }}
                src={NO_Image}
                alt="no_image"
              />
            </>
          )}
        </MainImage>
        <ImageList>
          {imageData !== undefined && imageData?.length > 0 ? (
            <>
              {imageData?.map((item, index) =>
                item.isImage ? (
                  <ImageThumbnail
                    onClick={() => handleMainImage(item)}
                    key={index}
                    active={checkActive(item.image)}
                  >
                    <img
                      width="100%"
                      height="100%"
                      src={`${item?.thumb}`}
                      alt={`img file ${item?.thumb}`}
                    />
                  </ImageThumbnail>
                ) : (
                  <VideoThumbnail
                    key={index}
                    onClick={() => showPlayer(item?.videoUrl)}
                  >
                    <img
                      width="100%"
                      height="100%"
                      className="effect__blur"
                      src={VideoThumb}
                      alt="video frame"
                    />
                    <PlayCircleOutlined className="icon play__icon__thumb" />
                  </VideoThumbnail>
                )
              )}
            </>
          ) : (
            <ImageThumbnail
              onClick={() => {}}
              // key={index}
              active={checkActive(false)}
            >
              <img
                width="100%"
                height="100%"
                src={NO_Image}
                alt="no_images list"
              />
            </ImageThumbnail>
          )}
        </ImageList>
      </ImagesBlock>
    </>
  );
};

export default React.memo(ImagesContainer);
