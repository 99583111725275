import React from 'react';
import { Wrapper } from './style';
import { useNavigate } from 'react-router-dom';
import { CiShare1 } from 'react-icons/ci';

export default ({ value, id, data }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/special-tasks/${id}`, {
      state: { value, forReference: data?.forReference },
    });
  };

  return (
    <>
      <Wrapper onClick={handleNavigate}>
        {value} <CiShare1 size={16} />
      </Wrapper>
    </>
  );
};
