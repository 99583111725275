import React from 'react';
import { Select } from 'antd';
import { Wrapper, Title } from './style';
import { useSelector } from 'react-redux';

const { Option } = Select;

export default ({
  handleChange,
  options = [],
  title = '',
  placeholder = '',
  defaultVal,
  disabled,
  id,
  name,
  status,
  size,
  objSelect,
  multiple = false,
  allowClear = true,
  ...rest
}) => {
  const { lang } = useSelector((state) => state.appReducer);

  const handleSelectChange = (e, id) => {
    if (multiple && e.includes('all')) {
      const regIds = options.map((item) => item._id);
      regIds.shift();
      handleChange(regIds, id);
    } else {
      handleChange(e, id);
    }
  };

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {objSelect == undefined ? (
        <Select
          allowClear={allowClear}
          size={size}
          status={status}
          dropdownStyle={{
            borderRadius: '10px',
          }}
          style={{
            maxWidth: multiple && '300px',
          }}
          mode={multiple && 'multiple'}
          name={name}
          disabled={disabled}
          value={defaultVal}
          onChange={(e) => handleSelectChange(e, id)}
          placeholder={placeholder}
          {...rest}
        >
          {options.map((item, idx) => (
            <Option key={`${idx + 1}`} value={item._id ?? item.title}>
              {item?.title ?? item?.titleUZ}
            </Option>
          ))}
        </Select>
      ) : (
        <Select
          style={{
            minWidth: 200,
            border: 'none',
          }}
          status={status}
          allowClear
          showSearch
          optionFilterProp="children"
          onChange={(value) => {
            handleChange(value);
          }}
          value={defaultVal}
          // onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {options?.map((item) => {
            return (
              <Option key={item?._id}>
                {item?.title ?? item?.['title' + lang]}
              </Option>
            );
          })}
        </Select>
      )}
    </Wrapper>
  );
};
