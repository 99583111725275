export const categoryHeaders = (categories = []) => {
  const parentCategories = categories.filter((item) => !item.parent);
  return parentCategories?.map((item, ind) => {
    return {
      id: ind + 300,
      Header: item?.title,
      accessor: item?._id,
      show: true,
      width: 150,
      align: 'center',
      border: true,
      // pl: 30,
      type: 'info',
      expanded: true,
    };
  });
};
