import styled from "styled-components";

export const Box = styled.div`
  margin: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const TextContainer = styled.div`
  padding: 20px;
`;
