import { ReactComponent as DashboardIcon } from '../../assets/icons/sidebar/dashboard.svg';
import { ReactComponent as LayerIcon } from '../../assets/icons/sidebar/layer.svg';
import { ReactComponent as DailyRatingIcon } from '../../assets/icons/sidebar/daily-rating.svg';
import { ReactComponent as TimeIntervalIcon } from '../../assets/icons/sidebar/time-interval.svg';
import { ReactComponent as ReportIcon } from '../../assets/icons/sidebar/report.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/sidebar/search.svg';
import { ReactComponent as MapIcon } from '../../assets/icons/sidebar/map.svg';
import { ReactComponent as WorkControlIcon } from '../../assets/icons/sidebar/work-control.svg';
import { ReactComponent as UserStatusIcon } from '../../assets/icons/sidebar/user-status.svg';
import { ReactComponent as TasksIcon } from '../../assets/icons/sidebar/tasks.svg';
import { ReactComponent as SpecialTasksIcon } from '../../assets/icons/sidebar/special-tasks.svg';
import { ReactComponent as WorksIcon } from '../../assets/icons/sidebar/sended-works.svg';
import { ReactComponent as NewWorksIcon } from '../../assets/icons/sidebar/new-works.svg';
import { ReactComponent as LibraryIcon } from '../../assets/icons/sidebar/book.svg';
import { ReactComponent as ComplaintsIcon } from '../../assets/icons/sidebar/message-question.svg';
import { ReactComponent as TestsIcon } from '../../assets/icons/sidebar/tests.svg';

export const leeadersTabs = [
  {
    title: 'Dashboard',
    path: '/',
    icon: DashboardIcon,
  },
  {
    title: 'Sohalar',
    path: '/specialities',
    icon: LayerIcon,
  },
  { title: 'Kunlik reyting', path: '/daily-rating', icon: DailyRatingIcon },
  { title: 'Vaqt kesimida', path: '/time-interval', icon: TimeIntervalIcon },
  { title: 'Hisobot', path: '/reports', icon: ReportIcon },
  { title: 'Qidirish', path: '/search', icon: SearchIcon },
  {
    title: "Xaritada ko'rish",
    path: '/map',
    icon: MapIcon,
  },
  {
    title: 'Mehnat intizomi',
    path: '/schedule',
    icon: WorkControlIcon,
  },
  // {
  //   title: 'Foydalanish holati',
  //   path: '/payments',
  //   icon: UserStatusIcon,
  // },
  {
    title: 'Maxsus topshiriqlar',
    path: '/special-tasks',
    icon: SpecialTasksIcon,
  },
  {
    title: 'Elektron kutubxona',
    path: '/online-library',
    icon: LibraryIcon,
    children: [
      {
        sTitle: 'Kutubxona',
        sPath: '/online-library',
      },
      {
        sTitle: 'Hisobot',
        sPath: '/library-report',
      },
    ],
  },
  // {
  //   title: 'Ibrat farzandlari',
  //   path: '/external-app',
  //   icon: UserStatusIcon,
  // },

  // { title: 'Натижавий хисобот', path: '/statistics' },  //keyingi versionda qo'shiladi!
];

export const provinceLeaderTabs = [
  {
    title: 'Dashboard',
    path: '/',
    icon: DashboardIcon,
  },
  {
    title: 'Sohalar',
    path: '/specialities',
    icon: LayerIcon,
  },
  { title: 'Kunlik reyting', path: '/daily-rating', icon: DailyRatingIcon },
  { title: 'Vaqt kesimida', path: '/time-interval', icon: TimeIntervalIcon },
  { title: 'Hisobot', path: '/reports', icon: ReportIcon },
  { title: 'Qidirish', path: '/search', icon: SearchIcon },
  {
    title: "Xaritada ko'rish",
    path: '/map',
    icon: MapIcon,
  },
  {
    title: 'Joylashuvni aniqlash',
    path: '/location',
    icon: MapIcon,
  },
  {
    title: 'Mehnat intizomi',
    path: '/schedule',
    icon: WorkControlIcon,
  },
  // {
  //   title: 'Foydalanish holati',
  //   path: '/payments',
  //   icon: UserStatusIcon,
  // },
  {
    title: 'Yuqoridan topshiriqlar',
    path: '/tasks',
    icon: TasksIcon,
  },
  {
    title: 'Maxsus topshiriqlar',
    path: '/special-tasks',
    icon: SpecialTasksIcon,
  },
  {
    title: 'Elektron kutubxona',
    path: '/online-library',
    icon: LibraryIcon,
    children: [
      {
        sTitle: 'Kutubxona',
        sPath: '/online-library',
      },
      {
        sTitle: 'Hisobot',
        sPath: '/library-report',
      },
    ],
  },
  // { title: 'Ibrat farzandlari', path: '/external-app', icon: UserStatusIcon },
  {
    title: "To'lov holati",
    path: '/payments',
    icon: DailyRatingIcon,
  },
  {
    title: 'Shikoyatlar',
    path: '/complaints',
    icon: ComplaintsIcon,
    withNotification: true,
  },
  {
    title: 'Testlar',
    path: '/tests',
    icon: TestsIcon,
    withNotification: true,
  },
];

export const regionLeaderTabs = [
  {
    title: 'Dashboard',
    path: '/',
    icon: DashboardIcon,
  },
  {
    title: 'Sohalar',
    path: '/specialities',
    icon: LayerIcon,
  },
  { title: 'Kunlik reyting', path: '/daily-rating', icon: DailyRatingIcon },
  { title: 'Vaqt kesimida', path: '/time-interval', icon: TimeIntervalIcon },
  { title: 'Hisobot', path: '/reports', icon: ReportIcon },
  { title: 'Qidirish', path: '/search', icon: SearchIcon },
  {
    title: "Xaritada ko'rish",
    path: '/map',
    icon: MapIcon,
  },
  {
    title: 'Joylashuvni aniqlash',
    path: '/location',
    icon: MapIcon,
  },
  {
    title: 'Mehnat intizomi',
    path: '/schedule',
    icon: WorkControlIcon,
  },
  // {
  //   title: 'Foydalanish holati',
  //   path: '/payments',
  //   icon: UserStatusIcon,
  // },
  {
    title: 'Yuqoridan topshiriqlar',
    path: '/tasks',
    icon: TasksIcon,
  },
  {
    title: 'Maxsus topshiriqlar',
    path: '/special-tasks',
    icon: SpecialTasksIcon,
  },
  {
    title: 'Elektron kutubxona',
    path: '/online-library',
    icon: LibraryIcon,
    children: [
      {
        sTitle: 'Kutubxona',
        sPath: '/online-library',
      },
      {
        sTitle: 'Hisobot',
        sPath: '/library-report',
      },
    ],
  },
  // { title: 'Ibrat farzandlari', path: '/external-app', icon: UserStatusIcon },
  {
    title: 'Testlar',
    path: '/tests',
    icon: TestsIcon,
    withNotification: true,
  },
];

export const advancedTabs = [
  {
    title: 'Sohalar',
    path: '/',
    icon: LayerIcon,
  },
  { title: 'Kunlik reyting', path: '/daily-rating', icon: DailyRatingIcon },
  { title: 'Vaqt kesimida', path: '/time-interval', icon: TimeIntervalIcon },
  {
    title: 'Yangi ishlar',
    path: '/new-works',
    icon: NewWorksIcon,
  },
  {
    title: 'Yuborilgan ishlar',
    path: '/works',
    icon: WorksIcon,
  },
];
