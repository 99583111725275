import React, { useEffect, useMemo, useState } from 'react';
import {
  CardsWrapper,
  FilterWrapper,
  NoData,
  ResultCount,
  ScheduleWrapper,
  SelectsWrapper,
  TabStatus,
} from './style';
import Select from 'components/FormElements/Select';
import { scheduleTableHeader, tabData } from './helper';
import { ScheduleStatusBtn } from 'components/Tabs';
import { ScheduleCard } from 'components/Cards';
import { scheduleColor } from 'constants/colors';
import { Empty, Pagination } from 'antd';
import { useShowAlert, useShowModal } from 'hooks';
import ModalBody from './ModalBody';
import { useSelector } from 'react-redux';
import { useFilter } from 'views/useFilter';
import schedule from 'services/schedule';
import Spinner from 'components/Spinner';
import DatePicker from 'components/DatePicker';
import DynamicFilters from 'components/DynamicFilters';
import districtsService from 'services/objects';
import { useWindowSize } from 'hooks/useDevice';
import DownloadToExcell from 'components/DownloadToExcell';
import RangePickerSchedule from 'components/FormElements/RangePickerSchedule';

export default () => {
  const [tabActive, setTabActive] = useState(0);
  const { role } = useSelector((state) => state.authReducer);

  const { width } = useWindowSize();
  const { showBlured } = useShowModal();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [excelData, setExcellData] = useState([]);
  const [allTotal, setAllTotal] = useState(0);
  const [cameTotal, setCameTotal] = useState(0);
  const [lateTotal, setLateTotal] = useState(0);
  const [absentTotal, setAbsentTotal] = useState(0);
  const [absentCauseTotal, setAbsentCauseTotal] = useState(0);
  const [paginate, setPaginate] = useState({
    limit: 12,
    page: 1,
  });
  const { singleDate, selectedDateSchedule } = useSelector(
    (state) => state.selectedDateReducer
  );

  const { success, error, warning } = useShowAlert();

  const dateQuery = useMemo(
    () =>
      selectedDateSchedule.every((item) => item !== '') && selectedDateSchedule,
    [selectedDateSchedule]
  );

  const { handleFilters, selectedFilters, provinces, regions, objects } =
    useFilter({
      withObject: true,
    });

  const fetchData = async (withPaginate) => {
    let payload = {};
    if (withPaginate) {
      setLoading(true);

      payload = {
        ...paginate,
      };
    }
    try {
      if (tabActive === 0) {
        if (
          selectedFilters?.province ||
          selectedFilters?.region ||
          selectedFilters?.district
        ) {
          payload['province'] = selectedFilters?.province;
          payload['region'] = selectedFilters?.region;
          payload['district'] = selectedFilters?.district;
        }
        if (singleDate?.length) {
          payload['date'] = singleDate;
        }
        const resData = await schedule.getAll(payload);
        setAllTotal(
          resData?.regulation?.totalDocs ?? resData?.regulation?.length
        );
        setAbsentTotal(resData?.regulation?.absentWithoutCauseTotal ?? 0);
        setAbsentCauseTotal(resData?.regulation?.absentWithCauseTotal ?? 0);
        setCameTotal(resData?.regulation?.cameTotal ?? 0);
        setLateTotal(resData?.regulation?.lateTotal ?? 0);

        if (withPaginate) {
          const finalData = resData?.regulation?.docs?.map((item) => ({
            ...item,
            status: item?.late === null ? 6 : item?.late === 0 ? 5 : 7,
          }));
          setData(finalData);
        } else {
          const finalData = resData?.regulation?.map((item, idx) => ({
            ...item,
            indx: idx + 1,
            status: item?.late === null ? 6 : item?.late === 0 ? 5 : 7,
          }));
          setExcellData(finalData);
        }
      } else if (tabActive === 5) {
        payload = {
          ...payload,
          status: 3,
        };
        if (
          selectedFilters?.province ||
          selectedFilters?.region ||
          selectedFilters?.district
        ) {
          payload['province'] = selectedFilters?.province;
          payload['region'] = selectedFilters?.region;
          payload['district'] = selectedFilters?.district;
        }
        if (singleDate?.length) {
          payload['start'] = singleDate;
          payload['end'] = singleDate;
        }

        const resData = await schedule.getLateOrDidNotCome(payload);
        setAllTotal(resData?.totalDocs ?? resData?.length);

        if (withPaginate) {
          const finalData = resData?.docs?.map((item) => ({
            ...item,
            status: tabActive === 5 ? 5 : 1,
          }));
          setData(finalData);
        } else {
          const finalData = resData?.map((item, idx) => ({
            ...item,
            indx: idx + 1,
            cameUser: item?.data ? item?.data[0]?.date : '',
            status: tabActive === 5 ? 5 : 1,
          }));
          setExcellData(finalData);
        }
      } else if (tabActive === 1) {
        payload = {
          ...payload,
          status: 1,
        };
        if (
          selectedFilters?.province ||
          selectedFilters?.region ||
          selectedFilters?.district
        ) {
          payload['province'] = selectedFilters?.province;
          payload['region'] = selectedFilters?.region;
          payload['district'] = selectedFilters?.district;
        }
        if (
          !selectedDateSchedule.every((item) => item === '') &&
          selectedDateSchedule[0] !== 'Invalid date' &&
          selectedDateSchedule[1] !== 'Invalid date'
        ) {
          payload['start'] = selectedDateSchedule[0];
          payload['end'] = selectedDateSchedule[1];
        }

        const resData = await schedule.getLateOrDidNotCome(payload);
        setAllTotal(resData?.totalDocs ?? resData?.length);

        if (withPaginate) {
          const finalData = resData?.docs?.map((item) => ({
            ...item,
            status: tabActive === 2 ? 2 : 1,
          }));
          setData(finalData);
        } else {
          const finalData = resData?.map((item, idx) => ({
            ...item,
            indx: idx + 1,
            status: tabActive === 2 ? 2 : 1,
          }));
          setExcellData(finalData);
        }
      } else if (tabActive === 2 || tabActive === 7) {
        payload = {
          ...payload,
          status: tabActive === 2 ? 4 : 2,
        };
        if (
          selectedFilters?.province ||
          selectedFilters?.region ||
          selectedFilters?.district
        ) {
          payload['province'] = selectedFilters?.province;
          payload['region'] = selectedFilters?.region;
          payload['district'] = selectedFilters?.district;
        }
        if (
          !selectedDateSchedule.every((item) => item === '') &&
          selectedDateSchedule[0] !== 'Invalid date' &&
          selectedDateSchedule[1] !== 'Invalid date'
        ) {
          payload['start'] = selectedDateSchedule[0];
          payload['end'] = selectedDateSchedule[1];
        }

        const resData = await schedule.getLateOrDidNotCome(payload);
        setAllTotal(resData?.totalDocs ?? resData?.length);

        if (withPaginate) {
          const finalData = resData?.docs?.map((item) => ({
            ...item,
            status: tabActive === 2 || tabActive === 7 ? 2 : 1,
          }));
          setData(finalData);
        } else {
          const finalData = resData?.map((item, idx) => ({
            ...item,
            indx: idx + 1,
            status: tabActive === 2 || tabActive === 7 ? 2 : 1,
          }));
          setExcellData(finalData);
        }
      } else if (tabActive === 3) {
        let reqBody = {};
        if (
          selectedFilters?.province ||
          selectedFilters?.region ||
          selectedFilters?.district
        ) {
          reqBody['province'] = selectedFilters?.province;
          reqBody['region'] = selectedFilters?.region;
          reqBody['district'] = selectedFilters?.district;
        }
        const resData = await schedule.getVacant(reqBody);
        setAllTotal(resData?.length);
        const finalData = resData?.map((item, idx) => ({
          indx: idx + 1,
          user: {
            ...item,
            district: {
              titleUZ: item?.titleUZ,
              titleRU: item?.titleRU,
              titleEN: item?.titleEN,
            },
          },
          status: 3,
        }));
        if (withPaginate) {
          setData(finalData);
        } else {
          setExcellData(finalData);
        }
      } else if (tabActive === 4) {
        payload = {
          ...payload,
          status: 1,
        };
        if (
          selectedFilters?.province ||
          selectedFilters?.region ||
          selectedFilters?.district
        ) {
          payload['province'] = selectedFilters?.province;
          payload['region'] = selectedFilters?.region;
          payload['district'] = selectedFilters?.district;
        }
        if (
          !selectedDateSchedule.every((item) => item === '') &&
          selectedDateSchedule[0] !== 'Invalid date' &&
          selectedDateSchedule[1] !== 'Invalid date'
        ) {
          payload['start'] = selectedDateSchedule[0];
          payload['end'] = selectedDateSchedule[1];
        }
        const resData = await schedule.getAreaBorder(payload);
        setAllTotal(resData?.totalDocs ?? resData?.length);

        if (withPaginate) {
          const finalData = resData?.docs?.map((item) => ({
            ...item,
            user: {
              ...item?.user,
              province: item?.province,
              region: item?.region,
              district: item?.district,
            },
            status: 4,
          }));
          setData(finalData);
        } else {
          const finalData = resData?.map((item, idx) => ({
            ...item,
            indx: idx + 1,
            user: {
              ...item?.user,
              province: item?.province,
              region: item?.region,
              district: item?.district,
            },
            status: 4,
          }));
          setExcellData(finalData);
        }
      } else if (tabActive === 6) {
        payload = {
          ...payload,
        };
        if (
          selectedFilters?.province ||
          selectedFilters?.region ||
          selectedFilters?.district
        ) {
          payload['province'] = selectedFilters?.province;
          payload['region'] = selectedFilters?.region;
          payload['district'] = selectedFilters?.district;
        }
        if (singleDate?.length) {
          payload['date'] = singleDate;
        }
        const resData = await schedule.getAreaBorderTime(payload);
        setAllTotal(resData?.totalDocs ?? resData?.length);

        if (withPaginate) {
          const finalData = resData?.docs?.map((item) => ({
            ...item,
            user: {
              ...item?.user,
              province: item?.province,
              region: item?.region,
              district: item?.district,
            },
          }));
          setData(finalData);
        } else {
          const finalData = resData?.map((item, idx) => ({
            ...item,
            indx: idx + 1,
            user: {
              ...item?.user,
              province: item?.province,
              region: item?.region,
              district: item?.district,
            },
          }));
          setExcellData(finalData);
        }
      }
    } catch (err) {
      console.log(err);
      error('Xatolik yuz beri', 2);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData('withpaginate');
  }, [tabActive, paginate, selectedFilters, dateQuery, singleDate]);

  const handleFetchExcellData = () => {
    fetchData();
    data?.length
      ? success("Ma'lumotlar tayyorlanmoqda.", 1)
      : warning("Bu sana uchun ma'lumot topilmadi", 1);
  };

  const handleCard = async (id, activeId) => {
    try {
      if (tabActive === 4) {
        setLoading(true);
        const payload = {
          user: id,
        };
        if (
          !selectedDateSchedule.every((item) => item === '') &&
          selectedDateSchedule[0] !== 'Invalid date' &&
          selectedDateSchedule[1] !== 'Invalid date'
        ) {
          payload['start'] = selectedDateSchedule[0];
          payload['end'] = selectedDateSchedule[1];
        }
        const res = await schedule.getOneAreaBorder(payload);
        const resData = res?.length && res[0];
        const newData = {
          ...resData,
          count: resData?.data?.length,
          user: {
            ...resData?.user,
            province: resData?.province,
            region: resData?.region,
            district: resData?.district,
          },
          status: 4,
        };
        let polygonData = [];

        const districtData = await districtsService.getOne(
          newData?.district?._id
        );

        polygonData = districtData?.coordinates.map((item) => item.reverse());
        setLoading(false);
        showBlured({
          maxWidth: '852px',
          maxHeight: '600px',
          withHeader: true,
          body: () => (
            <ModalBody active={activeId} item={newData} polygon={polygonData} />
          ),
        });
      } else if (tabActive === 6) {
        setLoading(true);
        const payload = {
          user: id,
        };
        if (singleDate?.length) {
          payload['date'] = singleDate;
        }
        const resData = await schedule.getAreaBorderTimeUser(payload);
        const newData = {
          ...resData,
          // count: resData?.data?.length,
          user: {
            ...resData?.doc?.user,
            province: resData?.doc?.province,
            region: resData?.doc?.region,
            district: resData?.doc?.district,
          },
          status: 6,
        };

        setLoading(false);
        showBlured({
          maxWidth: '652px',
          maxHeight: '600px',
          withHeader: true,
          body: () => <ModalBody active={8} item={newData} />,
        });
      } else {
        const userData = data?.find((item) => item?.user?._id === id);
        showBlured({
          maxWidth: '852px',
          maxHeight: '600px',
          withHeader: true,
          body: () => (
            <ModalBody
              active={activeId}
              item={userData}
              tabActive={tabActive}
            />
          ),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterItems = [
    {
      name: 'province',
      label: 'Viloyat',
      options: provinces,
      permissions: ['leader'],
      placeholder: 'Viloyatni tanlang...',
      type: 'select',
      span: 12,
      show: true,
    },
    {
      name: 'region',
      label: 'Shahar-Tuman',
      options: regions,
      permissions: ['leader', 'proleader'],
      placeholder: 'Shaharni tanlang...',
      type: 'select',
      span: 12,
      show: true,
    },
    {
      name: 'district',
      label: 'MFY',
      placeholder: 'MFY tanlang...',
      type: 'select',
      options: objects,
      permissions: ['leader', 'proleader', 'regleader'],
      span: 12,
      show: true,
    },
    {
      name: 'date',
      label: 'Sana',
      permissions: ['leader', 'proleader', 'regleader'],
      type: 'date',
      span: 12,
      show: tabActive === 0 || tabActive === 5 || tabActive === 6,
    },
    {
      name: 'datepicker',
      label: 'Sana',
      permissions: ['leader', 'proleader', 'regleader'],
      type: 'newDatepicker',
      span: 12,
      show:
        tabActive !== 3 &&
        tabActive !== 0 &&
        tabActive !== 5 &&
        tabActive !== 6,
    },
    // {
    //   name: 'sort',
    //   label: 'Saralash',
    //   permissions: ['leader', 'proleader', 'regleader'],
    // },
  ];

  const onChange = (pageNumber, limit) => {
    setPaginate({
      limit: limit,
      page: pageNumber,
    });
  };

  const handleActive = (activeValue) => {
    setPaginate({
      limit: 12,
      page: 1,
    });
    setTabActive(activeValue);
  };
  const filteredByRelevance = useMemo(() => {
    return filterItems.filter((item) => item.permissions?.includes(role));
  }, [filterItems, role]);

  const excelTable = useMemo(() => {
    return tabData.find((btn) => btn.status === tabActive);
  }, [tabActive]);

  const tableHeder = useMemo(() => {
    switch (tabActive) {
      case 0:
        return [
          ...scheduleTableHeader,
          {
            title: 'Kelgan (marta)',
            dataIndex: 'came',
            key: 'came',
          },
          {
            title: 'Kech qolgan',
            dataIndex: 'late',
            key: 'late',
          },
        ];
      case 5:
        return [
          ...scheduleTableHeader,
          {
            title: 'Kelgan',
            dataIndex: 'cameUser',
            key: 'cameUser',
          },
        ];
      case 1:
        return [
          ...scheduleTableHeader,
          {
            title: 'Kech qolgan (marta)',
            dataIndex: 'count',
            key: 'count',
          },
        ];
      case 2:
        return [
          ...scheduleTableHeader,
          {
            title: 'Kelmagan (marta)',
            dataIndex: 'count',
            key: 'count',
          },
        ];
      case 7:
        return [
          ...scheduleTableHeader,
          {
            title: 'Sababli kelmagan (marta)',
            dataIndex: 'count',
            key: 'count',
          },
        ];
      case 4:
        return [
          ...scheduleTableHeader,
          {
            title: 'Xududni tark etgan (marta)',
            dataIndex: 'count',
            key: 'count',
          },
        ];
      case 3:
        return [...scheduleTableHeader.slice(0, 4)];
      default:
        return scheduleTableHeader;
    }
  }, [excelTable, tabActive]);

  return (
    <ScheduleWrapper>
      <TabStatus>
        {tabData?.map((item, index) => (
          <ScheduleStatusBtn
            key={index}
            data={item}
            setTabActive={handleActive}
            active={tabActive === item?.status}
          />
        ))}
      </TabStatus>

      <SelectsWrapper>
        <FilterWrapper
          active={tabActive === 0 && width < 1800 ? 'true' : 'false'}
        >
          {width < 1765 ? (
            <div className="filter__menu">
              <DynamicFilters
                selectedFilters={selectedFilters} // valueState
                handleFilters={(newName, newValue) => {
                  handleFilters(newName, newValue);
                  setPaginate({
                    limit: 12,
                    page: 1,
                  });
                }} // filter handler
                setPaginate={setPaginate}
                filters={filteredByRelevance.filter((item) => item.show)} // schema filters
                tabActive={tabActive}
                allowClear={false}
              />
            </div>
          ) : (
            filteredByRelevance
              .filter((item) => item.show)
              .map(
                (item, index) =>
                  item.permissions.includes(role) &&
                  (item.type === 'select' ? (
                    <div className="select-wrap" key={index}>
                      <Select
                        title={item?.label}
                        placeholder={item.label}
                        options={item.options}
                        handleChange={(value) => {
                          handleFilters(item.name, value);
                          setPaginate({
                            limit: 12,
                            page: 1,
                          });
                        }}
                        value={selectedFilters?.[item.name]}
                      />
                    </div>
                  ) : item.type === 'date' ? (
                    <div className="select-wrap" key={index}>
                      <DatePicker
                        allowClear={false}
                        // title={item?.label}
                        // handleChange={(value) => handleFilters(item.name, value)}
                        // value={singleDate}

                        setPaginate={setPaginate}
                      />
                    </div>
                  ) : (
                    <div className="select-wrap" key={index}>
                      <div className="date-title">Sana</div>
                      <RangePickerSchedule
                        titleHide
                        setPaginate={setPaginate}
                      />
                    </div>
                  ))
              )
          )}
          {/* {tabActive !== 3 && tabActive !== 0 && (
            <div className="select-wrap">
              <div className="date-title">Sana</div>
              <RangePicker titleHide />
            </div>
          )}
          {tabActive === 0 && (
            <>
              <DatePicker />
            </>
          )} */}
          <div className="result-select-wrap">
            <div className="result-title">Natija</div>
            <ResultCount color={scheduleColor[tabActive]}>
              {allTotal}
            </ResultCount>
          </div>
          {tabActive === 0 ? (
            <>
              <div className="result-select-wrap">
                <div className="result-title">Kelgan</div>
                <ResultCount color={scheduleColor[5]}>{cameTotal}</ResultCount>
              </div>
              <div className="result-select-wrap lateTotal">
                <div className="result-title">Kech qolgan</div>
                <ResultCount color={scheduleColor[1]}>{lateTotal}</ResultCount>
              </div>
              <div className="result-select-wrap">
                <div className="result-title">Kelmagan</div>
                <ResultCount color={scheduleColor[2]}>
                  {absentTotal}
                </ResultCount>
              </div>
              <div className="result-select-wrap">
                <div className="result-title">Sababli kelmagan</div>
                <ResultCount color={scheduleColor[7]}>
                  {absentCauseTotal}
                </ResultCount>
              </div>
            </>
          ) : null}
          <div className="excell__btn">
            <DownloadToExcell
              handleFetchData={handleFetchExcellData}
              fileName={excelTable.title}
              tableName={'schedule_table'}
              tableHeader={tableHeder}
              tableData={excelData}
              setExcellData={setExcellData}
            />
          </div>
        </FilterWrapper>
      </SelectsWrapper>
      {loading ? (
        <Spinner maxHeight={'calc(100vh - 400px)'} />
      ) : (
        <>
          <CardsWrapper>
            {data?.length ? (
              data?.map((item, index) => (
                <ScheduleCard
                  handleCard={handleCard}
                  active={item?.status}
                  key={index}
                  data={item}
                  tabActive={tabActive}
                  cursor
                />
              ))
            ) : (
              <NoData>
                <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
              </NoData>
            )}
          </CardsWrapper>
          {tabActive !== 3 && allTotal > 12 && (
            <div className="pagination-wrap">
              <Pagination
                defaultCurrent={paginate?.page}
                total={allTotal}
                defaultPageSize={paginate?.limit}
                pageSizeOptions={[12, 20, 25]}
                onChange={onChange}
              />
            </div>
          )}
        </>
      )}
    </ScheduleWrapper>
  );
};
