import { FiMoreVertical } from 'react-icons/fi';
import React from 'react';
import { useSelector } from 'react-redux';
import { CiUser } from 'react-icons/ci';
import {
  Action,
  UserProfile,
  LogoutButton,
  Username,
  LogoContainer,
} from '../style';
import { Popover } from 'antd';
import styled from 'styled-components';

const StyledBtn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 4px 10px;
  cursor: pointer;
  color: #00826C;
  border-radius: 12px;
  border: none;
  outline: none;
  transition: all 0.3s linear;
  :hover {
    color: #fff;
    background: #00826C;
    transition: all 0.3s ease-in-out;
  }
`;
const PopoverContent = ({ handleLogout }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      alignItems: 'center',
      padding: '10px',
    }}
  >
    <StyledBtn onClick={handleLogout}>Chiqish</StyledBtn>
  </div>
);
const Options = ({ user, handleLogout, darkText }) => {
  const { fullname, username, role } = useSelector(
    (state) => state.authReducer
  );
  return (
    <Action>
      <UserProfile>
        <LogoContainer>
          <CiUser size={30} />
        </LogoContainer>
        <Username darkText={darkText}>
          <span>{username}</span>
          <span>{role}</span>
        </Username>
      </UserProfile>
      <LogoutButton>
        <Popover
          content={<PopoverContent handleLogout={handleLogout} />}
          trigger="click"
        >
          <FiMoreVertical size="23" color={!darkText ? '#fff' : '#080936'} />
        </Popover>
      </LogoutButton>
    </Action>
  );
};

export default Options;
