import { Popover } from 'antd';
import { Content } from './style';
import React from 'react';

export default ({ data }) => {
  const content = (
    <Content>
      <p>{data}</p>
    </Content>
  );

  return data?.split(', ')?.length >= 2 ? (
    <Popover placement="top" content={content}>
      <div style={{ cursor: 'pointer' }}>{data?.split(', ')[0]}, ...</div>
    </Popover>
  ) : (
    <div style={{ width: '100%' }}>{data}</div>
  );
};
