import styled, { css } from 'styled-components';

export const btnStyle = css`
  background: #00826C1a;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  margin: 0;
  color: var(--custom-primary);
`;

export const ComplaintsIconWrapper = styled.div`
  /* width: 100%; */
  padding-top: 28px;
`;

export const Header = styled.div`
  padding-bottom: 24px;
`;

export const PageBody = styled.div`
  width: 100%;

  .table-header-className {
    font-family: Segoe UI;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(8, 9, 54, 0.5) !important;
  }
  & .pagination {
    width: 100%;
    display: flex;
    justify-content: right;
    padding: 20px 0px;
    .ant-pagination-options-size-changer.ant-select {
      .ant-select-selector {
        height: 32px !important;
        min-height: 34px !important;
      }
    }
  }
`;

export const ModalWrapper = styled.div`
  width: 100%;
  padding: 20px 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  & .user__info {
    width: 572px;
    height: 122px;
    flex-shrink: 0;
    border-radius: 16px;
    background: rgba(76, 77, 220, 0.08);
    padding: 12px;
    & .left-section {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      & .user-image {
        width: 103px;
        height: 100%;
        border-radius: 12px;
        overflow: hidden;
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      & .left-section-text {
        & .username {
          color: #080936;
          font-family: Segoe UI;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          padding-bottom: 15px;
          max-width: 218px;
        }
        & .region-card {
          width: 218px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          & .region-text {
            width: 100%;
            display: flex;
            align-items: center;
            & .region-title {
              display: flex;
              align-items: flex-end;
              color: #08093650;
              font-family: Segoe UI;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 100%;
            }
            & .region-value {
              color: #080936;
              font-family: Segoe UI;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
            }
          }
        }
      }
    }
  }
  & .modal-text {
    color: rgba(8, 9, 54, 0.7);
    font-family: 'Segoe UI';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  & .modal-date {
    color: rgba(8, 9, 54, 0.5);
    font-family: 'Segoe UI';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  height: calc(100vh - 400px);
`;

export const ActionButton = styled.div`
  & .edit-btn {
    ${btnStyle}
  }
  & .edit-btn:hover {
    background: var(--custom-primary);
    color: #fff;
  }
  & .edit-btn:disabled {
    color: #c2c2c2;
  }
`;
