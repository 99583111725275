import { takeLatest, put, delay } from 'redux-saga/effects';
import types from '../../../constants/action-types';
import {
  setCategories,
  setRegions,
  setUsers,
  setLoading,
  setDistricts,
  setProvinces,
  setSupportTypes,
} from './actions';
import groups from '../../../services/groups';
import provinces from '../../../services/provinces';
import regions from '../../../services/regions';
import categories from '../../../services/categories';
import users from '../../../services/users';
import objects from 'services/objects';
import supportType from 'services/supportType';

function* fetchData({ payload }) {
  try {
    yield put(setLoading(true));

    const catsData = yield categories.getAll();
    yield put(setCategories(catsData));
    if (
      payload === 'leader' ||
      payload === 'proleader' ||
      payload === 'regleader'
    ) {
      const provincesData = yield provinces.getAll();
      yield put(setProvinces(provincesData));
    }
    if (
      payload === 'leader' ||
      payload === 'proleader' ||
      payload === 'regleader'
    ) {
      const regionsData = yield regions.getAll(); // buni olish uchun province id kerak bo'ladi
      yield put(setRegions(regionsData));
    }
    if (payload === 'regleader') {
      const objectsData = yield objects.getAllObjectByRegions();
      yield put(setDistricts(objectsData));
    }
    const supportTypes = yield supportType.getAll();
    yield put(setSupportTypes(supportTypes));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.log('error', error);
  }
}

export default function* commonsSaga() {
  yield takeLatest(types.APPLICATION_FETCH_COMMONS_DATA, fetchData);
}
