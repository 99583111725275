import styled from 'styled-components';

export const TaskCardWrap = styled.div`
  width: 371px;
  height: 183px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  cursor: ${({ noclick }) => (noclick === 'true' ? 'auto' : 'pointer')};
  user-select: none;

  & .card-header {
    display: flex;
    gap: 16px;
    border-bottom: 1px dashed rgba(8, 9, 54, 0.15);
    padding-bottom: 16px;
    & .image-wrapper {
      width: 85px;
      height: 85px;
      border-radius: 10px;
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.04);
      background: #eeeefc;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      /* & img {
        width: 100%;
        height: 100%;
      } */
    }
    & .card-text-wrap {
      width: calc(100% - 101px);
      display: flex;
      flex-direction: column;
      gap: 12px;
      & .card-title {
        color: #080936;
        font-family: 'Segoe UI';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        padding-bottom: 4px;
      }
      & .card-text-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & .list-item {
          color: rgba(8, 9, 54, 0.6);
          font-family: 'Segoe UI';
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }
        & .list-item-date {
          color: #080936;
          font-family: 'Segoe UI';
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
        }
        & .list-item-day {
          width: 60px;
          height: 23px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          background: rgba(76, 77, 220, 0.1);
          color: #00826C;
          font-family: 'Segoe UI';
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
        }
      }
    }
  }

  & .card-footer {
    color: #080936;
    opacity: 0.7;
    font-family: 'Segoe UI';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
`;
