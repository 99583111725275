import types from '../../../constants/action-types';

const defaultState = {
  token: localStorage.getItem('agency_access_token'),
  // role: 'supervisor', // supervisor, admin, governor, secretary
  role: localStorage.getItem('role'), //
  userId: localStorage.getItem('user_id'),
  fullname: localStorage.getItem('fullname'),
  username: localStorage.getItem('username'),
  // groupId: localStorage.getItem('groupId'),
  regionId: localStorage.getItem('regionId'),
  provinceId: localStorage.getItem('provinceId'),
  regionTitle: localStorage.getItem('regionTitle'),
  provinceTitle: localStorage.getItem('provinceTitle'),
};

const map = {
  [types.SET_TOKEN]: (state, { payload }) => ({
    ...state,
    token: payload,
  }),
  [types.SET_ROLE]: (state, { payload }) => ({
    ...state,
    role: payload,
  }),
  [types.SET_USER_ID]: (state, { payload }) => ({
    ...state,
    userId: payload,
  }),
  [types.SET_USERNAME]: (state, { payload }) => ({
    ...state,
    username: payload,
  }),
  [types.SET_FULLNAME]: (state, { payload }) => ({
    ...state,
    fullname: payload,
  }),
  [types.SET_REGION_ID]: (state, { payload }) => ({
    ...state,
    regionId: payload,
  }),
  [types.SET_PROVINCE_ID]: (state, { payload }) => ({
    ...state,
    provinceId: payload,
  }),
  [types.SET_REGION_TITLE]: (state, { payload }) => ({
    ...state,
    regionTitle: payload,
  }),
  [types.SET_PROVINCE_TITLE]: (state, { payload }) => ({
    ...state,
    provinceTitle: payload,
  }),
  [types.SET_group_ID]: (state, { payload }) => ({
    ...state,
    groupId: payload,
  }),
  [types.CLEAR_TOKEN]: (state) => ({
    ...state,
    token: undefined,
  }),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) =>
  (map[action.type] && map[action.type](state, action)) ||
  state ||
  defaultState;
