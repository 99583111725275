export default {
  APPLICATION_SHOW_MODAL: 'APPLICATION_SHOW_MODAL',
  APPLICATION_FETCH_COMMONS_DATA: 'APPLICATION_FETCH_COMMONS_DATA',
  APPLICATION_HIDE_MODAL: 'APPLICATION_HIDE_MODAL',
  APPLICATION_SET_GROUP_ID: 'APPLICATION_SET_GROUP_ID',
  APPLICATION_SET_SUB_DATA: 'APPLICATION_SET_SUB_DATA',
  APPLICATION_SET_CATEGORIES: 'APPLICATION_SET_CATEGORIES',
  APPLICATION_SET_REGIONS: 'APPLICATION_SET_REGIONS',
  APPLICATION_SET_OBJECTS: 'APPLICATION_SET_OBJECTS',
  APPLICATION_SET_PROVINCES: 'APPLICATION_SET_PROVINCES',
  APPLICATION_SET_USERS: 'APPLICATION_SET_USERS',
  APPLICATION_ACTIVE_ITEM: 'APPLICATION_ACTIVE_ITEM',
  APPLICATION_SET_LOADING: 'APPLICATION_SET_LOADING ',
  APPLICATION_SET_MODAL_LOADING: 'APPLICATION_SET_MODAL_LOADING ',
  APPLICATION_CLEAN_STATE: 'APPLICATION_CLEAN_STATE',
  APPLICATION_ACTIVE_PAGE_TITLE: 'APPLICATION_ACTIVE_PAGE_TITLE',
  APPLICATION_SET_DEFAULT_PAGE_TITLE: 'APPLICATION_SET_DEFAULT_PAGE_TITLE',
  APPLICATION_SET_SUPPORTS: 'APPLICATION_SET_SUPPORTS',
  APPLICATION_SET_SIDEBAR_COLLAPSED: 'APPLICATION_SET_SIDEBAR_COLLAPSED',
  APPLICATION_SET_SIDEBAR_DESKTOP_COLLAPSED:
    'APPLICATION_SET_SIDEBAR_DESKTOP_COLLAPSED',
  APPLICATION_SET_SIDEBAR_BADGE_COUNT: 'APPLICATION_FETCH_SIDEBAR_BADGE_COUNT',

};
