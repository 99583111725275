import styled from 'styled-components';
import { Button as BTN } from 'antd';

export const TestsWrapper = styled.div`
  width: 100%;
  height: 100%;
  .create-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 32px 0;
  }
  th:last-child {
    padding: 0;
  }
`;

export const Button = styled(BTN)`
  height: 44px;
  width: ${({ width }) => width && width};
  background: ${({ background }) => (background ? background : '#00c4fa')};
  border-radius: 12px;
  padding: 0 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4.5px;
  font-size: 15px;
  :hover {
    background: #33d3ff;
  }
`;

export const StatusWrapper = styled.div`
  color: ${({ color }) => color && color};
  font-family: 'Segoe UI';
  font-weight: 600;
`;

export const ManageWrapper = styled.div`
  width: auto;
  height: 28px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color && color};
  font-family: 'Segoe UI';
  font-weight: 600;
  color: white;
  border-radius: 40px;
  cursor: pointer;
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 28px;
  .icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

export const PopoverContent = styled.div`
  padding: 7px 12px;
  font-weight: 400;
  font-family: 'Segoe UI';
  color: #080936;
`;

export const LinkWrapper = styled.div`
  border: none;
  outline: none;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: ${({ status }) => (status ? 'default' : 'pointer')};
  transition: all 100ms linear;
  background: none;
  gap: 10px;
  color: ${({ status }) => (status ? 'black' : '#00826C')};
  font-weight: 600;
  font-size: 15px;
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  height: calc(100vh - 400px);
`;
