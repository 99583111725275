import { memo } from 'react';
import { Input } from 'antd';
const { TextArea } = Input;

const TextAutoSize = ({
  placeholder,
  descriptionValue,
  handleDescription,
  onBlur,
  status = false,
}) => {
  return (
    <>
      <TextArea
        value={descriptionValue}
        onBlur={onBlur}
        onChange={handleDescription}
        placeholder={placeholder}
        autoSize={{
          minRows: 2,
          maxRows: 5,
        }}
        status={status ? 'error' : undefined}
      />
    </>
  );
};

export default memo(TextAutoSize);
