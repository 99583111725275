const { default: styled } = require('styled-components');

export const CardBody = styled.div`
  width: 100%;
  height: auto;
  max-width: ${({ size }) => (size === 'small' ? '760px' : '1070px')};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-content: center;
  align-items: start;
  justify-content: center;
  flex-shrink: 0;
  gap: 10px;
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid rgba(8, 9, 54, 0.05);
  box-shadow: 0px 5px 14px 0px rgba(126, 126, 126, 0.05);
  padding: 16px;

  .item-1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    @media screen and (min-width: 1042px) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }

  .item-2 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    @media screen and (min-width: 1042px) {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }

  .item-3 {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    @media screen and (min-width: 1042px) {
      grid-column-start: 3;
      grid-column-end: 4;
    }
  }

  @media screen and (min-width: 960px) {
    grid-template-columns: ${({ size }) =>
      size !== 'small' ? '290px repeat(2, 1fr)' : '210px repeat(2, 1fr)'};
  }

  .image__block {
    width: ${({ size }) => (size !== 'small' ? '285px' : '205px')};
    height: ${({ size }) => (size !== 'small' ? '245px' : '179px')};
    flex-shrink: 0;
  }
  .description {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    border-right: 1px solid #0809360d;
    padding-right: 12px;
    /* @media screen and (max-width: 960px) {
      max-width: 155px;
    } */
    .title {
      width: 100%;
      color: #080936;
      font-family: Segoe UI;
      font-size: ${({ size }) => (size === 'small' ? '15px' : '20px')};
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
      letter-spacing: 0.4px;
      margin: 0;
      padding: 0;
      text-align: left;
    }
    .desc__text {
      width: 100%;
      text-align: justify;
      height: ${({ expanded }) => (expanded ? '100%' : '50px')};
      transition: height 0.4s linear;
      color: #080936;
      font-family: Segoe UI;
      font-size: ${({ size }) => (size === 'small' ? '11px' : '15px')};
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 21px */
      transition: height 0.4s linear;
      overflow-y: hidden;
      @media screen and (min-width: 960px) {
        height: ${({ expanded }) => (expanded ? '100%' : '133px')};
        min-height: 133px;
        transition: height 0.4s linear;
      }
      .show__more {
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        color: #3380ff;
        font-family: Segoe UI;
        font-size: ${({ size }) => (size === 'small' ? '11px' : '15px')};
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */
      }
    }
    .executor__info {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;
      gap: 10px;
      color: #080936;
      font-family: Segoe UI;
      font-size: 16px;
      font-size: ${({ size }) => (size === 'small' ? '12px' : '16px')};
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
      .date {
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 16px;
        opacity: 0.5;
        margin: 0;
        padding: 0;
      }
      .executor {
        margin: 0;
        padding: 0;
      }
    }
  }
  .info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 10px;
  }
`;
