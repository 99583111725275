import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  @media (max-width: 960px) {
    & .range-datepicker {
      width: 100%;
    }
  }
`;
