import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Space } from './style';
import Item from '../Item';
import {
  leeadersTabs,
  advancedTabs,
  regionLeaderTabs,
  provinceLeaderTabs,
} from '../helper';

const notificationChecker = (access, path, notifications) => {
  if (access && notifications && notifications[path.slice(1)]) {
    return notifications[path.slice(1)];
  }
  return 0;
};

export const SidebarBody = () => {
  const { role } = useSelector((state) => state.authReducer);
  const notifications = useSelector(
    (state) => state.appReducer.badgeNotification
  );

  const desktopCollapsed = useSelector(
    ({ appReducer }) => appReducer.desktopCollapsed
  );
  const [expand, setExpand] = useState(false);
  const sideItems = {
    regleader: regionLeaderTabs,
    proleader: provinceLeaderTabs,
    leader: leeadersTabs,
    advanced: advancedTabs,
  };

  return (
    <Container collapsed={desktopCollapsed}>
      <Space />
      {sideItems[role].map(
        ({ title, icon, path, children, withNotification }, index) => (
          <Item
            key={`${index + 1}`}
            title={title}
            icon={icon}
            path={path}
            elements={children}
            expand={expand}
            setExpand={setExpand}
            notification={notificationChecker(
              withNotification,
              path,
              notifications
            )}
          />
        )
      )}
    </Container>
  );
};

export default React.memo(SidebarBody);
