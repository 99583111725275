import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import {
  Cell,
  Container,
  Wrapper,
  Table,
  TR,
  TBody,
  TD,
  THead,
  TREmpty,
  ChildComponent,
  Content,
  Title,
  Body,
  CloseButton,
  CloseIcon,
  Header,
  Item,
  ItemText,
  RightContainer,
  LeftContainer,
  TH,
  PercentContainer,
} from './style';
import ExpandButton from './ExpandButton';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Empty } from 'antd';
import { setgroupId } from '../../../redux/modules/application/actions';
import Spinner from '../../../components/Spinner';
import { useLocation } from 'react-router-dom';
import { setModalWork } from '../../../redux/modules/modalwork/actions';
import Pagination from './Pagination';
import { TbSortAscending, TbSortDescending } from 'react-icons/tb';
import { LibraryModalColumns } from './helper';
import LibraryModalTable from '../LibraryModalTable';

const ChildModal = ({ title, data, setWorksModal, setExpand, loading }) => {
  const dispatch = useDispatch();
  if (loading)
    return (
      <ChildComponent className="child-container">
        <Content>
          <Header>
            <Title>{title}</Title>
            <CloseButton onClick={() => setExpand('')}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Body>
            <Spinner />
          </Body>
        </Content>
      </ChildComponent>
    );

  return (
    <ChildComponent className="child-container">
      <Content>
        <Header>
          <Title>{title}</Title>
          <CloseButton onClick={() => setExpand('')}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <Body>
          {data.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            data.map((item, idx) => (
              <Item key={`${idx + 1}`}>
                <LeftContainer>
                  <span
                    style={{
                      paddingRight: '20px',
                    }}
                  >
                    {item.id}
                  </span>
                  <ItemText style={{ textAlign: 'left' }}>{item.name}</ItemText>
                </LeftContainer>
                <RightContainer>
                  {/* <ItemText>{item.total}</ItemText> */}
                  <Button
                    disabled={!item.total}
                    style={{
                      background: !item.total ? '#a8a8a8' : '#00826C',
                      borderRadius: '6px',
                      cursor: !item.total && 'grab',
                      color: '#fff',
                      padding: '0 20px',
                    }}
                    onClick={() => {
                      dispatch(setModalWork(item));
                      setWorksModal(item);
                    }}
                  >
                    Kўриш
                  </Button>
                </RightContainer>
              </Item>
            ))
          )}
        </Body>
      </Content>
    </ChildComponent>
  );
};
const ChildTimeIntervalModal = ({
  title,
  data,
  setExpand,
  loading,
  transform,
}) => {
  if (loading)
    return (
      <ChildComponent transform={transform} className="child-container">
        <Content>
          <Header>
            <Title>{title}</Title>
            <CloseButton onClick={() => setExpand('')}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Body>
            <Spinner />
          </Body>
        </Content>
      </ChildComponent>
    );

  return (
    <ChildComponent transform={transform} className="child-container">
      <Content>
        <Header>
          <Title>{title}</Title>
          <CloseButton onClick={() => setExpand('')}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <Body>
          {data.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            data.map((item, idx) => (
              <Item key={`${idx + 1}`} timeInterval>
                <LeftContainer>
                  <span
                    style={{
                      paddingRight: '20px',
                    }}
                  >
                    {item.id}
                  </span>{' '}
                  <ItemText style={{ textAlign: 'left' }}>{item.name}</ItemText>
                </LeftContainer>
                <RightContainer>
                  {item?.count ? (
                    <p style={{ textAlign: 'center', margin: '0' }}>
                      {item.count} <br /> марта
                    </p>
                  ) : null}
                  <p
                    style={{
                      margin: '0',
                      minWidth: '30px',
                      textAlign: 'center',
                    }}
                  >
                    {item.total}
                  </p>
                  {location.pathname.substr(1) === 'library-report' && (
                    <PercentContainer percent={item.percent}>
                      {item.percent}%
                    </PercentContainer>
                  )}
                </RightContainer>
              </Item>
            ))
          )}
        </Body>
      </Content>
    </ChildComponent>
  );
};

const LibraryReportModal = ({ title, data, setExpand, loading, transform }) => {
  if (loading)
    return (
      <ChildComponent
        $width={'530px'}
        transform={transform}
        className="child-container"
      >
        <Content>
          <Header>
            <Title>{title}</Title>
            <CloseButton onClick={() => setExpand('')}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Body>
            <Spinner />
          </Body>
        </Content>
      </ChildComponent>
    );

  return (
    <ChildComponent
      $width={'530px'}
      transform={transform}
      className="child-container"
    >
      <Content>
        <Header>
          <Title>{title}</Title>
          <CloseButton onClick={() => setExpand('')}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <Body>
          {data?.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <LibraryModalTable headers={LibraryModalColumns} data={data} />
          )}
        </Body>
      </Content>
    </ChildComponent>
  );
};

export default React.memo(
  ({
    data = [],
    headers,
    fetchData,
    height,
    notCheckable,
    selectCategory,
    loading,
    onChange,
    setWorksModal,
    total,
    page,
    subLoading,
    id,
    sort,
    setSort,
  }) => {
    const [expand, setExpand] = useState('');
    const location = useLocation();
    const dispatch = useDispatch();
    const { groupId, tableIndex, subData, groupTitle, colIndex } = useSelector(
      (state) => state.appReducer
    );
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,
      canPreviousPage,
      canNextPage,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns: headers,
        data,
        manualPagination: true,
        // manualSortBy: true,
        // autoResetPage: false,
        initialState: {
          pageIndex: 0,
          pageSize: 10,
        },
        pageCount: page,
      },
      // useSortBy,
      usePagination
    );

    useEffect(() => {
      if (onChange) onChange({ pageIndex, pageSize });
    }, [pageIndex, pageSize]);

    // useEffect(() => {
    //   if (setSort) {
    //     const [sorted] = sortBy;
    //     setSort(sorted);
    //   }
    // }, [sortBy, setSort]);

    const handleExpand = (value, e) => {
      if (expand !== value) {
        setExpand(value);
      } else {
        setExpand('');
      }
    };

    if (data.length === 0)
      return (
        <h2 style={{ textAlign: 'center', fontSize: '26px', color: '#fff' }}>
          {"Tanlangan vaqt kesimi bo'yicha natijalar topilmadi!"}
        </h2>
      );

    const handleSelectCategory = (id, userId, value) => {
      if (expand !== value) {
        selectCategory(id, userId);
      }
    };

    const subCompos = {
      ['daily-rating']: (
        <ChildModal
          title={groupTitle}
          data={subData}
          setExpand={setExpand}
          loading={subLoading}
          setWorksModal={setWorksModal}
        />
      ),
      ['time-interval']: (
        <ChildTimeIntervalModal
          data={subData}
          loading={subLoading}
          setExpand={setExpand}
          title={groupTitle}
        />
      ),
      ['external-app']: (
        <ChildTimeIntervalModal
          data={subData}
          loading={subLoading}
          setExpand={setExpand}
          title={groupTitle}
          transform="translate(-12%)"
        />
      ),
      ['library-report']: (
        <LibraryReportModal
          data={subData}
          loading={subLoading}
          setExpand={setExpand}
          title={groupTitle}
        />
      ),
    };
    const checkSubComponent = (index, cIndex) => {
      if (expand === groupId && tableIndex === index && colIndex === cIndex) {
        return subCompos[location.pathname.substr(1)];
      }
      return null;
    };

    const handleSort = (value) => {
      if (value) setSort((prev) => !prev);
    };
    return (
      <>
        <Container>
          {loading ? (
            <Spinner maxHeight="600px" />
          ) : (
            <Wrapper height={height}>
              <>
                <Table {...getTableProps()} id={id}>
                  <THead>
                    {headerGroups.map((headerGroup, idx) => (
                      <TR
                        {...headerGroup.getHeaderGroupProps()}
                        notCheckable={notCheckable}
                        key={`${idx + 1}`}
                      >
                        {headerGroup.headers.map((header, index) => (
                          <TH
                            key={`${index + 1}`}
                            // {...header.getHeaderProps(
                            //   header.getSortByToggleProps()
                            // )}
                          >
                            <Cell
                              style={{
                                cursor: header.sort && 'pointer',
                              }}
                              {...header}
                              onClick={() => handleSort(header.sort)}
                            >
                              {header.render('Header')}
                              <span>
                                {header.sort ? (
                                  sort ? (
                                    <TbSortDescending size={20} />
                                  ) : (
                                    <TbSortAscending size={20} />
                                  )
                                ) : (
                                  ''
                                )}
                              </span>
                            </Cell>
                          </TH>
                        ))}
                      </TR>
                    ))}
                  </THead>

                  <TBody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                      prepareRow(row);
                      return (
                        <React.Fragment key={`${index + 1}`}>
                          <TREmpty />
                          <TR $loading={subLoading}>
                            {row.cells.map((cell, ind) => {
                              const { column } = cell;
                              return (
                                <React.Fragment key={`${ind + 1}`}>
                                  <TD
                                    {...column}
                                    className={data.length > 6 && 'body-cell'}
                                  >
                                    <Cell {...column}>
                                      {cell.render('Cell')}
                                      {column.expanded &&
                                        cell.value?.categoryValue >= 0 &&
                                        cell.value?.categoryValue !== null && (
                                          <ExpandButton
                                            // ref={referenceRef}
                                            handleExpand={(e) => {
                                              handleExpand(
                                                cell.value?.groupId,
                                                e
                                              );
                                              const payload = {
                                                groupId: cell.value?.groupId,
                                                index,
                                                colIndex: ind,
                                                title:
                                                  cell.value?.categoryTitle,
                                              };
                                              handleSelectCategory(
                                                cell.value.id,
                                                cell.value.userId,
                                                cell.value.groupId
                                              );
                                              dispatch(setgroupId(payload));
                                            }}
                                            active={
                                              expand === cell.value?.groupId
                                            }
                                            value={
                                              expand === cell.value?.groupId &&
                                              true
                                            }
                                          />
                                        )}
                                      {cell.value?.categoryValue === null && (
                                        <span
                                          style={{
                                            fontSize: '12px',
                                            fontWeight: 'bold',
                                            color: '#a8a8a8',
                                            textAlign: 'center',
                                          }}
                                        >
                                          Фойдаланувчига категория
                                          бириктирилмаган
                                        </span>
                                      )}
                                    </Cell>
                                    <>{checkSubComponent(index, ind)}</>
                                  </TD>
                                </React.Fragment>
                              );
                            })}
                          </TR>
                        </React.Fragment>
                      );
                    })}
                  </TBody>
                </Table>
              </>
            </Wrapper>
          )}
        </Container>
        {total > 10 ? (
          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageIndex={pageIndex}
          />
        ) : null}
      </>
    );
  }
);
