import types from '../../../constants/action-types';

export const setgroupId = (payload) => ({
  type: types.APPLICATION_SET_GROUP_ID,
  payload,
});
export const setProvinces = (payload) => ({
  type: types.APPLICATION_SET_PROVINCES,
  payload,
});

export const setRegions = (payload) => ({
  type: types.APPLICATION_SET_REGIONS,
  payload,
});
export const setDistricts = (payload) => ({
  type: types.APPLICATION_SET_OBJECTS,
  payload,
});
export const setCollapsed = (payload) => ({
  type: types.APPLICATION_SET_SIDEBAR_COLLAPSED,
  payload,
});

export const setDesktopCollapsed = (payload) => ({
  type: types.APPLICATION_SET_SIDEBAR_DESKTOP_COLLAPSED,
  payload,
});

export const setCategories = (payload) => ({
  type: types.APPLICATION_SET_CATEGORIES,
  payload,
});
export const setSupportTypes = (payload) => ({
  type: types.APPLICATION_SET_SUPPORTS,
  payload,
});
export const fetchData = (payload) => ({
  type: types.APPLICATION_FETCH_COMMONS_DATA,
  payload,
});

export const setReportSubData = (payload) => ({
  type: types.APPLICATION_SET_SUB_DATA,
  payload,
});

export const setLoading = (payload) => ({
  type: types.APPLICATION_SET_LOADING,
  payload,
});

export const setUsers = (payload) => ({
  type: types.APPLICATION_SET_USERS,
  payload,
});
export const setActiveItem = (payload) => ({
  type: types.APPLICATION_ACTIVE_ITEM,
  payload,
});
export const setBadgeNotificationCount = (payload) => ({
  type: types.APPLICATION_SET_SIDEBAR_BADGE_COUNT,
  payload,
});
export const applicationCleanState = () => ({
  type: types.APPLICATION_CLEAN_STATE,
});
