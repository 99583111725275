import styled from 'styled-components';

export const ImagesBlock = styled.div`
  width: 100%;
  max-width: 490px;
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 14px;
`;
export const MainImage = styled.div`
  width: 355px;
  height: 294px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;
  top: 0;
  left: 0;
  border-radius: 10px;
  border: 1px solid rgba(8, 9, 54, 0.1);
  background: #fff;
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & .deleteBtn {
    background-color: transparent;
    color: re;
    font-size: 30px;
    border: none;
    outline: none;
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1000;
    color: #0093d2;
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
    display: none;
  }
  &:hover .deleteBtn {
    display: inline-block;
  }
`;

export const ImageList = styled.div`
  width: 130px;
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const ImageThumbnail = styled.div`
  width: 119px;
  height: 94px;
  flex-shrink: 0;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  &:hover {
    & svg {
      color: #0093d2;
      transition-duration: 1.2s;
      transform: scale(1.5);
    }
  }
  & img {
    background-origin: center;
    background-size: cover;
    border-radius: 10px;
    object-fit: cover;
  }

  & .effect__blur {
    filter: blur(4px);
  }

  & svg {
    position: absolute;
    top: 40%;
    left: 45%;
    color: #fff;
    font-size: 24px;
    transition-duration: 1s;
  }
`;
export const VideoThumbnail = styled.div`
  width: ${({ width }) => (width ? width : '119px')};
  height: ${({ height }) => (height ? height : '94px')};
  /* height: 94px; */
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  &:hover {
    & svg {
      color: #00826C;
      transition-duration: 1.2s;
      transform: scale(1.5);
    }
  }
  & img {
    background-origin: center;
    background-size: cover;
    border-radius: 10px;
    object-fit: contain;
  }
  & .effect__blur {
    filter: blur(10px);
  }
  & .play__icon {
    position: absolute;
    top: 40%;
    left: 45%;
    color: #fff;
    font-size: 34px;
    transition-duration: 1s;
  }
  & .play__icon__thumb {
    position: absolute;
    top: 40%;
    left: 40%;
    color: #fff;
    font-size: 20px;
    transition-duration: 1s;
  }
`;
