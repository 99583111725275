import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;
  gap: 20px;
  .ant-select-selector {
    min-height: 32px !important;
    min-width: 120px !important;
    width: 140px !important;
  }
  .ant-select-arrow {
    background: none;
  }
`;

export const Container = styled.div`
  /* background-color: #fff; */
  display: flex;
  grid-gap: 13px;
  align-items: center;
  padding: 5px 10px;
  /* border: 1px solid rgba(0, 0, 0, 0.07); */
  /* border-radius: 10px; */
  justify-content: space-between;
`;

export const PageButtons = styled.div`
  /* background-color: transparent; */
  /* border-radius: 8px; */
  max-width: 350px;
  /* height: 30px; */
  display: grid;
  overflow: hidden;
  gap: 8px;
  grid-template-columns: ${({ repeat }) =>
    repeat ? `repeat(${repeat}, 1fr)` : 'repeat(7, 1fr)'};
`;

export const Button = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: ${({ active }) =>
    active ? '1px solid #00826C' : '1px solid rgba(140, 151, 171, 0.2)'};
  background: #fff;
  font-size: 15px;
  /* min-width: calc(300px / 7); */
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: ${({ active }) => !active && '#00826C'};
  }
  &:focus {
    outline: none;
  }
  ${({ active }) =>
    active &&
    `
    color: #00826C;
  `}
`;

export const ChangeButtons = styled.div`
  width: 90px;
  height: 32px;
  border-radius: 8px;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
`;

export const ChangeButton = styled.button`
  width: 32px;
  height: 32px;
  color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 6px;
  border: none;
  transition: all ease 300ms;
  color: #444;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:not(:disabled):hover {
    background: #00826C;
    color: #fff;
  }
  &:disabled {
    background: #f5f5f5;
  }
`;
