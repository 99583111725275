import types from '../../../constants/action-types';

const defaultState = {
  pathname: '',
};

const map = {
  [types.SET_PAGE_PATHNAME]: (state, { payload }) => ({
    ...state,
    pathname: payload,
  }),

  [types.CLEAR_PATHNAME]: (state) => ({
    ...state,
    pathname: '',
  }),
};

// eslint-disable-next-line max-len
export default (state, action) =>
  (map[action.type] && map[action.type](state, action)) ||
  state ||
  defaultState;
