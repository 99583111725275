import React from 'react';
import { Checkbox, Col, Row } from 'antd';
import { Item, Box, Text, FlexBox } from './style';
import Map from '../../../../Map';
import { useSelector } from 'react-redux';

export default ({
  bal,
  onChange,
  data,
  handleShowModal,
  workId,
  selectedRates,
}) => {
  const { lang } = useSelector((state) => state.appReducer);

  return (
    <div style={{ width: '100%' }}>
      <Row gutter={[10, 15]} wrap={true}>
        <Col sm={24} lg={24} xl={16} xxl={16}>
          {data?.map((item, index) => (
            <Item key={index + 1}>
              <Checkbox
                disabled={
                  !selectedRates.includes(item?._id) && bal - item.rate < 0
                }
                defaultValue={item._id}
                checked={selectedRates?.includes(item?._id)}
                onChange={() => onChange(workId, item?._id, item.rate)}
              >
                {item?.[`title${lang}`]}
              </Checkbox>
              {item.rate}
            </Item>
          ))}
        </Col>
        <Col sm={24} lg={8} xl={8} xxl={8}>
          <Box>
            <FlexBox>
              <Text>{bal}</Text>
              <Map handleShowModal={handleShowModal} />
            </FlexBox>
          </Box>
        </Col>
      </Row>
    </div>
  );
};
