import types from '../../../constants/action-types';

const defaultState = {
  activePageTitle: 'O’zbekiston mahallalari uyushmasi',
};

const map = {
  [types.APPLICATION_ACTIVE_PAGE_TITLE]: (state, { payload }) => ({
    ...state,
    activePageTitle: payload,
  }),
  [types.APPLICATION_SET_DEFAULT_PAGE_TITLE]: (state) => ({
    ...state,
    activePageTitle: 'O’zbekiston mahallalari uyushmasi',
  }),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) =>
  (map[action.type] && map[action.type](state, action)) ||
  state ||
  defaultState;
