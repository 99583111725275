import React, { useState } from 'react';
import './style.css';

export default ({ onChange, name }) => {
  const [fileName, setFileName] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const fileReader = new FileReader();
    setFileName(e.target.files[0]?.name);
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      try {
        const objectJSon = JSON.parse(e.target.result);
        if (objectJSon?.features[0]?.geometry?.coordinates.length > 1) {
          const newFiles = objectJSon?.features[0]?.geometry?.coordinates;
          onChange(newFiles);
        } else {
          onChange(objectJSon?.features[0]?.geometry?.coordinates);
        }
      } catch (error) {
        setError('Faylda xatolik aniqlandi. Iltimos faylni tekshiring');
      }
    };
  };

  return (
    <>
      <label htmlFor="file-upload" className="custom-file-upload">
        Fayl yuklang
      </label>
      <input
        id="file-upload"
        type="file"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
      {error ? (
        <span className="error-message">{error}</span>
      ) : fileName ? (
        fileName
      ) : (
        name && `${name}.json`
      )}
    </>
  );
};
