import { Carousel } from 'antd';
import styled from 'styled-components';

export const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
  > .slick-dots li.slick-active button {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: #fff;
  }
`;

export const CarouselItem = styled.div`
  width: ${({ size }) => (size !== 'small' ? '285px' : '205px')};
  height: ${({ size }) => (size !== 'small' ? '245px' : '179px')};
  /* width: 205px;
height: 179px; */
  flex-shrink: 0;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: start;
  background: #e9e9fa;
  @media screen and (max-width: 960px) {
    width: 155px;
    height: 155px;
  }
  .ant-image-mask {
    border-radius: 16px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
`;

export const VideoFrame = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  &:hover {
    & svg {
      color: #00826C;
      transition-duration: 1.2s;
      transform: scale(1.5);
    }
  }
  & img {
    background-origin: center;
    background-size: cover;
    border-radius: 10px;
    object-fit: contain;
  }
  & .effect__blur {
    filter: blur(10px);
  }
  & svg {
    position: absolute;
    top: 45%;
    left: 45%;
    color: #fff;
    font-size: 20px;
    transition-duration: 1s;
  }
`;
