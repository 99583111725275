import React, { useEffect, useMemo, useState } from 'react';
import Table from 'components/Tables/ExpandedTable';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'components/Spinner';
import service from 'services/reports';
import { Col, Pagination, Row, Empty } from 'antd';
import Select from 'components/FormElements/Select';
import { NoData } from '../style';
import { headerMaker } from 'components/Tables/ExpandedTable/helper';
import { setReportSubData } from 'redux/modules/application/actions';
import { useFilter } from 'views/useFilter';
import DownloadToExcell from 'components/DownloadToExcell';
import moment from 'moment';

export default () => {
  const { role } = useSelector((state) => state.authReducer);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [data, setData] = useState([]);
  const [excellData, setExcellData] = useState([]);

  const mainHeaders = [
    {
      id: 1,
      Header: '№',
      accessor: 'id',
      show: true,
      width: 30,
    },
    {
      id: 3,
      Header: 'VILOYAT',
      accessor: 'province',
      show: ['leader'].includes(role) ? true : false,
    },
    {
      id: 4,
      Header: 'TUMAN (SHAHAR)',
      accessor: 'region',
      show: ['leader', 'proleader'].includes(role) ? true : false,
    },
    {
      id: 9,
      Header: 'MFY',
      accessor: 'district',
      show: ['leader', 'proleader', 'regleader'].includes(role) ? true : false,
    },
    {
      id: 2,
      Header: 'F.I.O',
      accessor: 'user_name',
      width: 250,
      show: true,
    },
    {
      id: 6,
      Header: "JAMI / KO'RILGAN",
      accessor: 'total',
      show: true,
      align: 'center',
    },
    {
      id: 7,
      Header: "O'QILMOQDA",
      accessor: 'reading',
      //   width: 50,
      show: true,
      width: 150,
      align: 'center',
      border: true,
      type: 'info',
      expanded: true,
    },
    {
      id: 8,
      Header: "O'QILGAN",
      accessor: 'read',
      show: true,
      width: 150,
      align: 'center',
      border: true,
      type: 'info',
      expanded: true,
    },
  ];
  const headers = useMemo(() => headerMaker(mainHeaders), [mainHeaders]);
  const { handleFilters, selectedFilters, provinces, regions } = useFilter({
    withObject: true,
  });
  const fetchData = async (page, limit) => {
    try {
      const payload = {};
      if (page && limit) {
        setLoading(true);

        payload['page'] = page;
        payload['limit'] = limit;
      }
      if (
        selectedFilters?.province ||
        selectedFilters?.region ||
        selectedFilters?.district
      ) {
        payload['province'] = selectedFilters?.province;
        payload['region'] = selectedFilters?.region;
        payload['district'] = selectedFilters?.district;
      }
      if (selectedFilters?.sort) {
        payload['sort'] = selectedFilters?.sort;
      }
      setLoading(true);
      const result = await service.getBooksReports(payload);
      const respData = result?.docs ?? result;
      const report = respData?.map((item, idx) => ({
        id: pageIndex * pageSize - pageSize + idx + 1,
        user_name:
          `${item?.user?.lastName} ${item?.user?.firstName} ${item?.user?.middleName}` ??
          '-',
        province: item?.province?.titleUZ ?? '-',
        region: item?.region?.titleUZ ?? '-',
        district: item?.district?.titleUZ ?? '-',
        total:
          result?.bookTotal +
            ' / ' +
            Number(item?.readBook + item?.readingBook) ?? 0,
        reading: {
          value: item?.readingBook ?? 0,
          id: false,
          userId: item?.user._id,
          categoryValue: item?.readingBook ?? 0,
          categoryTitle: "O'qilayotgan kitoblar",
          groupId: Math.floor(Math.random() * Date.now()),
        },
        read: {
          value: item?.readBook ?? 0,
          id: true,
          userId: item?.user._id,
          categoryValue: item?.readBook ?? 0,
          categoryTitle: "O'qilgan kitoblar",
          groupId: Math.floor(Math.random() * Date.now()),
        },
      }));
      if (!page && !limit) {
        setExcellData(report.length ? report : []);
      } else {
        setData(report?.length ? report : []);
        setTotal(result?.totalDocs ?? 0);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData(pageIndex, pageSize);
  }, [pageSize, pageIndex, selectedFilters]);

  const onShowSizeChange = (_, pageSize) => {
    setPageSize(pageSize);
  };

  const handleExcellDataFetch = () => {
    fetchData();
  };

  const handleSelectArrow = async (status, userId) => {
    try {
      setLoadingSub(true);
      const data = {
        user: userId,
        success: status,
      };
      let resp = await service.getSubBooksReports(data);
      const report = resp?.map((item, idx) => ({
        id: idx + 1,
        name: item?.book ?? '-',
        total: `${item?.count}/${item.total}`,
        startDate: item?.startedDate
          ? moment(item?.startedDate).format('YYYY-MM-DD')
          : '-',
        lastDate: item?.lastDate
          ? moment(item?.lastDate).format('YYYY-MM-DD')
          : '-',
        percent: item.total ? Math.round((item?.count * 100) / item?.total) : 0,
      }));

      dispatch(setReportSubData(report ? report : []));
      setLoadingSub(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoadingSub(false);
    }
  };

  const filterItems = [
    {
      name: 'province',
      label: 'Viloyat',
      options: provinces,
      permissions: ['leader'],
      placeholder: 'Viloyatni tanlang...',
      type: 'select',
      span: 12,
      show: true,
    },
    {
      name: 'region',
      label: 'Shahar-Tuman',
      options: regions,
      permissions: ['leader', 'proleader'],
      placeholder: 'Shaharni tanlang...',
      type: 'select',
      span: 12,
      show: true,
    },
    {
      name: 'sort',
      label: 'Saralash',
      options: [
        {
          title: "O'sish tartibida",
          _id: 'asc',
        },
        {
          title: 'Kamayish tartibida',
          _id: 'desc',
        },
      ],
      permissions: ['leader', 'proleader', 'regleader'],
      placeholder: 'Shaharni tanlang...',
      type: 'select',
      span: 12,
      show: true,
    },
  ];

  if (loading) return <Spinner maxHeight={'100%'} />;
  return (
    <>
      <Row
        align="bottom"
        justify="space-between"
        style={{ padding: '0px 0px 20px' }}
        gutter={20}
      >
        <Col style={{ display: 'flex', gap: '20px' }}>
          {filterItems?.map(
            (item, index) =>
              item?.permissions?.includes(role) && (
                <Select
                  key={index}
                  title={item?.label}
                  name={item?.name}
                  id="sort"
                  handleChange={(value) => handleFilters(item?.name, value)}
                  value={selectedFilters[item?.name]}
                  options={item?.options}
                  placeholder="Tanlang!"
                />
              )
          )}
        </Col>
        <Col>
          <div className="excell__btn">
            <DownloadToExcell
              fileName={'Elektron kutubxona hisibot'}
              tableName={'online_library_report'}
              tableHeader={headers}
              tableData={excellData}
              handleFetchData={handleExcellDataFetch}
              setExcellData={setExcellData}
            />
          </div>
        </Col>
      </Row>
      {data?.length ? (
        <>
          <Table
            headers={headers}
            loading={loading}
            data={data}
            selectCategory={handleSelectArrow}
            subLoading={loadingSub}
          />
          {total > 10 && (
            <div className="custom-pagination-wrapper">
              <Pagination
                current={pageIndex}
                pageSize={pageSize}
                onShowSizeChange={onShowSizeChange}
                total={total}
                onChange={(page) => setPageIndex(page)}
              />
            </div>
          )}
        </>
      ) : (
        <NoData>
          <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
        </NoData>
      )}
    </>
  );
};
