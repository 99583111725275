import React from 'react';
import { MenuItem } from '../style';
import {
  DropdownMenu,
  DropdownContainer,
  DropdownButton,
  HamburgerMenu,
  ListItem,
} from './style';
import { GiHamburgerMenu } from 'react-icons/gi';
import Rightoptions from 'components/Navbar/RightOptions';

const MobileMenu = ({
  options,
  open,
  handleToggle,
  isActive,
  handlePage,
  username,
  handleLogOut,
}) => {
  return (
    <DropdownContainer>
      <DropdownButton onClick={handleToggle}>
        <HamburgerMenu open={open}>
          <GiHamburgerMenu size={24} color={open ? '#00826C' : '#000'} />
        </HamburgerMenu>
      </DropdownButton>
      {open && (
        <button className="close__outside__btn" onClick={handleToggle} />
      )}
      <DropdownMenu open={open}>
        <Rightoptions handleLogout={handleLogOut} user={username} darkText />
        {options.map((option, index) => (
          <ListItem
            isActive={isActive === option?.key}
            onClick={() => {
              handlePage(option.key, option.href);
            }}
            key={index}
          >
            {option?.title}
          </ListItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default MobileMenu;
