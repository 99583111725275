import React from 'react';
import { DeleteWrapper } from '../style';
import { useHideModal } from 'hooks';
import Spinner from 'components/Spinner';
import { useSelector } from 'react-redux';

export default ({ handleDelete }) => {
  const { hideModal } = useHideModal();
  const { loading } = useSelector((state) => state.modalReducer);

  return loading ? (
    <Spinner maxHeight={'100%'} />
  ) : (
    <DeleteWrapper>
      <div className="delete-title">Kitobni o’chirish</div>
      <div className="delete-text">
        Haqiqatan ushbu kitobni o’chirmoqchimisiz?
      </div>
      <div className="buttons-wrapper">
        <button className="cancel-button" onClick={() => hideModal()}>
          Yo‘q
        </button>
        <button className="button-submit" onClick={handleDelete}>
          Ha
        </button>
      </div>
    </DeleteWrapper>
  );
};
