import React from 'react';
import { CustomButton, Wrapper } from './style';

const Filters = ({ list = [], handleActive, active }) => {
  return (
    <Wrapper>
      {list.map(({ title, value, id }, ind) => (
        <CustomButton
          key={`${ind + 1}`}
          type="primary"
          size="large"
          style={{
            background: active === value && '#00C4FA',
          }}
          onClick={() => handleActive({ value, id })}
        >
          {title}
        </CustomButton>
      ))}
    </Wrapper>
  );
};

export default React.memo(Filters);
