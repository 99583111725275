import React, { useState } from 'react';
import { Col, Row, Table, Space, Button, Select } from 'antd';
import Typograpy from '../../../components/Typograpy';
import { AddContainer } from '../Users/style';
import AddButton from '../../../components/AddButton';
import ActionButton from '../../../components/ActionButton';
import { useHideModal, useShowModal } from '../../../hooks';
import { formData, initialValuesAdd } from './helper';
import FormCreator from '../../../components/FormCreator';
import Confirm from '../../../components/Confirm';
import provinceService from '../../../services/provinces';
import regionService from '../../../services/regions';
import objectService from '../../../services/objects';
import { useEffect } from 'react';
import { useShowAlert } from '../../../hooks/alert';
import FullScreen from '../../../components/Spinner/FullScreen';
import Spinner from '../../../components/Spinner';
import { Pagination } from 'antd';
import { objectConstructor } from '../../../utils/objectConstructor';
import { AiOutlineFilter } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { GiCloakDagger } from 'react-icons/gi';

export default () => {
  const { lang } = useSelector((state) => state.appReducer);
  const { success, error } = useShowAlert();
  const [sections, setSections] = useState({
    province: [],
    regions: [],
  });
  const { showBlured } = useShowModal();
  const { hideModal } = useHideModal();
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [parentObjects, setParentObjects] = useState({
    total: 0,
    pages: 0,
    districts: [],
  });
  const [bool, setBool] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState(false);
  const [filterItems, setFilterItems] = useState({ region: '', province: '' });
  const { Option } = Select;

  const fetchObjects = () => {
    setLoading(true);
    let data = {
      page: page,
      limit: limit,
    };
    if (filterItems?.province) {
      data['province'] = filterItems?.province;
    }
    if (filterItems?.region) {
      data['region'] = filterItems?.region;
    }
    objectService
      .getAll(data)
      .then((res) => {
        if (res?.docs)
          setParentObjects(() => ({
            total: res?.totalDocs,
            pages: res?.totalPages,
            districts: res?.docs.map((item) => ({ ...item, key: item._id })),
          }));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    fetchObjects();
  }, [page, limit, filterItems]);

  useEffect(() => {
    provinceService
      .getAll()
      .then((res) => {
        setSections((prevSections) => ({
          ...prevSections,
          province: res.map((item) => ({
            ...item,
            title: item['title' + 'UZ'],
          })),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (filterItems.province) {
      const payload = {
        province: filterItems.province,
      };
      regionService
        .getAll(payload)
        .then((res) => {
          setSections((prevSections) => ({
            ...prevSections,
            regions: res.map((item) => ({
              ...item,
              title: item['title' + 'UZ'],
            })),
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [filterItems.province]);
  //Add object

  const sendData = (data) => {
    hideModal();
    setLoading(true);
    if (data.parent === '') {
      delete data.parent;
    }
    const payload = {
      ...data,
      coordinate: [
        Number(data.coordinate.split(',')[0]),
        Number(data.coordinate.split(',')[1]),
      ],
    };
    objectService
      .create(payload)
      .then(() => {
        fetchObjects();
        success("MFY ma'lumoti saqlandi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("MFY ma'lumotini saqlashda xatolik", 2, 'bottom');
      });
  };
  const addObject = () => {
    showBlured({
      title: "MFY qo'shish",
      maxWidth: '600px',
      height: '400px',
      withHeader: false,
      body: () => (
        <FormCreator
          formData={formData}
          cancel={hideModal}
          sendData={sendData}
          selectOptions={sections}
          id=""
          fetchObject={false}
          initialVal={initialValuesAdd}
        />
      ),
    });
  };

  // Update

  const initialValuesEdit = (data) => {
    return {
      titleQQ: data.titleQQ,
      titleRU: data.titleRU,
      titleUZ: data.titleUZ,
      desc: data?.desc,
      province: data.province?._id,
      region: data.region?._id,
      coordinates: data?.coordinates,
      coordinate: data.coordinate.join(','),
    };
  };

  const updateData = (id, data) => {
    hideModal();
    setLoading(true);
    const payload = {
      ...data,
      coordinate: [
        Number(data.coordinate.split(',')[0]),
        Number(data.coordinate.split(',')[1]),
      ],
    };
    objectService
      .update(id, payload)
      .then(() => {
        fetchObjects();
        success("MFY ma'lumoti yangilandi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("MFY ma'lumoti yangilashda xatolik", 2, 'bottom');
      });
  };

  const handleEdit = (data) => {
    showBlured({
      title: 'MFY ni tahrirlash',
      maxWidth: '650px',
      height: '600px',
      withHeader: false,
      body: () => (
        <FormCreator
          formData={formData}
          cancel={hideModal}
          sendData={updateData}
          selectOptions={{ ...sections }}
          id={data._id}
          fetchObject={false}
          initialVal={initialValuesEdit(data)}
        />
      ),
    });
  };

  // Delete

  const deleteObject = (id) => {
    hideModal();
    setLoading(true);
    objectService
      .delete(id)
      .then(() => {
        fetchObjects();
        success("MFY o'chirildi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("MFY ni o'chirishda xatolik", 2, 'bottom');
      });
  };

  const handleDelete = (id) => {
    showBlured({
      maxWidth: '400px',
      height: '300px',
      withHeader: false,
      body: () => <Confirm agree={() => deleteObject(id)} cancel={hideModal} />,
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
  };

  const columns = [
    {
      title: 'Nomi',
      dataIndex: 'title',
      key: 'title',
      render: (_, row) => {
        return row?.['title' + lang];
      },
    },
    {
      title: 'Viloyat',
      dataIndex: 'province',
      key: '1',
      render: (props) => {
        return props?.['title' + lang];
      },
    },
    {
      title: 'Shahar (Tuman)',
      dataIndex: 'region',
      key: '1',
      render: (props) => {
        return props?.['title' + lang];
      },
    },
    {
      title: 'Hudud chegarasi',
      dataIndex: 'coordinates',
      width: '160px',
      align: 'center',
      key: '1',
      render: (props) => {
        return props?.length ? '+' : '-';
      },
    },
    {
      title: 'Ish joyi',
      width: '100px',
      align: 'center',
      dataIndex: 'coordinate',
      key: '1',
      render: (props) => (props?.length ? '+' : '-'),
    },

    {
      width: '20%',
      align: 'center',
      title: 'Action',
      dataIndex: '',
      key: 'y',
      render: (props) => (
        <ActionButton
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          props={props}
        />
      ),
    },
  ];

  const handleChangeProvinceSelect = (value) => {
    setFilterItems((prevVal) => ({
      ...prevVal,
      province: value,
      region: undefined,
    }));
    setPage(() => 1);
    setSections((prevVal) => ({
      ...prevVal,
      regions: [],
    }));
  };
  const handleChangeRegion = (value) => {
    setFilterItems((prevVal) => ({
      ...prevVal,
      region: value,
    }));
    setPage(() => 1);
  };
  if (loading) return <FullScreen />;
  return (
    <>
      <AddContainer>
        <AddButton text="MFY" formData={formData} addData={addObject} />
      </AddContainer>
      <Row>
        <Col span={24}>
          <>
            <Typograpy color="#080936"> MFY lar </Typograpy>
            <Space
              direction="vertical"
              size="middle"
              style={{
                width: '100%',
                height: '45px',
                padding: '0 20px',
              }}
            >
              <Row>
                <Col span={22}>
                  {filter ? (
                    <Space>
                      Viloyat
                      <Select
                        value={filterItems?.province}
                        allowClear
                        onChange={handleChangeProvinceSelect}
                      >
                        {sections.province?.map((item, ind) => (
                          <Option key={ind + 1} value={item._id}>
                            {item.title}
                          </Option>
                        ))}
                      </Select>
                      Shahar (Tumanlar)
                      <Select
                        value={filterItems?.region}
                        allowClear
                        onChange={handleChangeRegion}
                      >
                        {sections.regions?.map((item, ind) => (
                          <Option key={ind + 1} value={item._id}>
                            {item.title}
                          </Option>
                        ))}
                      </Select>
                    </Space>
                  ) : null}
                </Col>
                <Col span={2} style={{ textAlign: 'right' }}>
                  <Button
                    // className="edit-btn"
                    onClick={() => setFilter(!filter)}
                    shape="circle"
                    type="text"
                    icon={<AiOutlineFilter size={25} />}
                  />
                </Col>
              </Row>
            </Space>
            <Space
              direction="vertical"
              size="middle"
              style={{
                display: 'flex',
                padding: '0 20px',
                justifyContent: 'center',
              }}
            >
              <Table
                bordered
                columns={columns}
                dataSource={parentObjects?.districts}
                pagination={false}
              />
              {parentObjects.total > 10 ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Pagination
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    style={{ textAlign: 'center' }}
                    current={page === 0 ? 1 : page}
                    pageSize={limit}
                    onChange={(current, newSize) => {
                      setPage(newSize !== limit ? 1 : current);
                    }}
                    total={parentObjects.total}
                    // size="large"
                  />
                </div>
              ) : null}
            </Space>
          </>
        </Col>
      </Row>
    </>
  );
};
