import React from 'react';
// import { getColor } from '../../../../utils/getColor';
// import DashboardIcon from '../../../DashboardIcon';
import { Wrapper } from './style';

export default ({ name }) => (
  <Wrapper>
    {/* <IconContainer>
      <DashboardIcon color={getColor(Math.min(100, value))} />
    </IconContainer> */}
    <h4>{name}</h4>
  </Wrapper>
);
