// Render Prop
import React from "react";

import { Box, TextContainer } from "./style";
import { Col, Row, Button } from "antd";
import Typograpy from "../Typograpy";
export default ({ agree, cancel }) => {
  return (
    <Box>
      <TextContainer>
        <Typograpy color="#080936">Olib tashlashni tasdiqlaysizmi ?</Typograpy>
      </TextContainer>
      <Row justify="end" gutter={[8, 8]}>
        <Col>
          <Button onClick={cancel}>Bekor qilish</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={agree}>
            Tasdiqlash
          </Button>
        </Col>
      </Row>
    </Box>
  );
};
