export const statusName = {
  1: 'Yangi',
  2: 'Jarayonda',
  3: 'Tugallangan',
};

export const manageName = {
  1: 'Nashr qilish',
  2: 'Tugallash',
};
