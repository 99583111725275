import styled, { css } from 'styled-components';
const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchPageWrapper = styled.div`
  width: 100%;
  /* height: calc(100vh - 100px); */
  display: flex;
  ${flexCenter}
  flex-direction: column;
  justify-content: start;
  @media screen and (min-width: 1762px) {
    justify-content: space-between;
  }
  overflow: hidden;
  & .pagination-footer {
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }
`;

export const ResultsContent = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  border-radius: 8px;
  gap: 20px;
  /* height: calc(100vh - 400px); */
  padding: 10px 0;
  .result__body {
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-start;
    align-items: start;
  }
`;
export const FoundWorks = styled.div`
  width: 100%;
  height: 100%;
  margin: 10px auto;
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 0;
  /* height: 100%; */

  .select-container {
    & .ant-select-selector {
      max-width: 240px !important;
    }
  }

  .mobile__filters {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 3.59813px 10.07477px 0px rgba(126, 126, 126, 0.05);
  }
  .filters__row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    .left__col {
      width: 100%;
      max-width: 1150px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
    }
    .right__col {
      width: 100%;
      max-width: 500px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
    }
    .filters__item {
      width: 100%;
      max-width: 277px;
      float: left;
    }
  }
  .clear__btn {
    border: 1px solid rgba(255, 255, 255, 0.36);
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(5px);
  }
  .search__btn {
    background: #00c4fa;
  }
  .action__btn {
    display: flex;
    width: 156px;
    height: 44px;
    padding: 13px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    color: #fff;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
  }

  .switch__item {
    width: 100%;
    /* height: 44px; */
    padding: 12px;
    align-self: center;
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;
      cursor: pointer;
      color: #fff;
      font-family: Segoe UI;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */
      text-transform: uppercase;
      :hover {
        color: #00c4fa;
      }
    }
  }
`;
export const SearchInput = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  min-width: 280px;
  & .ant-input-affix-wrapper input.ant-input {
    min-width: 150px !important;
    width: 100%;
  }
`;
export const ResultInfo = styled.div`
  width: 100%;
  .result__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 14px;
    font-family: Segoe UI;
    font-size: 20px;
    font-style: normal;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: start;
      gap: 5px;
    }
    .title {
      color: #080936;
      font-weight: 400;
      line-height: 100%; /* 20px */
      opacity: 0.8;
      padding: 0;
      margin: 0;
    }
    .value {
      color: #3380ff;
      font-weight: 600;
      line-height: 100%; /* 20px */
      padding: 0;
      margin: 0;
    }
  }
  display: inline-flex;
  padding: 15px 24px;
  align-items: center;
  justify-content: flex-start;
  gap: 80px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 0.72px solid rgba(8, 9, 54, 0.05);
  background: #fff;
  box-shadow: 0px 3.59813px 10.07477px 0px rgba(126, 126, 126, 0.05);
`;

export const NoData = styled.div`
  width: 100%;
  /* height: calc(100vh - 220px); */
  max-height: 100%;
  margin: 0 auto;
  ${flexCenter}

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;
