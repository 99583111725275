import React, { useEffect, useState } from 'react';
import { Empty, Pagination } from 'antd';
import { Header, PageBody, NoData } from './style';
import { buttons, getColumn } from './helper';
import ExpandedTable from 'components/Tables/ExpandedTable';
import ModalBody from './Modal';
import ReportTab from 'components/Tabs/ReportTab';
import { useShowAlert, useShowModal } from 'hooks';
import complaints from 'services/complaints';
import moment from 'moment';
import Spinner from 'components/Spinner';
import { useDispatch } from 'react-redux';
import { setBadgeNotificationCount } from 'redux/modules/application/actions';

const descTextSlicer = (text) => {
  const result = text?.length > 100 ? text.slice(0, 100) + '...' : text;
  return result;
};

const Complaints = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('new');
  const { showBlured } = useShowModal();
  const { error } = useShowAlert();
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0); // => 0
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);

  const fetchComplaints = async () => {
    setLoading(true);

    try {
      const reqBody = {
        page,
        limit,
        readed: activeTab === 'new' ? false : true,
      };
      const { docs, totalDocs } = await complaints.getAll(reqBody);
      setTotal(() => totalDocs);
      const mappeddata = docs.map((el, idx) => ({
        ...el,
        indx: page * limit + idx + 1 - limit,
        fullname: [
          el?.user?.lastName,
          el?.user?.firstName,
          el?.user?.middleName,
        ].join(' '),
        provinceName: el?.province?.titleUZ,
        regionName: el?.region?.titleUZ,
        description: el?.text ? descTextSlicer(el?.text) : '-',
        districtName: el?.district?.titleUZ,
        status: el?.readed ? 1 : 0,
        date: moment(el?.createdAt).format('DD.MM.YYYY'),
      }));
      setData(() => mappeddata);
    } catch (err) {
      console.log('Error fetching complaints:', err);
      error('Shikoyatlarni olishda xatolik yuz berdi');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComplaints();
  }, [page, limit, activeTab]);

  const fetchOneData = async (id) => {
    setLoading(true);
    try {
      if (id) {
        const resp = await complaints.getOne(id);
        const complainstCount = await complaints.getSideBarNotificationCount();
        dispatch(
          setBadgeNotificationCount({
            complaints: complainstCount?.count,
          })
        );
        activeTab === 'new' && fetchComplaints();
        openModal(resp);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      error('Shikoyatni yuklashda xatolik');
    } finally {
      setLoading(false);
    }
  };

  const handleActive = ({ value }) => {
    setPage(1);
    setActiveTab(value);
  };

  const openModal = (value) => {
    showBlured({
      title: 'Shikoyatni mazmuni',
      maxWidth: '652px',
      height: '434px',
      withHeader: false,
      body: () => <ModalBody data={value} />,
    });
  };

  const handleClick = (id) => {
    if (id) {
      fetchOneData(id);
    }
  };

  const tableHeader = getColumn({ handleClick });
  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };

  if (loading) {
    return <Spinner maxHeight={'100vh'} />;
  }
  return (
    <>
      <Header>
        <ReportTab
          list={buttons}
          active={activeTab}
          handleActive={handleActive}
        />
      </Header>
      <PageBody>
        {total > 0 ? (
          <>
            <ExpandedTable
              data={data}
              headers={tableHeader}
              onChange={() => {}}
            />
            {total > 10 && (
              <div className="pagination custom-pagination-wrapper">
                <Pagination
                  current={page}
                  total={total}
                  onChange={(current, newSize) => {
                    setPage(newSize !== limit ? 1 : current);
                  }}
                  onShowSizeChange={onShowSizeChange}
                  defaultPageSize={limit}
                  showSizeChanger
                />
              </div>
            )}
          </>
        ) : (
          <NoData>
            <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
          </NoData>
        )}
      </PageBody>
    </>
  );
};

export default Complaints;
