import React from 'react';
import { StyledInput } from './styled';
const TextInput = ({
  type,
  value,
  onChange,
  placeholder,
  name,
  id,
  status = false,
}) => (
  <StyledInput
    type={type}
    name={name}
    id={id}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    status={status ? 'error' : undefined}
  />
);

export default TextInput;
