import { Button, Input, Select, Space, Table, Typography } from 'antd';
import React, { useState } from 'react';
import ActionButton from '../../../components/ActionButton';
import AddButton from '../../../components/AddButton';
import Confirm from '../../../components/Confirm';
import FormCreator from '../../../components/FormCreator';
import Typograpy from '../../../components/Typograpy';
import { useHideModal, useShowAlert, useShowModal } from '../../../hooks';
import { Container, AddContainer } from './style';
import {
  formData,
  initialValuesRateAdd,
  buttons,
  names,
  paymentPlantForm,
  paymentPlanInitial,
  supportTypeForm,
  roles,
} from './helper';
import { useEffect } from 'react';
import ratingService from '../../../services/rating';
import FullScreen from '../../../components/Spinner/FullScreen';
import { useDispatch, useSelector } from 'react-redux';
import Filters from '../../../components/Filters';
import weekendService from '../../../services/weekend';
import regionService from '../../../services/regions';
import tggroupService from '../../../services/tggroup';
import './style.css';
import paymentPlanService from 'services/paymentPlan';
import supportType from 'services/supportType';
import provinces from 'services/provinces';
import ModalBody from './ModalBody';
import { setModalLoading } from 'redux/modules/modals/actions';

export default () => {
  const { Title } = Typography;
  const { Option } = Select;
  const { lang } = useSelector((state) => state.appReducer);
  // const { userId } = useSelector((state) => state.authReducer);
  const [rates, setRates] = useState([]);
  // const [ratesType, setRatesType] = useState([]);
  const [province, setProvince] = useState([]);
  const [active, setActive] = useState('rate');
  const [bool, setBool] = useState([]);
  const [boolType, setBoolType] = useState(true);
  const [loading, setLoading] = useState(false);
  const [weekends, setWeekends] = useState([]);
  const [supportTypes, setSupportTypes] = useState([]);
  // const [groups, setGroups] = useState([]);
  const [regions, setRegions] = useState([]);
  const [telegramFilters, setTelegramFilters] = useState({});
  const [telegram, setTelegram] = useState('');
  const [telegramGroup, setTelegramGroup] = useState([]);
  const [paymentPlan, setPaymentPlan] = useState([]);
  // const [macData, setMacData] = useState([]);
  // const [postData, setPostData] = useState({});
  // const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const { success, error } = useShowAlert();

  const { showBlured } = useShowModal();
  const { hideModal } = useHideModal();
  const dispatch = useDispatch();

  const handleActive = (item) => {
    setActive(item.value);
  };
  const fetchWeekendData = () => {
    weekendService.getOne().then((res) => {
      setWeekends(res);
    });
  };

  useEffect(() => {
    if (telegramFilters?.province) {
      regionService
        .getAll({ province: telegramFilters.province })
        .then((res) => {
          setRegions(() =>
            res.map((item) => ({ ...item, title: item?.['title' + lang] }))
          );
        });
    }
  }, [telegramFilters?.province]);

  useEffect(() => {
    setLoading(true);
    ratingService
      .getAll()
      .then((res) => {
        let user = res.map((item) => {
          return { ...item, title: item?.['title' + lang] };
        });
        setRates(user?.map((item, index) => ({ ...item, key: index + 1 })));
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    supportType.getAll().then((res) => {
      setSupportTypes(res?.map((item, index) => ({ ...item, key: index + 1 })));
    });

    provinces
      .getAll()
      .then((res) => {
        setProvince(() =>
          res.map((item) => ({ ...item, title: item?.['title' + lang] }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
    fetchWeekendData();
    tggroupService.getAll().then((res) => {
      setTelegramGroup(res);
    });
  }, [bool]);

  useEffect(() => {
    setLoading(true);
    // ratingTypeService
    //   .getAll()
    //   .then((res) => {
    //     let data = res.map((item, index) => {
    //       return {
    //         key: index + 1,
    //         _id: item?._id,
    //         title: item?.title,
    //         range: `${item.startRange}-${item.endRange}`,
    //       };
    //     });
    //     setRatesType(data);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    paymentPlanService
      .getAll()
      .then((res) => {
        setPaymentPlan(() =>
          res?.map((item, index) => ({ ...item, key: index + 1 }))
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [boolType]);

  // MacAdress
  // useEffect(() => {
  //   let data = {
  //     ...postData,
  //   };
  //   if (active === 'macaddress') {
  //     setLoading(true);
  //     data = { ...data, page: page, limit: size };
  //     macaddressService
  //       .getAll(data)
  //       .then(({ docs, totalDocs, page }) => {
  //         setPage(page);
  //         setTotal(totalDocs);
  //         let data = docs?.map((item) => {
  //           return {
  //             // key: index + 1,
  //             region: item?.region.title,
  //             group: item?.group[0]?.title,
  //             fullName: item?.lastName + ' ' + item?.firstName,
  //             count: item?.macAddress?.length,
  //           };
  //         });
  //         setMacData(data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //       .finally(() => setLoading(false));
  //   }
  // }, [postData, active, page, size]);

  // useEffect(() => {
  //   let data = telegramGroup.find((item) => item.group == group);
  //   setTelegram(data?.telGroupId);
  // }, [telegramFilters]);

  // Create
  const sendData = (data) => {
    let newData = { ...data, rate: data.rate * 1 };
    hideModal();
    setLoading(true);
    ratingService
      .create(newData)
      .then(() => {
        setBool(!bool);
        success("Baholash ko'rsatkichi saqlandi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Baholash ko'rsatkichini saqlashda xatolik", 2, 'bottom');
      });
  };
  const addRate = () => {
    showBlured({
      title: "Baholash ko'rsatkichini qo'shish",
      maxWidth: '650px',
      height: '600px',
      withHeader: false,
      body: () => (
        <FormCreator
          formData={formData}
          cancel={hideModal}
          sendData={sendData}
          selectOptions={{}}
          id=""
          initialVal={initialValuesRateAdd}
        />
      ),
    });
  };

  const supportTypeAddFnc = (data) => {
    hideModal();
    setLoading(true);
    supportType
      .create(data)
      .then(() => {
        setBool(!bool);
        success("Yordam ko'rsatish turi saqlandi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Yordam  ko'rsatish turini saqlashda xatolik", 2, 'bottom');
      });
  };
  const addSupportType = () => {
    showBlured({
      title: "Yordam ko'rsatish turini qo'shish",
      maxWidth: '650px',
      height: '600px',
      withHeader: false,
      body: () => (
        <FormCreator
          formData={supportTypeForm}
          cancel={hideModal}
          sendData={supportTypeAddFnc}
          selectOptions={{}}
          id=""
          initialVal={initialValuesRateAdd}
        />
      ),
    });
  };
  // update supportType
  const updateSupportType = (id, data) => {
    let newData = { ...data };
    hideModal();
    setLoading(true);
    supportType
      .update(id, newData)
      .then(() => {
        setBool(!bool);
        success("Yordam ko'rsatish turi yangilandi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Yordam ko'rsatish turini yangilashda xatolik", 2, 'bottom');
      });
  };
  const supportTypeInitial = (data) => {
    return {
      titleUZ: data.titleUZ,
      titleRU: data.titleRU,
      titleQQ: data.titleQQ,
    };
  };

  const handleUpdateSupportType = (data) => {
    showBlured({
      title: "Yordam ko'rsatish turini tahrirlash",
      maxWidth: '650px',
      height: '600px',
      withHeader: false,
      body: () => (
        <FormCreator
          formData={supportTypeForm}
          cancel={hideModal}
          sendData={updateSupportType}
          selectOptions={{}}
          id={data._id}
          initialVal={supportTypeInitial(data)}
        />
      ),
    });
  };

  //Delete support type

  const deleteSupportType = (id) => {
    hideModal();
    setLoading(true);
    supportType
      .delete(id)
      .then(() => {
        setBool(!bool);
        success("Yordam ko'rsatish turi o'chirildi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Yordam ko'rsatish turini o'chirishda xatolik", 2, 'bottom');
      });
  };
  const handleDeleteSupportType = (data) => {
    showBlured({
      title: "O'chirish",
      maxWidth: '400px',
      height: '300px',
      withHeader: false,
      body: () => (
        <Confirm agree={() => deleteSupportType(data)} cancel={hideModal} />
      ),
    });
  };

  // const sendTypeData = (data) => {
  //   hideModal();
  //   setLoading(true);
  //   ratingTypeService
  //     .create(data)
  //     .then(() => {
  //       setBoolType(!boolType);
  //       success('Баҳолаш тури сақланди', 2, 'bottom');
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       error('Баҳолаш турини сақлашда хатолик', 2, 'bottom');
  //     });
  // };

  // const addType = () => {
  //   showBlured({
  //     title: 'Баҳолаш турини қўшиш',
  //     maxWidth: '650px',
  //     height: '600px',
  //     withHeader: false,
  //     body: () => (
  //       <FormCreator
  //         formData={formDataType}
  //         cancel={hideModal}
  //         sendData={sendTypeData}
  //         // selectOptions={}
  //         id=""
  //         initialVal={initialValuesAddType}
  //       />
  //     ),
  //   });
  // };

  const addPayPlan = (data) => {
    hideModal();
    setLoading(true);
    paymentPlanService
      .create(data)
      .then(() => {
        setBoolType(!boolType);
        success("To'lov tarifi saqlandi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("To'lov tarifini saqlashda xatolik", 2, 'bottom');
      });
  };
  const addPaymentPlan = () => {
    showBlured({
      title: "To'lov tarifi qo'shish",
      maxWidth: '650px',
      height: '600px',
      withHeader: false,
      body: () => (
        <FormCreator
          formData={paymentPlantForm}
          cancel={hideModal}
          sendData={addPayPlan}
          id=""
          initialVal={paymentPlanInitial}
        />
      ),
    });
  };

  // Update

  const initialValuesEdit = (data) => {
    return {
      titleUZ: data.titleUZ,
      titleRU: data.titleRU,
      titleQQ: data.titleQQ,
      rate: data.rate,
    };
  };

  const updateData = (id, data) => {
    let newData = { ...data };
    hideModal();
    setLoading(true);
    ratingService
      .update(id, newData)
      .then(() => {
        setBool(!bool);
        success("Baholash ko'rsatkichi yangilandi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Baholash ko'rsatkichini yangilashda xatolik", 2, 'bottom');
      });
  };

  const handleEditRate = (data) => {
    showBlured({
      title: "Baholash ko'rsatkichini tahrirlash",
      maxWidth: '650px',
      height: '600px',
      withHeader: false,
      body: () => (
        <FormCreator
          formData={formData}
          cancel={hideModal}
          sendData={updateData}
          selectOptions={{}}
          id={data._id}
          initialVal={initialValuesEdit(data)}
        />
      ),
    });
  };

  // const initialValuesEditType = (data) => {
  //   let rang = data.range.split('-');
  //   return {
  //     _id: data._id,
  //     title: data.title,
  //     startRange: rang[0] * 1,
  //     endRange: rang[1] * 1,
  //   };
  // };

  // const updateDataType = (id, data) => {
  //   hideModal();
  //   setLoading(true);
  //   ratingTypeService
  //     .update(id, data)
  //     .then(() => {
  //       setBoolType(!boolType);
  //       success('Баҳолаш тури янгиланди', 2, 'bottom');
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       error('Баҳолаш турини  янгилашда хатолик', 2, 'bottom');
  //     });
  // };

  // const handleEditType = (data) => {
  //   showBlured({
  //     title: 'Баҳолаш турини  таҳрирлаш',
  //     maxWidth: '650px',
  //     height: '600px',
  //     withHeader: false,
  //     body: () => (
  //       <FormCreator
  //         formData={formDataType}
  //         cancel={hideModal}
  //         sendData={updateDataType}
  //         // selectOptions={sections}
  //         id={data._id}
  //         initialVal={initialValuesEditType(data)}
  //       />
  //     ),
  //   });
  // };

  const updatePayPlan = (data) => {
    hideModal();
    setLoading(true);
    const id = data?._id;
    if (id) {
      delete data['_id'];
      paymentPlanService
        .update(id, data)
        .then(() => {
          setBoolType(!boolType);
          success("To'lov tarifi yangilandi", 2, 'bottom');
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          error("To'lov tarifini yangilashda xatolik", 2, 'bottom');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      error("To'lov tarifini yangilashda xatolik", 2, 'bottom');
    }
  };

  const updatePaymentPlan = (data) => {
    showBlured({
      title: "To'lov tarifi qo'shish",
      maxWidth: '650px',
      height: '600px',
      withHeader: false,
      body: () => (
        <FormCreator
          formData={paymentPlantForm}
          cancel={hideModal}
          sendData={updatePayPlan}
          id=""
          initialVal={{
            term: data.term,
            price: data.price,
            _id: data._id,
          }}
        />
      ),
    });
  };

  // Delete

  const deleteUser = (id) => {
    hideModal();
    setLoading(true);
    ratingService
      .delete(id)
      .then(() => {
        setBool(!bool);
        success("Baholash ko'rsatkichi o'chirildi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Baholash ko'rsatkichini o'chirishda xatolik", 2, 'bottom');
      });
  };

  const handleDelete = (data) => {
    showBlured({
      title: "O'chirish",
      maxWidth: '400px',
      height: '300px',
      withHeader: false,
      body: () => <Confirm agree={() => deleteUser(data)} cancel={hideModal} />,
    });
  };

  // const deleteType = (id) => {
  //   hideModal();
  //   setLoading(true);
  //   ratingTypeService
  //     .delete(id)
  //     .then(() => {
  //       setBoolType(!boolType);
  //       success('Баҳолаш тури ўчирилди', 2, 'bottom');
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       error('Баҳолаш турини ўчиришда хатолик', 2, 'bottom');
  //     });
  // };

  // delete payment plan
  // const handleDeleteType = (data) => {
  //   showBlured({
  //     title: "O'chirish",
  //     maxWidth: '400px',
  //     height: '300px',
  //     withHeader: false,
  //     body: () => <Confirm agree={() => deleteType(data)} cancel={hideModal} />,
  //   });
  // };
  const deletePaymentPlan = (id) => {
    hideModal();
    setLoading(true);
    paymentPlanService
      .delete(id)
      .then(() => {
        setBoolType(!boolType);
        success("To'lov tarifi o'chirildi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("To'lov tarifini o'chirishda xatolik", 2, 'bottom');
      });
  };
  const handleDeletePayPlan = (data) => {
    showBlured({
      title: "O'chirish",
      maxWidth: '400px',
      height: '300px',
      withHeader: false,
      body: () => (
        <Confirm agree={() => deletePaymentPlan(data)} cancel={hideModal} />
      ),
    });
  };

  const getTotalRate = (data) => {
    let total = data.reduce((tot, item) => {
      return tot + item.rate * 1;
    }, 0);
    return total;
  };

  const handleWorkday = (sendData) => {
    const data = {
      day: sendData?.days * 1,
      holidays: sendData?.holidays,
      start: sendData?.start,
      end: sendData?.end,
      lunchStart: sendData?.lunchStart,
      lunchEnd: sendData?.lunchEnd,
    };

    dispatch(setModalLoading(true));
    let isUpdate = weekends.find((item) => item.group == data.group);
    isUpdate
      ? weekendService
          .update(isUpdate._id, data)
          .then(() => {
            fetchWeekendData();
            hideModal();
          })
          .finally(() => {
            dispatch(setModalLoading(false));
          })
      : weekendService
          .create(data)
          .then(() => {
            fetchWeekendData();
            hideModal();
          })
          .finally(() => {
            dispatch(setModalLoading(false));
          });
  };

  const handleWorkDayModal = () => {
    showBlured({
      title: '',
      maxWidth: '600px',
      maxHeight: '70vh',
      withHeader: false,
      body: () => (
        <ModalBody
          handleWorkday={handleWorkday}
          defaultValue={
            weekends?.length
              ? weekends.map((item) => ({ ...item, days: item?.days?.length }))
              : []
          }
        />
      ),
    });
  };

  const handleTelegramId = () => {
    let sendData = {
      telGroupId: `-${telegram}`,
      province: telegramFilters?.province,
      region: telegramFilters?.region,
      role: telegramFilters?.role,
    };
    setLoading(true);
    tggroupService
      .create(sendData)
      .then((res) => {
        setBool(!bool);
        let foo = telegramGroup.map((item) => item);
        foo.push(res);
        setTelegramGroup(foo);
        setTelegram('');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      width: '10%',
      render: (id, item, index) => {
        let pageNumber = page > 1 ? page * size + index - size : ++index;
        return pageNumber;
      },
    },
    {
      title: 'Izoh',
      dataIndex: 'title',
      key: 'title',
      width: '60%',
    },
    {
      title: 'Ball',
      dataIndex: 'rate',
      align: 'center',
      key: 'rate',
      width: '10%',
    },

    {
      width: '15%',
      align: 'center',
      title: 'Amal',
      dataIndex: '',
      key: 'x',
      render: (props) => (
        <ActionButton
          handleEdit={handleEditRate}
          handleDelete={handleDelete}
          props={props}
        />
      ),
    },
  ];

  // const columnsType = [
  //   {
  //     title: 'Номи',
  //     dataIndex: 'title',
  //     key: 'title',
  //     width: '50%',
  //   },
  //   {
  //     title: 'Оралиқ',
  //     dataIndex: 'range',
  //     align: 'center',
  //     key: 'range',
  //     width: '25%',
  //   },

  //   {
  //     width: '25%',
  //     align: 'center',
  //     title: 'Амал',
  //     dataIndex: '',
  //     key: 'x',
  //     render: (props) => (
  //       <ActionButton
  //         handleEdit={handleEditType}
  //         handleDelete={handleDeleteType}
  //         props={props}
  //       />
  //     ),
  //   },
  // ];

  const telegramColumns = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
      width: '5%',
      align: 'center',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      align: 'center',
      key: 'role',
      width: '25%',
    },
    {
      title: 'Viloyat',
      dataIndex: 'province',
      align: 'center',
      key: 'province',
      width: '25%',
    },
    {
      title: 'Tuman/shahar',
      dataIndex: 'region',
      align: 'center',
      key: 'region',
      width: '25%',
    },

    {
      title: 'Telegram ID',
      dataIndex: 'telGroupId',
      align: 'center',
      key: 'telGroupId',
      width: '20%',
    },
  ];

  const weekdayColumns = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
      width: '10%',
      align: 'center',
    },
    // {
    //   title: 'Гуруҳ',
    //   dataIndex: 'group',
    //   align: 'center',
    //   key: 'group',
    //   width: '20%',
    // },
    {
      title: 'Haftalik ish kuni',
      dataIndex: 'workdays',
      align: 'center',
      key: 'workdays',
    },
    {
      title: 'Kunlar',
      dataIndex: 'days',
      align: 'center',
      key: 'days',
      width: '30%',
    },
    {
      title: 'Ishni boshlanish vaqti',
      dataIndex: 'workStartTime',
      align: 'center',
      key: 'workStartTime',
    },
    {
      title: 'Ishni tugash vaqti',
      dataIndex: 'workEndTime',
      align: 'center',
      key: 'workEndTime',
    },
    {
      title: 'Tushlik vaqti',
      dataIndex: 'lunchStartTime',
      align: 'center',
      key: 'lunchStartTime',
      width: '10%',
    },
    {
      title: 'Tushlik tugash vaqti',
      dataIndex: 'lunchEndTime',
      align: 'center',
      key: 'lunchEndTime',
      width: '10%',
    },
  ];

  // const macColumns = [
  //   {
  //     title: '№',
  //     dataIndex: 'key',
  //     key: 'key',
  //     width: '10%',
  //     align: 'center',
  //     render: (id, item, index) => {
  //       let pageNumber = page > 1 ? page * size + index + 1 - size : ++index;
  //       return pageNumber;
  //     },
  //   },
  //   {
  //     title: 'Шаҳар (туман)',
  //     dataIndex: 'region',
  //     align: 'center',
  //     key: 'region',
  //     width: '20%',
  //   },

  //   {
  //     title: 'Гуруҳ',
  //     dataIndex: 'group',
  //     align: 'center',
  //     key: 'group',
  //     width: '20%',
  //   },
  //   {
  //     title: 'Гуруҳ Раҳбари',
  //     dataIndex: 'fullName',
  //     align: 'center',
  //     key: 'fullName',
  //     width: '45%',
  //   },
  //   {
  //     title: 'Soni',
  //     dataIndex: 'count',
  //     align: 'center',
  //     key: 'count',
  //     width: '45%',
  //   },
  // ];
  const supportTypeColumns = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
      width: '10%',
      align: 'center',
      render: (id, item, index) => {
        let pageNumber = page > 1 ? page * size + index + 1 - size : ++index;
        return pageNumber;
      },
    },
    {
      title: 'Nomi',
      dataIndex: 'title',
      align: 'start',
      key: 'title',
      render: (_, row) => row?.['title' + lang],
      width: '75%',
    },
    {
      width: '15%',
      align: 'center',
      title: 'Amal',
      dataIndex: '',
      key: 'x',
      render: (props) => (
        <ActionButton
          handleEdit={handleUpdateSupportType}
          handleDelete={handleDeleteSupportType}
          props={props}
        />
      ),
    },
  ];
  const paymentTypeColumns = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
      width: '10%',
      align: 'center',
      render: (id, item, index) => {
        let pageNumber = page > 1 ? page * size + index : ++index;
        return pageNumber;
      },
    },
    {
      title: 'Tarif turi',
      dataIndex: 'ppt',
      align: 'center',
      key: 'ppt',
      width: '20%',
      render: (_, item) => {
        return <p style={{ margin: 0 }}>{item?.term}-ой</p>;
      },
    },
    {
      title: 'Muddati (oy)',
      dataIndex: 'term',
      align: 'center',
      key: 'term',
      width: '20%',
    },
    {
      title: 'Summasi (UZS)',
      dataIndex: 'price',
      align: 'center',
      key: 'price',
      width: '30%',
    },
    {
      width: '15%',
      align: 'center',
      title: 'Amal',
      dataIndex: '',
      key: 'x',
      render: (props) => (
        <ActionButton
          handleEdit={updatePaymentPlan}
          handleDelete={handleDeletePayPlan}
          props={props}
        />
      ),
    },
  ];

  // const tableData = groups.map((item, index) => {
  const tableData = telegramGroup.map((item, index) => {
    return {
      ...item,
      key: index + 1,
      region: item.region?.['title' + lang],
      province: item.province?.['title' + lang],
    };
  });

  const tableWeekdaysData = weekends?.map((item, index) => {
    return {
      key: index + 1,
      // group: groups?.find((itm) => itm._id == item.group)?.title,
      workdays: item?.days.length,
      lunchEndTime: item?.lunchEnd,
      lunchStartTime: item?.lunchStart,
      workStartTime: item?.start,
      workEndTime: item?.end,
      days: item.holidays?.map((itm, ind) => (
        <span
          key={ind + 1}
          style={{
            backgroundColor: '#f6ffed',
            color: '#389e0d',
            padding: '3px',
            border: 'solid #b7eb8f 1px',
          }}
        >
          {itm}
        </span>
      )),
    };
  });

  // const selectGroup = (groupId) => {
  //   let data = weekends.find((item) => item.group == groupId);

  //   data
  //     ? setWeekend({
  //         ...weekend,
  //         group: groupId,
  //         days: data.days.length,
  //         holidays: data.holidays,
  //       })
  //     : setWeekend({ group: groupId });
  // };

  // const onShowSizeChange = (current, pageSize) => {
  //   setPage(1);
  //   setSize(pageSize);
  // };

  const content = {
    rate: (
      <>
        <AddContainer align="flex-end">
          {/* <Row gutter={5} align="content-center" justify="start">
            <Col>
              <CustomSelect
                handleChange={(value) => {
                  setPostData({ ...postData, ['group']: value });
                  setPage(() => 1);
                }}
                name="group"
                options={groups}
                defaultVal={postData?.group || groups[0]?._id}
                title="Гуруҳ"
                placeholder="Гуруҳ"
                allowClear={false}
              />
            </Col>
          </Row> */}
          <AddButton text="Baholash ko'rsatkichini" addData={addRate} />
        </AddContainer>
        <Typograpy color="#080936">{names[active]} </Typograpy>
        <Table
          bordered
          columns={columns}
          dataSource={rates}
          pagination={false}
        />
        <Space
          align="end"
          direction="vertical"
          style={{ fontSize: '20px', marginRight: '20%' }}
        >
          {'Jami ball:       ' + getTotalRate(rates)}
        </Space>
      </>
    ),
    // ratetype: (
    //   <>
    //     <AddContainer align="end">
    //       <AddButton text="Баҳолаш турини" addData={addType} />
    //     </AddContainer>
    //     <Typograpy>{names[active]} </Typograpy>
    //     <Table
    //       bordered
    //       columns={columnsType}
    //       dataSource={ratesType}
    //       pagination={false}
    //     />

    //   </>
    // ),
    supportType: (
      <>
        <AddContainer align="end">
          <AddButton text="Yordam ko'rsatish turini" addData={addSupportType} />
        </AddContainer>
        <Typograpy color="#080936">{names[active]} </Typograpy>
        <Table
          bordered
          columns={supportTypeColumns}
          dataSource={supportTypes}
          pagination={false}
        />
      </>
    ),
    weekend: (
      <>
        <div className="wrap__container">
          <Button
            type="primary"
            size="large"
            style={{
              background: 'var(--custom-primary)',
              color: 'rgb(255,255,255)',
              border: 'none',
              borderRadius: '10px',
            }}
            onClick={handleWorkDayModal}
          >
            {weekends?.length ? 'Yangilash' : 'Belgilash'}
          </Button>
        </div>
        <Table
          bordered
          columns={weekdayColumns}
          dataSource={tableWeekdaysData}
          pagination={false}
        />
      </>
    ),

    telegram: (
      <>
        {' '}
        <div
          style={{
            marginTop: '50px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Title level={5}>Viloyat</Title>
          <Select
            defaultValue=""
            style={{ width: 200 }}
            allowClear
            onChange={(val) =>
              setTelegramFilters((prev) => ({ ...prev, province: val }))
            }
          >
            {province?.map((item, ind) => (
              <Option key={ind + 1} value={item._id}>
                {item.title}
              </Option>
            ))}
          </Select>
          <Title level={5}>Tuman/shahar</Title>
          <Select
            defaultValue=""
            style={{ width: 200 }}
            allowClear
            onChange={(val) =>
              setTelegramFilters((prev) => ({ ...prev, region: val }))
            }
          >
            {regions?.map((item, ind) => (
              <Option key={ind + 1} value={item._id}>
                {item.title}
              </Option>
            ))}
          </Select>
          <Title level={5}>Role</Title>
          <Select
            defaultValue=""
            style={{ width: 200 }}
            allowClear
            onChange={(val) =>
              setTelegramFilters((prev) => ({ ...prev, role: val }))
            }
          >
            {roles?.map((item, ind) => (
              <Option key={ind + 1} value={item._id}>
                {item.title}
              </Option>
            ))}
          </Select>
          <Title level={5}>Telegram id kiriting</Title>
          <Space className="h-42" style={{ width: '200px' }}>
            <Input
              width={50}
              placeholder="Telegram guruh ID"
              value={telegram}
              onChange={(e) => {
                const number = e.target.value;
                const re = /^[0-9\b]+$/;
                if (number === '' || re.test(number)) {
                  setTelegram(e.target.value);
                }
              }}
            />
          </Space>
          <Button
            type="primary"
            size="large"
            disabled={!telegram.length || !telegramFilters.role}
            style={{
              background: 'var(--custom-primary)',
              color: 'rgb(255,255,255)',
              border: 'none',
              borderRadius: '10px',
            }}
            onClick={handleTelegramId}
          >
            Belgilash
          </Button>
        </div>
        <Table
          bordered
          columns={telegramColumns}
          dataSource={tableData}
          pagination={false}
        />
      </>
    ),
    // macaddress: (
    //   <>
    //     <Row gutter={5} align="content-center" justify="start">
    //       {active === 'macaddress' && (
    //         <Col>
    //           <CustomSelect
    //             // status={mustBeSelect ? 'error' : ''}
    //             handleChange={(value) => {
    //               setPostData({ ...postData, ['region']: value });
    //               setPage(() => 1);
    //             }}
    //             options={regions}
    //             name="region"
    //             defaultVal={postData?.region || undefined}
    //             title="Шаҳар(туман)"
    //             placeholder="Шаҳар(туман)"
    //             allowClear={false}
    //           />
    //         </Col>
    //       )}
    //       {(active === 'macaddress' || active === 'rate') && (
    //         <Col>
    //           <CustomSelect
    //             handleChange={(value) => {
    //               setPostData({ ...postData, ['group']: value });
    //               setPage(() => 1);
    //             }}
    //             name="group"
    //             options={groups}
    //             defaultVal={postData?.group || undefined}
    //             title="Гуруҳ"
    //             placeholder="Шаҳар(туман)"
    //             allowClear={false}
    //           />
    //         </Col>
    //       )}
    //     </Row>
    //     <Typograpy>{names[active]} </Typograpy>
    //     <Table
    //       bordered
    //       columns={macColumns}
    //       dataSource={macData}
    //       pagination={false}
    //     />
    //     <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
    //       <Pagination
    //         total={total}
    //         current={page}
    //         defaultPageSize={size}
    //         showSizeChanger
    //         onChange={(current) => setPage(current)}
    //         onShowSizeChange={onShowSizeChange}
    //       />
    //     </div>
    //   </>
    // ),

    paymentPlan: (
      <>
        <AddContainer align="end">
          <AddButton text="To'lov tarifi" addData={addPaymentPlan} />
        </AddContainer>

        <Typograpy color="#080936">{names[active]} </Typograpy>
        <Table
          bordered
          columns={paymentTypeColumns}
          dataSource={paymentPlan}
          pagination={false}
        />
      </>
    ),
  };

  if (loading) return <FullScreen />;
  return (
    <>
      <Container>
        <Filters list={buttons} active={active} handleActive={handleActive} />

        {content[active]}
      </Container>
    </>
  );
};
