import React, { useState } from 'react';
import { Button, DatePicker } from 'antd';
import './style.js';
import moment from 'moment';
import { ModalBody } from './style.js';
import { useHideModal } from 'hooks/modal.js';
import { useFormik } from 'formik';
import { validationSchema } from '../../helper.js';
import TextArea from 'components/FormElements/TextArea/index.jsx';

const { RangePicker } = DatePicker;

export default ({ id, handleSubmit }) => {
  const { hideModal } = useHideModal();

  const handleChangeDateInput = (_, dateString) => {
    const data = [
      moment(dateString[0]).format('YYYY-MM-DD'),
      moment(dateString[1]).format('YYYY-MM-DD'),
    ];
    formik.setFieldValue('date', data);
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      date: [],
      cause: '',
    },
    onSubmit: (values) => {
      handleSubmit(id, values);
    },
  });

  const handleOnFocus = () => {
    formik.setFieldValue('date', []);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalBody>
        <div className="range-datepicker">
          <RangePicker
            value={[
              formik.values.date[0] && moment(formik.values.date[0]),
              formik.values.date[1] && moment(formik.values.date[1]),
            ]}
            onClick={handleOnFocus}
            format="YYYY-MMM-DD"
            onChange={handleChangeDateInput}
          />
          {formik.errors.date && formik.touched.date && (
            <div className="error-message">{formik.errors.date}</div>
          )}
        </div>
        <div className="reason__input">
          <span>{formik?.values.cause.length}/200</span>
          <TextArea
            name="cause"
            id="cause"
            placeholder="Izoh qoldiring"
            handleDescription={(e) =>
              formik.setFieldValue('cause', e.target.value)
            }
            onBlur={formik.handleBlur}
            descriptionValue={formik?.values.cause}
          />
          {formik.errors.cause && formik.touched.cause && (
            <div className="error-message">{formik.errors.cause}</div>
          )}
        </div>
        <div className="action-button">
          <button
            type="button"
            size="large"
            onClick={() => hideModal()}
            className="cancel-btn"
          >
            Bekor qilish
          </button>
          <button type="submit" className="submit-btn" size="large">
            Yangilash
          </button>
        </div>
      </ModalBody>
    </form>
  );
};
