import styled from 'styled-components';

export const Wrapper = styled.button`
  width: 100%;
  border: none;
  outline: none;
  display: flex;
  gap: 22px;
  justify-content: space-between;
  align-items: center;
  background: none;
  /* padding: 10px; */
  & .eye {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;

export const StatusWrap = styled.div`
  padding: 4px 12px;
  height: 28px;
  border-radius: 40px;
  background: ${({ bgcolor }) => bgcolor && bgcolor};
  color: ${({ color }) => color && color};
  display: flex;
  justify-content: center;
  align-items: center;
`;
