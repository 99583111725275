import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden auto;
  height: ${({ collapsed }) =>
    !collapsed ? 'calc(100vh - (120px + 175px))' : 'calc(100vh - (115px))'};
`;

export const Space = styled.div`
  height: 34px;
`;
