import React, { useEffect, useMemo, useState } from 'react';
import Table from '../../../components/Tables/ExpandedTable';
import { headerMaker } from '../../../components/Tables/ExpandedTable/helper';
// import { ratingsHeader } from '../../../redux/modules/table/common';
import { useSelector, useDispatch } from 'react-redux';
import reports from '../../../services/reports';
import Spinner from '../../../components/Spinner';
import { setReportSubData } from '../../../redux/modules/application/actions';
import { makeAdvancedReport, makeReport } from '../../../utils/makeReport';
import { categoryHeaders } from '../../../utils/categoryHeader';
import RangePicker from '../../../components/RangePicker';
import Select from '../../../components/FormElements/Select';
import { Col, Empty, Pagination, Row } from 'antd';
import { setSelectedDate } from '../../../redux/modules/dates/actions';
import moment from 'moment';
import { useFilter } from 'views/useFilter';
import { NoData } from './style';

export default () => {
  const { selectedDate } = useSelector((state) => state.selectedDateReducer);
  const { role } = useSelector((state) => state.authReducer);
  const { handleFilters, selectedFilters, categories, provinces, regions } =
    useFilter({ withObject: false });

  const [reportsData, setReportsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);
  const dispatch = useDispatch();
  const [newHeader, setNewHeader] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [sort, setSort] = useState(true);

  const mainHeadCols = [
    {
      id: 1,
      Header: '№',
      accessor: 'id',
      show: true,
      width: 30,
    },
    {
      id: 3,
      Header: 'Viloyat',
      accessor: 'province',
      show: ['leader'].includes(role) ? true : false,
      type: 'name',
      width: 200,
      // ...(role === 'leader' && { type: 'name', width: 200 }),
    },
    {
      id: 4,
      Header: 'Tuman (Shahar)',
      accessor: 'region',
      show: ['leader', 'proleader'].includes(role) ? true : false,
      type: 'name',
      width: 200,
      // ...(role === 'proleader' && { type: 'name', width: 200 }),
    },
    {
      id: 5,
      Header: 'MFY',
      accessor: 'district',
      show: ['leader', 'proleader', 'regleader'].includes(role) ? true : false,
      type: 'name',
      width: 200,
      // ...(role === 'regleader' && { type: 'name', width: 250 }),
    },
    {
      id: 2,
      Header: 'Ism, Familiya',
      accessor: 'user_name',
      show: true,
      // width: 250,
      type: 'info',
    },
    {
      id: 6,
      Header: 'Jami',
      accessor: 'total',
      type: 'info',
      width: 50,
      show: true,
      ...(role !== 'advanced' && { sort: true }),
      align: 'center',
    },
  ];

  useEffect(() => {
    let newHeads = mainHeadCols;
    if (selectedFilters?.province) {
      newHeads = mainHeadCols.filter((item) => item.accessor !== 'province');
    }
    if (selectedFilters?.region) {
      newHeads = mainHeadCols.filter(
        (item) => item.accessor !== 'province' && item.accessor !== 'region'
      );
    }
    newHeads = newHeads.filter((item) => item.show);
    newHeads[1].type = 'name_rating';
    newHeads[1].width = 200;
    const foo = headerMaker(newHeads.concat(categoryHeaders(newHeader)));
    setHeaders(foo);
  }, [newHeader, selectedFilters]);

  const dateQuery = useMemo(
    () => selectedDate.every((item) => item !== '') && selectedDate,
    [selectedDate]
  );

  const fetchRateReports = async () => {
    try {
      setLoading(true);
      const data = {
        province: selectedFilters.province,
        region: selectedFilters.region,
        startDate: selectedDate[0],
        endDate: selectedDate[1],
        limit: pageSize,
        page: pageIndex,
        sort: sort ? -1 : 1,
      };
      // const pCats = categories.filter((i) => !i.parent);
      const reportData = await reports.getAllRateReport(data);
      const pCats =
        reportData &&
        reportData?.docs[0]?.categorys.map((i) => ({
          ...i.category,
          title: i?.category?.titleUZ,
        }));
      setTotal(reportData?.totalDocs);
      const sortedData = pCats?.sort((a, b) => {
        return a._id.localeCompare(b._id);
      });
      setNewHeader(sortedData);
      const report = makeReport(
        reportData.docs,
        pCats,
        reportData.page * reportData.limit - pageSize,
        role
      );
      setReportsData(report);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      !selectedDate.every((item) => item === '') &&
      !selectedDate.every((item) => item === 'Invalid date') &&
      role !== 'advanced'
    ) {
      fetchRateReports();
    }
    if (
      !selectedDate.every((item) => item === '') &&
      !selectedDate.every((item) => item === 'Invalid date') &&
      role === 'advanced'
    ) {
      fetchAdvancedRateReports();
    }
  }, [
    dateQuery,
    categories,
    regions,
    selectedFilters,
    pageIndex,
    pageSize,
    sort,
  ]);

  const fetchAdvancedRateReports = async () => {
    try {
      setLoading(true);
      const data = {
        startDate: selectedDate[0],
        endDate: selectedDate[1],
      };
      const pCats = categories.filter((i) => !i.parent);
      const reportData = await reports.getAdvancedReportsByTimeInterval(data);
      setNewHeader(pCats);
      const report = makeAdvancedReport(reportData, pCats);
      setReportsData(report.sort((a, b) => b.total.value - a.total.value));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSelectCategory = async (id, userId) => {
    try {
      setLoadingSub(true);
      const data = {
        startDate: selectedDate[0],
        endDate: selectedDate[1],
        user: userId,
        category: id,
      };
      let resp;
      role === 'advanced'
        ? (resp = await reports.getAdvancedReportsByTimeIntervalSub(data))
        : (resp = await reports.getAllSubReport(data));

      const subCats = categories.filter((item) => item.parent === id);
      const report = subCats.map((item, idx) => {
        const subCat = resp.find((itm) => itm.subCategory === item._id);
        return {
          id: idx + 1,
          name: item?.title,
          total: subCat?.rate ? Math.min(Math.round(subCat?.rate), 100) : 0,
          count: subCat?.workCount ? subCat?.workCount : 0,

          // regionId: regionId,
        };
      });

      dispatch(setReportSubData(report ? report : []));
      setLoadingSub(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    return () => {
      if (selectedDate[0] === '' && selectedDate[1] === '') {
        const today = moment();
        const yesterday = moment(today).subtract(1, 'days');
        dispatch(
          setSelectedDate([
            today.format('YYYY-MM-DD'),
            yesterday.format('YYYY-MM-DD'),
          ])
        );
      }
    };
  }, []);

  const filterItems = [
    {
      name: 'province',
      label: 'Viloyat',
      options: provinces,
      permissions: ['leader'],
    },
    {
      name: 'region',
      label: 'Tuman',
      options: regions,
      permissions: ['leader', 'proleader'],
    },
  ];

  const onShowSizeChange = (_, pageSize) => {
    setPageSize(pageSize);
  };

  if (loading) return <Spinner maxHeight={'100%'} />;
  return (
    <>
      <Row align="middle" gutter={20} style={{ padding: '0px 0px 20px' }}>
        {filterItems.map(
          (item) =>
            item.permissions.includes(role) && (
              <Col key={item.name}>
                <Select
                  title={item.label}
                  name={item.name}
                  id={item.name}
                  handleChange={(value) => handleFilters(item.name, value)}
                  value={selectedFilters?.[item.name]}
                  options={item.options || []}
                  placeholder="Hududni tanlang!"
                />
              </Col>
            )
        )}
        <Col>
          <div>
            <RangePicker direction="column" />
          </div>
        </Col>
      </Row>

      {reportsData?.length ? (
        <>
          <Table
            headers={headers}
            selectCategory={handleSelectCategory}
            subLoading={loadingSub}
            data={reportsData}
            sort={sort}
            setSort={setSort}
          />
          {total > 10 && (
            <div className="custom-pagination-wrapper">
              <Pagination
                current={pageIndex}
                pageSize={pageSize}
                onShowSizeChange={onShowSizeChange}
                total={total}
                onChange={(page) => setPageIndex(page)}
              />
            </div>
          )}
        </>
      ) : (
        <NoData>
          <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
        </NoData>
      )}
    </>
  );
};
