import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../Logo';
import RightOptions from './RightOptions';
import { Content } from './style';
import { clearToken } from '../../redux/modules/auth/actions';
import {
  clearSelectedDate,
  clearSelectedDateSchedule,
} from '../../redux/modules/dates/actions';
import { clearNewWorksTotal } from '../../redux/modules/works/actions';
import {
  applicationCleanState,
  setActiveItem,
} from 'redux/modules/application/actions';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { setNavbarTitle } from 'redux/modules/navbar/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  leeadersTabs,
  provinceLeaderTabs,
  regionLeaderTabs,
} from 'components/Sidebar/helper';
import MenuBtn from './MenuButton';
import { useWindowSize } from 'hooks/useDevice';
import * as devices from 'constants/devices';

export default () => {
  const dispatch = useDispatch();
  const { device } = useWindowSize();
  const { role, provinceTitle, regionTitle } = useSelector(
    (state) => state.authReducer
  );
  const { activePageTitle } = useSelector((state) => state.navbarReducer);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.value) {
      dispatch(setNavbarTitle(location?.state?.value));
      return;
    } else if (location.pathname === '/') {
      if (role === 'proleader') {
        dispatch(
          setNavbarTitle(
            `O’zbekiston mahallalari uyushmasi ${provinceTitle} boshqarmasi`
          )
        );
      } else if (role === 'regleader') {
        dispatch(
          setNavbarTitle(
            `O’zbekiston mahallalari uyushmasi ${regionTitle} boshqarmasi`
          )
        );
      } else {
        dispatch(setNavbarTitle('O’zbekiston mahallalari uyushmasi'));
      }
    }
    // else {
    //   const activeItem = [
    //     ...leeadersTabs,
    //     ...regionLeaderTabs,
    //     ...provinceLeaderTabs,
    //   ].find(
    //     (item) => item.path.split('/')[1] === location.pathname.split('/')[1]
    //   )?.title;
    //   console.log('Active item', activeItem);
    //   // dispatch(setNavbarTitle(activeItem));
    // }
  }, [location.pathname, location?.state?.value]);

  const handleLogout = () => {
    dispatch(clearToken());
    dispatch(applicationCleanState());
    dispatch(clearNewWorksTotal());
    dispatch(clearSelectedDate());
    dispatch(clearSelectedDateSchedule());
    dispatch(setActiveItem('all'));
  };

  const roles = {
    supervisor: true,
  };

  const handleBackBtn = () => {
    if (!location?.pathname?.split('/').includes('tests')) {
      const path = location?.pathname?.split('/').slice(0, 2).join('/');
      if (path) {
        const activeItem = [
          ...leeadersTabs,
          ...regionLeaderTabs,
          ...provinceLeaderTabs,
        ].find((item) => item.path === path)?.title;
        navigate(path);
        dispatch(setNavbarTitle(activeItem));
        return;
      }
    } else {
      const path = location?.pathname?.split('/').slice(0, 2).join('/');
      const activeItem = [
        ...leeadersTabs,
        ...regionLeaderTabs,
        ...provinceLeaderTabs,
      ].find((item) => item.path === path)?.title;
      navigate(-1);
      dispatch(setNavbarTitle(activeItem));
      return;
    }
  };

  // console.log(activePageTitle);
  return (
    <Content>
      {roles[role] && <Logo />}
      <div className="title">
        {location?.state && (
          <div className="back">
            <Button
              className="back__btn"
              icon={<ArrowLeftOutlined />}
              onClick={() => handleBackBtn()}
            />
          </div>
        )}
        {device === devices.TABLET && <MenuBtn />}
        {!roles[role] && (
          <span
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '24px',
              color: '#FFF',
            }}
          >
            {activePageTitle}
          </span>
        )}
      </div>

      <RightOptions handleLogout={handleLogout} />
    </Content>
  );
};
