import React, { useLayoutEffect } from 'react';
import Content from '../../components/Content';
// import works from '../../services/works';
import { useDispatch, useSelector } from 'react-redux';
// import { setTotal } from '../../redux/modules/works/actions';
import { fetchData } from '../../redux/modules/application/actions';
import Spinner from '../../components/Spinner/FullScreen';
import Sidebar from '../../components/Sidebar';
import Container from '../../components/Container';
// import categories from 'services/categories';

export default () => {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.authReducer);
  const { loading } = useSelector((state) => state.appReducer);

  const roles = {
    admin: false,
    leader: true,
    proleader: true,
    regleader: true,
    regsupervisor: true,
    prosupervisor: true,
    supervisor: true,
    advanced: true,
  };

  useLayoutEffect(() => {
    if (roles[role]) dispatch(fetchData(role));
  }, []);

  if (loading && role !== 'admin') return <Spinner />;

  const checkSidebar = {
    leader: true,
    proleader: true,
    regleader: true,
    regsupervisor: false,
    prosupervisor: false,
    supervisor: false,
    advanced: true,
  };
  return (
    <>
      {roles[role] ? (
        <Container>
          {checkSidebar[role] && <Sidebar />}
          <Content />
        </Container>
      ) : (
        <Content />
      )}
    </>
  );
};
