import React, { useState, useEffect } from 'react';
import { Container, Body } from './style';
import ImageBlock from '../Contents/ImageGallery';
import { useShowModal } from '../../../../hooks';
import InfoSection from 'components/Cards/WorkCard/InfoSection';
import moment from 'moment';
import { useSelector } from 'react-redux';
import MapY from 'components/Map/MapModal';

const SupervisorCard = ({
  data = {},
  canDeleteImage,
  handleDeleteImage,
  unrated,
  size = 'large',
  showLocation = false,
}) => {
  const [geoLocation, setGeoLocation] = useState([]);
  const [mainImage, setMainImage] = useState({});
  const { lang } = useSelector((state) => state.appReducer);
  const [expandedText, setExpandedText] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const { showBlured } = useShowModal();

  const descTextSlicer = (text) => {
    const result =
      !expandedText && text?.length > 200 ? text?.slice(0, 200) + '...' : text;
    return result;
  };

  useEffect(() => {
    if (data.video.length > 0) {
      const video = data?.video.map((item) => ({
        ...item,
        videoUrl: item ?? '',
        isImage: false,
      }));
      setMediaData((prev) => [...prev, ...video]);
    }
    if (
      data?.media !== undefined &&
      data?.media !== null &&
      data?.media?.length > 0
    ) {
      const media = data?.media.map((item) => ({ ...item, isImage: true }));
      setMediaData((prev) => [...prev, ...media]);
      const latLng = [data?.media[0]?.lat, data?.media[0]?.lng];
      if (latLng !== undefined && latLng !== null) {
        setGeoLocation(latLng);
        setMainImage(data?.media && data?.media[0]);
      }
    } else {
      setGeoLocation([40.5, 71.25]);
    }
    0;
  }, [data]);

  const checkActive = (image) => {
    if (image === mainImage?.image) return true;
  };

  const handleMainImage = (image) => {
    const latLng = [image?.lat, image?.lng];
    setGeoLocation(latLng);
    setMainImage(image);
  };

  const handleShowModal = () => {
    showBlured({
      title: '',
      maxWidth: '1200px',
      height: '600px',
      withHeader: false,
      body: () => <MapY latLang={geoLocation} />,
    });
  };

  return (
    <Container>
      <Body expanded={expandedText} size={size}>
        <ImageBlock
          size={size}
          imageData={mediaData}
          checkActive={checkActive}
          handleMainImage={handleMainImage}
          mainImage={mainImage}
          canDeleteImage={canDeleteImage}
          deleteImage={handleDeleteImage}
        />
        <div className="description">
          <div className="desc__text">
            <p className="title">{data?.subCategory?.['title' + lang]}</p>
            {descTextSlicer(data?.desc)}
            {data?.desc?.length > 200 && (
              <button
                className="show__more"
                onClick={() => setExpandedText(!expandedText)}
              >
                {expandedText ? 'Yopish' : 'Ko’proq o’qish'}
              </button>
            )}
          </div>
          <div className="executor__info">
            <div className="date">
              {moment(data?.uploadTime).format('DD.MM.YYYY')}
              <span className="time">
                {moment(data?.uploadTime).format('HH:mm:ss')}
              </span>
            </div>
            <p className="executor">
              {`${data?.user?.lastName} ${data?.user?.firstName} ${data?.user?.middleName}`}
            </p>
          </div>
        </div>
        <div className="info">
          <InfoSection
            unrated={unrated}
            data={data}
            size={size}
            handleShowModal={handleShowModal}
            showLocation={showLocation}
          />
        </div>
      </Body>
    </Container>
  );
};

export default SupervisorCard;
