import React, { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Title,
  Wrapper,
  IconContainer,
  SubElements,
  SubElement,
} from './style';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultTitle, setNavbarTitle } from 'redux/modules/navbar/actions';
import { setPathname } from 'redux/modules/pathname/actions';
import { setCollapsed } from 'redux/modules/application/actions';
import * as devices from '../../../constants/devices';
import { useWindowSize } from 'hooks/useDevice';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const Item = ({
  title,
  path,
  icon: Icon,
  elements = [],
  expand = false,
  setExpand,
  notification = 0,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hovered, setHovered] = useState(false);
  const desktopCollapsed = useSelector(
    ({ appReducer }) => appReducer.desktopCollapsed
  );
  const dispatch = useDispatch();
  const { device } = useWindowSize();

  const isActive = (checkPath) =>
    location.pathname.split('/')[1] === checkPath.split('/')[1];

  const handleNavigate = (path) => {
    dispatch(setCollapsed(false));
    if (path !== '/map') {
      dispatch(setPathname(path));
    }
    if (location.pathname === '/') {
      navigate(path, { replace: true });
    } else {
      navigate(path, { replace: true });
    }
    if (title && path !== '/') {
      dispatch(setNavbarTitle(title));
    } else {
      dispatch(setDefaultTitle());
    }
  };

  const checkSubItems = () => {
    if (elements.length > 0 && expand) {
      return <FiChevronUp size={18} color="#00826C" />;
    }
    if (elements.length > 0) return <FiChevronDown color="#83849a" size={18} />;
  };

  const handleClickContainer = useCallback((p, t) => {
    if (elements.length !== 0) {
      setExpand((prev) => !prev);
    } else {
      setExpand(false);
      handleNavigate(p, t);
    }
  }, []);

  const handleNavigateChild = (p, t) => {
    dispatch(setNavbarTitle(t));
    navigate(p);
  };
  return (
    <Wrapper>
      <Container
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        active={elements.length && expand ? true : isActive(path)}
        hovered={hovered}
        onClick={() => handleClickContainer(path, title)}
        collapsed={desktopCollapsed}
      >
        <Container.Left>
          <IconContainer
            active={elements.length && expand ? true : isActive(path)}
            hovered={hovered}
          >
            <Icon hovered={+hovered} size={18} active={+isActive(path)} />
          </IconContainer>
          {device === devices.DESKTOP ? (
            !desktopCollapsed && (
              <Title
                hovered={hovered}
                active={elements.length && expand ? true : isActive(path)}
              >
                {title}
              </Title>
            )
          ) : (
            <Title
              hovered={hovered}
              active={elements.length && expand ? true : isActive(path)}
            >
              {title}
            </Title>
          )}
        </Container.Left>
        {notification > 0 && (
          <div className="badge__notification">
            {notification >= 100 ? '99+' : notification}
          </div>
        )}
        {checkSubItems()}
      </Container>
      {elements.length > 0 && (
        <SubElements expand={expand}>
          {elements?.map(({ sPath, sTitle }, index) => (
            <SubElement
              key={`${index + 1}`}
              onClick={() => handleNavigateChild(sPath, sTitle)}
            >
              <Title active={isActive(sPath)}>{sTitle}</Title>
            </SubElement>
          ))}
        </SubElements>
      )}
    </Wrapper>
  );
};

export default React.memo(Item);
