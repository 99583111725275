import styled from 'styled-components';

export const BookWrapper = styled.div`
  width: 294px;
  border-radius: 12px;
  background: ${({ background }) => (background ? background : '#fff')};
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.15);

  & .image-wrapper {
    width: 100%;
    height: 192px;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    position: relative;

    & .ant-image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 11px 11px 0px 0px;
      background: #fff;
      & .ant-image-mask {
        border-radius: 11px 11px 0px 0px;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .delete-icon {
      position: absolute;
      right: 8px;
      top: 8px;
      display: inline-flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #fff;
      cursor: pointer;
      width: 36px;
      height: 36px;

      & img {
        width: 16px;
        height: 16px;
      }
    }

    .completed-icon {
      position: absolute;
      right: 55px;
      top: 8px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #fff;
      cursor: pointer;
      width: 36px;
      height: 36px;

      & img {
        width: 16px;
        height: 16px;
      }
    }
  }

  & .card-text {
    width: 100%;
    padding: 20px 24px 24px;

    & .card-status-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      & .card-status {
        color: rgba(8, 9, 54, 1);
        font-family: 'Segoe UI';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 12px;
        & .card-count {
          display: flex;
          gap: 10px;
          justify-content: center;
          font-family: 'Segoe UI';
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
          & img {
            width: 20px;
            height: 20px;
          }
        }
        & .card-count-loading {
          color: #ffbd00;
        }
        & .card-count-completed {
          color: #2dce99;
          font-family: 'Segoe UI';
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
        }
      }
    }
  }
`;

export const CardTitle = styled.div`
  color: #00826C;
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  padding-bottom: ${({ padding }) => padding && padding};
  word-wrap: break-word;
`;

export const ButtonTest = styled.div`
  width: fit-content;
  min-height: 14px;
  display: flex;
  align-items: center;
  color: #00826C;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  margin-top: 20px;
  text-decoration: underline;
`;
