import styled, { css } from 'styled-components';

export const Header = styled.div`
  background: white;
  grid-area: header;
  display: grid;
  place-items: center;
  height: var(--sidebar-header-height);
  box-sizing: border-box;
  padding: 20px var(--sidebar-padding-x) 0 var(--sidebar-padding-x);
  position: relative;
  z-index: 11;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  position: relative;

  & .header-line {
    width: 100%;
    overflow: hidden;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const SideBarButton = styled.button`
  position: absolute;
  top: 30px;
  right: -20px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  border-radius: 10px;
  box-shadow: rgb(238, 238, 238) 0px 0px 7px;
  cursor: pointer;
  border: none;
`;
