import { useDispatch, useSelector } from 'react-redux';
import { PublishingWrapper } from './style';
import { Button, Checkbox, Col, Row } from 'antd';
import { useState } from 'react';
import quiz from 'services/quiz';
import { useHideModal, useShowAlert } from 'hooks';
import { setModalLoading } from 'redux/modules/modals/actions';

export default ({ testId, fetchData = () => {} }) => {
  const { regions: allRegion } = useSelector((state) => state.appReducer);
  const { role } = useSelector((state) => state.authReducer);
  const [regions, setRegions] = useState([]);
  const { hideModal } = useHideModal();
  const dispatch = useDispatch();
  const { error } = useShowAlert();

  const handleChange = (value) => {
    if (value === 'all') {
      if (allRegion?.length === regions?.length) {
        setRegions(() => []);
      } else {
        const newValue = allRegion?.map((itm) => itm?._id);
        setRegions(() => newValue);
      }
    } else {
      if (!regions?.includes(value)) {
        setRegions((prev) => [...prev, value]);
      } else {
        const newValue = regions?.filter((itm) => itm !== value);
        setRegions(() => newValue);
      }
    }
  };

  const handleSubmit = () => {
    const sentData = {
      regions: [...regions],
      status: 2,
    };
    if (role === 'regleader') {
      delete sentData['regions'];
    }

    dispatch(setModalLoading(true));
    quiz
      .update(testId, sentData)
      .then(() => {
        fetchData();
        hideModal();
      })
      .catch((err) => {
        console.log(err);
        error('Xatolik yuz berdi');
      })
      .finally(() => dispatch(setModalLoading(false)));
  };

  return (
    <PublishingWrapper>
      {role === 'proleader' ? (
        <>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Checkbox
                className="checkbox-item"
                onChange={() => handleChange('all')}
                checked={allRegion?.length === regions?.length ? true : false}
              >
                Barchasi
              </Checkbox>
            </Col>
            {allRegion?.map((item, index) => (
              <Col key={index} span={12} className="checkbox-item">
                <Checkbox
                  onChange={() => handleChange(item?._id)}
                  checked={regions?.includes(item?._id)}
                >
                  {item?.titleUZ}
                </Checkbox>
              </Col>
            ))}
          </Row>
          <div span={10} className="button-wrapper">
            <Button onClick={handleSubmit}>Nashr qilish</Button>
          </div>
        </>
      ) : (
        <div className="regionLeader-wrapper">
          <div className="title">
            Haqiqatdan ushbu testni nashr qilmoqchimisiz?
          </div>
          <div className="button-wrapper">
            <Button onClick={hideModal}>Bekor qilish</Button>
            <Button onClick={handleSubmit}>Nashr qilish</Button>
          </div>
        </div>
      )}
    </PublishingWrapper>
  );
};
