import styled from 'styled-components';
// import { centeredFlex } from '../Contents/WorkFooter/style';

export const CardContent = styled.div`
  width: 100%;
  min-height: 330px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px;
  transition: height 0.4s linear;
  background-color: #fff;
  .image__gallery {
    width: 100%;
    max-width: 500px;
  }

  .description {
    width: 100%;
    height: 100%;
    max-width: 500px;
    border-right: 1px solid #0809360d;
    padding-right: 12px;
    .title {
      width: 100%;
      color: #080936;
      font-family: Segoe UI;
      font-size: ${({ size }) => (size === 'small' ? '15px' : '20px')};
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
      letter-spacing: 0.4px;
      padding: 0;
      text-align: left;
      margin-bottom: 12px;
    }
    .desc__text {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: start;
      flex-direction: column;
      gap: 8px;
      textarea {
        width: 100%;
        color: #080936;

        padding: 10px;
        border-radius: 16px;
        border: 1px solid rgba(8, 9, 54, 0.1);
        background: #fff;
        height: 228px;
        flex-shrink: 0;
        font-family: Segoe UI;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 15px */
        :focus {
          outline: none;
        }
      }

      .remember {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: space-between;
        p {
          margin: 0;
          padding: 0;
          font-family: Segoe UI;
          font-style: normal;
          line-height: 100%; /* 15px */
          color: #080936;

          :nth-child(1) {
            font-size: 15px;
            font-weight: 600;

            span {
              padding-left: 10px;
              font-size: 13px;
              font-weight: 400;
              opacity: 0.5;
            }
          }
          :nth-child(2) {
            font-size: 13px;
            font-weight: 400;
            opacity: 0.5;
          }
        }
      }
    }
    .executor__info {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;
      gap: 10px;
      color: #080936;
      font-family: Segoe UI;
      font-size: 16px;
      font-size: ${({ size }) => (size === 'small' ? '12px' : '16px')};
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
      .date {
        opacity: 0.5;
        margin: 0;
        padding: 0;
      }
      .executor {
        margin: 0;
        padding: 0;
      }
    }
  }
  .info {
    width: 100%;
    height: 100%;
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: start;
  }
`;
export const Description = styled.div``;
