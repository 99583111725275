export const buttons = [
  {
    id: 1,
    title: 'Yangi',
    value: 'new',
  },
  {
    id: 2,
    title: 'Yakunlangan',
    value: 'completed',
  },
];
