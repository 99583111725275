import styled from 'styled-components';

export const Content = styled.div`
  /* width: 100%; */
  padding: 0 30px;
  height: var(--navbar-height);
  /* box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75); */
  /* background-color: var(--navbar-background); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 28px;
    .back {
      display: flex;
      .back__btn {
        border-radius: 8px;
        background: #fff;
        border: none;
        outline: none;
        :hover {
          color: #00826C;
          border: none;
          outline: none;
        }
      }
    }
  }
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 250px;
  gap: 20px;
`;
export const UserProfile = styled.div`
  display: flex;
  width: 100%;
  /* max-width: -webkit-fill-available; */
  align-items: center;
  gap: 16px;
`;
export const LogoutButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 24px;
  max-height: 24px;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  border-radius: 12px;
  .ant-popover-inner {
    border-radius: 12px !important;
  }
`;
export const LogoContainer = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  background: #f5f7f9;
  border-radius: 50%;
  flex-shrink: 0;
`;
export const Username = styled.div`
  width: 100%;
  min-width: fit-content;
  color: ${({ darkText }) => (!darkText ? '#fff' : '#080936')};
  display: flex;
  flex-direction: column;
  font-family: 'Segoe UI';
  font-style: normal;
  /* line-height: normal; */
  span:first-child {
    color: ${({ darkText }) => (!darkText ? '#fff' : '#080936')};
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  span:last-child {
    font-size: 14px;
    margin: 0;
    text-transform: capitalize;
    font-weight: 400;
  }
`;
