import React from 'react';
import { StatusCard } from '../style';

export default ({ color }) => {
  return (
    <StatusCard color={color}>
      <div className="late-came">
        <div className="late-came-text">Vakant</div>
      </div>
    </StatusCard>
  );
};
