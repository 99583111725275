import styled from 'styled-components';
import { Button as BTN } from 'antd';

export const Wrapper = styled.div`
  padding-top: ${({ pt }) => pt && pt};

  & .excell__btn {
    padding-bottom: 30px;
  }

  & .pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0px;

    & .ant-pagination-item {
      border-radius: 6px;
      :hover {
        border-color: #00826C !important;
        & a {
          color: #00826C !important;
        }
      }
    }
    & .ant-pagination-item-active {
      border: 1px solid #00826C;
      & a {
        color: #00826C !important;
      }
    }
    .ant-pagination-prev button {
      border-radius: 6px !important;
      :hover {
        border-color: #00826C !important;
        & svg {
          color: #00826C !important;
        }
      }
    }
    .ant-pagination-next button {
      border-radius: 6px !important;
      :hover {
        border-color: #00826C !important;
        & svg {
          color: #00826C !important;
        }
      }
    }
    .ant-pagination-disabled button {
      :hover {
        border-color: #d9d9d9 !important;
        & svg {
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }
    }

    & .ant-select {
      width: 125px !important;
      & .ant-select-selector {
        min-width: 100% !important;
        min-height: 32px !important;
        border-radius: 6px !important;
        height: 100%;
        color: #080936;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        .ant-select-selection-item {
          margin-right: 0px !important;
        }
        & .ant-select-arrow {
          margin-top: 12px !important;
        }
      }
    }
  }
`;
export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-bottom: 10px;
  margin-bottom: 20px;
  row-gap: 10px;
  width: 100%;
`;
export const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  width: 100%;

  .filters_wrap {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .range-datepicker {
    width: 300px !important;
  }
  .add-btn {
    margin-top: 26px;
    align-self: flex-start;
    font-family: 'Segoe UI';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    :hover {
      background: #33d3ff;
    }
  }
`;
export const Button = styled(BTN)`
  height: 42px;
  width: ${({ width }) => width && width};
  background: ${({ background }) => (background ? background : '#00c4fa')};
  border-radius: 12px;
  padding: 0 10px;
  /* :hover {
    background: #33D3FF;
  } */
`;
export const ModalContainer = styled.div`
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* justify-content: center; */
  /* flex-wrap: wrap; */
  /* gap: 10px; */
  /* align-items: flex-end; */

  & .ant-clear-icon {
    & .ant-select {
      max-width: 100% !important;
      & .ant-select-selection-overflow {
        overflow-y: hidden !important;
      }
      & .ant-select-clear {
        right: 8px !important;
      }
    }
  }

  .date-picker {
    width: 100%;
  }
  .ant-picker-panel .ant-picker-footer {
    display: none;
  }
  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    display: block;
    color: #00826C;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #9a9aeb;
  }
  .date-picker > .ant-picker {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    height: 42px;
    & .ant-picker-input > input {
      text-align: left;
    }
  }
  .ant-picker-input > input {
    font-weight: normal;
  }
  .range-text {
    display: flex;
    font-size: 14px;
    color: #fff;
    margin: 2px;
  }
`;
export const Item = styled.div`
  flex-grow: 1;
  flex-basis: auto;
  & .ant-input {
    border-radius: 8px;
    height: 40px;
  }
  textarea {
    min-height: 100px;
  }
  & .date-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & .ant-picker {
      width: 140px;
      background: #E0F0ED;
      border-radius: 10px;
      border: none;
      height: 44px;
    }
  }
  & .d-picker {
    max-width: 250px;
  }
  &:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;
export const Title = styled.h4`
  color: #28303f;
  font-size: 24px;
  font-weight: 600;
`;
export const SwitchContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
  color: #080936;
  font-family: Segoe UI;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  :last-child {
    margin-bottom: 0;
  } /* 15px */
`;

export const Label = styled.span`
  color: #8c97ab;
  font-family: Segoe UI;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 15px */
  & span {
    color: red;
  }
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  height: calc(100vh - 400px);
`;
