import styled, { css } from 'styled-components';

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WorkPageWrapper = styled.div`
  ${flexCenter}
  flex-direction: column;
  align-items: center;
  width: 100%;
  .filter__date {
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: start;
    gap: 10px;
    .label {
      color: #fff;
    }
  }
  .sent__works {
    width: 100%;
    height: 100%;
    ${flexCenter};
    flex-direction: column;
    flex-shrink: 0;
    gap: 20px;
    .work {
      width: 100%;
      position: relative;
      max-width: 1566px;
      padding: 40px;
      background: #ffffff;
      border-radius: 12px !important;

      top: 0;
      .download__btn {
        position: absolute;
        top: 20px;
        right: 20px;
        border-radius: 10px;
        color: #00826C;
        cursor: pointer;
        border: none;
        outline: none;
        background: rgba(76, 77, 220, 0.12);
        display: flex;
        width: 44px;
        height: 44px;
        padding: 13px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;

        :hover {
          background: #00826C;
          color: #fff;
          transition: all 0.3s ease;
        }
        :active {
          transform: scale(0.9);
          transition: all 0.3s linear;
        }
      }
    }
  }
  .works {
    width: 100%;
    max-width: 1566px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    gap: 20px;
    border-radius: 12px !important;
    padding: 40px;
    border-radius: 12px;
    .work {
      width: 100%;
      position: relative;
      .download__btn {
        position: absolute;
        top: -20px;
        right: -20px;
        border-radius: 10px;
        color: #00826C;
        cursor: pointer;
        border: none;
        outline: none;
        background: rgba(76, 77, 220, 0.12);
        display: flex;
        width: 44px;
        height: 44px;
        padding: 13px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;

        :hover {
          background: #00826C;
          color: #fff;
          transition: all 0.3s ease;
        }
        :active {
          transform: scale(0.9);
          transition: all 0.3s linear;
        }
      }
    }
    .button__group {
      width: 100%;
      ${flexCenter}
      justify-content: space-between;
      button {
        display: flex;
        width: 180px;
        height: 44px;
        padding: 15px 3px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 10px;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 12px;
        font-family: Segoe UI;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 15px */
      }
      .add {
        color: #fff;
        background: #00826C;
        :disabled {
          cursor: not-allowed;
          background: #e2e8f0;
          color: #a0aec0;
        }
      }
      .del {
        color: #ff6060;
        background: rgba(255, 96, 96, 0.12);
      }
    }
  }
`;

export const NoData = styled.div`
  width: 100%;
  height: calc(100vh - 220px);
  /* max-height: 100%; */
  margin: 0 auto;
  ${flexCenter}

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 120px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;
