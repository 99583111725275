import styled from 'styled-components';

export const UserBlock = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 50px;
`;

export const UserAnswer = styled.div`
  max-width: 726px;
  width: 100%;
  background-color: #ffffff;
  padding: 32px;
  border-radius: 12px;

  .block-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 24px;

    .user-image {
      width: 100px;
      height: 100px;
      margin-bottom: 24px;
      border-radius: 50%;
      border: 1px solid #eee;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .username {
      color: #080936;
      font-size: 24px;
      line-height: 24px;
      font-weight: 500;
      padding-bottom: 24px;
    }
    .quiz-title {
      color: #001444;
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      padding-bottom: 16px;
    }
    .answer-percent {
      color: #2dce99;
      font-size: 36px;
      line-height: 36px;
      font-weight: 600;
    }
  }

  .block-body {
    .result-count {
      padding: 20px 0px 23px;
      color: #080936;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      text-align: right;
      span {
        color: #2dce99;
      }
    }
    .answers {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }
`;
