import React, { memo } from 'react';
import VideoThumb from 'assets/images/effect_blur.jpg';
import { PlayCircleOutlined } from '@ant-design/icons';
import { CarouselItem, CarouselWrapper, VideoFrame } from './style';
import Image from 'components/Cards/WorkCards/Contents/Image';
// import Image from 'components/Cards/TaskCard/Image';

const ImageCarousel = ({ data = [], showPlayer, size = 'small' }) => {
  return (
    <CarouselWrapper autoplay>
      {data.map((item, index) => (
        <CarouselItem key={index} size={size}>
          {item.isImage ? (
            <Image
              images={data}
              image={`${item.image}`}
              alt={`image ${item.image}}`}
            />
          ) : (
            <VideoFrame onClick={() => showPlayer(item.videoUrl)}>
              <img
                width="100%"
                height="100%"
                className="effect__blur"
                src={VideoThumb}
                alt="video frame"
              />
              <PlayCircleOutlined className="icon" />
            </VideoFrame>
          )}
        </CarouselItem>
      ))}
    </CarouselWrapper>
  );
};
export default memo(ImageCarousel);
