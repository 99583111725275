import React, { useMemo, useState } from 'react';
import { useRef, useEffect } from 'react';
import {
  Button,
  ButtonWrapper,
  FilterBody,
  FilterContainer,
  Header,
  MapContainer,
  Wrapper,
} from './style';

import L from 'leaflet';
import 'leaflet-plugins/layer/tile/Yandex';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import { FiLayers, FiX } from 'react-icons/fi';
import Select from 'components/FormElements/Select';
import { Dropdown, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import RangePicker from 'components/RangePicker';
import { setDefaultTitle } from 'redux/modules/navbar/actions';
import TextArea from 'components/FormElements/TextArea';
import { IoOptions } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useFilter } from 'views/useFilter';
import works from 'services/works';
import Spinner from 'components/Spinner/FullScreen';
import { useShowAlert, useShowModal } from 'hooks';
import WorkCard from 'components/Cards/WorkCard';
import uzbekistanPolygon from '../../../constants/uzbekistanPolygon.json';
import './helper';
import MapLayer from 'components/FormElements/MapLayer';

const items = [
  {
    label: 'Yandex',
    key: '0',
  },
  {
    label: 'Leaflet',
    key: '1',
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
const WorksMap = ({ loading, data, fetchMapData }) => {
  const mapRef = useRef(null);
  const dispatch = useDispatch();
  const [filterExpand, setFilterExpand] = useState(false);
  const { pathname } = useSelector((state) => state.pathNameReducer);
  const { selectedDate } = useSelector((state) => state.selectedDateReducer);
  const { role } = useSelector((state) => state.authReducer);
  const [layer, setLayer] = useState('0');
  const [layerType, setLayerType] = useState('map');
  const navigate = useNavigate();
  const {
    categories,
    objects,
    handleFilters,
    provinces,
    regions,
    supportTypes,
    selectedFilters,
    setSelectedFilters,
  } = useFilter({ withObject: true });
  const { error } = useShowAlert();
  const { showBlured } = useShowModal();

  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
    iconUrl: require(layer === '0'
      ? '../../../assets/images/icon_map_marker.png'
      : '../../../assets/icons/marker.png'),
    iconSize: layer === '0' ? [25, 25] : [22, 34],
    shadowSize: layer === '0' ? [0, 0] : [48, 34],
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  });
  L.Icon.extend({
    options: {
      iconUrl: '../../../assets/images/icon_map_marker__active.png',
      iconSize: [36, 36],
      iconAnchor: [18, 18],
    },
  });

  const polyStyle = useMemo(
    () => ({
      fillColor: 'transparent',
      color: layer == '0' ? '#F69320' : '#639542',
    }),
    [layer]
  );

  useEffect(() => {
    const container = L.DomUtil.get(mapRef?.current);
    let map = L.map(container, {
      attributionControl: false,
      fadeAnimation: true,
      minZoom: 6,
      maxZoom: 18,
    }).setView([40.3734, 71.7978], 6);
    if (layer == '0') {
      const yndx = new L.Yandex({
        type: layerType, // 'map', 'satellite', 'hybrid',
      });
      map.addLayer(yndx);
    } else {
      const yndx = L.tileLayer(
        'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          className: 'map-tiles',
        }
      ).addTo(map);
      map.addLayer(yndx);
    }

    L.geoJson(uzbekistanPolygon, polyStyle).addTo(map);

    let markers = L.markerClusterGroup(
      layer == '0' && {
        spiderfyOnMaxZoom: true,
        zoomToBoundsOnClick: false,
        iconCreateFunction: function (cluster) {
          return L.divIcon({
            html:
              '<div class="mycluster"><div class="mycluster_count">' +
              cluster.getChildCount() +
              '</div><div class="mycluster_circle"></div></div>',
          });
        },
      }
    ).on('clusterclick', function (e) {
      if (e.layer._zoom == 18 || e.layer._zoom > 14) {
        let ids = [];
        let markers = e.layer.getAllChildMarkers();
        markers.forEach(function (marker) {
          ids.push(marker.options.id);
        });
      } else {
        e.layer.zoomToBounds();
      }
    });
    data
      .filter(
        (item) => item.lat && item.lng && item.lat !== null && item.lng !== null
      )
      .map((item) => {
        const mark = new L.Marker([item.lat, item.lng], {
          id: item._id,
        });
        mark.on('click', function () {
          handleMarker(item._id);
        });
        markers.addLayer(mark);
      });
    map.addLayer(markers);
    return () => {
      map.off();
      map.remove();
    };
  }, [mapRef, layer, data, layerType]);

  const handleCloseMap = () => {
    navigate(pathname, { replace: true });
    setFilterExpand(false);
    dispatch(setDefaultTitle());
  };

  const handleLayer = ({ key }) => {
    setLayer(key);
  };

  const handleMarker = (id) => {
    works
      .getOneWork(id)
      .then((resp) => {
        showBlured({
          loading: false,
          title: "Bajarilgan ish to'g'risida ma'lumot",
          body: () => <WorkCard data={resp} />,
        });
      })
      .catch((err) => {
        error("Ma'lumotlarni yuklashda xatolik yuzaga keldi!", 2, 'topRight');
        console.log(err);
      });
  };

  const filterItems = [
    {
      name: 'province',
      label: 'Viloyat',
      options: provinces,
      permissions: ['leader'],
    },
    {
      name: 'region',
      label: 'Shahar-tuman',
      options: regions,
      permissions: ['leader', 'proleader'],
    },
    {
      name: 'district',
      label: 'MFY',
      options: objects,
      permissions: ['leader', 'proleader', 'regleader'],
    },
    {
      name: 'category',
      label: 'Sohalar',
      options: categories.filter((item) => !item.parent),
      permissions: ['leader', 'proleader', 'regleader'],
    },
    {
      name: 'subCategory',
      label: 'Ishlar',
      options: categories.filter(
        (item) => item?.parent === selectedFilters?.category
      ),
      permissions: ['leader', 'proleader', 'regleader'],
    },
  ];

  const handleSearchButton = () => {
    const payload = {
      province: selectedFilters.province,
      region: selectedFilters.region,
      district: selectedFilters.district,
      category: selectedFilters.category,
      subCategory: selectedFilters.subCategory,
      search: selectedFilters.search,
    };

    if (
      !selectedDate.every((item) => item === '') &&
      selectedDate[0] !== 'Invalid date' &&
      selectedDate[1] !== 'Invalid date'
    ) {
      payload['startDate'] = selectedDate[0];
      payload['endDate'] = selectedDate[1];
    }

    fetchMapData(payload);
  };
  const handleCancelButton = () => {
    setSelectedFilters({});
    fetchMapData();
    setFilterExpand(false);
  };

  if (loading) return <Spinner />;
  return (
    <Wrapper>
      <MapContainer ref={mapRef} id="map">
        <ButtonWrapper>
          {layer === '0' ? (
            <MapLayer setValue={setLayerType} value={layerType} />
          ) : null}
          <Dropdown menu={{ items, onClick: handleLayer }} trigger={['click']}>
            <Space>
              <Button className="location-layer">
                <FiLayers size={24} />
              </Button>
            </Space>
          </Dropdown>
          <Button onClick={() => setFilterExpand(true)} className="filter-btn">
            <IoOptions size={24} />
            <span>Filter</span>
          </Button>
          <Button onClick={handleCloseMap} className="closeMap">
            <FiX size={24} />
          </Button>
        </ButtonWrapper>
      </MapContainer>
      <FilterContainer expand={filterExpand}>
        <Header>
          <button onClick={() => setFilterExpand(false)}>
            <FiX />
          </button>
        </Header>
        <FilterBody>
          <div>
            <label>{'Sana'}</label>
            <div className="date-picker-wrap">
              <RangePicker titleHide />
            </div>
            {filterItems.map(
              (item, index) =>
                item?.permissions?.includes(role) && (
                  <React.Fragment key={index}>
                    <label>{item?.label}</label>
                    <Select
                      placeholder={item?.label}
                      name={item?.name}
                      options={item?.options}
                      handleChange={(value) => handleFilters(item.name, value)}
                      value={selectedFilters?.[item.name]}
                    />
                  </React.Fragment>
                )
            )}

            <label>{'Matn bo’yicha qidirish'}</label>
            <div className="textarea-wrap">
              <TextArea
                placeholder={"Matn bo'yicha qidirish"}
                descriptionValue={selectedFilters?.['search']}
                handleDescription={(e) =>
                  handleFilters('search', e.target.value)
                }
              />
            </div>
            <label>{'Yordam'}</label>
            <Select
              options={supportTypes}
              placeholder="Yordam turini tanlang"
              name={'supportType'}
              handleChange={(value) => handleFilters('supportType', value)}
              value={selectedFilters?.['supportType']}
            />
          </div>
          <div className="button-container">
            <button
              type="button"
              className="custom-filter-button"
              onClick={handleCancelButton}
            >
              {'Bekor qilish'}
            </button>
            <button
              type="submit"
              className="custom-filter-button"
              onClick={handleSearchButton}
            >
              {'Qidirish'}
            </button>
          </div>
        </FilterBody>
      </FilterContainer>
    </Wrapper>
  );
};

export default WorksMap;
