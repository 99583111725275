import styled from 'styled-components';

export const Text = styled.div`
  display: inline-block;
  padding: 0px 10px;
  background: #ffffff;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  text-align: center;
`;
export const Content = styled.div`
  padding: ${({ isSmall }) => (isSmall ? '0 10px' : '0 30px')};
  height: var(--navbar-height);
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1000;
`;
export const LeftContent = styled.div`
  padding: ${({ isSmall }) => (isSmall ? '0 10px' : '0 30px')};
  height: var(--navbar-height);
  align-items: center;
  display: flex;
  gap: ${({ isSmall }) => (isSmall ? '20px' : '100px')};
`;
export const RightContent = styled.div`
  width: 100%;
  padding: 0 30px;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
`;
export const Menu = styled.div`
  padding: 0 10px;
  height: var(--navbar-height);
  align-items: center;
  display: flex;
  gap: 24px;
`;
export const MenuItem = styled.button`
  background: ${({ isActive }) => (isActive ? '#00826C' : `#fff`)};
  border: none;
  border-radius: 8px;
  display: inline-flex;
  padding: 14px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? '#fff' : '#080936')};
  font-family: Segoe UI;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
`;
// export const =
