import styled from 'styled-components';

export const OnlineLibraryWrap = styled.div`
  width: 100%;
  padding: 20px 0px 20px;

  & .pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 40px 10px 0px;

    & .ant-pagination-item {
      border-radius: 6px;
      :hover {
        border-color: #00826C !important;
        & a {
          color: #00826C !important;
        }
      }
    }
    & .ant-pagination-item-active {
      border: 1px solid #00826C;
      & a {
        color: #00826C !important;
      }
    }
    .ant-pagination-prev button {
      border-radius: 6px !important;
      :hover {
        border-color: #00826C !important;
        & svg {
          color: #00826C !important;
        }
      }
    }
    .ant-pagination-next button {
      border-radius: 6px !important;
      :hover {
        border-color: #00826C !important;
        & svg {
          color: #00826C !important;
        }
      }
    }
    .ant-pagination-disabled button {
      :hover {
        border-color: #d9d9d9 !important;
        & svg {
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }
    }

    & .ant-select {
      width: 125px !important;
      & .ant-select-selector {
        min-width: 100% !important;
        min-height: 32px !important;
        border-radius: 6px !important;
        height: 100%;
        color: #080936;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        .ant-select-selection-item {
          margin-right: 0px !important;
        }
        & .ant-select-arrow {
          margin-top: 12px !important;
        }
      }
    }
  }
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  height: calc(100vh - 350px);
`;

export const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 18px;
`;
