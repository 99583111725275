import { useState } from 'react';
import districts from '../services/objects';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import moment from 'moment';

export function useFilter({ withObject }) {
  var today = moment();
  let startDate = moment().format('YYYY-MM-DD');
  var yesterday = moment(today).subtract(1, 'days').format('YYYY-MM-DD');

  const { categories, regions, provinces, activeItem, objects, supportTypes } =
    useSelector((state) => state.appReducer);
  const role = useSelector((state) => state.authReducer.role);

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: moment(yesterday).format('YYYY-MM-DD'),
    endDate: moment(startDate).format('YYYY-MM-DD'),
  });
  const [filterOptions, setFilterOptions] = useState({
    regions: [],
    districts: [],
    subCategories: [],
  });

  const fetchDistrictsByRegionId = async (regionId) => {
    try {
      const districtsResult = await districts.getAllObjectByRegions({
        region: regionId,
      });
      setFilterOptions((prev) => ({
        ...prev,
        districts: districtsResult.map((item) => ({
          ...item,
          coordinates: item?.coordinates.filter(
            (item) => item?.length > 0 && item?.reverse()
          ),
        })),
      }));
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleFilters = useCallback(
    (name, value) => {
      if (name === 'province') {
        setFilterOptions((prev) => ({
          ...prev,
          regions: regions.filter((item) => item.province?._id === value),
        }));
        delete selectedFilters['region'];
        selectedFilters['district'] && delete selectedFilters['district'];
        setSelectedFilters((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else if (name === 'region') {
        if (value && withObject) {
          fetchDistrictsByRegionId(value);
        } else {
          setFilterOptions((prev) => ({ ...prev, districts: [] }));
        }
        selectedFilters['district'] && delete selectedFilters['district'];
        setSelectedFilters((prev) => ({ ...prev, [name]: value }));
      } else if (name === 'categories') {
        setFilterOptions((prev) => ({
          ...prev,
          works: categories.filter((item) => item.parent === value),
        }));
        selectedFilters['categories'] && delete selectedFilters['categories'];
        setSelectedFilters((prev) => ({ ...prev, [name]: value }));
      } else {
        setSelectedFilters((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    },
    [selectedFilters]
  );

  return {
    filterOptions,
    selectedFilters,
    setSelectedFilters,
    handleFilters,
    categories,
    provinces,
    regions: role === 'leader' ? filterOptions?.regions : regions,
    objects: role === 'regleader' ? objects : filterOptions?.districts,
    works: filterOptions?.works,
    supportTypes,
  };
}
