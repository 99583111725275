import { FiEye } from 'react-icons/fi';
import { Button } from 'antd';
import { ActionButton } from './style';

export const buttons = [
  {
    id: 1,
    title: 'Yangi',
    value: 'new',
  },
  {
    id: 2,
    title: 'Ko’rilgan',
    value: 'viewed',
  },
];
export const getColumn = ({ handleClick }) => {
  const tableHeader = [
    {
      id: 0,
      Header: () => {
        return <span className="table-header-className">№</span>;
      },
      accessor: 'indx',
      show: true,
      width: 50,
    },
    {
      id: 1,
      Header: () => {
        return <span className="table-header-className">SHIKOYAT MAZMUNI</span>;
      },
      accessor: 'description',
      show: true,
      width: 360,
      overflow: 'hidden',
      paddingLeft: '5px',
    },
    {
      id: 2,
      Header: () => {
        return <span className="table-header-className">F.I.SH</span>;
      },
      accessor: 'fullname',
      show: true,
      width: 200,
    },
    {
      id: 4,
      Header: () => {
        return <span className="table-header-className">SHAHAR-TUMAN</span>;
      },
      accessor: 'regionName',
      show: true,
      width: 200,
    },
    {
      id: 5,
      Header: () => {
        return <span className="table-header-className">M.F.Y</span>;
      },
      accessor: 'districtName',
      show: true,
      width: 200,
    },
    {
      id: 6,
      Header: () => {
        return <span className="table-header-className">SANASI</span>;
      },
      accessor: 'date',
      show: true,
      width: 100,
    },
    {
      id: 7,
      Header: '',
      show: true,
      width: 50,
      Cell: ({ row: { original } }) => {
        return (
          <ActionButton>
            <Button
              className="edit-btn"
              onClick={() => handleClick(original._id)}
              shape="circle"
              icon={<FiEye size={25} />}
            ></Button>
          </ActionButton>
        );
      },
    },
  ];
  return tableHeader;
};

export const mock_data_complaints = Array.from({ length: 10 }).map(
  (_, idx) => ({
    indx: idx + 1,
    _id: 'adasdasdasdjjasdn' + idx,
    fullname: 'Tursunov Anvar Shavkatovich',
    provinceName: 'Fargona viloati',
    regionName: 'Rishton tumani',
    description: 'Uyushmasi tomonidan zarur harbiy-texnik mahsulotlar...',
    districtName: "Toshog'aliq MFY",
    status: 0,
    date: '11.02.2024',
  })
);
