export default {
  SET_PAGE_WORKS_FETCH_DATA: "SET_PAGE_WORKS_FETCH_DATA",
  SET_PAGE_WORKS_SET_DATA: "SET_PAGE_WORKS_SET_DATA",
  PAGE_WORKS_ERROR: "PAGE_WORKS_ERROR",
  PAGE_SET_LOADING: "PAGE_SET_LOADING",
  PAGE_WORKS_SAGA_ONSEARCH_ACTION: "PAGE_WORKS_SAGA_ONSEARCH_ACTION",
  SET_NEW_WORKS_TOTAL: "SET_NEW_WORKS_TOTAL",
  CLEAR_NEW_WORKS_TOTAL: "CLEAR_NEW_WORKS_TOTAL",
  CLEAR_NEW_WORKS_DATA: "CLEAR_NEW_WORKS_DATA",
};
