import styled from 'styled-components';

export const SubElements = styled.div`
  transition: max-height 0.3s;
  max-height: ${({ expand }) => (expand ? '30em' : 0)};
  overflow: hidden;
  padding: 5px 0 0 20px;
`;

export const SubElement = styled.div`
  /* height: 40px; */
  padding: 8px 0;
  display: flex;
  align-items: center;
  padding-left: 51px;
  cursor: pointer;
`;
export const Wrapper = styled.div`
  padding: 0 8px 8px 8px;
`;

export const Container = styled.div`
  padding: ${({ collapsed }) =>
    !collapsed ? '0 var(--sidebar-padding-x)' : '0 17px'};
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  cursor: pointer;
  width: 100%;
  height: 42px;
  background: ${({ active, hovered }) =>
    active || hovered ? 'var(--sidebar-item-active)' : ''};
  position: relative;

  .badge__notification {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff6060;
    position: absolute;
    top: ${({ collapsed }) => (collapsed ? '-13px' : '3.5px')};
    right: ${({ collapsed }) => (collapsed ? '-5%' : '15%')};
    border-radius: 50%;
    font-family: Segoe UI;
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }
`;
Container.Left = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.span`
  font-size: 15px;
  font-family: 'Segoe UI';
  font-weight: 600;
  font-style: normal;
  color: ${({ active, hovered }) =>
    active || hovered
      ? 'var(--sidebar-active-title)'
      : 'var(--sidebar-inactive-title)'};
  opacity: ${({ collapsed }) => (collapsed ? '0' : '1')};
  display: inline-block;
  transition: 0.1s;
  text-wrap: nowrap;
`;

export const IconContainer = styled.div`
  color: ${({ active, hovered }) =>
    active || hovered
      ? 'var(--sidebar-active-title)'
      : 'var(--sidebar-inactive-title)'};
  display: flex;
  align-items: center;
  min-width: 25px;
  svg {
    path {
      stroke: ${({ active, hovered }) =>
        active || hovered ? '#00826C' : '#83849a'};
    }
  }
`;
