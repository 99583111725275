import {
  Col,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Input,
  Button,
} from 'antd';
import React, { useRef, useState } from 'react';
import ActionButton from '../../../components/ActionButton';
import AddButton from '../../../components/AddButton';
import Confirm from '../../../components/Confirm';
import FormCreator from '../../../components/FormCreator';
import Typograpy from '../../../components/Typograpy';
import { useHideModal, useShowAlert, useShowModal } from '../../../hooks';
import { Container, AddContainer } from './style';
import { formData, initialValuesAdd } from './helper';
import { useEffect } from 'react';
import userService from '../../../services/users';
import provinceService from '../../../services/provinces';
import regionService from '../../../services/regions';
import objectService from '../../../services/objects';
import FullScreen from '../../../components/Spinner/FullScreen';
import { FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { setModalLoading } from 'redux/modules/modals/actions';
import { MdOutlinePhonelinkSetup } from 'react-icons/md';
import { BtnGroup } from 'components/ActionButton/style';

export default () => {
  const { lang } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  const { Option } = Select;
  const [users, setUsers] = useState([]);
  const [bool, setBool] = useState([]);
  const [loading, setLoading] = useState(false);
  const { success, error } = useShowAlert();
  const [search, setSearch] = useState(undefined);
  const [searchText, setSearchText] = useState(undefined);
  const rowRef = useRef(null);

  const [sections, setSections] = useState({
    role: [
      // { _id: 'admin', title: 'Admin' },
      { _id: 'leader', title: 'Leader' },
      { _id: 'proleader', title: 'Province leader' },
      { _id: 'regleader', title: 'Region leader' },
      { _id: 'supervisor', title: 'Supervisor' },
      { _id: 'prosupervisor', title: 'Province supervisor' },
      { _id: 'regsupervisor', title: 'Region supervisor' },
      { _id: 'advanced', title: 'Mahalla raisi' },
    ],
  });
  const [filterItems, setFilterItems] = useState({
    region: '',
    province: '',
    role: '',
    object: '',
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const { showBlured } = useShowModal();
  const { hideModal } = useHideModal();

  const titleHandler = (data = []) => {
    let result = data.map((item) => ({
      ...item,
      title: item?.['title' + lang],
    }));
    return result;
  };

  useEffect(() => {
    const payload = {
      page,
      limit: size,
    };
    provinceService
      .getAll(payload)
      .then((res) => {
        setSections((prevSections) => ({
          ...prevSections,
          province: titleHandler(res),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (filterItems.province) {
      regionService
        .getAll({ province: filterItems.province })
        .then((res) => {
          setSections((prevSections) => ({
            ...prevSections,
            regions: titleHandler(res),
            region: titleHandler(res),
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (filterItems.region) {
      const payload = {
        province: filterItems.province,
        region: filterItems.region,
      };
      objectService
        .getAllObjectByRegions(payload)
        .then((resp) =>
          setSections({ ...sections, objects: titleHandler(resp) })
        )
        .catch((err) => console.log(err));
    }
  }, [filterItems.region, filterItems.province]);

  const fetchUserData = () => {
    setLoading(true);
    let data = { limit: size, page: page };
    filterItems?.region !== ''
      ? (data = { ...data, region: filterItems.region })
      : null;
    filterItems?.province !== ''
      ? (data = { ...data, province: filterItems.province })
      : null;
    filterItems?.role !== ''
      ? (data = { ...data, role: filterItems.role })
      : null;
    filterItems?.object !== ''
      ? (data = { ...data, district: filterItems.object })
      : null;
    search !== '' ? (data = { ...data, phone: search }) : null;
    searchText !== '' ? (data = { ...data, search: searchText }) : null;
    userService
      .getUsersByFilter(data)
      .then((res) => {
        setTotal(res?.totalDocs);
        let users = res?.docs?.map((item, index) => {
          return {
            ...item,
            phone: '+998' + item?.phone,
            key: index + 1 + (page - 1) * size,
            info: `${item?.info?.version ? item?.info?.version : ''}${
              item?.info?.deviceName ? ', ' + item?.info?.deviceName : ''
            }${item?.info?.appVersion ? ', ' + item?.info?.appVersion : ''}`,
          };
        });
        setUsers(users);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (search || searchText) {
      const timeoutId = setTimeout(() => {
        fetchUserData();
      }, 700);
      return () => clearTimeout(timeoutId);
    } else {
      fetchUserData();
    }
  }, [bool, filterItems, page, size, searchText, search]);

  const changeData = (data) => {
    if (data?.role == 'leader' || data?.role === 'supervisor') {
      return {
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        password: data.password,
        active: data.active,
        phone: data.phone,
        role: data.role,
        avatar: data.avatar,
      };
    }

    if (data?.role === 'proleader' || data?.role === 'prosupervisor') {
      return {
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        password: data.password,
        active: data.active,
        phone: data.phone,
        role: data.role,
        avatar: data.avatar,
        province: data.province,
      };
    }
    if (data?.role === 'regleader' || data?.role === 'regsupervisor') {
      return {
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        password: data.password,
        active: data.active,
        phone: data.phone,
        role: data.role,
        avatar: data.avatar,
        province: data.province,
        region: data.region,
      };
    }
    if (data?.role === 'advanced') {
      return {
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        password: data.password,
        active: data.active,
        phone: data.phone,
        role: data.role,
        avatar: data.avatar,
        province: data.province,
        region: data.region,
        district: data.district,
      };
    }
  };

  const changeUpdateData = (data) => {
    if (data?.role == 'leader' || data?.role === 'supervisor') {
      return {
        firstName: data?.firstName,
        lastName: data?.lastName,
        middleName: data?.middleName,
        password: data?.password,
        active: data?.active,
        phone: data?.phone,
        role: data?.role,
        avatar: data?.avatar,
      };
    }

    if (data?.role === 'proleader' || data?.role === 'prosupervisor') {
      return {
        firstName: data?.firstName,
        lastName: data?.lastName,
        middleName: data?.middleName,
        password: data?.password,
        active: data?.active,
        phone: data?.phone,
        role: data?.role,
        avatar: data?.avatar,
        province: data?.province?._id,
      };
    }
    if (data?.role === 'regleader' || data?.role === 'regsupervisor') {
      return {
        firstName: data?.firstName,
        lastName: data?.lastName,
        middleName: data?.middleName,
        password: data?.password,
        active: data?.active,
        phone: data?.phone,
        role: data?.role,
        avatar: data?.avatar,
        province: data?.province?._id,
        region: data?.region?._id,
      };
    }
    if (data?.role === 'advanced') {
      return {
        firstName: data?.firstName,
        lastName: data?.lastName,
        middleName: data?.middleName,
        password: data?.password,
        active: data?.active,
        phone: data?.phone,
        role: data?.role,
        avatar: data?.avatar,
        province: data?.province?._id,
        region: data?.region?._id,
        district: data?.district?._id,
      };
    }
  };
  // Create
  const sendData = (data) => {
    hideModal();
    setLoading(true);
    const formData = new FormData();
    let changeFormData = changeData(data);
    Object.keys(changeFormData).forEach((key) => {
      if (changeFormData[key]) {
        formData.append(key, changeFormData[key]);
      }
    });
    userService
      .create(formData)
      .then(() => {
        setBool(!bool);
        success("Foydalanuvchi ma'lumoti saqlandi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Foydalanuvchi ma'lumotini saqlashda xatolik", 2, 'bottom');
      });
  };

  const addUser = () => {
    showBlured({
      title: "Foydalanuvchi qo'shish",
      maxWidth: '650px',
      height: '600px',
      withHeader: false,
      body: () => (
        <FormCreator
          formData={formData}
          cancel={hideModal}
          sendData={sendData}
          selectOptions={sections}
          id=""
          initialVal={initialValuesAdd}
        />
      ),
    });
  };
  // Update
  const updateData = (id, data) => {
    hideModal();
    setLoading(true);
    const formData = new FormData();
    let changeFormData = changeData(data);
    Object.keys(changeFormData).forEach((key) => {
      if (changeFormData[key]) {
        formData.append(key, changeFormData[key]);
      }
    });
    userService
      .update(id, formData)
      .then(() => {
        setBool(!bool);
        success("Foydalanuvchi ma'lumoti yangilandi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Foydalanuvchi ma'lumoti yangilashda xatolik", 2, 'bottom');
      });
  };

  const handleEdit = (data) => {
    rowRef.current = data;
    setLoading(true);
    userService
      .getOne(data._id)
      .then((resp) => {
        setLoading(false);
        showBlured({
          title: 'Foydalanuvchini tahrirlash',
          maxWidth: '650px',
          height: '600px',
          withHeader: false,
          body: () => (
            <FormCreator
              formData={formData}
              cancel={hideModal}
              sendData={updateData}
              selectOptions={sections}
              id={resp?._id}
              initialVal={changeUpdateData(resp)}
              deleteImage={deleteImage}
            />
          ),
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        error("Foydalanuvchi ma'lumotini olishda xatolik", 2, 'bottom');
      });
  };

  // Delete
  const deleteUser = (id) => {
    hideModal();
    dispatch(setModalLoading(true));
    userService
      .delete(id)
      .then(() => {
        setBool(!bool);
        success("Foydalanuvchi ma'lumoti o'chirildi", 2, 'bottom');
      })
      .catch((err) => {
        console.log(err);
        error("Foydalanuvchi ma'lumotini o'chirishda xatolik", 2, 'bottom');
      })
      .finally(() => {
        dispatch(setModalLoading(false));
      });
  };

  const handleDelete = (data) => {
    showBlured({
      title: "O'chirish",
      maxWidth: '400px',
      height: '300px',
      withHeader: false,

      body: () => <Confirm agree={() => deleteUser(data)} cancel={hideModal} />,
    });
  };
  const handleDeleteMacAddress = (data) => {
    showBlured({
      title: "Mac adresni o'chirish",
      maxWidth: '400px',
      height: '300px',
      withHeader: false,

      body: () => (
        <Confirm agree={() => deleteMacAddress(data)} cancel={hideModal} />
      ),
    });
  };
  const deleteMacAddress = (id) => {
    hideModal();
    userService
      .deleteMacAddress(id)
      .then(() => {
        setBool(!bool);
        success("Foydalanuvchi mac adresi o'chirildi", 2, 'bottom');
      })
      .catch((err) => {
        console.log(err);
        error("Foydalanuvchi mac adresini o'chirishda xatolik", 2, 'bottom');
      });
  };

  const deleteImage = (userId, formik, name) => {
    // dispatch(setModalLoading(true));
    userService
      .deleteAvatarImg(userId)
      .then(() => {
        success("Rasm o'chirildi", 2, 'bottom');
        formik.setFieldValue(name, '');
      })
      .catch((err) => {
        console.log(err);
        error("Rasm o'chirishda xatolik", 2, 'bottom');
      });
    // .finally(() => {
    //   dispatch(setModalLoading(false));
    // });
  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (
  //       (search !== undefined && search?.length === 0) ||
  //       search?.length > 3
  //     ) {
  //       setPage(1);
  //       fetchUserData();
  //     }
  //   }, 500);

  //   return () => clearTimeout(timeout);
  // }, [search]);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (
  //       (searchText !== undefined && searchText?.length === 0) ||
  //       searchText?.length > 3
  //     ) {
  //       fetchUserData();
  //       setPage(1);
  //     }
  //   }, 500);

  //   return () => clearTimeout(timeout);
  // }, [searchText]);

  const columns = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Familiya',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Ism',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Viloyat',
      key: 'province',
      render: (_, row) => {
        return row && row.province?.['title' + lang];
      },
    },
    {
      title: 'Tuman/Shahar',
      key: 'region',
      render: (_, row) => {
        return row && row.region?.['title' + lang];
      },
    },
    {
      title: 'MFY',
      // dataIndex: "object",
      key: 'district',
      width: 150,
      render: (_, row) => row && row.district?.['title' + lang],
    },
    {
      title: 'Info',
      dataIndex: 'info',
      key: 'info',
      width: 150,
      align: 'center',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Telefon raqami',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
    },
    {
      width: '15%',
      align: 'center',
      title: 'Amal',
      dataIndex: '',
      key: 'x',
      render: (props) => (
        <ActionButton
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          props={props}
        />
      ),
    },
  ];

  const popColumn = columns.pop();
  const newColumns =
    filterItems?.role === 'advanced'
      ? [
          ...columns,
          {
            width: '10%',
            align: 'center',
            title: 'Mac adresni tozalash',
            dataIndex: '',
            key: 'macAddress',
            render: (props) => {
              return props?.macAddress?.length ? (
                <BtnGroup>
                  <Button
                    className="edit-btn"
                    onClick={() =>
                      props?._id && handleDeleteMacAddress(props?._id)
                    }
                    shape="circle"
                    icon={<MdOutlinePhonelinkSetup size={25} />}
                  />
                </BtnGroup>
              ) : null;
            },
          },
          popColumn,
        ]
      : [...columns, popColumn];

  if (loading) return <FullScreen />;

  // const combineData = (users) => {
  //   const newData = users.map((item) => {
  //     // const region =
  //     //   sections.region &&
  //     //   sections.region.find((itm) => itm._id === item.region);
  //     // const group =
  //     //   sections.group &&
  //     //   sections.group.find((itm) => itm._id === (item.group && item.group[0]));
  //     return {
  //       ...item,
  //       // region: region?.title,
  //       // group: item.group && item.group.length === 1 && group?.title,
  //       info:
  //         item?.info?.appVersion != ''
  //           ? item?.info?.version + ', ' + 'App:' + item?.info?.appVersion
  //           : '',
  //     };
  //   });
  //   return newData
  //     .sort((a, b) => (a.region < b.region ? -1 : 1))
  //     .map((item, ind) =>
  //       page == 1
  //         ? { ...item, key: ind + 1 }
  //         : { ...item, key: (page - 1) * size + ind + 1 }
  //     );
  // };

  const onShowSizeChange = (current, pageSize) => {
    setSize(() => pageSize);
    if (current > 1) setPage(1);
  };

  const handleSelect = (value, name) => {
    setPage(1);
    setFilterItems((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  };

  return (
    <Container>
      <AddContainer>
        <AddButton text="Foydalanuvchi" addData={addUser} />
      </AddContainer>
      <Typograpy color="#080936">Foydalanuvchilar </Typograpy>

      <Row>
        <Col span={24}>
          <Space>
            Viloyat
            <Select
              value={filterItems?.province != '' ? filterItems?.province : null}
              allowClear
              onChange={(value) => {
                handleSelect(value, 'province');
                handleSelect('', 'region');
                handleSelect('', 'object');
              }}
            >
              {sections.province?.map((item, ind) => (
                <Option key={ind + 1} value={item._id}>
                  {item.title}
                </Option>
              ))}
            </Select>
            Shahar (Tuman)
            <Select
              value={filterItems?.region != '' ? filterItems?.region : null}
              style={{ maxWidth: 300, minWidth: 200 }}
              allowClear
              onChange={(value) => {
                handleSelect(value, 'region');
                handleSelect('', 'object');
              }}
            >
              {sections.regions?.map((item, ind) => (
                <Option key={ind + 1} value={item._id}>
                  {item.title}
                </Option>
              ))}
            </Select>
            Role
            <Select
              value={filterItems?.role != '' ? filterItems?.role : null}
              allowClear
              onChange={(value) => handleSelect(value, 'role')}
            >
              {sections.role?.map((item, ind) => (
                <Option key={ind + 1} value={item._id}>
                  {item.title}
                </Option>
              ))}
            </Select>
            MFY
            <Select
              value={filterItems?.object != '' ? filterItems?.object : null}
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? '').includes(
                  input.toLowerCase()
                )
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              onChange={(value) => handleSelect(value, 'object')}
              options={sections?.objects?.map((item) => ({
                label: item?.title,
                value: item?._id,
              }))}
            ></Select>
            <Input
              placeholder="Telefon bo'yicha qidiruv..."
              allowClear
              prefix={<FiSearch color="#CCCCCC" size={12} />}
              className="search"
              style={{
                background: '#FFFFFF',
                border: '1px solid rgba(0, 0, 0, 0.07)',
                borderRadius: '10px',
                height: '42px',
                fontSize: '14px',
                minWidth: '160px',
              }}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <Input
              placeholder="Ism yoki familiya orqali..."
              allowClear
              prefix={<FiSearch color="#CCCCCC" size={12} />}
              className="search"
              style={{
                background: '#FFFFFF',
                border: '1px solid rgba(0, 0, 0, 0.07)',
                borderRadius: '10px',
                height: '42px',
                fontSize: '14px',
                minWidth: '160px',
              }}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
            />
          </Space>
        </Col>
      </Row>

      <Table
        bordered
        columns={newColumns}
        dataSource={users}
        // dataSource={combineData(users)} // combineData nega kerakligini bilmadim
        pagination={false}
      />
      {total / size > 1 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <Pagination
            total={total}
            current={page}
            defaultPageSize={size}
            showSizeChanger
            onChange={(current, newSize) => {
              setPage(newSize !== size ? 1 : current);
            }}
            onShowSizeChange={(currentPage, newSize) => {
              onShowSizeChange(currentPage, newSize);
            }}
          />
        </div>
      )}
    </Container>
  );
};
