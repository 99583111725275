import { service } from '.';

export default {
  getAll: (data) => service.post('/web/specialTask/user', data),
  create: (data) => service.post('/web/specialTask', data),
  getOne: (data) => service.post('/web/specialTask/byTask', data),
  getShowTask: (data) => service.post('/web/specialWorks/getOne', data),

  getAllTasks: (data) => service.post('/web/specialTask/userTask', data),
  getDoneTask: (data) => service.post('/web/specialWorks', data),
};
