import React, { useEffect, useState } from 'react';
import { CardsWrapper, NoData, OnlineLibraryWrap } from '../style';
import BookCard from 'components/Cards/BookCard';
import Spinner from 'components/Spinner';
import { Empty, Pagination } from 'antd';
import book from 'services/book';

const Library = () => {
  const [allTotal, setAllTotal] = useState(0);
  const [allData, setAllData] = useState([]);
  const [paginate, setPaginate] = useState({
    limit: 12,
    page: 1,
  });
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const resData = await book.getAllReport(paginate);
      setAllTotal(resData?.totalDocs);
      const finallyData = resData?.docs?.map((item) => ({
        ...item,
        read: item?.readingBook,
        completed: item?.readBook,
      }));
      setAllData(finallyData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChange = (pageNumber, limit) => {
    setPaginate({
      limit: limit,
      page: pageNumber,
    });
  };

  if (loading) return <Spinner maxHeight={'100%'} />;
  return (
    <OnlineLibraryWrap>
      {allData?.length ? (
        <CardsWrapper>
          {allData?.map((item, index) => (
            <BookCard data={item} key={index} hideDelete />
          ))}
        </CardsWrapper>
      ) : (
        <NoData>
          <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
        </NoData>
      )}
      {allTotal > 12 && (
        <div className="pagination-wrap">
          <Pagination
            defaultCurrent={paginate?.page}
            total={allTotal}
            defaultPageSize={paginate?.limit}
            pageSizeOptions={[12, 20, 25]}
            onChange={onChange}
          />
        </div>
      )}
    </OnlineLibraryWrap>
  );
};

export default Library;
