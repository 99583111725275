import { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { IoPlay } from 'react-icons/io5';
import { MdOutlinePause } from 'react-icons/md';
import { AudioWrap } from './style';

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: '#eee',
  progressColor: 'rgb(0, 130, 108)',
  cursorColor: 'rgb(0, 130, 108)',
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 16,
  normalize: true,
  partialRender: true,
  backend: 'WebAudio',
});

export default ({ url }) => {
  const waveformRef = useRef(null);
  const waveSurfer = useRef(null);
  const [playing, setPlay] = useState(false);

  useEffect(() => {
    const options = formWaveSurferOptions(waveformRef?.current);
    waveSurfer.current = WaveSurfer.create(options);
    waveSurfer.current.on('finish', () => {
      waveSurfer.current?.setTime(0);
      setPlay(() => false);
    });
    url && waveSurfer.current.load(url);
    return () => waveSurfer?.current?.destroy();

    // eslint-disable-next-line
  }, [url]);

  const handlePlayPause = () => {
    setPlay(!playing);
    if (waveSurfer.current) {
      waveSurfer.current.playPause();
    }
  };

  return (
    <AudioWrap>
      <div className="voice-content">
        <button onClick={handlePlayPause}>
          {playing ? (
            <MdOutlinePause color="rgb(0, 130, 108)" size={20} />
          ) : (
            <IoPlay color="rgb(0, 130, 108)" size={20} />
          )}
        </button>
        <div id="waveform" ref={waveformRef} />
      </div>
    </AudioWrap>
  );
};
