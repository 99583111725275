import React, { useEffect, useMemo } from 'react';
import Table from 'components/Tables/ExpandedTable';
import { headerMaker } from 'components/Tables/ExpandedTable/helper';
import { specialTaskResultHeader } from 'redux/modules/table/common';
import { NoData, Wrapper } from '../style';
import specialTask from 'services/specialTasks';
import { useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';
import moment from 'moment';
import Spinner from 'components/Spinner';
import { useShowAlert, useShowModal } from 'hooks';
import ModalBody from './ModalBody';
import { Empty, Pagination } from 'antd';
import { useSelector } from 'react-redux';
import DownloadToExcell from 'components/DownloadToExcell';

function Detail() {
  const { showBlured } = useShowModal();
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { role } = useSelector((state) => state.authReducer);
  const [cardType, setCardType] = useState('large');
  const [paginate, setPaginate] = useState({
    limit: 10,
    page: 1,
  });
  const [excelData, setExcelData] = useState([]);
  const { success, error } = useShowAlert();

  const screenChecker = (innerWidth) => {
    if (innerWidth >= 1056) {
      setCardType('large');
    } else {
      setCardType('small');
    }
  };

  useEffect(() => {
    screenChecker(window.innerWidth);
    const handleResize = () => screenChecker(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const headers = useMemo(
    () =>
      headerMaker(
        role === 'proleader'
          ? specialTaskResultHeader?.filter(
              (item) => item?.accessor !== 'province'
            )
          : role === 'regleader'
          ? specialTaskResultHeader?.filter(
              (item) =>
                item?.accessor !== 'region' && item?.accessor !== 'province'
            )
          : specialTaskResultHeader
      ),
    [specialTaskResultHeader, role]
  );

  const { id: taskId } = useParams();
  const location = useLocation();

  const fetchData = async () => {
    setLoading(true);
    try {
      const resData = await specialTask?.getOne({ specialTask: taskId });
      const finalData = resData?.map((item, idx) => ({
        id: idx + 1,
        user: item?.lastName + ' ' + item?.firstName,
        district: item?.district?.titleUZ ?? '-',
        province: item?.province?.titleUZ ?? '-',
        region: item?.region?.titleUZ ?? '-',
        endTime: item?.endTime
          ? moment(item?.endTime).format('DD.MM.YYYY')
          : '-',
        uploadTime: item?.uploadTime
          ? moment(item?.uploadTime).format('DD.MM.YYYY HH:mm')
          : '-',
        status: item?.done ? '1' : '0',
        userId: item?._id,
        forReference: location?.state?.forReference,
        taskId: taskId,
        handleShowTask: handleShowTask,
      }));
      setUserData(finalData);
    } catch (err) {
      console.log(err);
      error('Xatolik yuz berdi', 1);
    } finally {
      setLoading(false);
    }
  };

  const handleShowTask = (sendData) => {
    setLoading(true);
    specialTask
      .getShowTask(sendData)
      .then((res) => {
        setLoading(false);
        showBlured({
          maxWidth: cardType === 'large' ? '1080px' : '780px',
          maxHeight: '600px',
          withHeader: true,
          body: () => <ModalBody data={res} />,
        });
      })
      .catch(() => {
        error('Xatolik yuz berdi', 2, 'topRight');
      })
      .finally(() => setLoading(false));
  };

  // const handleChangePaginate = (options) => {
  //   handleOnTableChange(userData, options);
  // };
  const onChange = (pageNumber, limit) => {
    setPaginate({
      limit: limit,
      page: pageNumber,
    });
  };

  const paginatedData = useMemo(() => {
    const start = (paginate?.page - 1) * paginate?.limit;
    const end = start + paginate?.limit;
    return userData?.slice(start, end);
  }, [userData, paginate]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchDataExcel = async () => {
    success("Ma'lumotlar tayyorlanmoqda.", 1);
    try {
      const resData = await specialTask?.getOne({ specialTask: taskId });
      const finalData = resData?.map((item, idx) => ({
        id: idx + 1,
        user: item?.lastName + ' ' + item?.firstName,
        district: item?.district?.titleUZ ?? '-',
        province: item?.province?.titleUZ ?? '-',
        region: item?.region?.titleUZ ?? '-',
        endTime: item?.endTime
          ? moment(item?.endTime).format('DD.MM.YYYY')
          : '-',
        uploadTime: item?.uploadTime
          ? moment(item?.uploadTime).format('DD.MM.YYYY HH:mm')
          : '-',
        status: item?.done ? '1' : '0',
        userId: item?._id,
        forReference: location?.state?.forReference,
        taskId: taskId,
        handleShowTask: handleShowTask,
      }));
      setExcelData(finalData);
    } catch (err) {
      console.log(err);
      error("Ma'lumotlarni yuklashda xatolik yuz berdi.", 2);
    }
  };

  if (loading) return <Spinner maxHeight="100%" />;
  return (
    <Wrapper pt="30px">
      <div className="excell__btn">
        <DownloadToExcell
          fileName={'Mahsus topshiriqlar'}
          tableName={`Mahsus topshiriqlar`}
          tableHeader={headers}
          tableData={excelData}
          setExcellData={setExcelData}
          handleFetchData={fetchDataExcel}
        />
      </div>
      {userData?.length ? (
        <Table headers={headers} data={paginatedData} />
      ) : (
        <NoData>
          <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
        </NoData>
      )}
      {userData?.length > 10 && (
        <div className="pagination-wrap">
          <Pagination
            defaultCurrent={paginate?.page}
            total={userData?.length}
            defaultPageSize={paginate?.limit}
            pageSizeOptions={[15, 20, 25]}
            onChange={onChange}
          />
        </div>
      )}
    </Wrapper>
  );
}

export default Detail;
