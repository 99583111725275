export const buttons = [
  {
    id: 2,
    title: "Sohalar bo'yicha",
    value: 'category',
  },
  {
    id: 3,
    title: "Ishlar bo'yicha",
    value: 'subCategory',
  },
  {
    id: 4,
    title: "Rejalar bo'yicha",
    value: 'users',
  },
  {
    id: 5,
    title: "Yordam ko'rsatilganlar",
    value: 'support',
  },
];
