import React from 'react';
import PropTypes from 'prop-types';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Container, PageButtons, Button, ChangeButton, Wrapper } from './style';

import { useButtons } from './useButtons';
import Select from 'components/FormElements/Select';

const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  pageSize,
  setPageSize,
  pageIndex,
}) => {
  const buttons = useButtons({ pageCount, pageIndex });

  const handlePageChange = (title) => {
    if (title !== '...') gotoPage(Number(title));
  };

  return (
    <Wrapper>
      <Container>
        <ChangeButton
          color={!canPreviousPage ? '#a8a8a8' : '#00826C'}
          disabled={!canPreviousPage}
          onClick={previousPage}
        >
          <FiChevronLeft size={24} />
        </ChangeButton>
        <PageButtons repeat={pageCount > 7 ? 7 : pageCount}>
          {buttons.map((title, index) => (
            <Button
              key={`${index + 1}`}
              onClick={() => handlePageChange(title)}
              active={pageIndex == title}
            >
              {title !== '...' ? title + 1 : title}
            </Button>
          ))}
        </PageButtons>
        <ChangeButton
          color={!canNextPage ? '#a8a8a8' : '#00826C'}
          disabled={!canNextPage}
          onClick={nextPage}
        >
          <FiChevronRight size={24} />
        </ChangeButton>
      </Container>
      <Select
        allowClear={false}
        defaultVal={pageSize}
        options={[
          { _id: 10, title: '10 / page' },
          { _id: 20, title: '20 / page' },
          { _id: 50, title: '50 / page' },
          { _id: 100, title: '100 / page' },
        ]}
        handleChange={(value) => setPageSize(value)}
      />
    </Wrapper>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number,
};
Pagination.defaultProps = {
  pageCount: 40,
};

export default Pagination;
