import styled from 'styled-components';
import RightBg from 'assets/images/loginBg.png';
import DashboadrImg from 'assets/images/newDashboard.svg';
import ReportChartImg from 'assets/images/dashboard-chart.png';

export const LoginWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  background-color: #fff;
  overflow: hidden;
`;
export const Left = styled.div`
  width: ${({ width }) => (width === 'true' ? '100%' : '50%')};
  height: 100vh;
  padding: 40px;
  background: #fff;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;

  .logo {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    h1 {
      margin: 0;
      padding: 0;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #080936;
    }
  }
  .login_area {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 470px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    .login__title p {
      color: #080936;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 36px */
      margin: 0;
    }
  }

  .login__form {
    width: 100%;
    height: 100%;
    max-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .input__title {
    width: 100%;
    color: #080936;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: -30px;
    left: 0;
  }
  .form__input {
    display: flex;
    border-radius: 64px;
    border: 1px solid rgba(160, 174, 192, 0.4);
    background: rgba(255, 255, 255, 0.05);
    width: 100%;
    display: flex;
    height: 56px;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-shrink: 0;
    font-size: 20px;
    padding: 0 20px;
    position: relative;

    &:first-child {
      margin-bottom: 50px;
    }
    &:hover {
      border: 1px solid #00826c;
    }
    &:focus {
      border: 1px solid #00826c !important;
    }
  }

  .form__input input {
    padding: 10px 0px;
    border: none;
  }
  .form__input .pass__input {
    font-size: 20px;
  }
  .form__input .input__mask::selection {
    background: #00826c;
  }

  .form__input .input__mask:focus {
    outline: none;
    outline: 0;
  }

  .form__input .input__mask:hover {
    outline: none;
    outline: 0;
  }
  .form__input .ant-input::selection {
    background: #00826c;
  }

  .form__input .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border: none;
    box-shadow: none;
  }

  .addonBefore {
    color: #080936;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }

  .ant-input-affix-wrapper {
    padding: 0;
    border: none;
    outline: none;
    font-size: 20px;
  }

  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:active {
    border: none;
    outline: none;
  }
  .fair__mode {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .reset__pass {
      button {
        cursor: pointer;
        border: none;
        outline: none;
        background: transparent;
        color: #00826c;
        text-align: right;
        font-family: Segoe UI;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 16px */
        &:hover {
          border: none;
          outline: none;
        }
      }
    }
    .check__mode {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      .text {
        color: #a0aec0;
        font-feature-settings: 'clig' off, 'liga' off;
        text-align: right;
        cursor: pointer;
        font-family: Segoe UI;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 16px */
      }
      .ant-checkbox-wrapper {
        /* &:hover {
          border: red;
        } */
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #00826c;
        border-color: #00826c;
        border-radius: 4px;
      }
    }
  }

  .ant-input-affix-wrapper > input.ant-input {
    padding: 0;
    border: none;
    outline: none;
    font-size: 20px;
  }
  .login_btn {
    border-radius: 40px;
    background: linear-gradient(93.13deg, #00b798 18.77%, #00826c 91.67%);
    cursor: pointer;
    width: 100%;
    padding: 16px;
    margin-top: 50px;
    color: #fff;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Segoe UI;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    position: relative;
    z-index: 2;
    border: none;
    outline: none;
    & span {
      position: relative;
      z-index: 3;
    }

    &:hover {
      background: linear-gradient(93.13deg, #00b798 18.77%, #00826c 91.67%);
    }
    &::after {
      content: '';
      width: 100%;
      height: 30%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10%;
      border-radius: 100px;
      opacity: 0.8;
      background: #00826c;
      filter: blur(20px);
      z-index: 1;
    }
  }
  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    max-width: 500px;
    .qr__code {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        color: #a0aec0;
        text-align: center;
        padding: 0;
        margin: 0;
        font-family: Segoe UI;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 16px */
      }
    }
    .websites {
      width: 100%;
      height: 100%;
      max-width: 170px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      gap: 36px;
    }
  }
`;
export const Right = styled.div`
  width: ${({ width }) => (width === 'true' ? '0%' : '50%')};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  padding: 40px;
  box-sizing: border-box;
  z-index: 111;

  .right-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 80px);
    background: url(${RightBg});
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    background-position: center center;
  }
  .img__dashboard {
    width: 85%;
    height: 75%;
    background-image: url(${DashboadrImg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
    margin-top: 15%;
    position: absolute;
    right: 0;
    z-index: 5;
    @media (max-width: 1600px) {
      right: 0;
      height: 64%;
    }
    @media (max-width: 1200px) {
      display: none;
    }
  }
`;
