import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 15px;
`;

export const BtnWrap = styled.div`
  & button {
    background: ${({ background }) =>
      background === 'true' ? 'var(--custom-primary)' : 'transparent'};
    border: 1px solid var(--custom-primary);
    color: ${({ background }) =>
      background !== 'true' ? 'var(--custom-primary)' : 'white'};
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    border-color: var(--custom-primary);
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background: var(--custom-primary);
  }
`;

export const WithDaysWrap = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 15px;
  font-weight: 600;

  .ant-picker {
    border-radius: 10px !important;
    height: 32px !important;
    border: 1px solid #d9d9d9;
  }
`;

export const WithWeekDay = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 15px;
  font-weight: 600;
  .ant-picker {
    border-radius: 10px !important;
  }
  .ant-select-selector {
    min-width: 100% !important;
    min-height: 32px !important;
  }
  .custom-input {
    border-radius: 10px;
  }
`;

export const DefaultWrap = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 15px;
  font-weight: 600;

  .ant-picker {
    border-radius: 10px !important;
    height: 32px !important;
  }
  .custom-input {
    border-radius: 10px;
  }
`;
