// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
  SET_SELECTED_DATE_SCHEDULE: 'SET_SELECTED_DATE_SCHEDULE',
  SET_SINGLE_DATE: 'SET_SINGLE_DATE',
  SET_DETERMINED_DATE: 'SET_DETERMINED_DATE',
  CLEAR_SELECTED_DATE: 'CLEAR_SELECTED_DATE',
  CLEAR_SELECTED_DATE_SCHEDULE: 'CLEAR_SELECTED_DATE_SCHEDULE',
  HIDE_RANGE_PICKER_IN_group: 'HIDE_RANGE_PICKER_IN_group',
  SET_SUPERVISOR_DATE: 'SET_SUPERVISOR_DATE',
};
