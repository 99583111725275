import Table from 'components/Tables/ExpandedTable';
import React, { useEffect, useState } from 'react';
import { NoData, TableHead, TableWrapper } from './style';
import { ReactComponent as CompletedIcon } from '../../../../assets/icons/completed.svg';
import { ReactComponent as ErrorQuestionIcon } from '../../../../assets/icons/errorQuestion.svg';
import { LinkWrapper } from '../style';
import { useLocation, useNavigate } from 'react-router-dom';
import { CiShare1 } from 'react-icons/ci';
import { useShowAlert } from 'hooks';
import Spinner from 'components/Spinner';
import quiz from 'services/quiz';
import { Empty, Pagination } from 'antd';

export default () => {
  const navigate = useNavigate();
  const location = useLocation().state;
  const { error } = useShowAlert();
  const [totalDocs, setTotalDocs] = useState(0);
  const [paginate, setPaginate] = useState({
    limit: 10,
    page: 1,
  });
  const [loading, setLoading] = useState([]);
  const [data, setData] = useState([]);

  const mainHeadCols = [
    {
      id: 1,
      Header: '№',
      accessor: 'id',
      show: true,
      width: 30,
    },
    {
      id: 2,
      Header: 'MFY',
      accessor: 'district',
      type: 'name',
      width: 300,
    },
    {
      id: 3,
      Header: 'YETAKCHI F.I.O',
      type: 'name',
      width: 300,
      accessor: 'username',
      Cell: ({ cell }) => {
        return (
          <LinkWrapper
            status={cell?.row?.original?.totalQuestions <= 0 ? true : false}
            onClick={() =>
              handleClickRow(
                cell?.row?.original?.userId,
                cell?.row?.original?.district,
                cell?.row?.original?.totalQuestions <= 0 ? true : false
              )
            }
          >
            {cell?.row?.original?.username}{' '}
            {cell?.row?.original?.totalQuestions !== 0 && (
              <CiShare1 size={16} />
            )}
          </LinkWrapper>
        );
      },
    },
    {
      id: 4,
      Header: 'SAVOLLAR SONI',
      type: 'name',
      width: 200,
      align: 'center',
      accessor: 'totalQuestions',
    },
    {
      id: 5,
      Header: () => (
        <TableHead>
          <CompletedIcon />
          {'TO’G’RI JAVOBLAR'}
        </TableHead>
      ),
      accessor: 'trueAnswers',
      type: 'name',
      width: 200,
      align: 'center',
    },
    {
      id: 6,
      Header: () => (
        <TableHead>
          <ErrorQuestionIcon />
          NOTO’G’RI JAVOBLAR
        </TableHead>
      ),
      accessor: 'wrongAnswers',
      show: true,
      align: 'center',
      width: 200,
    },
    {
      id: 7,
      Header: 'NATIJA %',
      accessor: 'result',
      show: true,
      align: 'center',
      width: 150,
    },
  ];

  const handleClickRow = (userId, text, status) => {
    if (!status) {
      navigate('/tests/get-one/region/user', {
        state: {
          value: location.value + ' > ' + text,
          quizId: location?.quizId,
          userId: userId,
        },
      });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const query = `limit=${paginate?.limit}&page=${paginate?.page}&quiz=${location?.quizId}&region=${location?.regionId}`;
      const resData = await quiz.getOneTest(query);
      setTotalDocs(resData?.totalDocs);
      const finallyData = resData?.docs?.map((itm, index) => ({
        id: paginate?.limit * paginate?.page - paginate?.limit + index + 1,
        district: itm?.title?.titleUZ,
        username: `${itm?.user?.firstName ?? ''} ${
          itm?.user?.lastName ?? ''
        }  ${itm?.user?.middleName ?? ''}`,
        userId: itm?.user?._id,
        totalQuestions: itm?.totalQuestions,
        result: itm?.trueAnswersPercent + '%',
        trueAnswers: itm?.trueAnswers,
        wrongAnswers: itm?.wrongAnswers,
      }));
      setData(finallyData);
    } catch (err) {
      console.log(err);
      error('Xatolik yuz berdi');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location?.testId, paginate]);

  const handlePaginate = (page, limit) => {
    setPaginate({
      page: page,
      limit: limit,
    });
  };

  if (loading) {
    return <Spinner maxHeight={'calc(100% - 100px)'} />;
  }
  return (
    <TableWrapper>
      {data?.length ? (
        <>
          <Table headers={mainHeadCols} data={data} />
          {totalDocs > 10 && (
            <div className="pagination custom-pagination-wrapper">
              <Pagination
                current={paginate?.page}
                total={totalDocs}
                onChange={(page, limit) => handlePaginate(page, limit)}
                defaultPageSize={paginate?.limit}
                showSizeChanger
              />
            </div>
          )}
        </>
      ) : (
        <NoData>
          <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
        </NoData>
      )}
    </TableWrapper>
  );
};
