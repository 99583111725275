import { Image } from 'antd';
import React, { useMemo, useState } from 'react';
import './style.css';
const ImageBox = ({ image, images = [] }) => {
  const [visible, setVisible] = useState(false);
  const imagesList = useMemo(
    () => images.filter((item) => item?.isImage),
    [images]
  );
  return (
    <>
      <Image
        preview={{
          visible: false,
        }}
        rootClassName="image"
        src={image}
        onClick={() => setVisible(true)}
      />
      <div
        style={{
          display: 'none',
        }}
      >
        <Image.PreviewGroup
          items={imagesList}
          preview={{
            visible,
            onVisibleChange: (vis) => setVisible(vis),
          }}
        >
          {imagesList.map((item) => {
            return <Image src={item?.image} key={item?.image} />;
          })}
        </Image.PreviewGroup>
      </div>
    </>
  );
};

export default React.memo(ImageBox);
