export const formData = {
  title: "Baholash ko'rsatkichini  qo'shish",
  data: [
    {
      type: 'text',
      label: 'Nomi UZ',
      name: 'titleUZ',
      value: '',
      size: 24,
    },
    {
      type: 'text',
      label: 'Nomi RU',
      name: 'titleRU',
      value: '',
      size: 24,
    },
    {
      type: 'text',
      label: 'Nomi QQ',
      name: 'titleQQ',
      value: '',
      size: 24,
    },
    // { type: 'text', label: 'Изоҳ', name: 'title', value: '', size: 24 },
    { type: 'text', label: 'Ball', name: 'rate', value: '', size: 24 },
    // { type: 'select', label: 'Гуруҳ', name: 'group', value: '', size: 24 },
  ],
};

export const formDataType = {
  title: "baholash turini qo'shish",

  data: [
    { type: 'text', label: 'Izoh', name: 'title', value: '', size: 24 },
    {
      type: 'text',
      label: "Boshlang'ich qiymat",
      name: 'startRange',
      value: '',
      size: 24,
    },
    {
      type: 'text',
      label: 'Oxirgi qiymat',
      name: 'endRange',
      value: '',
      size: 24,
    },
  ],
};
export const supportTypeForm = {
  title: "Yordam ko'rsatish turini qo'shish",
  data: [
    {
      type: 'text',
      label: 'Nomi UZ',
      name: 'titleUZ',
      value: '',
      size: 24,
    },
    {
      type: 'text',
      label: 'Nomi RU',
      name: 'titleRU',
      value: '',
      size: 24,
    },
    {
      type: 'text',
      label: 'Nomi QQ',
      name: 'titleQQ',
      value: '',
      size: 24,
    },
  ],
};
export const paymentPlantForm = {
  title: "To'lov tarifini qo'shish",

  data: [
    {
      type: 'text',
      label: 'Tarif muddati (oy)',
      name: 'term',
      value: '',
      size: 24,
    },
    {
      type: 'text',
      label: 'Summasi (UZS)',
      name: 'price',
      value: '',
      size: 24,
    },
  ],
};

export const paymentPlanInitial = {
  term: '',
  price: '',
};
// rate
export const initialValuesRateAdd = {
  titleUZ: '',
  titleRU: '',
  titleQQ: '',
  rate: '',
  // group: '',
};
export const initialValuesAddType = {
  title: '',
  startRange: '',
  endRange: '',
};
export const buttons = [
  {
    id: 0,
    title: 'Ishni baholash',
    value: 'rate',
  },
  // {
  //   id: 1,
  //   title: 'Баҳолаш тури',
  //   value: 'ratetype',
  // },
  {
    id: 2,
    title: 'Dam olish kunlari',
    value: 'weekend',
  },
  {
    id: 3,
    title: 'Telegram guruh',
    value: 'telegram',
  },
  // {
  //   id: 4,
  //   title: 'Mac Address',
  //   value: 'macaddress',
  // },
  {
    id: 5,
    title: "Yordam ko'rsatish turi",
    value: 'supportType',
  },
  {
    id: 4,
    title: "To'lov tariflari",
    value: 'paymentPlan',
  },
];
export const names = {
  rate: 'Ish baholash',
  ratetype: 'Baholash turi',
  weekend: 'Dam olish kunlari',
  telegram: 'Telegram guruh',
  macaddress: 'Max Address',
  paymentPlan: "To'lov tariflari",
  supportType: "Yordam ko'rsatish turi",
};

export const roles = [
  { _id: 'leader', title: 'Respublika' },
  { _id: 'proleader', title: 'Viloyat' },
  { _id: 'regleader', title: 'Shaxar/Tuman' },
];

export const timeOptions = [
  {
    title: '08:00',
    _id: '08:00',
  },
  {
    title: '08:30',
    _id: '08:30',
  },
  {
    title: '09:00',
    _id: '09:00',
  },
  {
    title: '09:30',
    _id: '09:30',
  },
  {
    title: '10:00',
    _id: '10:00',
  },
  {
    title: '10:30',
    _id: '10:30',
  },
  {
    title: '11:00',
    _id: '11:00',
  },
  {
    title: '11:30',
    _id: '11:30',
  },
  {
    title: '12:00',
    _id: '12:00',
  },
  {
    title: '12:30',
    _id: '12:30',
  },
  {
    title: '13:00',
    _id: '13:00',
  },
  {
    title: '13:30',
    _id: '13:30',
  },
  {
    title: '14:00',
    _id: '14:00',
  },
  {
    title: '14:30',
    _id: '14:30',
  },
  {
    title: '15:00',
    _id: '15:00',
  },
  {
    title: '15:30',
    _id: '15:30',
  },
  {
    title: '16:00',
    _id: '16:00',
  },
  {
    title: '16:30',
    _id: '16:30',
  },
  {
    title: '17:00',
    _id: '17:00',
  },
  {
    title: '17:30',
    _id: '17:30',
  },
  {
    title: '18:00',
    _id: '18:00',
  },
  {
    title: '18:30',
    _id: '18:30',
  },
  {
    title: '19:00',
    _id: '19:00',
  },
  {
    title: '19:30',
    _id: '19:30',
  },
  {
    title: '20:00',
    _id: '20:00',
  },
];
