import React, { useEffect, useRef } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { RiFileExcel2Line } from 'react-icons/ri';
import { ExcelContainer, HiddenTable } from './style';
import Table from 'components/Tables/ReportTable';
import Scheduletable from './CustomTables/Scheduletable';
import LibraryTable from './CustomTables/LibraryTable';
import Scheduletable2 from './CustomTables/Scheduletable2';

const ButtonTex = ({ onClick }) => (
  <button
    id="my-id"
    className="for-button"
    onClick={onClick}
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
    }}
  >
    {' '}
    <RiFileExcel2Line size={20} />
    <div>Excelga yuklash</div>
  </button>
);

const ExcelTable = ({
  tableName,
  tableData = [],
  tableHeader = [],
  fileName,
  setExcellData,
  handleFetchData = () => {},
}) => {
  const exampleRef = useRef();

  useEffect(() => {
    if (tableData.length > 0) {
      exampleRef.current.handleDownload();
      setExcellData(() => []);
    }
  }, [tableData]);
  
  const handleClick = () => {
    handleFetchData();
  };

  const tableRender = (name_table) => {
    switch (name_table) {
      case 'payments':
        return <Table data={tableData} headers={tableHeader} id={tableName} />;
      case 'online_library_report':
        return (
          <LibraryTable data={tableData} headers={tableHeader} id={tableName} />
        );
      case 'schedule':
        return (
          <Scheduletable
            data={tableData}
            headers={tableHeader}
            id={tableName}
          />
        );
      case 'schedule_table':
        return (
          <Scheduletable2
            data={tableData}
            headers={tableHeader}
            id={tableName}
          />
        );
      default:
        return <Table data={tableData} headers={tableHeader} id={tableName} />;
    }
  };

  return (
    <ExcelContainer>
      <ButtonTex onClick={handleClick} className="download-table-xls-button" />
      <HiddenTable>
        <ReactHtmlTableToExcel
          ref={exampleRef}
          id="test-table-xls-button"
          className="download-table-xls-button"
          table={tableName}
          filename={fileName}
          sheet={fileName}
          buttonText="download to excel XLS"
        />
        {tableRender(tableName)}
      </HiddenTable>
    </ExcelContainer>
  );
};
export default React.memo(ExcelTable);
