import types from "../../../constants/action-types";


export const setNavbarTitle = (payload) => ({
  type: types.APPLICATION_ACTIVE_PAGE_TITLE,
  payload,
});
export const setDefaultTitle = (payload) => ({
  type: types.APPLICATION_SET_DEFAULT_PAGE_TITLE,
  payload,
});