import { service } from '.';

export default {
  getAllHeader: () => service.get('/web/reports/cardChart'),
  getPieChart: () => service.get('/web/reports/doughnutChart'),
  getAreaChart: (body) => service.post('/web/reports/lineChart', body),
  getBarChart: () => service.get('/web/reports/barChart'),
  getCategoryChart: () => service.get('/web/reports/categoryChart'),
  getMonthChart: (body) => service.post('/web/webReport/doughnutPlan', body),
};
