import React, { useMemo } from 'react';
import { DatePicker, Col, Row, Button } from 'antd';
import Wrapper from '../../../components/Wrapper';
import Table from '../../../components/Tables/ReportTable';
import Typograpy from '../../../components/Typograpy';
import { statisticsHeader } from '../../../redux/modules/table/common';
import { headerMaker } from '../../../components/Tables/ExpandedTable/helper';
import { mockData } from './helper';
import Select from '../../../components/FormElements/Select';
import { Title } from '../../../components/FormElements/Select/style';

const { RangePicker } = DatePicker;

export default () => {
  const headers = useMemo(
    () => headerMaker(statisticsHeader),
    [statisticsHeader]
  );
  const handleChange = (value) => {};
  const handleChangeRangePicker = (_, value) => {};

  return (
    <Wrapper>
      <Typograpy>Барча сохалар буйича курсаткичлар</Typograpy>
      <Row gutter={[10, 10]} align='bottom'>
        <Col>
          <Select
            handleChange={handleChange}
            options={[]}
            title='Шаҳар(туман) ни танланг'
          />
        </Col>
        <Col>
          <Select
            handleChange={handleChange}
            options={[]}
            title='Гуруҳни танланг'
          />
        </Col>
        <Col>
          <Select
            handleChange={handleChange}
            options={[]}
            title='Сохани танланг'
          />
        </Col>
        <Col>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Title>Даврни танлаш</Title>
            <RangePicker onChange={handleChangeRangePicker} />
          </div>
        </Col>
        <Col>
          <Button
            style={{ backgroundColor: '#00b5e4', border: 'none' }}
            type='primary'
          >
            Қидириш
          </Button>
        </Col>
      </Row>
      <Table data={mockData} headers={headers} height='600px' />
    </Wrapper>
  );
};
