import React from 'react';

export default ({ color }) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 26 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M26 1.3C26 0.58203 25.418 0 24.7 0H15.4629C14.7449 0 14.1629 0.58203 14.1629 1.3V13.5257C14.1629 14.2436 14.7449 14.8257 15.4629 14.8257H24.7C25.418 14.8257 26 14.2436 26 13.5257V1.3ZM1.3 0C0.582029 0 0 0.58203 0 1.3V7.70242C0 8.42039 0.58203 9.00242 1.3 9.00242H10.5371C11.2551 9.00242 11.8371 8.42039 11.8371 7.70242V1.3C11.8371 0.582029 11.2551 0 10.5371 0H1.3ZM11.8371 12.4743C11.8371 11.7564 11.2551 11.1743 10.5371 11.1743H1.3C0.582029 11.1743 0 11.7564 0 12.4743V24.7C0 25.418 0.58203 26 1.3 26H10.5371C11.2551 26 11.8371 25.418 11.8371 24.7V12.4743ZM26 18.2976C26 17.5796 25.418 16.9976 24.7 16.9976H15.4629C14.7449 16.9976 14.1629 17.5796 14.1629 18.2976V24.7C14.1629 25.418 14.7449 26 15.4629 26H24.7C25.418 26 26 25.418 26 24.7V18.2976Z'
      fill={color ? color : '#F4F2FF'}
    />
  </svg>
);
