import React, { useEffect, useState } from 'react';
import {
  ActionsWrapper,
  Button,
  LinkWrapper,
  ManageWrapper,
  NoData,
  PopoverContent,
  StatusWrapper,
  TestsWrapper,
} from './style';
import { FiPlus } from 'react-icons/fi';
import Table from '../../../components/Tables/ExpandedTable';
import { testManageColor, testStatusColor } from 'constants/colors';
import { manageName, statusName } from './helper';
import { Empty, Pagination, Popover } from 'antd';

import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DuplicateIcon } from '../../../assets/icons/duplicate.svg';
import { useNavigate } from 'react-router-dom';
import { useShowAlert, useShowModal } from 'hooks';
import Publishing from './Publishing';
import { CiShare1 } from 'react-icons/ci';
import quiz from 'services/quiz';
import Spinner from 'components/Spinner';
import Duplicate from './Duplicate';
import Completed from './Completed';
import { useSelector } from 'react-redux';

export default () => {
  const navigate = useNavigate();
  const { showBlured } = useShowModal();
  const [totalDocs, setTotalDocs] = useState(0);
  const [paginate, setPaginate] = useState({
    limit: 10,
    page: 1,
  });
  const [loading, setLoading] = useState(false);
  const [testData, setTestData] = useState([]);
  const { error } = useShowAlert();
  const { role, regionId, regionTitle } = useSelector(
    (state) => state.authReducer
  );

  const mainHeadCols = [
    {
      id: 1,
      Header: '№',
      accessor: 'id',
      show: true,
      width: 30,
    },
    {
      id: 3,
      Header: 'NOMI',
      accessor: 'title',
      type: 'name',
      width: 500,
      Cell: ({ cell }) => {
        return (
          <LinkWrapper
            status={cell?.row?.original?.status === 1}
            onClick={() =>
              handleClickRow(
                cell?.row?.original?._id,
                cell?.row?.original?.title,
                cell?.row?.original?.status
              )
            }
          >
            {cell?.row?.original?.title}{' '}
            {cell?.row?.original?.status !== 1 && <CiShare1 size={16} />}
          </LinkWrapper>
        );
      },
    },
    {
      id: 4,
      Header: 'HOLATI',
      type: 'name',
      width: 200,
      accessor: 'status',
      Cell: ({ cell: { value } }) => {
        return (
          <StatusWrapper color={testStatusColor[value]}>
            {statusName[value]}
          </StatusWrapper>
        );
      },
    },
    {
      id: 5,
      Header: 'BOSHQARISH',
      accessor: 'status',
      type: 'name',
      width: 200,
      Cell: ({ cell }) => {
        return (
          cell?.value !== 3 && (
            <ManageWrapper
              color={testManageColor[cell.value]}
              onClick={() =>
                cell?.value === 1
                  ? handlePublishing(cell?.row?.original?._id)
                  : handleCompleted(cell?.row?.original?._id)
              }
            >
              {manageName[cell?.value]}
            </ManageWrapper>
          )
        );
      },
    },
    {
      id: 2,
      Header: 'AMALLAR',
      accessor: '_id',
      show: true,
      align: 'center',
      width: 200,
      Cell: ({ cell }) => {
        return (
          <ActionsWrapper>
            {cell?.row?.original?.status === 1 && (
              <Popover
                content={<PopoverContent>Tahrirlash</PopoverContent>}
                trigger="hover"
              >
                <div
                  className="icon"
                  onClick={() =>
                    navigate('/tests/create', {
                      state: {
                        value: 'Test yaratish',
                        id: cell?.row?.original?._id,
                      },
                    })
                  }
                  aria-hidden={true}
                >
                  <EditIcon />
                </div>
              </Popover>
            )}
            <Popover
              content={<PopoverContent>Nusxa olish</PopoverContent>}
              trigger="hover"
            >
              <div
                className="icon"
                onClick={() => handleDuplicate(cell?.row?.original?._id)}
                aria-hidden={true}
              >
                <DuplicateIcon />
              </div>
            </Popover>
          </ActionsWrapper>
        );
      },
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const body = {
        ...paginate,
      };
      const resTest = await quiz.getAllWithPaginate(body);
      setTotalDocs(resTest?.totalDocs);
      const finallyData = resTest.docs?.map((item, index) => ({
        ...item,
        id: paginate?.limit * paginate?.page + index + 1 - paginate?.limit,
      }));
      setTestData(finallyData);
    } catch (err) {
      console.log(err);
      error('Xatolik yuz berdi');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [paginate]);

  const handleDuplicate = (id) => {
    showBlured({
      title: '',
      maxWidth: '547px',
      maxHeight: '687px',
      body: () => <Duplicate testId={id} fetchData={fetchData} />,
    });
  };

  const handleCompleted = (id) => {
    showBlured({
      title: '',
      maxWidth: '547px',
      maxHeight: '687px',
      body: () => <Completed testId={id} fetchData={fetchData} />,
    });
  };

  const handlePublishing = (id) => {
    showBlured({
      title: 'Testni nashr qilish',
      maxWidth: '547px',
      maxHeight: '687px',
      body: () => <Publishing testId={id} fetchData={fetchData} />,
    });
  };

  const handleClickRow = (id, text, status) => {
    if (status !== 1) {
      if (role === 'regleader') {
        navigate('/tests/get-one/region', {
          state: {
            value: 'Testlar' + ' > ' + text + ' > ' + regionTitle,
            regionId: regionId,
            quizId: id,
          },
        });
      } else {
        navigate('/tests/get-one', {
          state: { value: text, quizId: id },
        });
      }
    }
  };

  const handlePaginate = (page, limit) => {
    setPaginate({
      page: page,
      limit: limit,
    });
  };

  return (
    <TestsWrapper>
      <div className="create-wrapper">
        <Button
          className="add-btn"
          type="primary"
          onClick={() =>
            navigate('/tests/create', {
              state: { value: 'Test yaratish' },
            })
          }
        >
          <FiPlus size={22} color="white" /> <span>Test yaratish</span>
        </Button>
      </div>
      {loading ? (
        <Spinner maxHeight={'calc(100% - 300px)'} />
      ) : (
        <>
          {testData.length ? (
            <>
              <Table headers={mainHeadCols} data={testData} />
              {totalDocs > 10 && (
                <div className="pagination custom-pagination-wrapper">
                  <Pagination
                    current={paginate?.page}
                    total={totalDocs}
                    onChange={(page, limit) => handlePaginate(page, limit)}
                    defaultPageSize={paginate?.limit}
                    showSizeChanger
                  />
                </div>
              )}
            </>
          ) : (
            <NoData>
              <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
            </NoData>
          )}
        </>
      )}
    </TestsWrapper>
  );
};
