import React from 'react';
import { DesktopSidebar, MobileSidebar } from './style';
import { useWindowSize } from 'hooks/useDevice';
import * as devices from '../../../constants/devices';
import { useSelector } from 'react-redux';

export default ({ collapsed, children }) => {
  const { device } = useWindowSize();
  const desktopCollapsed = useSelector(
    ({ appReducer }) => appReducer.desktopCollapsed
  );

  if (device === devices.MOBILE)
    return <MobileSidebar collapsed={collapsed}>{children}</MobileSidebar>;
  if (device === devices.TABLET)
    return <MobileSidebar collapsed={collapsed}>{children}</MobileSidebar>;
  if (device === devices.DESKTOP)
    return (
      <DesktopSidebar collapsed={desktopCollapsed}>{children}</DesktopSidebar>
    );
  return <></>;
};
