import styled from 'styled-components';

export const ScheduleCardWrap = styled.div`
  min-width: 505px;
  height: 139px;
  border-radius: 16px;
  background: ${({ background }) => (background ? background : '#fff')};
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 35px;
  cursor: ${({ cursor }) => (cursor === 'true' ? 'pointer' : 'auto')};

  @media (max-width: 1400px) {
    min-width: 480px;
    gap: 25px;
  }

  @media (max-width: 1100px) {
    min-width: 100%;
    max-width: 100%;
  }

  @media (max-width: 960px) {
    min-width: 100%;
    max-width: 100%;
  }

  & .left-section {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    & .user-image {
      width: 103px;
      height: 100%;
      border-radius: 12px;
      overflow: hidden;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    & .left-section-text {
      & .username {
        color: #080936;
        font-family: Segoe UI;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        padding-bottom: 15px;
        max-width: 218px;
      }
      & .region-card {
        width: 218px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        & .region-text {
          width: 100%;
          display: flex;
          align-items: center;
          & .region-title {
            display: flex;
            align-items: flex-end;
            color: #08093650;
            font-family: Segoe UI;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
          }
          & .region-value {
            color: #080936;
            font-family: Segoe UI;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
          }
        }
      }
    }
  }
`;

export const DotCard = styled.div`
  width: ${({ width }) => width && width};
  border-bottom: 1px dashed rgba(8, 9, 54, 0.2);
`;

export const StatusCard = styled.div`
  width: 103px;
  height: 100%;
  border-radius: 12px;
  background: ${({ color, bgcolor }) =>
    bgcolor !== 'true' && color ? color + '14' : '#fff'};
  padding: 11px 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & .late-came {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 9px;

    & .late-came-text {
      color: ${({ color }) => color && color};
      text-align: center;
      font-family: Segoe UI;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
    & .late-came-count {
      color: #080936;
      font-family: Segoe UI;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
  }

  & .came {
    width: 100%;
    text-align: center;
    color: ${({ color }) => color && color};
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    padding-bottom: 9px;
  }
  & .came-time {
    width: 100%;
    text-align: center;
    color: #080936;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
  & .came-time-text {
    width: 100%;
    text-align: center;
    color: #08093640;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    padding-bottom: 7px;
  }
  & .came-date {
    padding: 6px;
    color: #080936;
    font-family: Segoe UI;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    border-radius: 76px;
    background: #fff;
  }
`;
