import React from 'react';
import { BookWrapper, ButtonTest, CardTitle } from './style';
import { Image } from 'antd';
import deleteIcon from '../../../assets/book/deleteIcon.png';
import refreshIcon from '../../../assets/book/refresh.png';
import tickIcon from '../../../assets/book/tick.png';
import { useNavigate } from 'react-router-dom';
import { MdOutlineFileDownloadDone } from 'react-icons/md';

export default ({
  data,
  hideDelete,
  hideCount,
  handleDelete,
  background,
  createTest,
  completed,
  handleCompleted = () => {},
}) => {
  const navigate = useNavigate();
  return (
    <BookWrapper background={background}>
      <div className="image-wrapper">
        <Image src={data?.photo} />
        {!hideDelete && (
          <div
            className="delete-icon"
            onClick={() => handleDelete(data?._id)}
            aria-hidden
          >
            <img src={deleteIcon} alt="" />
          </div>
        )}
        {data?.status !== 3 && completed && (
          <div
            className="completed-icon"
            onClick={() => handleCompleted(data?._id)}
            aria-hidden
          >
            <MdOutlineFileDownloadDone size={18} color="rgb(76, 77, 220)" />
          </div>
        )}
      </div>
      <div className="card-text">
        <CardTitle padding={hideCount ? '0px' : '20px'}>
          {data?.titleUZ}
        </CardTitle>

        {!hideCount && (
          <div className="card-status-wrap">
            <div className="card-status">
              <div>O’qilmoqda</div>
              <div className="card-count card-count-loading">
                <img src={refreshIcon} alt="" />
                <div>{data?.read ?? 0}</div>
              </div>
            </div>
            <div className="card-status">
              <div>O’qib bo’lindi</div>
              <div className="card-count card-count-completed">
                <img src={tickIcon} alt="" />
                <div>{data?.completed ?? 0}</div>
              </div>
            </div>
          </div>
        )}
        {createTest ? (
          data?.status !== 3 ? (
            <ButtonTest
              onClick={() =>
                navigate('/books/add', { state: { bookId: data?._id } })
              }
            >
              {"Test qo'shish"}
            </ButtonTest>
          ) : (
            <ButtonTest></ButtonTest>
          )
        ) : null}
      </div>
    </BookWrapper>
  );
};
