import styled from 'styled-components';

export const ModalBody = styled.div`
  width: 100%;
  padding: 30px 20px;

  & .ant-space-item {
    width: 100%;
    & input {
      :focus {
        border-color: #9a9aeb;
        outline: 0;
        box-shadow: 0 0 0 2px #e9e9fa;
      }
      :hover {
        border-color: #9a9aeb;
        outline: 0;
      }
    }
  }
  & .rmdp-container {
    width: 100%;
    & input {
      width: 100%;
      border-radius: 10px;
      :focus {
        border-color: #9a9aeb;
        outline: 0;
        box-shadow: 0 0 0 2px #e9e9fa;
      }
      :hover {
        border-color: #9a9aeb;
        outline: 0;
      }
    }
  }

  & .submit-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    & .button {
      background: var(--custom-primary);
      color: rgb(255, 255, 255);
      border: none;
      border-radius: '10px';
      padding: 0px 15px;
    }
  }
`;
