import styled from "styled-components";

const color = (value) => {
  switch (value) {
    case 1:
      return { text: "#007F00", bg: "#CDFFCD" };

    case 2:
      return { text: "#D30000", bg: "#FFE0E0" };

    case 3:
      return { text: "#965E00", bg: "#FFECCC" };
    default:
      return { text: "#000" };
  }
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const Badge = styled.div`
  border-radius: 10px;
  display: flex;
  gap: 5px;
  padding: 4px 12px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  border: ${({ border }) => border ?? "none"};
  color: ${({ color }) => color};
  /* background-color: ${({ value }) => color(value)?.bg || "transparent"}; */
  background-color: ${({ bg }) => bg};
`;
