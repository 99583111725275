import { Button, Col, Pagination, Row, Select, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import Filters from '../../../components/Filters';
import Typograpy from '../../../components/Typograpy';
import Wrapper from '../../../components/Wrapper';
import { buttons, formProvinceData, formRegionData } from './helper';
import './style.css';
import { AddContainer } from '../Users/style';
import AddButton from '../../../components/AddButton';
import ActionButton from '../../../components/ActionButton';
import { useHideModal, useShowAlert, useShowModal } from '../../../hooks';
import FormCreator from '../../../components/FormCreator';
import Confirm from '../../../components/Confirm';
import provinceService from '../../../services/provinces';
import regionService from '../../../services/regions';
import FullScreen from '../../../components/Spinner/FullScreen';
import { useSelector } from 'react-redux';
import { AiOutlineFilter } from 'react-icons/ai';

const { Option } = Select;

export default () => {
  const { lang } = useSelector((state) => state.appReducer);
  const { showBlured } = useShowModal();
  const { hideModal } = useHideModal();
  const { success, error } = useShowAlert();
  const [active, setActive] = useState('province');
  const [bool, setBool] = useState(true);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [provinceData, setProvinceData] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(false);
  const [filterItems, setFilterItems] = useState({ province: '' });
  const names = {
    province: 'Viloyat',
    region: 'Tuman/shahar',
  };

  const formData = {
    province: formProvinceData,
    region: formRegionData,
  };

  const selectOptions = {
    province: provinceData,
    region: data,
    // group: groupData,
  };

  const initialValuesAdd = (active) => {
    if (active == 'province') {
      return {
        titleUZ: '',
        titleRU: '',
        titleQQ: '',
        desc: '',
      };
    } else {
      return {
        titleUZ: '',
        titleRU: '',
        titleQQ: '',
        desc: '',
        province: '',
      };
    }
  };

  const initialValuesEdit = (data, active) => {
    if (active == 'province') {
      return {
        titleUZ: data.titleUZ,
        titleRU: data.titleRU,
        titleQQ: data.titleQQ,
        desc: data.desc,
      };
    } else {
      return {
        titleUZ: data.titleUZ,
        titleRU: data.titleRU,
        titleQQ: data.titleQQ,
        desc: data.desc,
        province: data.province?._id,
      };
    }
  };

  const handleActive = (item) => {
    setActive(item.value);
    setFilterItems(() => ({
      province: '',
    }));
    setFilter(false);
  };

  const handleDataStateClear = () => {
    setData([]);
  };

  useEffect(() => {
    const data = {
      limit: size,
      page: page,
    };
    setLoading(true);
    if (active == 'province') {
      provinceService
        .getAll()
        .then((res) => {
          let provinces = res?.map((item, index) => {
            return { ...item, key: index + 1, title: item['title' + lang] };
          });
          setData(provinces);
          setProvinceData(provinces);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          // handleDataStateClear();
        });
    }
    if (active == 'region') {
      if (filterItems?.province) {
        data['province'] = filterItems?.province;
      }
      regionService
        .getAllPaginate(data)
        .then(({ docs: res, totalDocs }) => {
          setTotal(totalDocs);
          let regions = res.map((item, index) => {
            return { ...item, key: index + 1 };
          });
          setData(regions);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          handleDataStateClear();
        });
    }
  }, [active, bool, page, size, filterItems.province]);

  //Create section

  const sendProvinceData = (data) => {
    provinceService
      .create(data)
      .then(() => {
        success("Ma'lumot qo'shildi", 2, 'bottom');
        setBool(!bool);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Viloyat qo'shishda xatolik", 2, 'bottom');
      });
  };

  const sendRegionData = (data) => {
    regionService
      .create(data)
      .then(() => {
        success("Tuman/shahar ma'lumoti qo'shildi", 2, 'bottom');
        setBool(!bool);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Tuman/shahar qo'shishda xatolik", 2, 'bottom');
      });
  };

  const sendData = (data) => {
    hideModal();
    setLoading(true);
    if (active == 'province') {
      return sendProvinceData(data);
    }
    if (active == 'region') {
      return sendRegionData(data);
    }
    // else if (active == 'organization') {
    //   return sendOrganizationData(data);
    // } else {
    //   return sendgroupData(data);
    // }
  };

  const addData = () => {
    showBlured({
      title: `${names[active]} qo'shish`,
      maxWidth: '600px',
      height: '400px',
      withHeader: false,
      body: () => (
        <FormCreator
          selectOptions={selectOptions}
          formData={formData[active]}
          initialVal={initialValuesAdd(active)}
          cancel={hideModal}
          sendData={sendData}
          id=""
        />
      ),
    });
  };

  //Update Section
  const updateProvinceData = (id, data) => {
    provinceService
      .update(id, data)
      .then(() => {
        success("Viloyat ma'lumoti yangilandi", 2, 'bottom');
        setBool(!bool);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Viloyat ma'lumotini yangilashda xatolik", 2, 'bottom');
      });
  };

  const updateRegionData = (id, data) => {
    regionService
      .update(id, data)
      .then(() => {
        success('Tuman/shahar yangilandi', 2, 'bottom');
        setBool(!bool);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error('Tuman/shahar yangilashda xatolik', 2, 'bottom');
      });
  };

  const updateData = (id, data) => {
    hideModal();
    setLoading(true);
    if (active == 'province') {
      return updateProvinceData(id, data);
    }
    if (active == 'region') {
      return updateRegionData(id, data);
    }
    // } else if (active == 'organization') {
    //   return updateOrganizationData(id, data);
    // } else {
    //   return updategroupData(id, data);
    // }
  };

  const handleEdit = (data) => {
    showBlured({
      title: `${names[active]} qo'shish`,
      maxWidth: '600px',
      height: '400px',
      withHeader: false,
      body: () => (
        <FormCreator
          id={data._id}
          fetchRegion={false}
          selectOptions={selectOptions}
          formData={formData[active]}
          cancel={hideModal}
          initialVal={initialValuesEdit(data, active)}
          sendData={updateData}
        />
      ),
    });
  };

  //Delete Section

  const deleteProvince = (id) => {
    provinceService
      .delete(id)
      .then(() => {
        setBool(!bool);
        success("Viloyat o'chirildi", 2, 'bottom');
      })
      .catch((err) => {
        console.log(err);
        success("Viloyat o'chirishda xatolik", 2, 'bottom');
      });
  };

  const deleteRegion = (id) => {
    regionService
      .delete(id)
      .then(() => {
        setBool(!bool);
        success("Tuman/shahar o'chirildi", 2, 'bottom');
      })
      .catch((err) => {
        console.log(err);
        success("Tuman/shahar o'chirishda xatolik", 2, 'bottom');
      });
  };
  const deleteSection = (id) => {
    hideModal();
    setLoading(true);
    if (active == 'province') {
      deleteProvince(id);
    }
    if (active == 'region') {
      deleteRegion(id);
    }
  };
  const handleDelete = (_id) => {
    showBlured({
      title: 'Olib tashlash',
      maxWidth: '400px',
      height: '300px',
      withHeader: false,

      body: () => (
        <Confirm agree={() => deleteSection(_id)} cancel={hideModal} />
      ),
    });
  };

  // Column table

  const columns = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
      width: '10%',
      align: 'center',
      render: (_, row) => {
        return page * size - size + Number(row?.key);
      },
    },
    {
      title: 'Nomi',
      dataIndex: 'title',
      key: 'title',
      render: (_, row) => {
        return row?.['title' + lang];
      },
    },
    // {
    //   title: 'Номи RU',
    //   dataIndex: 'titleRU',
    //   key: 'titleRU',
    // },
    // {
    //   title: 'Номи QQ',
    //   dataIndex: 'titleQQ',
    //   key: 'titleQQ',
    // },

    {
      width: '20%',
      align: 'center',
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (props) => (
        <ActionButton
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          props={props}
        />
      ),
    },
  ];

  if (active === 'region') {
    columns.splice(2, 0, {
      title: 'Viloyat',
      dataIndex: 'province',
      key: 'province',
      render: (props) => {
        return props?.['title' + lang];
      },
    });
  }

  const onShowSizeChange = (current, pageSize) => {
    setPage(current);
    setSize(pageSize);
  };

  const handleChangeProvinceFilter = (value) => {
    setFilterItems((prevVal) => ({
      ...prevVal,
      province: value,
    }));
    setPage(() => 1);
  };
  if (loading) return <FullScreen />;
  return (
    <Wrapper>
      <Filters list={buttons} active={active} handleActive={handleActive} />
      <AddContainer>
        <AddButton text={names[active]} addData={addData} />
      </AddContainer>
      <Typograpy color="#080936">{names[active]}</Typograpy>
      <Space
        direction="vertical"
        size="middle"
        style={{
          width: '100%',
          height: '25px',
          padding: '0 10px',
        }}
      >
        {active === 'region' ? (
          <Row>
            <Col span={22}>
              {filter ? (
                <Space>
                  Viloyat
                  <Select
                    value={filterItems?.province}
                    allowClear
                    onChange={handleChangeProvinceFilter}
                  >
                    {provinceData?.map((item, ind) => (
                      <Option key={ind + 1} value={item._id}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Space>
              ) : null}
            </Col>
            <Col span={2} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => setFilter(!filter)}
                shape="circle"
                type="text"
                icon={<AiOutlineFilter size={25} />}
              />
            </Col>
          </Row>
        ) : null}
      </Space>

      <Table bordered columns={columns} dataSource={data} pagination={false} />
      {active == 'region' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <Pagination
            total={total}
            current={page}
            defaultPageSize={size}
            showSizeChanger
            onChange={(current, newSize) => {
              setPage(newSize !== size ? 1 : current);
            }}
            onShowSizeChange={onShowSizeChange}
          />
        </div>
      )}
    </Wrapper>
  );
};
