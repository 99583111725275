import React, { useEffect, useState } from 'react';
import { ModalBodyWrapper } from './style';
import SpecialTaskCard from 'components/Cards/WorkCards/SpecialTask';

export default ({ data }) => {
  const [cardType, setCardType] = useState('large');
  const screenChecker = (innerWidth) => {
    if (innerWidth >= 1056) {
      setCardType('large');
    } else {
      setCardType('small');
    }
  };

  useEffect(() => {
    screenChecker(window.innerWidth);
    const handleResize = () => screenChecker(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ModalBodyWrapper>
      <SpecialTaskCard size={cardType} data={data} unrated showLocation />
    </ModalBodyWrapper>
  );
};
