import types from '../../../constants/action-types';

const defaultState = {
  groupId: null,
  tableIndex: null,
  colIndex: null,
  groupTitle: ',',
  subData: [],
  categories: [],
  provinces: [],
  regions: [],
  objects: [],
  users: [],
  supportTypes: [],
  loading: false,
  collapsed: false,
  desktopCollapsed: false,
  activeItem: 'all',
  lang: localStorage.getItem('lang') || 'UZ',
  badgeNotification: {},
};

const map = {
  [types.APPLICATION_SET_GROUP_ID]: (state, { payload }) => ({
    ...state,
    groupId: payload.groupId,
    tableIndex: payload.index,
    colIndex: payload.colIndex,
    groupTitle: payload.title,
  }),
  [types.APPLICATION_SET_SUB_DATA]: (state, { payload }) => ({
    ...state,
    subData: payload,
  }),
  [types.APPLICATION_ACTIVE_ITEM]: (state, { payload }) => ({
    ...state,
    activeItem: payload,
  }),
  [types.APPLICATION_SET_CATEGORIES]: (state, { payload }) => ({
    ...state,
    categories: payload.map((item) => ({ ...item, title: item?.titleUZ })),
  }),
  [types.APPLICATION_SET_SUPPORTS]: (state, { payload }) => ({
    ...state,
    supportTypes: payload.map((item) => ({ ...item, title: item?.titleUZ })),
  }),
  [types.APPLICATION_SET_REGIONS]: (state, { payload }) => ({
    ...state,
    regions: payload.map((item) => ({ ...item, title: item?.titleUZ })),
  }),
  [types.APPLICATION_SET_OBJECTS]: (state, { payload }) => ({
    ...state,
    objects: payload.map((item) => ({ ...item, title: item?.titleUZ })),
  }),
  [types.APPLICATION_SET_PROVINCES]: (state, { payload }) => ({
    ...state,
    provinces: payload.map((item) => ({ ...item, title: item?.titleUZ })),
  }),
  [types.APPLICATION_SET_USERS]: (state, { payload }) => ({
    ...state,
    users: payload,
  }),
  [types.APPLICATION_SET_LOADING]: (state, { payload }) => ({
    ...state,
    loading: payload,
  }),
  [types.APPLICATION_SET_SIDEBAR_COLLAPSED]: (state, { payload }) => ({
    ...state,
    collapsed: payload,
  }),
  [types.APPLICATION_SET_SIDEBAR_DESKTOP_COLLAPSED]: (state, { payload }) => ({
    ...state,
    desktopCollapsed: payload,
  }),
  [types.APPLICATION_SET_SIDEBAR_BADGE_COUNT]: (state, { payload }) => ({
    ...state,
    badgeNotification: {
      ...state.badgeNotification,
      ...payload,
    },
  }),
  [types.APPLICATION_CLEAN_STATE]: (state) => ({
    ...state,
    subData: [],
    categories: [],
    provinces: [],
    regions: [],
    users: [],
    badgeNotification: {},
  }),
};

// eslint-disable-next-line max-len
export default (state, action) =>
  (map[action.type] && map[action.type](state, action)) ||
  state ||
  defaultState;
