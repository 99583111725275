import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100vh;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: 1fr auto;
`;
export const MapContainer = styled.div`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  & .marker-cluster > div {
    font-size: 12px;
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
    color: white !important;
  }
  & .map-tiles {
    filter: var(--map-tiles-filter, none);
  }
`;
export const MapSideBar = styled.div`
  width: ${({ expand }) => (expand ? 'var(--map-sidebar-width)' : '0')};
  background: var(--map-sidebar-background);
  transition: 0.2s width;
  height: 100vh;
  box-sizing: border-box;
  color: #282828;
`;
export const MapSideBarBody = styled.div`
  color: #282828;
  overflow-y: auto;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  & .body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const Header = styled.div`
  height: 60px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 11;
  & button {
    font-family: Montserrat;
    font-size: 20px;
    padding: 5px;
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    border-radius: 100%;
    cursor: pointer;
    top: 10px;
    transition: 0.2s outline;
    color: var(--map-filter-color);
  }
  & h2 {
    font-family: Montserrat;
    font-size: 16px;
    cursor: pointer;
    top: 10px;
    color: #282828;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1111;

  & .closeMap {
    padding: 13px 12px;
    z-index: 1111111;
  }

  .filter-btn {
    padding: 0px 30px;
  }
  .location-layer {
    padding: 13px 12px;
  }
`;
export const Button = styled.button`
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  box-shadow: 0 var(--shadow-y) var(--shadow-blur) 0 var(--shadow-color);
  transition: box-shadow 0.1s ease-out, background-color 0.1s ease-out;
  border-radius: 10px;
  background-color: var(--map-filter-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  gap: 10px;
  z-index: 1201;
  border: 1px solid var(--map-filter-color);
  font-style: normal;
  cursor: pointer;
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
`;

export const FilterContainer = styled.div`
  width: ${({ expand }) => (expand ? 'var(--map-sidebar-width)' : '0')};
  background: var(--map-sidebar-background);
  transition: 0.2s width;
  height: 100vh;
  position: absolute;
  box-sizing: border-box;
  right: 0;
  top: 0;
  box-shadow: 0px 34px 13px rgba(189, 194, 203, 0.01),
    0px 19px 11px rgba(189, 194, 203, 0.05),
    0px 8px 8px rgba(189, 194, 203, 0.09), 0px 2px 5px rgba(189, 194, 203, 0.1),
    0px 0px 0px rgba(189, 194, 203, 0.1);
  z-index: 1;
  overflow-y: auto;

  @media (max-width: 959.9px) {
    width: ${({ expand }) =>
      expand ? 'var(--map-sidebar-width-mobile)' : '0'};
  }
`;
export const FilterBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 960px) {
    padding: 25px;
  }

  & .ant-input:hover {
    border-color: #9a9aeb !important;
  }

  & label {
    padding: 20px 5px 5px 0px;
    display: inline-block;
    color: #080936;
    font-family: 'Segoe UI';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    :first-child {
      padding: 0px 5px 5px 5px;
    }
  }

  & .date-picker-wrap {
    width: 100%;
    & .range-datepicker {
      width: 100% !important;
    }
  }

  & .textarea-wrap textarea {
    border-radius: 10px;
    border: 1px solid #ececec !important;
    background: #fff;
    height: 104px !important;
  }

  & .custom-filter-button {
    width: 183px;
    height: 42px;
    border-radius: 10px;
    background: #00826C;
    font-size: 16px;
    line-height: 29px;
    color: #f9fdff;
    border: none;
    cursor: pointer;
    font-family: Roboto;
    font-weight: 500;
  }
  button:first-child {
    background: #eaeaea;
    font-size: 16px;
    line-height: 29px;
    color: #080936;
    border: none;
  }
  & .button-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    font-style: normal;
  }
`;
// export const FilterItem = styled.div`
//   border: 1px solid rgba(0, 0, 0, 0.2);
//   min-width: 200px;
//   border-radius: 8px;
// `;
