import styled from 'styled-components';

export const TaskWrapper = styled.div`
  width: 100%;
  padding-top: 28px;
`;

export const Header = styled.div`
  padding-bottom: 24px;
`;

export const TaskBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  .pagination-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
  }
`;

export const ModalWrapper = styled.div`
  width: 100%;
  padding: 20px 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  & .image-wrapper {
    width: 100%;
    height: 280px;
    border-radius: 10px;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.04);
    overflow: hidden;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  & .modal-text {
    color: rgba(8, 9, 54, 0.7);
    font-family: 'Segoe UI';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  & .modal-date {
    color: rgba(8, 9, 54, 0.5);
    font-family: 'Segoe UI';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  height: calc(100vh - 400px);
`;
