import React, { memo, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Content, RightContent, LeftContent, Menu, MenuItem } from './style';
import { clearToken } from 'redux/modules/auth/actions';
import Rightoptions from 'components/Navbar/RightOptions';

import { useNavigate } from 'react-router-dom';
import {
  clearSelectedDate,
  clearSelectedDateSchedule,
  setSupervisorDate,
} from 'redux/modules/dates/actions';
import Logo from 'components/Logo';
import MultiDatePicker from 'components/FormElements/MultiDatePicker';
import works from 'services/works';
import moment from 'moment';
import MobileMenu from './MobileMenu';
import { useWindowSize } from 'hooks/useDevice';

const SupervisorNavbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { role } = useSelector((state) => state.authReducer);
  const [isActive, setIsActive] = useState(1);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [determinedDays, setDeterminedDays] = useState([]);
  const pureToday = new Date();
  const pureYesterday = new Date(pureToday);
  pureYesterday.setDate(pureYesterday.getDate() - 1);
  const [startDate, setStartDate] = useState(pureYesterday);
  const { width } = useWindowSize();

  useEffect(() => {
    if (
      location.pathname !== '/rated-works' &&
      location.pathname !== '/users'
    ) {
      const determinedData = {
        date: moment().format('YYYY-MM-DD'),
        status: 2,
      };
      works
        .getWorkDays(determinedData)
        .then((res) => {
          setDeterminedDays(res);
        })
        .catch((err) => console.error(err));
    } else {
      setDeterminedDays([]);
    }
  }, [location.pathname]);

  const { username } = useSelector((state) => state.authReducer);

  const navigate = useNavigate();

  const data = [
    {
      key: 1,
      permission: ['supervisor', 'prosupervisor', 'regsupervisor'],
      title: 'Baholanmagan',
      href: '/',
    },
    {
      key: 2,
      title: 'Baholangan',
      permission: ['supervisor', 'prosupervisor', 'regsupervisor'],

      href: '/rated-works',
    },
    {
      key: 3,
      title: 'Ishchilar',
      permission: ['regsupervisor'],
      href: '/users',
    },
    // {
    //   key: 4,
    //   title: 'Tadbirlar',
    //   permission: ['regsupervisor'],
    //   href: '/events',
    // },
  ];

  const handlePage = (key, href) => {
    setIsActive(key);
    navigate(href);
  };
  const handleChangeDateInput = (dateString) => {
    setStartDate(dateString);
    const data = moment(dateString).format('YYYY-MM-DD');
    if (data) {
      dispatch(setSupervisorDate(data));
    } else {
      dispatch(setSupervisorDate(''));
    }
  };

  useEffect(() => {
    setIsActive(data?.find((item) => item.href === location.pathname)?.key);
  }, [location]);

  const filteredByRelevance = useMemo(
    () => data?.filter((item) => item.permission.includes(role)),
    [role]
  );

  const handleLogOut = () => {
    dispatch(clearToken());
    dispatch(clearSelectedDate());
    dispatch(clearSelectedDateSchedule());
  };
  const handleMenuToggle = () => {
    setOpenMobileMenu(!openMobileMenu);
  };
  return (
    <Content isSmall={width < 1240 && true}>
      <LeftContent isSmall={width < 1240 && true}>
        {width < 1240 && (
          <MobileMenu
            options={filteredByRelevance}
            open={openMobileMenu}
            handleToggle={handleMenuToggle}
            isActive={isActive}
            handlePage={handlePage}
            username={username}
            handleLogOut={handleLogOut}
          />
        )}
        <Logo />
        {width > 1240 && (
          <Menu>
            {filteredByRelevance.map((item, index) => (
              <MenuItem
                key={index + 1}
                isActive={isActive === item.key}
                onClick={() => {
                  handlePage(item.key, item.href);
                }}
              >
                {item.title}
              </MenuItem>
            ))}
          </Menu>
        )}
      </LeftContent>
      <RightContent>
        <div>
          <MultiDatePicker
            dates={determinedDays}
            onChange={handleChangeDateInput}
            startDate={startDate}
          />
        </div>
        {width > 1240 && (
          <Rightoptions handleLogout={handleLogOut} user={username} darkText />
        )}
      </RightContent>
    </Content>
  );
};
export default memo(SupervisorNavbar);
