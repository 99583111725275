import Province from '../../assets/icons/dashboardIcons/province.svg';
import Region from '../../assets/icons/dashboardIcons/region.svg';
import Mfy from '../../assets/icons/dashboardIcons/mfy.svg';
import Yetakchi from '../../assets/icons/dashboardIcons/yetakchi.svg';
import AllLand from '../../assets/icons/dashboardIcons/allLand.svg';
import NotDone from '../../assets/icons/dashboardIcons/notDone.svg';
import Daily from '../../assets/icons/dashboardIcons/daily.svg';

export const headerCardLeader = [
  {
    title: 'Viloyatlar',
    img: Province,
    color: '#00826C',
    key: 'province',
  },
  {
    title: 'Shahar-tuman',
    img: Region,
    color: '#00826C',
    key: 'region',
  },
  {
    title: 'M.F.Y. lar',
    img: Mfy,
    color: '#2DCEA9',
    key: 'district',
  },
  {
    title: 'Mahalla raislari',
    img: Yetakchi,
    color: '#FB7F40',
    key: 'advanced',
  },
  {
    title: 'Jami rejalar',
    img: AllLand,
    color: '#4075FB',
    key: 'totalPlan',
  },
  {
    title: 'Jami yuborilgan ma’lumotlar',
    img: Daily,
    color: '#54D9E1',
    key: 'work',
  },
  {
    title: 'Bajarilmagan',
    img: NotDone,
    color: '#F5464F',
    key: 'notDone',
  },
];
export const headerCardProLeader = [
  {
    title: 'Shahar-tuman',
    img: Region,
    color: '#00826C',
    key: 'region',
  },
  {
    title: 'M.F.Y. lar',
    img: Mfy,
    color: '#2DCEA9',
    key: 'district',
  },
  {
    title: 'Mahalla raislari',
    img: Yetakchi,
    color: '#FB7F40',
    key: 'advanced',
  },
  {
    title: 'Jami rejalar',
    img: AllLand,
    color: '#4075FB',
    key: 'totalPlan',
  },
  {
    title: 'Jami yuborilgan ma’lumotlar',
    img: Daily,
    color: '#54D9E1',
    key: 'work',
  },
  {
    title: 'Bajarilmagan',
    img: NotDone,
    color: '#F5464F',
    key: 'notDone',
  },
];
export const headerCardRegLeader = [
  {
    title: 'M.F.Y. lar',
    img: Mfy,
    color: '#2DCEA9',
    key: 'district',
  },
  {
    title: 'Mahalla raislari',
    img: Yetakchi,
    color: '#FB7F40',
    key: 'advanced',
  },
  {
    title: 'Jami rejalar',
    img: AllLand,
    color: '#4075FB',
    key: 'totalPlan',
  },
  {
    title: 'Jami yuborilgan ma’lumotlar',
    img: Daily,
    color: '#54D9E1',
    key: 'work',
  },
  {
    title: 'Bajarilmagan',
    img: NotDone,
    color: '#F5464F',
    key: 'notDone',
  },
];

export const getHour = (num) => {
  switch (num) {
    case 0:
      return '00:00';
    case 1:
      return '01:00';
    case 2:
      return '02:00';
    case 3:
      return '03:00';
    case 4:
      return '04:00';
    case 5:
      return '05:00';
    case 6:
      return '06:00';
    case 7:
      return '07:00';
    case 8:
      return '08:00';
    case 9:
      return '09:00';
    case 10:
      return '10:00';
    case 11:
      return '11:00';
    case 12:
      return '12:00';
    case 13:
      return '13:00';
    case 14:
      return '14:00';
    case 15:
      return '15:00';
    case 16:
      return '16:00';
    case 17:
      return '17:00';
    case 18:
      return '18:00';
    case 19:
      return '19:00';
    case 20:
      return '20:00';
    case 21:
      return '21:00';
    case 22:
      return '22:00';
    case 23:
      return '23:00';
  }
};

export const getMonthName = (num) => {
  switch (num) {
    case 1:
      return 'Yanvar';
    case 2:
      return 'Fevral';
    case 3:
      return 'Mart';
    case 4:
      return 'Aprel';
    case 5:
      return 'May';
    case 6:
      return 'Iyun';
    case 7:
      return 'Iyul';
    case 8:
      return 'Avgust';
    case 9:
      return 'Sentabr';
    case 10:
      return 'Oktabr';
    case 11:
      return 'Noyabr';
    case 12:
      return 'Dekabr';
    default:
      return;
  }
};

export const mockAreaData = [
  {
    time: 1,
    value: 2,
  },
  {
    time: 2,
    value: 1,
  },
  {
    time: 3,
    value: 0,
  },
  {
    time: 4,
    value: 0,
  },
  {
    time: 5,
    value: 0,
  },
  {
    time: 6,
    value: 8,
  },
  {
    time: 7,
    value: 5,
  },
  {
    time: 8,
    value: 7,
  },
  {
    time: 9,
    value: 3,
  },
  {
    time: 10,
    value: 5,
  },
  {
    time: 11,
    value: 2,
  },
  {
    time: 12,
    value: 1,
  },
  {
    time: 13,
    value: 0,
  },
  {
    time: 14,
    value: 0,
  },
  {
    time: 15,
    value: 0,
  },
  {
    time: 16,
    value: 0,
  },
  {
    time: 17,
    value: 0,
  },
  {
    time: 18,
    value: 0,
  },
  {
    time: 19,
    value: 0,
  },
  {
    time: 20,
    value: 0,
  },
  {
    time: 21,
    value: 0,
  },
  {
    time: 22,
    value: 0,
  },
  {
    time: 23,
    value: 0,
  },
  {
    time: 24,
    value: 0,
  },
  {
    time: 25,
    value: 0,
  },
  {
    time: 26,
    value: 0,
  },
  {
    time: 27,
    value: 0,
  },
  {
    time: 28,
    value: 0,
  },
  {
    time: 29,
    value: 0,
  },
  {
    time: 30,
    value: 0,
  },
];

export const mockDataDiagram = [
  {
    regionName: 'Фарғона шахар',
    percent: 43,
  },
  {
    regionName: 'Данғара тумани',
    percent: 11,
  },
  {
    regionName: 'Қўштепа тумани',
    percent: 10,
  },
  {
    regionName: 'Марғилон шахар',
    percent: 9,
  },
  {
    regionName: 'Риштон тумани',
    percent: 7,
  },
  {
    regionName: 'Бағдод тумани',
    percent: 6,
  },
  {
    regionName: 'Фарғона тумани',
    percent: 6,
  },
  {
    regionName: 'Бувайда тумани',
    percent: 5,
  },
  {
    regionName: 'Қувасой шахар',
    percent: 4,
  },
  {
    regionName: 'Қўқон шахар',
    percent: 0,
  },
  {
    regionName: 'Қува тумани',
    percent: 0,
  },
  {
    regionName: 'Тошлоқ тумани',
    percent: 0,
  },
  {
    regionName: 'Ёзёвон тумани',
    percent: 0,
  },
  {
    regionName: 'Олтиариқ тумани',
    percent: 0,
  },
  {
    regionName: 'Учкўприк тумани',
    percent: 0,
  },
  {
    regionName: 'Фурқат тумани',
    percent: 0,
  },
  {
    regionName: 'Ўзбекистон тумани',
    percent: 0,
  },
  {
    regionName: 'Бешариқ тумани',
    percent: 0,
  },
  {
    regionName: 'Сўх тумани',
    percent: 0,
  },
];

export const mockSpecialityData = [
  {
    category: {
      _id: '633ebc01ed9a367f5ecfed95',
      title: 'Ободонлаштириш',
    },
    count: 29,
    title: 'Ободонлаштириш',
    type: 0,
    color: '#3380FF',
    value: 38,
  },
  {
    category: {
      _id: '634d255eb79a564b13ee962d',
      title: 'Ҳуқуқбузарлик',
    },
    count: 0,
    title: 'Ҳуқуқбузарлик',
    type: 1,
    color: '#7171EA',
  },
  {
    category: {
      _id: '634d2c43b79a564b13ee9710',
      title: 'Жамоат транспорти',
    },
    count: 0,
    title: 'Жамоат транспорти',
    type: 2,
    color: '#6344BE',
  },
  {
    category: {
      _id: '635395c4dcf2f98d4527f6f7',
      title: 'Йўл',
    },
    count: 47,
    title: 'Йўл',
    type: 3,
    color: '#E467AC',
    value: 62,
  },
  {
    category: {
      _id: '635395ebdcf2f98d4527f6fa',
      title: 'Коммунал',
    },
    count: 0,
    title: 'Коммунал',
    type: 4,
    color: '#FFAD65',
  },
  {
    category: {
      _id: '63db8f3d95ba2d12ef024832',
      title: 'Санитария',
    },
    count: 0,
    title: 'Санитария',
    type: 5,
    color: '#FFEB30',
  },
  {
    category: {
      _id: '63db8fdb95ba2d12ef0249cf',
      title: 'Ширкат',
    },
    count: 0,
    title: 'Ширкат',
    type: 6,
    color: '#2BC6D8',
  },
  {
    category: {
      _id: '63db91c395ba2d12ef024e8f',
      title: 'Экология',
    },
    count: 0,
    title: 'Экология',
    type: 7,
    color: '#96D90C',
  },
];
