import React from 'react';
import { StatusCard } from '../style';

export default ({ color, bgColor }) => {
  return (
    <StatusCard color={color} bgcolor={bgColor ? 'true' : 'false'}>
      <div className="late-came">
        <div className="late-came-text">Kelmagan</div>
      </div>
    </StatusCard>
  );
};
