import styled from 'styled-components';

export const Item = styled.div`
  background-color: white;
  margin-top: 8px;
  padding: 2px 10px;
  box-shadow: 0px 0px 1px 1px #eee;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--textcolor-01, #080936);
  font-family: Segoe UI;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 19.5px */
  & .ant-check{
    
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  background: #f8f9fa;
  width: 327px;
  height: 100px;
  flex-shrink: 0;
`;
export const FlexBox = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 15px;
`;

export const Text = styled.div`
  display: inline-block;
  color: #00826C;
  font-family: Segoe UI;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 32px */
`;
export const SwitchBox = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  flex-direction: row;
  gap: 20px;
`;
