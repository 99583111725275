import styled from 'styled-components';

export const CustomButton = styled.button`
  width: 100%;
  height: 100%;
  padding: 5px 20px;
  cursor: pointer;
  background: var(--background-gradient-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ iconFirst }) => (iconFirst ? 'row-reverse' : 'row')};
  gap: 15px;
`;
