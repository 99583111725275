import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1262px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  fill: #fff;
  background: #ffffff;
  gap: 20px;
  border-radius: 12px !important;
`;

export const Body = styled.div`
  width: 100%;
  min-height: 330px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-wrap: wrap;
  gap: 20px;
  transition: height 0.4s linear;
  .description {
    width: 100%;
    height: 100%;
    max-width: 330px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    border-right: 1px solid #0809360d;
    padding-right: 12px;
    .title {
      width: 100%;
      color: #080936;
      font-family: Segoe UI;
      font-size: ${({ size }) => (size === 'small' ? '15px' : '20px')};
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
      letter-spacing: 0.4px;
      padding: 0;
      text-align: left;
      margin-bottom: 12px;
    }
    .desc__text {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: start;
      flex-direction: column;
      min-height: 150px;
      height: ${({ expanded }) => (expanded ? '100%' : '200px')};
      transition: height 0.4s linear;
      color: #080936;
      font-family: Segoe UI;
      font-size: ${({ size }) => (size === 'small' ? '11px' : '15px')};
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 21px */
      transition: height 0.6s linear;
      overflow-y: hidden;
      .show__more {
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        color: #3380ff;
        font-family: Segoe UI;
        font-size: ${({ size }) => (size === 'small' ? '11px' : '15px')};
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */
      }
    }
    .executor__info {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;
      gap: 10px;
      color: #080936;
      font-family: Segoe UI;
      font-size: 16px;
      font-size: ${({ size }) => (size === 'small' ? '12px' : '16px')};
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
      .date {
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 16px;
        opacity: 0.5;
        margin: 0;
        padding: 0;
      }
      .executor {
        margin: 0;
        padding: 0;
      }
    }
  }
  .info {
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: start;
  }
`;
export const Title = styled.h1`
  height: 100%;
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  // font-weight: 700;
  // font-size: 26px;
  line-height: 30px;
  // text-align: center;
  color: #3d3d3d;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const MainTitle = styled.div`
  height: 100%;
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
`;
export const RatingBox = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  position: relative;
  top: 0;
  padding: 15px 20px;
  border-top: 1px solid #dadada;
`;
