export const colors = {
  0: '#EE1111',
  5: '#E24343',
  10: '#F35656',
  15: '#F46363',
  20: '#F76E43',
  25: '#F98966',
  30: '#F19172',
  35: '#E09270',
  40: '#D79173',
  45: '#BEAD17',
  50: '#DECC28',
  55: '#E9D94D',
  60: '#F4E456',
  65: '#F3E458',
  70: '#BFE968',
  75: '#A6E425',
  80: '#96D90C',
  85: '#85BF0E',
  90: '#37DC47',
  95: '#2AD73B',
  100: '#11B521',
};

export const dashboardPieColors = {
  1: '#2dce99',
  2: '#fb7e40',
  3: '#00826C',
};

export const dashboardPieMonthColors = {
  1: '#00826C',
  2: '#2dce99',
  3: '#FF6060',
};

export const categoryColors = {
  0: '#3380FF',
  1: '#7171EA',
  2: '#6344BE',
  3: '#E467AC',
  4: '#FFAD65',
  5: '#FFEB30',
  6: '#2BC6D8',
  7: '#96D90C',
  8: 'rgba(0, 0, 255, 0.7)',
  9: '#3ACA74',
  10: '#D79173',
  11: '#FF5151',
  12: '#FF5151',
};

export const scheduleColor = {
  0: '#00C4FA',
  1: '#FFBD00',
  2: '#FF6060',
  3: '#2DCE99',
  4: '#FF7100',
  5: '#2DCE99',
  6: '#E467AC',
  7: '#FF6060',
};

export const testStatusColor = {
  1: '#2DCE99',
  2: '#EDB000',
  3: '#00826C',
};
export const testManageColor = {
  1: '#2DCE99',
  2: '#00826C',
};
