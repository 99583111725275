export const initialValuesAdd = {
  titleUZ: '',
  titleRU: '',
  titleQQ: '',
  desc: '',
  parent: '',
  province: '',
  region: '',
  coordinates: [],
  coordinate: '',
};
export const formData = {
  title: "MFY qo'shish",
  data: [
    {
      type: 'text',
      label: 'Nomi UZ',
      name: 'titleUZ',
      value: '',
      size: 24,
    },
    {
      type: 'text',
      label: 'Nomi RU',
      name: 'titleRU',
      value: '',
      size: 24,
    },
    {
      type: 'text',
      label: 'Nomi QQ',
      name: 'titleQQ',
      value: '',
      size: 24,
    },
    { type: 'textarea', label: 'Izoh', name: 'desc', value: '', size: 24 },

    {
      type: 'select',
      label: 'Viloyatni tanlang',
      name: 'province',
      value: '',
      size: 24,
    },
    {
      type: 'select',
      label: 'Tumanni tanlang',
      name: 'region',
      value: '',
      size: 24,
    },
    {
      type: 'text',
      label: 'Koordinatani kiriting',
      name: 'coordinate',
      value: '',
      size: 24,
    },
    {
      type: 'coordinates',
      label: 'Fayl tanlang',
      name: 'coordinates',
      value: '',
      size: 24,
    },
  ],
};
