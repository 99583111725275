import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 24px;
  border-radius: 16px;
`;
export const PageHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20px;
`;
export const FilterContainer = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: flex-end;
`;
export const FilterItem = styled.div`
  display: flex;
  gap: 10px;
`;
export const Title = styled.h4`
  color: #28303f;
  font-size: 24px;
  font-weight: 600;
`;

export const Label = styled.span`
  color: #a8a8a8;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  height: calc(100vh - 400px);
`;
export const ResultCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  padding: 15px 24px;
  border-radius: 8px;
  background: ${({ color }) => color && color};
  transition: all 0.4s ease-in-out;
  color: #fff;
  font-family: Segoe UI;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
`;
