import { Button, Col, Input, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { ModalBody } from './style';
import Select from 'components/FormElements/Select';
import { timeOptions } from '../helper';
import { useSelector } from 'react-redux';
import Spinner from 'components/Spinner';

export default ({ handleWorkday, defaultValue }) => {
  const [weekend, setWeekend] = useState({});
  const { loading } = useSelector((state) => state.modalReducer);
  const { Title } = Typography;

  useEffect(() => {
    if (defaultValue?.length) {
      setWeekend(defaultValue[0]);
    }
  }, []);

  const handleSubmit = () => {
    if (
      weekend?.days ||
      weekend?.holidays?.length ||
      weekend?.start?.length ||
      weekend?.end?.length ||
      weekend?.lunchStart?.length ||
      weekend?.lunchEnd?.length
    ) {
      handleWorkday(weekend);
    }
  };
  if (loading) return <Spinner maxHeight="100%" />;
  return (
    <ModalBody>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Title level={5}>Haftalik ish kuni</Title>
          <Space className="h-42" style={{ width: '100%' }}>
            <Input
              type="number"
              width={30}
              placeholder=""
              value={weekend?.days}
              onChange={(e) =>
                (e?.target?.value > 0 || e?.target?.value == '') &&
                setWeekend({ ...weekend, days: e.target.value })
              }
            />
          </Space>
        </Col>
        <Col span={12}>
          <Title level={5}>Dam olish kunlari</Title>
          <DatePicker
            shadow={false}
            weekend="highlight"
            FirstDayOfWeek="Monday"
            multiple
            value={weekend?.holidays ? weekend.holidays : null}
            onChange={(e) => {
              let data = e.map(
                (item) =>
                  `${item.year}-${
                    item.month < 10 ? `0${item.month}` : item.month
                  }-${item.day < 10 ? `0${item.day}` : item.day}`
              );
              setWeekend({ ...weekend, holidays: data });
            }}
          />
        </Col>
        <Col span={12}>
          <Title level={5}>Ishni boshlanish vaqti</Title>
          <Select
            defaultVal={weekend?.start}
            options={timeOptions}
            handleChange={(e) => setWeekend({ ...weekend, start: e })}
          />
        </Col>
        <Col span={12}>
          <Title level={5}>Ishni tugash vaqti</Title>
          <Select
            defaultVal={weekend?.end}
            options={timeOptions}
            handleChange={(e) => setWeekend({ ...weekend, end: e })}
          />
        </Col>
        <Col span={12}>
          <Title level={5}>Tushlik vaqti</Title>
          <Select
            defaultVal={weekend?.lunchStart}
            options={timeOptions}
            handleChange={(e) => setWeekend({ ...weekend, lunchStart: e })}
          />
        </Col>
        <Col span={12}>
          <Title level={5}>Tushlik tugash vaqti</Title>
          <Select
            defaultVal={weekend?.lunchEnd}
            options={timeOptions}
            handleChange={(e) => setWeekend({ ...weekend, lunchEnd: e })}
          />
        </Col>
        <Col span={24} className="submit-button">
          <Button
            disabled={
              !weekend?.days ||
              !weekend?.holidays?.length ||
              !weekend?.start?.length ||
              !weekend?.end?.length ||
              !weekend?.lunchStart?.length ||
              !weekend?.lunchEnd?.length
            }
            type="primary"
            size="large"
            onClick={handleSubmit}
          >
            Belgilash
          </Button>
        </Col>
      </Row>
    </ModalBody>
  );
};
