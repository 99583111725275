import React from 'react';
import styled from 'styled-components';

const Results = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  text-align: center;
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: ${({ small }) => (small ? '24px' : '32px')};
    line-height: 120%;
    @media screen and (max-width: 960px) {
      font-size: 26px;
    }
  }
`;

const Task = ({ title, value, color, small, className }) => {
  return (
    <Results className={className} small={small}>
      <h3
        style={{
          color: color,
        }}
      >
        {value}
      </h3>
      <p
        style={{
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: small ? '8.5px' : '14px',
          lineHeight: small ? '11px' : '15px',
          color: '#A8A8A8',
        }}
      >
        {title}
      </p>
    </Results>
  );
};

export default Task;
