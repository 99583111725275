import React from 'react';
import { StatusCard } from '../style';
import moment from 'moment';

export default ({ color, date, late }) => {
  const getTime = (late) => {
    if (Math.floor(late / 60) > 0) {
      if (late % 60 > 0) {
        return Math.floor(late / 60) + ' s. ' + (late % 60) + ' min';
      } else {
        return Math.floor(late / 60) + ' soat';
      }
    } else {
      return (late % 60) + ' min';
    }
  };

  return (
    <StatusCard color={color}>
      <div>
        <div className="came">Kech qolgan</div>
        <div className="came-time">{getTime(late)}</div>
      </div>
      <div>
        <div className="came-time-text">Kelgan vaqti</div>
        <div className="came-date">{moment(date).format('DD.MM.YY HH:mm')}</div>
      </div>
    </StatusCard>
  );
};
