import styled, { css } from 'styled-components';
import headerBg from '../../assets/header_bg_2.png';

const common = css`
  width: 100vw;
  height: 100vh;
  display: grid;
`;
const roles = {
  regsupervisor: true,
  prosupervisor: true,
  supervisor: true,
};

const tabletDesktop = css`
  ${common};
  grid-template-areas: 'sidebar content';
  grid-template-columns: auto 1fr;
  gap: 10px;
  padding: 16px 16px 0 16px;
  background-image: url(${headerBg});
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f5f7f8;

  @media (max-width: 1800px) {
    background-size: auto;
  }
  @media (max-width: 959.9px) {
    grid-template-areas: 'content';
    grid-template-columns: auto;
    background-size: auto;
  }
`;

export const DesktopContainer = styled.div`
  ${tabletDesktop};
  ${({ role }) =>
    roles[role] &&
    `grid-template-areas: 'content';
     grid-template-columns: 1fr`}
`;

export const TabletContainer = styled.div`
  ${common};
  grid-template-areas: 'content';
  grid-template-columns: 1fr;
  gap: 10px;
  padding: 16px 16px 0 16px;
  background-image: url(${headerBg});
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f5f7f8;
`;

export const MobileContainer = styled.div`
  ${common};
  grid-template-areas: 'content';
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
`;
