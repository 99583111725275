import styled from 'styled-components';

export const TabButton = styled.button`
  display: flex;
  padding: 15px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: ${({ color }) => color && `1px solid ${color}`};
  color: ${({ active, color }) => (active ? '#fff' : color)};
  background: ${({ active, color }) =>
    active ? color : 'rgba(255, 255, 255, 0.12)'};
  backdrop-filter: blur(5px);
  outline: none;
  cursor: pointer;
  font-family: Segoe UI;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.15px;
  transition: all 0.4s ease-in-out;

  @media (max-width: 1655px) {
    min-width: 190px;
  }
`;
