import { Button, Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { Span } from '../../style';
import Select from 'components/FormElements/Select';
import { WeekDays } from '../../helper';
import { WithWeekDay } from '../style';

export default ({ id, data, handleRep }) => {
  const [date, setDate] = useState(data?.date);
  const [week, setWeek] = useState(data?.week);

  return (
    <WithWeekDay>
      <Row justify="center" align="middle">
        <Col span={12}>
          <Span>Sana:</Span>
        </Col>
        <Col span={12}>
          <DatePicker
            placeholder=""
            value={date != null ? moment(date) : null}
            onChange={(e) => setDate(moment(e).format('YYYY-MM-DD'))}
            allowClear={false}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Span>Hafta kuni:</Span>
        </Col>
        <Col span={12}>
          <Select
            options={WeekDays}
            value={week}
            onChange={(e) => setWeek(e)}
          />
        </Col>
      </Row>
      <Row justify="end">
        <Col span={3}>
          <Button
            onClick={() => handleRep(id, { date, type: 'week', week })}
            type="primary"
          >
            Ok
          </Button>
        </Col>
      </Row>
    </WithWeekDay>
  );
};
