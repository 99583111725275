import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MapWrapper } from './style';
import Map from 'components/Map/MapContainer';
import { useDispatch, useSelector } from 'react-redux';
import categoryService from 'services/categories';
import provinceService from 'services/provinces';
import regionService from 'services/regions';
import objectService from 'services/objects';
import supportTypeService from 'services/supportType';
import {
  setCategories,
  setDistricts,
  setProvinces,
  setRegions,
  setSupportTypes,
} from 'redux/modules/application/actions';
import Spinner from 'components/Spinner/FullScreen';
import { useShowAlert } from 'hooks';
import works from 'services/works';

export default () => {
  const { pathname } = useLocation();
  const [data, setData] = useState([]);
  const { error } = useShowAlert();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);
  const { categories, regions, provinces, objects, supportTypes } = useSelector(
    (state) => state.appReducer
  );

  const fetchDataAll = async () => {
    setLoading(true);
    try {
      const result = await works.getAllForMap();
      if (
        categories?.length === 0 &&
        objects?.length === 0 &&
        provinces?.length === 0 &&
        regions?.length === 0 &&
        supportTypes?.length === 0
      ) {
        const catsData = await categoryService.getAll();
        const provincesData = await provinceService.getAll();
        const regionsData = await regionService.getAll();
        const objectsData = await objectService.getAllObjectByRegions();
        const supportTypes = await supportTypeService.getAll();
        dispatch(setDistricts(objectsData));
        dispatch(setCategories(catsData));
        dispatch(setProvinces(provincesData));
        dispatch(setRegions(regionsData));
        dispatch(setSupportTypes(supportTypes));
      }
      setData(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pathname === '/map') {
      fetchDataAll();
    }
  }, [pathname]);

  const fetchMapData = async (payload) => {
    try {
      setMapLoading(true);
      const result = await works.getAllForMap(payload);
      setData(result);
      setMapLoading(false);
    } catch (err) {
      setMapLoading(false);
      error("Ma'lumotlarni yuklashda xatolik yuzaga keldi!", 2, 'topRight');
      console.log(err);
    }
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    pathname === '/map' && (
      <MapWrapper>
        <Map data={data} fetchMapData={fetchMapData} loading={mapLoading} />
      </MapWrapper>
    )
  );
};
