import React from 'react';
import jwt_decode from 'jwt-decode';
import { Container, Content } from './style';
import Navbar from '../Navbar';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminNavbar from '../../views/Admin/AdminNavbar';
import SupervisorNavbar from 'views/Web/Supervisor/SupervisorNavbar';

export default () => {
  const { token, role } = useSelector((state) => state.authReducer); // bunda roleno tokendan olish kerak
  // const { role } = jwt_decode(token);

  const roles = {
    regsupervisor: false,
    prosupervisor: false,
    supervisor: false,
    admin: false,
  };
  const navbarSelector = (role) => {
    switch (role) {
      case 'admin':
        return <AdminNavbar />;
      case 'supervisor':
        return <SupervisorNavbar />;
      case 'prosupervisor':
        return <SupervisorNavbar />;
      case 'regsupervisor':
        return <SupervisorNavbar />;
      default:
        return <Navbar />;
    }
  };

  return (
    <>
      {roles[role] ? (
        <Container>
          <Navbar />
          <Content bg="transparent">
            <Outlet />
          </Content>
        </Container>
      ) : (
        <Container>
          {navbarSelector(role)}
          <Content bg={role === 'admin' ? '#fff' : 'transparent'}>
            <Outlet />
          </Content>
        </Container>
      )}
    </>
  );
};
