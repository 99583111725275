import AddButton from 'components/AddButton';
import React, { useEffect, useState } from 'react';
import { AddContainer, BooksWrapper, CardWrapper, NoData } from './style';
import BookCard from 'components/Cards/BookCard';
import Spinner from 'components/Spinner';
import book from 'services/book';
import { useHideModal, useShowModal } from 'hooks';
import ModalBody from './ModalBody';
import { useDispatch } from 'react-redux';
import { setModalLoading } from 'redux/modules/modals/actions';
import { Empty, Pagination } from 'antd';
import ModalDeleteBody from './ModalDeleteBody';
import ModalCompleted from './ModalCompleted';

export default () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [allTotal, setAllTotal] = useState(0);
  const [paginate, setPaginate] = useState({
    limit: 12,
    page: 1,
  });
  const { showBlured } = useShowModal();
  const { hideModal } = useHideModal();
  const dispatch = useDispatch();

  const fetchData = async () => {
    setLoading(true);
    try {
      const resData = await book.getAllWithPaginate({ limit: 10, page: 1 });
      setAllTotal(resData.totalDocs);
      setData(resData?.docs);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAdd = () => {
    showBlured({
      title: "Kitob ma'lumotlarini kiriting",
      maxWidth: '800px',
      maxHeight: '75vh',
      withHeader: false,
      body: () => <ModalBody handleSubmit={handleSubmit} />,
    });
  };

  const handleSubmit = (payload) => {
    const formData = new FormData();
    formData.append('titleUZ', payload?.titleUZ);
    formData.append('titleQQ', payload?.titleUZ);
    formData.append('titleRU', payload?.titleUZ);
    formData.append('authorUZ', payload?.authorUZ);
    formData.append('authorRU', payload?.authorRU);
    formData.append('authorQQ', payload?.authorQQ);
    formData.append('book', payload?.book);
    formData.append('photo', payload?.photo);
    formData.append('percent', payload?.percent);
    for (let i = 0; i < payload?.provinces?.length; i++) {
      formData.append(`provinces[${i}]`, payload?.provinces[i]);
    }

    dispatch(setModalLoading(true));
    book
      .create(formData)
      .then(() => {
        fetchData();
        hideModal();
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setModalLoading(false)));
  };

  const handleDeleteModal = (id) => {
    showBlured({
      title: '',
      maxWidth: '283px',
      maxHeight: '250px',
      withHeader: false,
      body: () => <ModalDeleteBody handleDelete={() => handleDelete(id)} />,
    });
  };

  const handleDelete = (id) => {
    dispatch(setModalLoading(true));
    book
      .delete(id)
      .then(() => {
        fetchData();
        hideModal();
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setModalLoading(false)));
  };

  const handleCompletedModal = (id) => {
    showBlured({
      title: '',
      maxWidth: '283px',
      maxHeight: '250px',
      withHeader: false,
      body: () => (
        <ModalCompleted handleCompleted={() => handleCompleted(id)} />
      ),
    });
  };

  const handleCompleted = (id) => {
    dispatch(setModalLoading(true));
    book
      .getCompletedBookQuiz(id)
      .then(() => {
        fetchData();
        hideModal();
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setModalLoading(false)));
  };

  const onChange = (pageNumber, limit) => {
    setPaginate({
      limit: limit,
      page: pageNumber,
    });
  };

  if (loading) return <Spinner maxHeight={'100%'} />;
  return (
    <BooksWrapper>
      <AddContainer>
        <AddButton text={'Kitob'} addData={handleAdd} />
      </AddContainer>

      <CardWrapper>
        {data?.length ? (
          data?.map((item, index) => (
            <BookCard
              key={index}
              handleDelete={handleDeleteModal}
              handleCompleted={handleCompletedModal}
              data={item}
              background={'#F5F7F8'}
              hideCount
              createTest
              completed
            />
          ))
        ) : (
          <NoData>
            <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
          </NoData>
        )}
      </CardWrapper>

      {allTotal > 12 && (
        <div className="pagination-wrap">
          <Pagination
            defaultCurrent={paginate?.page}
            total={allTotal}
            defaultPageSize={paginate?.limit}
            pageSizeOptions={[12, 20, 25]}
            onChange={onChange}
          />
        </div>
      )}
    </BooksWrapper>
  );
};
