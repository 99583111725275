import styled from 'styled-components';

export const HiddenTable = styled.div`
  display: none;
`;
export const ExcelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  border: none;
  & button {
    padding: 12px 16px;
    border: none;
    color: white;
    font-weight: 550;
    cursor: pointer;
    border-radius: 12px;
    background: #00c4fa;
    width: 167px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;
