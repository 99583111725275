import React from 'react';
import { CorrectWrapper } from './style';
import { ReactComponent as InCorrectIcon } from '../../../../../../assets/icons/inCorrectAnswer.svg';

export default ({ item, id }) => {
  return (
    <CorrectWrapper>
      <div className="question-header">
        <div className="question-title">
          {id}. {item?.question?.title}
        </div>
        <div className="questionStatus-icon">
          <InCorrectIcon />
        </div>
      </div>
      <div className="answer-result">
        <div className="answer answer-incorrect">{item?.userAnswer?.title}</div>
        <div className="answer answer-correct">
          {item?.correctAnswer?.title}
        </div>
      </div>
    </CorrectWrapper>
  );
};
