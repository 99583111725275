import { service } from '.';

export default {
  getAll: (data) => service.post('/web/reports/discipline', data),
  getLateOrDidNotCome: (data) => service.post('/web/reports/benighted', data),
  getVacant: (data) => service.post('/web/reports/vacancy', data),
  getAreaBorder: (data) => service.post('/web/reports/areaReport', data),
  getOneAreaBorder: (data) =>
    service.post('/web/reports/areaReport/user', data),
  getOneAreaLine: (data) =>
    service.post('/web/reports/areaReport/user/map', data),

  getAreaBorderTime: (data) =>
    service.post('/web/reports/areaReport/deviceOff', data),

  getAreaBorderTimeUser: (data) =>
    service.post('/web/reports/areaReport/deviceOff/user', data),
};
