import styled from 'styled-components';

export const TestCreate = styled.div`
  width: 100%;
  padding: 33px 15px;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;

    .select-wrap {
      width: 100%;
      max-width: 726px;
      input {
        padding-left: 14px;
        color: #080936;
        box-shadow: none;
      }
    }
    .add-btn {
      color: white;
      border: none;
      width: 120px;
      :focus {
        background: rgb(51, 211, 255);
      }
    }
  }

  .blocks {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 20px;

    .add-test {
      color: white;
      background-color: var(--custom-primary);
      width: 165px;
      height: 44px;
      padding: 10px 20px;
      :hover {
        border-color: var(--custom-primary);
      }
      font-size: 15px;
    }
  }
`;

export const Label = styled.div`
  color: white;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  padding-bottom: 10px;
`;

export const TestBlock = styled.div`
  max-width: 726px;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  padding: 0px 24px;

  .test-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
    border-bottom: 1px solid #eff1f8;

    .title-number {
      font-weight: 500;
      font-size: 18px;
      color: #080936;
    }
    .delete-icon {
      height: 22px;
      cursor: pointer;
    }
  }

  .test-body {
    padding: 16px 0px 24px;

    textarea {
      border-radius: 8px;
      background-color: #f3f5f9 !important;
      border-color: #f3f5f9;
      resize: vertical !important;
      padding: 16px 20px;
      font-size: 15px;
      box-shadow: none;
      :focus {
        border-color: #f3f5f9;
        box-shadow: none;
      }
    }

    .answers {
      width: 100%;
      .ant-radio-wrapper {
        width: 20px;
        height: 20px;
        margin: 0;
        span {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

export const Answers = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: 24px;

  .ant-radio-inner {
    border-color: ${({ status }) => (status ? '#ff4d4f' : '')} !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #00826C !important;
  }

  .ant-radio {
    top: -1px;
  }
  .answer-input {
    width: calc(100% - 32px);
    input {
      border: none;
      box-shadow: none;
      border-radius: 0px;
      border-bottom: 1px solid transparent;
      font-weight: 400;
      font-size: 15px;
      padding-left: 0px;
      color: #080936;

      :hover {
        border-bottom: 1px solid #105fff;
        box-shadow: none !important;
      }
      :focus {
        box-shadow: none !important;
      }
      ::placeholder {
        color: #a3aed0;
      }
    }
  }
`;
