import React, { useEffect, useState } from 'react';
import {
  ButtonWrapper,
  ImageUploadWrap,
  Item,
  Label,
  ModalBodyWrapper,
} from './style';
import { Col, Image, Input, Row } from 'antd';
import fileUpload from '../../../../assets/book/fileUpload.svg';
import imageUpload from '../../../../assets/book/imageUpload.svg';
import editIcon from '../../../../assets/book/editIcon.svg';
import deleteIcon from '../../../../assets/book/deleteIcon.png';
import { useSelector } from 'react-redux';
import Spinner from 'components/Spinner';
import Select from 'components/FormElements/Select';
import provinceService from 'services/provinces';

export default ({ handleSubmit }) => {
  const [sentData, setSentData] = useState({});
  const { loading } = useSelector((state) => state.modalReducer);
  const [provinces, setProvinces] = useState([]);

  const data = [
    {
      id: 1,
      value: 'titleUZ',
      title: 'Kitob nomi(UZ)',
      type: 'string',
    },
    {
      id: 2,
      value: 'titleRU',
      title: 'Kitob nomi(RU)',
      type: 'string',
    },
    {
      id: 3,
      value: 'titleQQ',
      title: 'Kitob nomi(QQ)',
      type: 'string',
    },
    {
      id: 4,
      value: 'authorUZ',
      title: 'Muallifi(UZ)',
      type: 'string',
    },
    {
      id: 5,
      value: 'authorRU',
      title: 'Muallifi(RU)',
      type: 'string',
    },
    {
      id: 6,
      value: 'authorQQ',
      title: 'Muallifi(QQ)',
      type: 'string',
    },
    {
      id: 7,
      value: 'percent',
      title: 'Foizi(%)',
      type: 'number',
    },
  ];

  const handleFileUpload = (e) => {
    if (e.target.files[0] !== undefined) {
      const book = e.target.files[0];
      setSentData({ ...sentData, book: book });
    }
  };

  const handleImageUpload = (e) => {
    if (e.target.files[0] !== undefined) {
      const photo = e.target.files[0];
      setSentData({ ...sentData, photo: photo });
    }
  };

  const handleRemoveImage = () => {
    const newSentData = { ...sentData };
    delete newSentData['photo'];
    setSentData({ ...newSentData });
  };

  const handleClickButton = () => {
    const newSentData = {
      ...sentData,
    };
    if (sentData?.percent) {
      newSentData['percent'] = Number(sentData?.percent);
    } else {
      delete newSentData['percent'];
    }
    handleSubmit(newSentData);
  };

  useEffect(() => {
    provinceService.getAll().then((res) => {
      const finallyData = res?.map((itm) => ({
        ...itm,
        title: itm?.titleUZ,
      }));
      setProvinces(finallyData);
    });
  }, []);

  return (
    <ModalBodyWrapper>
      {loading ? (
        <Spinner maxHeight={'100%'} />
      ) : (
        <Row gutter={[20, 20]}>
          {data?.map((item) => (
            <Col span={12} key={item?.id}>
              <Item>
                <Label>{item?.title}</Label>
                {item?.type === 'string' ? (
                  <Input
                    onChange={(e) =>
                      setSentData({
                        ...sentData,
                        [`${item?.value}`]: e?.target?.value,
                      })
                    }
                    value={sentData[item?.value] || ''}
                  />
                ) : (
                  <Input
                    type={item.type}
                    onChange={(e) =>
                      (e?.target.value >= 0 || e.target.value === '') &&
                      setSentData({
                        ...sentData,
                        [`${item?.value}`]: e?.target?.value,
                      })
                    }
                    value={sentData[item?.value] || ''}
                  />
                )}
              </Item>
            </Col>
          ))}
          <Col span={12}>
            <Item>
              <Label>Fayl yuklash</Label>
              <div className="file-upload">
                <label htmlFor="fileUpload">
                  <img src={fileUpload} alt="" />
                  Fayl yuklang
                </label>
                <input
                  type="file"
                  id="fileUpload"
                  onChange={(e) => handleFileUpload(e)}
                  accept=".epub"
                />
                <div>
                  {sentData?.book?.name?.length > 12
                    ? sentData?.book?.name?.slice(0, 9) +
                      '...' +
                      sentData?.book?.name.slice(-3)
                    : sentData?.book?.name}
                  {sentData?.book &&
                    `. (${
                      (sentData?.book?.size / 1024 / 1024).toFixed(2) + 'mb'
                    })`}
                </div>
              </div>
            </Item>
          </Col>
          <Col span={12}>
            <Item>
              <Label>Viloyatni tanlang</Label>
              <Select
                multiple
                options={provinces ?? []}
                defaultVal={sentData?.provinces}
                handleChange={(e) => setSentData({ ...sentData, provinces: e })}
              />
            </Item>
          </Col>
          <Col span={24}>
            <ImageUploadWrap>
              <div className="image-upload">
                {sentData?.photo ? (
                  <Image src={URL?.createObjectURL(sentData?.photo)} />
                ) : (
                  <label htmlFor="imageUpload">
                    <img src={imageUpload} alt="" />
                  </label>
                )}

                <input
                  type="file"
                  id="imageUpload"
                  onChange={(e) => handleImageUpload(e)}
                  accept=".png, .jpg, .jpeg"
                />
              </div>
              <div className="right-section">
                <div className="right-section-label">Muqova rasmi</div>
                {sentData?.photo && (
                  <div className="right-action">
                    <label htmlFor="imageUpload" className="editButton action">
                      <img src={editIcon} alt="" /> Rasmni tahrirlash
                    </label>
                    <div
                      className="deleteButton action"
                      onClick={handleRemoveImage}
                      aria-hidden
                    >
                      <img src={deleteIcon} alt="" /> Rasmni o’chirish
                    </div>
                  </div>
                )}
              </div>
            </ImageUploadWrap>
          </Col>
          <Col span={24}>
            <ButtonWrapper>
              <button
                className="submit-button"
                onClick={handleClickButton}
                disabled={
                  !sentData?.titleUZ ||
                  !sentData?.titleRU ||
                  !sentData?.titleQQ ||
                  !sentData?.authorUZ ||
                  !sentData?.authorRU ||
                  !sentData?.authorQQ ||
                  !sentData?.provinces?.length ||
                  !sentData?.book ||
                  !sentData?.photo
                }
              >
                Saqlash
              </button>
            </ButtonWrapper>
          </Col>
        </Row>
      )}
    </ModalBodyWrapper>
  );
};
