import styled from 'styled-components';

export const CorrectWrapper = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background: #fc66660d;
  border: 1px solid #fc666633;

  & .question-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 16px;

    .question-title {
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      color: #001444;
    }

    .questionStatus-icon {
      width: 20px;
      height: 20px;
    }
  }

  .answer-result {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    .answer {
      width: auto;
      padding: 11px 16px;
      margin-top: 15px;
      background-color: #ffffff;
      border-radius: 6px;
      font-size: 15px;
      line-height: 19.5px;
      font-weight: 400;
      font-style: italic;
    }

    .answer-correct {
      color: #019875;
    }
    .answer-incorrect {
      color: #fc6666;
    }
  }
`;
