import { service } from '.';

export default {
  getAllgroupsReport: (data) =>
    service.post('/web/reports/planAllgroups', data),
  // getAllSpecialtyReport: (data) =>
  //   service.post('/web/reports/categoryPlan', data), // sohalarda all plans by category
  getAllNewSpecialtyReport: (data) =>
    service.post('/web/webReport/categoryPlan', data), // new sohalarda all plans by category
  // getOneSpecialtyReport: (data) =>
  //   service.post('/web/reports/subCategoryPlan', data), // sohalarda category bo'yicha plan
  getOneNewSpecialtyReport: (data) =>
    service.post('/web/webReport/subCategoryPlan', data), // new sohalarda category bo'yicha plan
  getAllAdvancedReport: (data) =>
    service.post('/web/reports/advancedPlan', data), // yetkachgi role sohalarda all plans by category
  getAllRegionsReport: (data) => service.post('/reports/planRegions', data),
  getGroupReport: (data) => service.post('/reports/reportGroup', data),
  getAllByRegions: (data) => service.post('/reports/planRegionsAll', data),
  getOneRateReport: (data) => service.post('/reports/oneRate', data),
  getOneDaiyReport: (data) => service.post('/reports/oneRateByDaily', data),
  getAllgroupRateReport: (data) => service.post('/reports/groupRate', data),
  getAllgroupDailyReport: (data) =>
    service.post('/reports/groupRateDaily', data),
  getReportByCategory: (data) => service.post('/reports/report', data),
  getAllOnegroupReport: (data) => service.post('/reports/getOne', data),

  getReportByOrganization: (data) => service.post('reports/organization', data),
  getUserReportBySchedule: (data) => service.post('/reports/comeBack', data),
  getUserReportByPayment: (data) => service.post('/reports/paymentCheck', data),

  // Integration
  getAdvancedReportsByDaily: (data) =>
    service.post('/web/webReport/deilyRating', data),
  getAdvancedReportsByTimeInterval: (data) =>
    service.post('/web/webReport/dateRating', data),
  getAllDaiyReport: (data) =>
    service.post('/web/webReport/dailyRateRating', data),
  getAllSubReport: (data) => service.post('/web/webReport/subRateRating', data),
  getAllRateReport: (data) =>
    service.post('/web/webReport/findRateRating', data),
  getAllReportByCategories: (data) =>
    service.post('/web/webReport/speciality', data),
  getAllReportBySubCategories: (data) =>
    service.post('/web/webReport/affairs', data),
  getAllUserPlans: (data) => service.post('/web/reports/arrearage', data),

  getAdvancedReportsByDailySub: (data) =>
    service.post('/web/webReport/deilyRatingSub', data),
  getAdvancedReportsByTimeIntervalSub: (data) =>
    service.post('/web/webReport/dateRatingSub', data),
  getUsedAppReports: (data) => service.post('/web/reports/usedApp', data),
  getUsedAppSubReports: (data) =>
    service.post('/web/reports/usedApp/user', data),
  getBooksReports: (data) => service.post('/web/reports/readbookReport', data),
  getSubBooksReports: (data) =>
    service.post('/web/reports/readbookFullReport', data),
  getSupportReports: (data) => service.post('/web/reports/support', data),
  getPaymentReports: (data) => service.post('/web/reports/checkPay', data),
};
