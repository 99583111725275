export const makeReport = (reportData = [], categories = [], page, role) => {
  const report = reportData.map((item, idx) => {
    let categoriesAll = {};

    categories.forEach((itmn) => {
      const cat = item?.categorys.find((e) => e?.category?._id === itmn?._id);
      categoriesAll[itmn?._id] = {
        value: cat?.rate ? Math.round(cat?.rate) : 0,
        id: itmn?._id,
        userId: item?.user?._id,
        categoryValue: cat?.rate ? Math.round(cat?.rate) : 0,
        categoryTitle: itmn?.titleUZ,
        groupId: Math.floor(Math.random() * Date.now()),
      };
    });
    return {
      id: page + idx + 1,
      ...categoriesAll,
      user_name: {
        value: item?.user?._id
          ? `${item?.user?.firstName} ${item?.user?.lastName}`
          : '',
      },
      province: {
        name: item?.user?.province?.titleUZ ?? '-',
        value: Math.min(Math.round(item?.rates), 100),
      },

      region: {
        name: item?.user?.region?.titleUZ ?? '-',
        value: Math.min(Math.round(item?.rates), 100),
      },
      district: {
        name: item?.user?.district?.titleUZ ?? '-',
        value: Math.min(Math.round(item?.rates), 100),
      },
      total: {
        value: Math.min(Math.round(item?.rates), 100),
      },
    };
  });
  return report;
};

export const makeAdvancedReport = (reportData = [], categories = []) => {
  const total =
    reportData.reduce((value, item) => value + item?.rate, 0) /
    reportData.length;
  const userInfo = {
    value: total,
    name: reportData[0]?.user
      ? `${reportData[0]?.user.firstName} ${reportData[0]?.user.lastName}`
      : '',
  };
  const report = reportData.map((item) => {
    return {
      value: item?.rate ? Math.round(item?.rate) : 0,
      id: item?.category?._id,
      userId: item?.user?._id,
      categoryValue: item?.rate ? Math.round(item?.rate) : 0,
      categoryTitle: item?.category?.titleUZ,
      groupId: Math.floor(Math.random() * Date.now()),
    };
  });
  let finalRes = report.reduce((ac, a) => ({ ...ac, [a.id]: a }), {});
  const catHeads = categories.map((item) => {
    return Object.keys(finalRes).includes(item?._id)
      ? finalRes[item?._id]
      : {
          value: 0,
          id: item?._id,
          userId: reportData[0]?.user?._id,
          categoryValue: 0,
          categoryTitle: item?.titleUZ,
          groupId: Math.floor(Math.random() * Date.now()),
        };
  });
  let res = catHeads.reduce((ac, a) => ({ ...ac, [a.id]: a }), {});

  return report.length > 0
    ? [
        {
          id: 1,
          user_name: userInfo,
          total: {
            value: Math.round(Math.min(total, 100)),
          },
          ...res,
        },
      ]
    : [];
};

//************************************** */
const titleSelector = (data) => {
  const lang = ['UZ', 'RU', 'EN'].includes(localStorage.getItem('lang'))
    ? localStorage.getItem('lang')
    : 'UZ';
  return data.titleUZ
    ? data?.[`title${lang}`]
    : `${data.district?.[`title${lang}`]}`;
};

export const makeReportAllCategory = (
  // Sohalarga report qiberadi
  reportsData = []
) => {
  const report = reportsData.map((item) => {
    return {
      id: Date.now(),
      mainTitle: titleSelector(item?.category) ?? '',
      categoryId: item?.category?._id ?? '',
      tasks: {
        marked: item?.doc?.total,
        completed: item?.doc?.done,
      },
      percent: checkWorkAndPlan(item?.doc?.done, item?.doc?.total),
    };
  });
  return report;
};

export const makeReportAllgroups = (
  // Sohalarga report qiberadi
  reportsData = []
) => {
  const report = reportsData.map((item) => {
    const results = item?.titles.map((value) => {
      return {
        id: Date.now(),
        title: titleSelector(value?.title),
        percent: checkWorkAndPlan(value?.work, value?.plan),
      };
    });

    return {
      id: Date.now(),
      mainTitle: titleSelector(item?.category) ?? '',
      categoryId: item?.category?._id ?? '',
      tasks: {
        marked: item?.plans,
        completed: item?.works,
      },
      percent: checkWorkAndPlan(item?.works, item?.plans),
      results: results
        ?.filter((item) => item?.title !== 'undefined')
        ?.sort((a, b) => b?.percent - a?.percent)
        ?.slice(-4),
    };
  });
  return report;
};

export const makeReportGrops = (card = [], categories = []) => {
  const cardReport = card.map((item) => {
    const category = categories.find((itm) => itm._id === item?.category);
    const group = item?.groups
      .map((value) => {
        return {
          id: value?.group?._id,
          title: value?.group?.title ?? '',
          percent: checkWorkAndPlan(value.work, value.plan),
        };
      })
      ?.sort((a, b) => b?.percent - a?.percent)
      ?.slice(-4);

    return {
      id: Date.now(),
      mainTitle: category ? category.title : '',
      categoryId: category ? category._id : '',
      resultsTitle: 'Гуруҳлар умумий кўрсаткичлари',
      tasks: {
        marked: item?.plans,
        completed: item?.works,
      },
      percent: checkWorkAndPlan(item?.works, item?.plans),
      results: group,
    };
  });
  return cardReport;
};

export const makeReportOnegroup = (reportsData = []) => {
  const report = reportsData.map((item) => {
    return {
      id: Date.now(),
      mainTitle: item?.category.title ? item?.category.title : '',
      categoryId: item?.category?._id ? item?.category?._id : '',
      resultsTitle: 'Гуруҳлар умумий кўрсаткичлари',
      tasks: {
        marked: item?.plans,
        completed: item?.works,
      },

      percent: checkWorkAndPlan(item?.works, item?.plans),
      results: '',
    };
  });
  return report;
};
export const checkWorkAndPlan = (work, plan) => {
  if (work === 0 && plan === 0) {
    return 0;
  }
  if (plan === 0 && work > 0) {
    return 100;
  }
  return Math.min(Math.round((Number(work) * 100) / Number(plan)), 100);
};

const checkWorksAndPlans = (data = []) => {
  if (data.length == 0) return 0;
  return (
    data
      .map((item) => checkWorkAndPlan(item?.work, item?.plan))
      .reduce((total, itm) => total + itm) / data.length
  );
};

export const makeReportBygroup = (reportsData = [], regions = [], category) => {
  const data = regions.map((item) => {
    const foo = reportsData[0].titles.filter(
      (itm) => itm?.region?._id === item?._id
    );
    if (foo.length === 1) {
      return {
        regionName: item?.title,
        percent: checkWorkAndPlan(foo[0].work, foo[0].plan),
      };
    } else {
      return {
        regionName: item?.title,
        percent: checkWorksAndPlans(foo),
      };
    }
  });
  return data;
};

export const makeReportOneCategory = (reportsData = []) => {
  const card = reportsData.map((item) => ({
    id: Date.now(),
    mainTitle: titleSelector(item?.category) ?? '',
    categoryId: item?.category?._id || '',
    // resultsTitle: 'Гуруҳлар умумий кўрсаткичлари',
    plans: item?.plans,
    works: item?.works,
    percent: checkWorkAndPlan(item?.works, item?.plans),
  }));

  const diagramm = reportsData[0]?.titles
    ?.map((item) => ({
      regionName: titleSelector(item?.title),
      percent: checkWorkAndPlan(item?.work, item?.plan) ?? 0,
    }))
    .sort((a, b) => b?.percent - a?.percent);
  return {
    card,
    diagramm,
  };
};
