import styled from 'styled-components';

export const PageFilters = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 0;
  position: relative;
  top: 0;
  left: 0;
  box-sizing: border-box;
  gap: 10px;
  .main__filter {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    @media screen and (max-width: 992px) {
      .child-1 {
        order: 2;
        width: 100%;
      }
      .child-2 {
        order: 1;
      }
    }
  }
  .excell__btn {
    margin-left: auto;
    justify-self: end;
    @media screen and (max-width: 1713px) {
      align-self: flex-start;
      padding-top: 20px;
    }
    @media screen and (max-width: 992px) {
      position: absolute;
      right: 0;
      top: 0;
      padding-top: 10px;
    }
  }
`;

export const FilterGroups = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  transition: height 0.3s ease-in-out;
  .main__form {
    display: flex;
    min-height: 50px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 10px;
    label {
      color: #fff !important;
    }
  }

  .filter__item {
    .range-datepicker {
      max-width: 100% !important;
      min-width: 100% !important;
    }
    .filter__label {
      color: #fff;
      font-family: Segoe UI;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 15px */
      padding: 0;
      margin: 0;
    }
  }
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0px;
  color: #080936;
  font-family: 'Segoe UI';
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  height: calc(100vh - 400px);
`;

export const ActionTabs = styled.div`
  /* width: 100%; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  /* text-align: center; */
  gap: 10px;
`;
