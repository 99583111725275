import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRoutes } from 'react-router-dom';
import {
  adminRoutes,
  leadersRoutes,
  publicRoutes,
  supervisorRoutes,
  advancedRoutes,
  regionLeadersRoutes,
  provinceLeadersRoutes,
} from 'routes/routes';

const routes = {
  regsupervisor: supervisorRoutes,
  prosupervisor: supervisorRoutes,
  supervisor: supervisorRoutes,
  admin: adminRoutes,
  proleader: provinceLeadersRoutes,
  regleader: regionLeadersRoutes,
  leader: leadersRoutes,
  advanced: advancedRoutes,
};

const App = () => {
  const { pathname } = useLocation();
  const { role, token } = useSelector((state) => state.authReducer);
  const content = useRoutes(token ? routes?.[role] : publicRoutes);
  return <>{pathname !== '/map' && content}</>;
};

export default React.memo(App);
