import React from 'react';
import { CustomButton } from './style';

export default ({ icon: Icon, text, onClick, iconFirst }) => {
  return (
    <CustomButton onClick={onClick} iconFirst={iconFirst}>
      {text} <Icon />
    </CustomButton>
  );
};
