import { ButtonSelect, Text } from './style';
import React, { useState } from 'react';
import { Button, Col, Row, Table, Space, Input, DatePicker } from 'antd';
import Typograpy from '../../../components/Typograpy';
import { AddContainer } from './style';
import AddButton from '../../../components/AddButton';
import ActionButton from '../../../components/ActionButton';
import { useHideModal, useShowModal } from '../../../hooks';
import {
  formData,
  initialValuesAdd,
  workStatusList,
  evalutionRoles,
} from './helper';
import FormCreator from '../../../components/FormCreator';
import Confirm from '../../../components/Confirm';
import categoryService from '../../../services/categories';
// import groupService from '../../../services/groups';
import planService from '../../../services/plans';
// import tgService from '../../../services/tggroup';
// import objectService from '../../../services/objects';
import { useEffect } from 'react';
import { useShowAlert } from '../../../hooks/alert';
import FullScreen from '../../../components/Spinner/FullScreen';
import { useDataFetcher } from '../../../hooks';
import moment from 'moment';
// import { Calendar } from 'react-multi-date-picker';
// import { Switch } from 'antd';
import { BiEdit, BiTrash } from 'react-icons/bi';

import ModalRep from './ModalRep';
// import Select from 'components/FormElements/Select';
// import ModalImportant from './ModalImportant';
import { useSelector } from 'react-redux';

export default () => {
  const { lang } = useSelector((state) => state.appReducer);
  const [repetition, setRepetition] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const { success, error } = useShowAlert();
  const { regionsList, provinceList } = useDataFetcher({
    province: true,
    region: true,
  });
  const { showBlured } = useShowModal();
  const { hideModal } = useHideModal();
  const [loading, setLoading] = useState(true);
  const [parentCategories, setParentCategories] = useState([]);
  const [bool, setBool] = useState(true);
  const [active, setActive] = useState(false);
  const [tempData, setTempData] = useState([]);
  // const [telegramGroups, setTelegramGroups] = useState([]);
  // const [important, setImportant] = useState([]);
  // const [group, setGroup] = useState('');
  const [objects, setObjects] = useState([]);
  // const [valueImportant, setValueImportant] = useState([]);

  useEffect(() => {
    const data = { language: lang };
    setActive(undefined);

    categoryService
      .getAll(data)
      .then((res) => {
        setTempData(res);
        setParentCategories(
          res
            .filter((item) => item.parent == null)
            .map((item) => {
              return {
                ...item,
                title: item['title' + lang],
              };
            })
        );
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [bool]);

  useEffect(() => {
    if (active) {
      const data = { category: active };
      fetchPlans(data);
    }
  }, [active]);

  useEffect(() => {
    const subcat = tempData
      .filter((item) => {
        return item.parent === active;
      })
      .map((item, index) => {
        return { ...item, key: index + 1, title: item['title' + lang] };
      });
    setSubCategories(subcat);
  }, [active]);

  // useEffect(() => {
  //   // fetchUserData(); // fetch groups old code
  //   fetchImportants();
  // }, []);

  const fetchPlans = (payload) => {
    planService
      .getAllByCategory(payload)
      .then((resp) => {
        const data = resp.map((item) => {
          return {
            category: item?.category,
            subCategory: item?.subCategory,
            repetition: item?.repetition,
            count: item?.count,
            date: item?.dates[item.dates.length - 1],
            week: item?.week,
            type: item?.type,
            days: item?.days,
          };
        });

        const pls = getUniqueListBy(data, 'subCategory');
        setRepetition(pls);
      })
      .catch((err) => console.log(err));
  };

  // const fetchImportants = () => {
  //   categoryService
  //     .importants()
  //     .then((resp) => {
  //       setImportant(
  //         resp.map((item) => ({
  //           category: item._id,
  //           dates: item.dates,
  //         }))
  //       );
  //     })
  //     .finally(() => setLoading(false))
  //     .catch((err) => console.log(err));
  // };

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  //Add category

  const sendData = (data) => {
    let reqBody = data;
    hideModal();
    setLoading(true);
    for (let key of Object.keys(data)) {
      if (data[key] === '' || data[key] === undefined) {
        delete data[key];
      }
    }

    // if (data['role']) {
    //   delete reqBody['role'];
    // }
    // if (data['regions']) {
    //   reqBody['regions'] = data['regions'];
    // }

    categoryService
      .create(reqBody)
      .then(() => {
        setBool(!bool);
        setActive(undefined);
        success("Kategoriya ma'lumoti saqlandi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Kategoriya ma'lumotini saqlanda xatolik", 2, 'bottom');
      });
  };
  const addCategory = () => {
    showBlured({
      title: "Kategoriya qo'shish",
      maxWidth: '50vw',
      height: '400px',
      withHeader: false,
      body: () => (
        <FormCreator
          formData={formData}
          cancel={hideModal}
          sendData={sendData}
          selectOptions={{
            parent: parentCategories,
            object: objects,
            regions: regionsList,
            evalutionRole: evalutionRoles,
            // group: groups,
            // workStatus: workStatusList,
          }}
          id=""
          initialVal={{
            ...initialValuesAdd,
            // group: group,
            parent: active,
          }}
        />
      ),
    });
  };

  // Update

  const initialValuesEdit = (data) => {
    let newData = {
      titleQQ: data.titleQQ,
      titleRU: data.titleRU,
      titleUZ: data.titleUZ,
      desc: data.desc,
      meet: data.meet,
      support: data.support,
      webEdit: data.webEdit,
      withVideo: data.withVideo,
      // group: data.group,
      // special: data.special,
      // public: data.public,
      // telGroupId: data.telGroupId,
      // workStatus: data.workStatus,
      // regions: data.regions,
      // role: 'editor',
    };
    if (data.parent)
      newData = {
        ...newData,
        parent: data.parent,
        evalutionRole: data.evalutionRole,
      };

    return newData;
  };

  const updateData = (id, data) => {
    hideModal();
    setLoading(true);
    categoryService
      .update(id, data)
      .then(() => {
        setBool(!bool);
        setActive(undefined);
        success("Kategoriya ma'lumoti yangilandi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Kategoriya ma'lumoti yangilashda xatolik", 2, 'bottom');
      });
  };

  const handleEdit = (data) => {
    showBlured({
      title: 'Kategoriyani tahrirlash',
      maxWidth: '650px',
      height: '600px',
      withHeader: false,
      body: () => (
        <FormCreator
          formData={formData}
          cancel={hideModal}
          sendData={updateData}
          selectOptions={{
            parent: parentCategories,
            object: objects,
            evalutionRole: evalutionRoles,
            // group: groups,
            regions: regionsList,
            workStatus: workStatusList,
          }}
          id={data._id}
          initialVal={initialValuesEdit(data)}
        />
      ),
    });
  };

  // Delete
  const deleteCategory = (id) => {
    hideModal();
    setLoading(true);
    categoryService
      .delete(id)
      .then(() => {
        setBool(!bool);
        if (subCategories.length != []) {
          setSubCategories((prev) => prev.filter((item) => item._id !== id));
        } else {
          setActive(undefined);
        }
        success("Kategoriya o'chirildi", 2, 'bottom');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        error("Kategoriyani o'chirishda xatolik", 2, 'bottom');
      });
  };

  const handleDelete = (id) => {
    showBlured({
      maxWidth: '400px',
      height: '300px',
      withHeader: false,
      body: () => (
        <Confirm agree={() => deleteCategory(id)} cancel={hideModal} />
      ),
    });
  };

  const handleRep = (subCategory, value) => {
    const data = {
      category: active,
      subCategory: subCategory,
      ...value,
    };
    setLoading(true);
    planService
      .create(data)
      .then(() => fetchPlans({ category: active }))
      .finally(() => setLoading(false))
      .catch((err) => console.log(err));
    hideModal();
  };

  const getIndex = (id) => {
    return repetition.findIndex((item) => item.subCategory === id);
  };

  // const handleSendImportant = (catId, impId, publicT, selects) => {
  //   hideModal();
  //   const data = {
  //     subCategory: catId,
  //     dates: valueImportant,
  //     region: selects?.region,
  //     province: selects?.province,
  //   };
  //   impId
  //     ? categoryService
  //         .updateImportant(impId, data)
  //         .then(() => {
  //           fetchImportants();
  //         })
  //         .catch((err) => {
  //           error("Xatolik yuz berdi qayta urinib ko'ring!", 2, 'bottom');
  //           console.log(err);
  //         })
  //     : categoryService
  //         .addImportant(data)
  //         .then(() => {
  //           fetchImportants();
  //         })
  //         .catch((err) => {
  //           error("Xatolik yuz berdi qayta urinib ko'ring!", 2, 'bottom');
  //           console.log(err);
  //         });
  // };

  // const handleImportant = (id) => {
  //   let imp = important.filter((item) => item.category == id);
  //   let dates;
  //   let impId;
  //   if (imp) {
  //     dates = imp[0]?.dates.map((item) => new Date(item));
  //     impId = imp[0]?._id;
  //   }

  //   showBlured({
  //     title: 'Dolzarb',
  //     maxWidth: '400px',
  //     height: '600px',
  //     withHeader: false,
  //     body: () => {
  //       return (
  //         <ModalImportant
  //           dates={dates}
  //           valueImportant={valueImportant}
  //           setValueImportant={setValueImportant}
  //           impId={impId}
  //           id={id}
  //           handleSendImportant={handleSendImportant}
  //           provinceData={provinceList}
  //           regionData={regionsList}
  //         />
  //       );
  //     },
  //   });
  // };

  const handleRepetition = (id, editData) => {
    showBlured({
      title: 'Takroriyligi',
      maxWidth: '400px',
      maxHeight: '600px',
      withHeader: false,
      body: () => {
        return <ModalRep handleRep={handleRep} id={id} data={editData} />;
      },
    });
  };

  const DeleteRepetition = (id) => {
    // const data = {
    //   category: active,
    //   subCategory: id,
    //   repetition: 4,
    // };
    setLoading(true);
    planService
      .deleteByCategory(id)
      .then(() => fetchPlans())
      .finally(() => setLoading(false))
      .catch((err) => console.log(err));
    hideModal();
  };

  const handleDeleteRepetition = (id) => {
    showBlured({
      maxWidth: '400px',
      height: '300px',
      withHeader: false,
      body: () => {
        return (
          <Confirm agree={() => DeleteRepetition(id)} cancel={hideModal} />
        );
      },
    });
  };

  // function getDates(id) {
  //   let imp = important?.find((item) => item.category == id);
  //   return imp ? imp.dates : [];
  // }

  const columns = [
    {
      title: 'Nomi',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Takroriyligi',
      dataIndex: 'takroriyligi',
      key: '',
      align: 'center',
      width: '160px',
      render: (props, row) => (
        <div
          style={{
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
          }}
        >
          <DatePicker
            placeholder=""
            disabled
            value={
              repetition[getIndex(row._id)]?.date
                ? moment(repetition[getIndex(row._id)]?.date)
                : null
            }
            onChange={() => {}}
          />
          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'row',
            }}
          >
            <Input
              disabled
              // key={props._id}
              type="number"
              onChange={() => {}}
              value={repetition[getIndex(row._id)]?.repetition}
              width="20px"
              style={{ padding: '4px 7px' }}
            />
            <Button
              style={{
                background: 'none',
                border: 'none',
                margin: '0',
                color: '#ff0000',
              }}
              onClick={() => handleDeleteRepetition(row._id)}
              shape="circle"
              icon={<BiTrash size={20} />}
            ></Button>
            <Button
              style={{
                background: 'none',
                border: 'none',
                margin: '0',
                color: '#c2c2c2',
              }}
              onClick={() =>
                handleRepetition(row._id, repetition[getIndex(row._id)])
              }
              shape="circle"
              icon={<BiEdit size={20} />}
            ></Button>
          </div>
        </div>
      ),
    },
    // {
    //   title: 'Dolzarb',
    //   dataIndex: '',
    //   align: 'center',
    //   key: 'x',
    //   width: '20%',
    //   render: (props, row) => (
    //     <div
    //       style={{
    //         display: 'flex',
    //         alignItems: 'flex-start',
    //         justifyContent: 'space-between',
    //         padding: '5px',
    //       }}
    //     >
    //       <div>
    //         <Row gutter={[20, 10]}>
    //           {getDates(row?._id).map((item, ind) => (
    //             <Col key={ind + 1}>
    //               <BadgeContainer>
    //                 <Tag color="green">{moment(item).format('DD-MM')}</Tag>
    //                 <Badge
    //                   onClick={() =>
    //                     handleDeleteDate({
    //                       subCategory: row?._id,
    //                       dates: [item],
    //                     })
    //                   }
    //                 >
    //                   x
    //                 </Badge>
    //               </BadgeContainer>
    //             </Col>
    //           ))}
    //         </Row>
    //       </div>
    //       <Button
    //         style={{
    //           background: 'none',
    //           border: 'none',
    //           margin: '0',
    //           color: '#c2c2c2',
    //         }}
    //         onClick={() => handleImportant(row._id)}
    //         shape="circle"
    //         icon={<BiEdit size={25} />}
    //       ></Button>
    //     </div>
    //   ),
    // },
    {
      width: '100px',
      align: 'center',
      title: 'Action',
      dataIndex: '',
      key: 'y',
      render: (props) => (
        <ActionButton
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          props={props}
        />
      ),
    },
  ];
  // const handleDeleteDate = (payload) => {
  //   setLoading(true);
  //   categoryService
  //     .deleteImportant(payload)
  //     .then(() => {
  //       fetchImportants();
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const handleSelect = (foof) => {
  //   setGroup(foof);
  // };

  if (loading) return <FullScreen />;
  return (
    <>
      <AddContainer>
        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            fontWeight: 550,
          }}
        >
          {' '}
          <div>Гуруҳ</div>
          <Select
            allowClear={false}
            options={groups}
            handleChange={handleSelect}
            defaultVal={group}
          />
        </div> */}
        <AddButton
          text="Kategoriya"
          formData={formData}
          addData={addCategory}
        />
      </AddContainer>
      <Row>
        <Col span={7}>
          <Typograpy color="#080936">Asosiy kategoriyalar</Typograpy>{' '}
          <Space
            direction="vertical"
            size="middle"
            style={{
              display: 'flex',
              padding: '0 20px',
            }}
          >
            {parentCategories.map((item, index) => (
              <Row key={index + 1}>
                <Col span={24}>
                  <ButtonSelect
                    isActive={active == item?._id}
                    onClick={() => setActive(item._id)}
                  >
                    <Text>
                      {item?.title?.length > 25
                        ? item?.title.slice(0, 25) + '...'
                        : item?.title}
                    </Text>
                    <Text>
                      {active == item?._id ? (
                        <ActionButton
                          handleEdit={handleEdit}
                          handleDelete={handleDelete}
                          props={item}
                        />
                      ) : null}
                    </Text>
                  </ButtonSelect>
                </Col>
              </Row>
            ))}
          </Space>
        </Col>
        <Col span={17}>
          <Typograpy color="#080936">Sub kategoriyalar</Typograpy>
          <Space
            direction="vertical"
            size="middle"
            style={{
              display: 'flex',
              padding: '0 20px',
            }}
          >
            <Table
              bordered
              columns={columns}
              dataSource={subCategories}
              pagination={false}
            />
          </Space>
        </Col>
      </Row>
    </>
  );
};
