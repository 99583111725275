import React, { useMemo, useState } from 'react';
import Wrapper from '../../../components/Wrapper';
import Typograpy from '../../../components/Typograpy';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import BySubcategories, {
  createUserDataBySubcategory,
} from './BySubcategories';
import ByCategories, { createUserDataByCategory } from './ByCategories';
import ByUsersPlan, { createUserDataByUserPlan } from './ByUsersPlan';
import ReportTab from 'components/Tabs/ReportTab';
import { buttons } from './helper';
import { useFilter } from 'views/useFilter';
import DynamicFilters from 'components/DynamicFilters';
import FilterForm from 'components/DynamicFilters/Form';
import { useWindowSize } from 'hooks/useDevice';
import DownloadToExcell from 'components/DownloadToExcell';
import { ActionTabs, FilterGroups, PageFilters } from './style';
import reportService from 'services/reports';
import Supports, {createUserDataBySupport} from './Supports';

export default () => {
  const { role } = useSelector((state) => state.authReducer);
  const { selectedDate } = useSelector((state) => state.selectedDateReducer);

  const [activeTab, setActiveTab] = useState('category');
  const [excellTableHeader, setExcellTableHeader] = useState([]);
  const [excellData, setExcellData] = useState([]);
  const {
    handleFilters,
    selectedFilters,
    provinces,
    regions,
    setSelectedFilters,
  } = useFilter({
    withObject: false,
  });
  const { width } = useWindowSize();
  const fetchDataBySubcategory = () => {
    let data = {
      province: selectedFilters.province,
      region: selectedFilters.region,
    };
    if (
      !selectedDate.every((item) => item === '') &&
      selectedDate[0] !== 'Invalid date' &&
      selectedDate[1] !== 'Invalid date'
    ) {
      data['start'] = selectedDate[0];
      data['end'] = selectedDate[1];
    }
    data = { ...data };

    if (!selectedDate.every((item) => item === '') && activeTab !== '') {
      if (activeTab === 'subCategory') {
        reportService
          .getAllReportBySubCategories(data)
          .then((resp) => {
            const rowData = createUserDataBySubcategory(resp);
            setExcellData(rowData);
          })
          .catch((err) => console.log(err));
      }
      if (activeTab === 'category') {
        reportService
          .getAllReportByCategories(data)
          .then((resp) => {
            const rowData = createUserDataByCategory(resp);
            setExcellData(rowData);
          })
          .catch((err) => console.log(err));
      }
      if (activeTab === 'users') {
        reportService
          .getAllUserPlans({
            province: selectedFilters.province,
            region: selectedFilters.region,
          })
          .then((resp) => {
            const rowData = createUserDataByUserPlan(resp);
            setExcellData(rowData);
          })
          .catch((err) => console.log(err));
      }
      if (activeTab === 'support') {
        reportService
          .getSupportReports(data)
          .then((resp) => {
            const rowData = createUserDataBySupport(resp);
            setExcellData(rowData);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const fetchExcellData = () => {
    fetchDataBySubcategory();
  };

  const components = {
    subCategory: (
      <BySubcategories
        active={activeTab}
        selectedFilters={selectedFilters}
        headerGetter={setExcellTableHeader}
      />
    ),
    category: (
      <ByCategories
        active={activeTab}
        selectedFilters={selectedFilters}
        headerGetter={setExcellTableHeader}
      />
    ),
    users: (
      <ByUsersPlan
        active={activeTab}
        selectedFilters={selectedFilters}
        headerGetter={setExcellTableHeader}
      />
    ),
    support: (
      <Supports
        active={activeTab}
        selectedFilters={selectedFilters}
        headerGetter={setExcellTableHeader}
      />
    ),
  };

  const handleActive = ({ value }) => {
    setActiveTab(value);
    const values = { ...selectedFilters };
    if (values['region']) {
      delete values['region'];
      setSelectedFilters(values);
    }
  };

  const filterItems = [
    {
      name: 'province',
      label: 'Viloyat',
      options: provinces,
      permissions: ['leader'],
      placeholder: 'Viloyatni tanlang...',
      type: 'select',
      span: 12,
      isShow: true,
    },
    {
      label: 'Shahar-tuman',
      placeholder: 'Tuman(Shahar) tanlang...',
      name: 'region',
      type: 'select',
      options: regions,
      permissions: ['leader', 'proleader'],
      span: 12,
      isShow: true,
    },
    {
      label: 'Sana',
      name: 'date',
      type: 'datepicker',
      permissions: ['leader', 'proleader'],
      span: 12,
      isShow: activeTab !== 'users' && true,
    },
  ];

  const filterByRelavent = useMemo(() => {
    return filterItems.filter(
      (item) => item.permissions.includes(role) && item.isShow
    );
  }, [activeTab, selectedFilters]);

  const excelTable = useMemo(() => {
    return buttons.find((btn) => btn.value === activeTab);
  }, [activeTab]);

  return (
    <Wrapper>
      <Typograpy>
        {activeTab === 'users' ? (
          <div>
            Tizimdagi barcha mahalla raislari tomonidan <br />
            bajarilishi zarur {"bo'lgan"} ishlar jadvali
          </div>
        ) : (
          <div>
            Tizimdagi barcha mahalla raislari tomonidan xisobot <br />
            kesimida rejalashtirilgan va bajarilgan ishlar tahlili
          </div>
        )}
      </Typograpy>
      <PageFilters>
        <div className="main__filter">
          <FilterGroups className="child-1">
            {width > 997 ? (
              <div className="main__form">
                {filterByRelavent.map((item) => (
                  <FilterForm
                    key={item.name}
                    item={item}
                    selectedFilters={selectedFilters}
                    handleFilter={handleFilters}
                  />
                ))}
              </div>
            ) : (
              <DynamicFilters
                filters={filterByRelavent}
                selectedFilters={selectedFilters}
                handleFilters={handleFilters}
              />
            )}
          </FilterGroups>
          <ActionTabs className="child-2">
            <ReportTab
              list={buttons}
              active={activeTab}
              handleActive={handleActive}
            />
          </ActionTabs>
        </div>
        <div className="excell__btn">
          <DownloadToExcell
            fileName={excelTable.title}
            tableName={`report_by_${excelTable.value}`}
            tableHeader={excellTableHeader}
            tableData={excellData}
            setExcellData={setExcellData}
            handleFetchData={fetchExcellData}
          />
        </div>
      </PageFilters>
      {components[activeTab]}
    </Wrapper>
  );
};
