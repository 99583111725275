import React from 'react';
import {
  BodyWrapper,
  ButtonViewWrapper,
  ButtonWrapper,
  HeaderCardWrap,
  SendTaskWrapper,
  UploadImage,
  VideoFrame,
} from './style';
import ImageUrl from '../../../../assets/taskCard/image.svg';
import VideoUrl from '../../../../assets/taskCard/video.svg';
import TextUrl from '../../../../assets/taskCard/text.svg';
import imageUpload from '../../../../assets/taskCard/imageUpload.svg';
import videoUpload from '../../../../assets/taskCard/videoUpload.svg';
import { Image } from 'antd';
import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import TextArea from 'components/FormElements/TextArea';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlayCircleOutlined } from '@ant-design/icons';
import ModalPlayer from 'components/ModalPlayer';
import specialTask from 'services/specialTasks';
import Spinner from 'components/Spinner';
import moment from 'moment';

export default () => {
  const [uploadImage, setUploadImage] = useState([]);
  const [uploadVideo, setUploadVideo] = useState();
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const { data } = useLocation().state;

  const showPlayer = () => {
    setModalOpen(!modalOpen);
  };

  const handleUploadImage = (e) => {
    if (e.target.files[0] !== undefined) {
      const image = e.target.files[0];
      setUploadImage((prev) => [...prev, image]);
    }
  };

  const handleUploadVideo = (e) => {
    if (e.target.files[0] !== undefined) {
      const video = e.target.files[0];
      setUploadVideo(video);
    }
  };

  const handleRemove = (ind) => {
    setUploadImage(uploadImage.filter((_, index) => index !== ind));
  };

  const handleSubmit = () => {
    if (
      (data.withPhoto && uploadImage?.length) ||
      (data?.withVideo && uploadVideo) ||
      description?.length
    ) {
      const formData = new FormData();
      formData.append('specialTask', data?._id);
      formData.append('desc', description);
      formData.append(
        'uploadTime',
        moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      );
      data?.withVideo && formData.append('files', uploadVideo);
      if (data?.withPhoto) {
        for (let i = 0; i < uploadImage?.length; i++) {
          formData.append(`files`, uploadImage[i]);
        }
      }
      setLoading(true);
      specialTask
        .getDoneTask(formData)
        .then(() => {
          navigate(-1);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  const handleViewSubmit = () => {
    const payload = {
      specialTask: data?._id,
    };
    setLoading(true);
    specialTask
      .getDoneTask(payload)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  if (loading) return <Spinner maxHeight="100%" />;

  return (
    <>
      {modalOpen && (
        <ModalPlayer
          url={uploadVideo && URL?.createObjectURL(uploadVideo)}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
      <SendTaskWrapper>
        <HeaderCardWrap>
          <div className="left-section">
            <div className="section-title">{data?.titleUZ}</div>
            <div className="section-desc">{data?.desc}</div>
          </div>

          <div className="right-section">
            <div className="image-wrapper">
              <img
                src={
                  data?.withVideo
                    ? VideoUrl
                    : data?.withPhoto
                    ? ImageUrl
                    : TextUrl
                }
                alt=""
              />
            </div>
          </div>
        </HeaderCardWrap>

        {data?.forReference && (
          <ButtonViewWrapper>
            <button className="submit-button" onClick={handleViewSubmit}>
              Tanishdim
            </button>
          </ButtonViewWrapper>
        )}

        {!data?.forReference && (
          <BodyWrapper>
            <div className="body-title">Topshiriqni bajarish</div>
            {(data?.withPhoto || data?.withVideo) && (
              <div className="image-upload-wrap">
                {uploadImage?.length < 4 ? (
                  <label htmlFor="upload" className="image-upload">
                    <div className="image-upload-icon">
                      <img
                        src={
                          data?.withVideo
                            ? videoUpload
                            : (data?.withPhoto && imageUpload) || ''
                        }
                        alt=""
                      />
                      {data?.withVideo ? 'Video' : data?.withPhoto && 'Rasm'}{' '}
                      yuklash
                    </div>
                  </label>
                ) : null}
                <input
                  type="file"
                  id="upload"
                  onChange={
                    data?.withVideo ? handleUploadVideo : handleUploadImage
                  }
                  accept={
                    data?.withVideo
                      ? 'video/*'
                      : (data?.withPhoto &&
                          'image/png, image/gif, image/jpeg') ||
                        ''
                  }
                  maxLength={5}
                />
                {data?.withVideo
                  ? uploadVideo !== undefined &&
                    uploadVideo && (
                      <VideoFrame onClick={showPlayer}>
                        <img
                          className="effect__blur"
                          src={URL?.createObjectURL(uploadVideo)}
                          alt="video frame"
                        />
                        <div className="player-icon-wrap">
                          <PlayCircleOutlined className="icon" />
                        </div>
                      </VideoFrame>
                    )
                  : data?.withPhoto && (
                      <UploadImage>
                        {uploadImage?.map((item, index) => (
                          <div key={index} className="card">
                            <Image src={URL?.createObjectURL(item)} />
                            <div
                              className="delete-image"
                              onClick={() => handleRemove(index)}
                              aria-hidden
                            >
                              <AiOutlineDelete color="#fff" />
                            </div>
                          </div>
                        ))}
                      </UploadImage>
                    )}
              </div>
            )}
            <div className="description">
              <div className="desc-title">Tavsif</div>
              <div className="textarea-wrap">
                <TextArea
                  placeholder={'Matn'}
                  handleDescription={(e) => setDescription(e?.target?.value)}
                  descriptionValue={description}
                />
              </div>
            </div>
            <ButtonWrapper>
              <button
                className="submit-button"
                onClick={handleSubmit}
                disabled={
                  (data.withPhoto && !uploadImage?.length) ||
                  (data?.withVideo && !uploadVideo) ||
                  (description?.length < 3 && true)
                }
              >
                Yuborish
              </button>
              <button className="cancel-button" onClick={handleCancel}>
                Bekor qilish
              </button>
            </ButtonWrapper>
          </BodyWrapper>
        )}
      </SendTaskWrapper>
    </>
  );
};
