import styled from 'styled-components';

export const MapContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  height: calc(100vh - 220px);
  box-sizing: border-box;
  position: relative;
  border: 1px solid #fbfbfb;
  z-index: 1;
  & .marker-cluster > div {
    font-size: 12px;
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
    color: white !important;
  }
  & .map-tiles {
    filter: var(--map-tiles-filter, none);
  }

  .map-layer-wrap {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1111;
  }
`;

export const FilterGroups = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 80px;
  gap: 10px;
  padding: 20px 0;
  box-sizing: border-box;
  transition: height 0.3s ease-in-out;
  .main__form {
    display: flex;
    min-height: 50px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 10px;
    label {
      color: #fff !important;
    }
  }
  .search__btn {
    background: #00c4fa;
  }
  .search__btn:disabled {
    background: #00a0cc;
    color: #fff;
  }
  .action__btn {
    display: flex;
    width: 200px;
    height: 44px;
    padding: 13px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    color: #fff;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
  }
  .filter__item {
    .range-datepicker {
      max-width: 100% !important;
      min-width: 100% !important;
    }
    .filter__label {
      color: #fff;
      font-family: Segoe UI;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 15px */
      padding: 0;
      margin: 0;
    }
    span {
      /* background-color: transparent; */
    }
  }
`;
