import React from 'react';
import { CorrectWrapper } from './style';
import { ReactComponent as CorrectIcon } from '../../../../../../assets/icons/correctAnswer.svg';

export default ({ item, id }) => {
  return (
    <CorrectWrapper>
      <div className="question-header">
        <div className="question-title">
          {id}. {item?.question?.title}
        </div>
        <div className="questionStatus-icon">
          <CorrectIcon />
        </div>
      </div>
      <div className="answer">{item?.correctAnswer?.title}</div>
    </CorrectWrapper>
  );
};
