export const leaderRoles = [
  {
    _id: 'proleader',
    title: "Viloyat bo'limi",
  },
  {
    _id: 'regleader',
    title: "Tuman bo'limi",
  },
  {
    _id: 'advanced',
    title: 'Yetakchi',
  },
];

export const proleaderRoles = [
  {
    _id: 'regleader',
    title: "Tuman bo'limi",
  },
  {
    _id: 'advanced',
    title: 'Yetakchi',
  },
];

export const regleaderRoles = [
  {
    _id: 'advanced',
    title: 'Yetakchi',
  },
];
