import { Pie } from '@ant-design/plots';
import { dashboardPieColors } from 'constants/colors';
import React from 'react';
import { DotFooterCard } from './style';

const PieChartCard = ({ analysisOfAppeals, defaultData }) => {
  return (
    <>
      <div className="chart-name">Ma’lumotlarni baholash holati</div>
      <div className="pie-chart-container">
        <div className="pie-chart-wrap">
          <Pie {...analysisOfAppeals} />
        </div>
      </div>
      <div className="pie-chart-card-footer">
        {defaultData
          ?.map((item, index) => (
            <div className="pie-footer-card" key={index}>
              <DotFooterCard color={dashboardPieColors[item?.status]} />
              <div className="pie-footer-title">{item?.type}</div>
            </div>
          ))
          .reverse()}
      </div>
    </>
  );
};

export default React.memo(PieChartCard);
