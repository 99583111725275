import React from 'react';

export default ({ headers, data, id }) => {
  return (
    <table id={id}>
      <thead>
        <tr>
          {headers.map((item) => (
            <th key={item?.accessor}>{item?.Header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item?.indx}>
            {headers.map((header) => {
              if (header?.accessor === 'total')
                return (
                  <td key={header?.accessor}>{`${item[header?.accessor]}.`}</td>
                );
              return (
                <td key={header?.accessor}>
                  {typeof item[header?.accessor] === 'object'
                    ? item[header?.accessor]?.categoryValue
                    : item[header?.accessor]}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
