import { Button, Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { Span } from '../../style';
import { WithDaysWrap } from '../style';

const { RangePicker } = DatePicker;

export default ({ id, data, handleRep }) => {
  const [date, setDate] = useState(data?.date);
  const [days, setDays] = useState(data?.days?.length ? [...data.days] : []);

  return (
    <WithDaysWrap>
      <Row justify="center" align="middle">
        <Col span={9}>
          <Span>Sana:</Span>
        </Col>
        <Col span={15}>
          <DatePicker
            placeholder=""
            value={date != null ? moment(date) : null}
            onChange={(e) => setDate(moment(e).format('YYYY-MM-DD'))}
            allowClear={false}
          />
        </Col>
      </Row>
      <Row>
        <Col span={9}>
          <Span>Oy kunlari :</Span>
        </Col>
        <Col span={15}>
          <RangePicker
            placeholder=""
            value={days?.length ? [moment(days[0]), moment(days[1])] : ['', '']}
            onChange={(_, event) => setDays(event)}
            allowClear={false}
          />
        </Col>
      </Row>
      <Row justify="end">
        <Col span={3}>
          <Button
            onClick={() => handleRep(id, { days, type: 'days', date })}
            type="primary"
          >
            Ok
          </Button>
        </Col>
      </Row>
    </WithDaysWrap>
  );
};
