import { service } from '.';

export default {
  getAll: () => service.get('/books'),
  getAllWithPaginate: (data) => service.post('/books/paginate', data),
  getOne: (id) => service.get(`/books/${id}`),
  create: (data) => service.post(`/books`, data),
  update: (id, data) => service.put(`/books/${id}`, data),
  delete: (id) => service.delete(`/books/${id}`),
  getAllReport: (data) => service.post(`/web/reports/bookReport`, data),

  getAllBookQuiz: (data) => service.post('/web/bookQuiz/paginate', data),
  createTestForSection: (id, data) =>
    service.put(`/web/bookQuiz/update/${id}`, data),
  getOneBookQuiz: (id) => service.get(`/web/bookQuiz/${id}`),

  getCompletedBookQuiz: (id) => service.get(`/books/update/${id}`),
};
