import React, { useMemo, useState, useEffect } from 'react';
import Table from '../../../../components/Tables/ReportTable';
import { headerMaker } from '../../../../components/Tables/ExpandedTable/helper';
import reportService from 'services/reports';
import { useSelector } from 'react-redux';
import Spinner from 'components/Spinner';
import { Empty } from 'antd';
import { reportUserPlanHeader } from 'redux/modules/table/common';
import { NoData } from '../style';

export const createUserDataByUserPlan = (data = []) => {
  const rowData = data?.map((item, ind) => {
    let row = {
      id: ind + 1,
      name: item?.title.titleUZ
        ? item.title?.titleUZ
        : `${item.title?.lastName} ${item?.title?.firstName}`,
      total: item.data.reduce((acc, i) => acc + i?.plan, 0),
      district: item.title?.district?.titleUZ ?? '-',
    };
    item.data.forEach((itm) => {
      row[itm.subCategory + 'plan'] = itm?.plan;
    });
    return row;
  });
  return rowData;
};

export default ({ active = '', selectedFilters, headerGetter }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newHeader, setNewHeader] = useState([]);
  const { categories } = useSelector((state) => state.appReducer);

  useEffect(() => {
    setLoading(true);
    let data = {
      province: selectedFilters.province,
      region: selectedFilters.region,
    };
    if (active !== '') {
      reportService
        .getAllUserPlans(data)
        .then((resp) => {
          const rowData = createUserDataByUserPlan(resp);
          setRows(rowData);
          makeReportHeaders(resp);
        })
        .finally(() => setLoading(false))
        .catch((err) => console.log(err));
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (newHeader?.length > 0) {
      if (selectedFilters?.region) {
        const heads = [...newHeader];
        heads[1].show = true;
        setNewHeader(heads);
      } else {
        const heads = [...newHeader];
        heads[1].show = false;
        setNewHeader(heads);
      }
    }
  }, [selectedFilters.region]);

  const makeReportHeaders = (plans = []) => {
    const hechCategories = new Map();
    categories.forEach((item) => hechCategories.set(item?._id, item));
    let foo = [];
    plans?.forEach((item) =>
      item?.data?.map((subItem) => foo.push(subItem?.subCategory))
    );
    foo = new Set([...foo]);
    const headerCats = [...foo].map((item) => hechCategories.get(item));

    let subHeaders = [...reportUserPlanHeader];
    if (selectedFilters?.region) {
      subHeaders[1].show = true;
    } else {
      subHeaders[1].show = false;
    }

    if (subHeaders.length === 4) {
      headerCats &&
        headerCats
          .filter((item) => item && item)
          .forEach((item) => {
            subHeaders.push({
              Header: item?.title,
              show: true,
              textAlign: 'center',
              width: 160,
              accessor: item?._id + 'plan',
            });
          });
    }
    setNewHeader(subHeaders);
  };

  const headers = useMemo(() => headerMaker(newHeader), [newHeader]);
  useEffect(() => {
    headerGetter(headers);
  }, [headers]);

  if (loading) return <Spinner maxHeight="300px" />;
  return rows?.length > 0 ? (
    <Table
      data={rows}
      headers={headers}
      // withExcel={true}
      // onChange={handleOnTableChange}
      // total={rows.length}
      // reportsData={rows}
      // page={pageCount}
      tableName="Reja(plan)lar bo'yicha"
    />
  ) : (
    <NoData>
      <Empty description={<h1>{"Ma'lumot topilmadi!"}</h1>} />
    </NoData>
  );
};
