import React from 'react';
import { StatusWrap, Wrapper } from './style';
import { FiEye } from 'react-icons/fi';

export default ({ id, data }) => {
  const { handleShowTask } = data;

  const handleNavigate = (userId, taskId) => {
    const sendData = {
      user: userId,
      specialTask: taskId,
    };
    handleShowTask(sendData);
  };

  const statusTitle = {
    0: {
      title: 'Bajarilmagan',
      bgColor: 'rgba(255, 96, 96, 0.12)',
      color: '#FF6060',
    },
    1: {
      title: 'Bajarilgan',
      bgColor: '#00826C1F',
      color: '#00826C',
    },
  };

  return (
    <>
      <Wrapper>
        <StatusWrap
          color={statusTitle[id]?.color}
          bgcolor={statusTitle[id]?.bgColor}
        >
          {statusTitle[id]?.title}
        </StatusWrap>
        {!data?.forReference && id === '1' && (
          <div className="eye">
            <FiEye
              size={24}
              color="#00826C"
              onClick={() => handleNavigate(data?.userId, data?.taskId)}
            />
          </div>
        )}
      </Wrapper>
    </>
  );
};
