import React, { useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import InputMask from 'react-input-mask';

import { Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import service from '../../services/auth';
import { useShowAlert } from '../../hooks';
import RaisQrImage from 'assets/RaisQr.png';
import Rais from 'assets/rais.svg';
import PremiumSoft from 'assets/premium-soft.png';
import Spinner from '../../components/Spinner/FullScreen';

import {
  setToken,
  setRole,
  setUsername,
  setFullname,
  setRegionId,
  setProvinceId,
  setUserId,
  setRegionTitle,
  setProvinceTitle,
} from '../../redux/modules/auth/actions';
import { Left, LoginWrapper, Right } from './style';
import { HiUser } from 'react-icons/hi2';
import { AiFillLock } from 'react-icons/ai';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import users from 'services/users';
import { useWindowSize } from 'hooks/useDevice';
import RaisLogo from 'components/Icons/RaisLogo';

export default () => {
  const { error } = useShowAlert();
  const navigate = useNavigate();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isFair, setIsFair] = useState(false);
  const { width } = useWindowSize();

  const checkRequest = (status) => {
    switch (status) {
      case 404:
      case 403:
        return 'Parol yoki telefon raqam kiritishda xatolik!';
    }
  };

  const fetchUserInformation = async (id) => {
    try {
      const resData = await users.getUserInformation(id);
      if (resData?.province) {
        localStorage.setItem('provinceId', resData?.province?._id);
        localStorage.setItem('provinceTitle', resData?.province?.titleUZ);
        dispatch(setProvinceId(resData?.province?._id));
        dispatch(setProvinceTitle(resData?.province?.titleUZ));
      }
      if (resData?.region) {
        localStorage.setItem('regionId', resData?.region?._id);
        localStorage.setItem('regionTitle', resData?.region?.titleUZ);
        dispatch(setRegionId(resData?.region?._id));
        dispatch(setRegionTitle(resData?.region?.titleUZ));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      phone: login.replace(/\D+/g, ''),
      password,
    };
    service
      .login(data)
      .then((resp) => {
        setLoading(false);
        const decodedToken = jwt_decode(resp.data.token);
        let fullname =
          decodedToken.title.lastName +
          ' ' +
          decodedToken.title.firstName +
          ' ' +
          decodedToken.title.middleName;
        localStorage.setItem('user_id', decodedToken._id);
        localStorage.setItem('agency_access_token', resp.data.token);
        localStorage.setItem('role', decodedToken.role);
        localStorage.setItem(
          'username',
          `${decodedToken.title.lastName}. ${decodedToken.title.firstName[0]}`
        );
        dispatch(setUserId(decodedToken._id));
        localStorage.setItem('fullname', fullname);
        dispatch(setToken(resp.data.token));
        dispatch(setRole(decodedToken.role));
        dispatch(setFullname(fullname));
        dispatch(
          setUsername(
            `${decodedToken.title.lastName}. ${decodedToken.title.firstName[0]}`
          )
        );
        fetchUserInformation(decodedToken._id);
        navigate('/', { replace: true });
      })
      .catch((err) => {
        setLoading(false);
        error(
          checkRequest(err.response.status) ??
            "Aloqa bilan bog'liq xatolik! iltimos qayta urinib ko'ring",
          2,
          'topRight'
        );
      });
  };

  if (loading) return <Spinner />;
  return (
    <LoginWrapper>
      <Left width={width < 1200 ? 'true' : 'false'}>
        <div className="logo">
          <RaisLogo />
        </div>

        <div className="login_area">
          <div className="login__title">
            <p>Xush kelibsiz!</p>
          </div>
          <form
            className="login__form"
            autoComplete="off"
            // onSubmit={(e) => formHandler(e)}
          >
            <div className="form__input">
              <span className="input__title">Telefon raqam</span>
              <HiUser size={24} />
              <div className="addonBefore">+998</div>
              <InputMask
                className="input__mask"
                mask="(99) 999-99-99"
                placeholder="(_ _) 000-00-00"
                onChange={(e) => setLogin(e.target.value)}
                name="login"
                value={login}
                autoComplete="off"
                type="tel"
                max={9}
                min={9}
                //   maskChar={null}
              />
            </div>
            <div className="form__input">
              <span className="input__title">Parol</span>

              <Input.Password
                prefix={<AiFillLock size={24} style={{ marginRight: 16 }} />}
                className="pass__input"
                placeholder="Parolingizni kiriting!"
                onChange={(e) => setPassword(e.target.value)}
                iconRender={(visible) =>
                  visible ? (
                    <EyeTwoTone twoToneColor="#00826C" />
                  ) : (
                    <EyeInvisibleOutlined />
                  )
                }
              />
            </div>
            <div className="fair__mode">
              <label htmlFor="fair" className="check__mode">
                <Checkbox
                  id="fair"
                  defaultChecked={isFair}
                  onChange={() => setIsFair(!isFair)}
                />

                <div className="text">Ishonchli qurilma</div>
              </label>
              <div className="reset__pass">
                <button
                  type="button"
                  onClick={() => alert('Moderator bilan bog`laning!')}
                  className="reset__btn"
                >
                  Parolni unutdingizmi?
                </button>
              </div>
            </div>
            <button
              type="submit"
              className="login_btn"
              onClick={(e) => formHandler(e)}
              disabled={!(login.length && password.length) ? true : false}
            >
              <span>Hisobga kirish</span>
            </button>
          </form>
        </div>

        <div className="footer">
          <div className="qr__code">
            <p>MOBIL ILOVAMIZ</p>
            <img
              style={{ width: '132px', height: '132px' }}
              src={RaisQrImage}
              alt="our__mobile__qr"
            />
          </div>
          <div className="websites">
            <img
              src={Rais}
              alt="O'zbekistan mahallalari uyushmasi"
              height="40px"
            />

            <img src={PremiumSoft} alt="PremiumSoft" height="35px" />
          </div>
        </div>
      </Left>
      <Right width={width < 1200 ? 'true' : 'false'}>
        <div className="right-wrapper">
          <div className="img__dashboard" />
        </div>
      </Right>
    </LoginWrapper>
  );
};
