import styled, { css } from 'styled-components';

const tabletDesktop = css`
  box-sizing: border-box;
  background: var(--sidebar-background);
  transition: 0.2s width;
  grid-area: sidebar;
  position: relative;
  display: grid;
  z-index: 11;
  ${
    '' /* grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: 75px auto 150px; */
  }
  grid-template-columns: 1fr;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding-bottom: 24px;
`;

export const DesktopSidebar = styled.div`
  ${tabletDesktop}
  width: ${({ collapsed }) => (collapsed ? '70px' : 'var(--sidebar-width)')};
  grid-template-areas: ${({ collapsed }) =>
    collapsed ? `'header' 'body'` : `'header' 'body' 'footer' `};
  grid-template-rows: ${({ collapsed }) =>
    collapsed ? '75px auto' : '75px auto 150px'};
`;

export const MobileSidebar = styled.div`
  width: var(--sidebar-width);
  height: 100vh;
  background: var(--sidebar-background);
  position: fixed;
  top: 0;
  right: ${({ collapsed }) =>
    !collapsed ? 'calc(-1*var(--sidebar-width))' : '0'};
  transition: 0.2s;
  z-index: 11;
  display: grid;
  grid-template-areas: 'header' 'body' 'footer';
  grid-template-rows: 75px auto 150px;
  grid-template-columns: 1fr;
  overflow: hidden;
  padding-bottom: 24px;
`;
