import React from 'react';
import { IoLocationSharp } from 'react-icons/io5';
import { LocationButton, Map } from './style';

const MapButton = ({ handleShowModal, smaller }) => {
  return (
    <Map smaller={smaller}>
      <LocationButton onClick={handleShowModal}>
        <IoLocationSharp color="#00826C" size={21} />
      </LocationButton>
    </Map>
  );
};

export default MapButton;
