import { Status } from './style';

export const tabData = [
  {
    title: 'Barcha mahalla raislari',
    count: 60,
    status: 0,
  },
  {
    title: 'Kelganlar',
    count: 12,
    status: 5,
  },
  {
    title: 'Kech qolganlar',
    count: 12,
    status: 1,
  },
  {
    title: 'Kelmaganlar',
    count: 12,
    status: 2,
  },
  {
    title: 'Sababli kelmaganlar',
    count: 12,
    status: 7,
  },
  {
    title: 'Vakantlar',
    count: 12,
    status: 3,
  },
  {
    title: 'Hudud chegarasi',
    count: 12,
    status: 4,
  },
  {
    title: 'Aloqa uzilgan',
    count: 12,
    status: 6,
  },
];

export const DaysName = [
  'Dushanba',
  'Seshanba',
  'Chorshanba',
  'Payshanba',
  'Juma',
  'Shanba',
  'Yakshanba',
];

export const lateComeColumn = [
  {
    title: '№',
    dataIndex: 'key',
    key: 'key',
    width: '55px',
  },
  {
    title: 'Hafta kuni',
    dataIndex: 'day',
    key: 'day',
  },
  {
    title: 'Sana',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Kelgan vaqti',
    dataIndex: 'cameTime',
    key: 'cameTime',
  },
  {
    title: 'Kech qolgan vaqti',
    dataIndex: 'lateTime',
    key: 'lateTime',
    width: '175px',
  },
];

export const didNotComeColumn = [
  {
    title: '№',
    dataIndex: 'key',
    key: 'key',
    width: '55px',
  },
  {
    title: 'Hafta kuni',
    dataIndex: 'day',
    key: 'day',
  },
  {
    title: 'Sana',
    dataIndex: 'date',
    key: 'date',
  },
];

export const didNotComeColumnBecauseOf = [
  {
    title: '№',
    dataIndex: 'key',
    key: 'key',
    width: '55px',
  },
  {
    title: 'Hafta kuni',
    dataIndex: 'day',
    key: 'day',
  },
  {
    title: 'Sana',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: '',
    key: 'day',
    // width: '100%',
    render: (value) => {
      return (
        !value?.active && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Status>Sababli </Status>
            <span style={{ marginLeft: '10px' }}>{value?.cause}</span>
          </div>
        )
      );
    },
  },
];

export const areaBorderData = [
  {
    key: '1',
    day: 'Dushanba',
    date: '02.02.2023',
    cameTime: '09:20',
  },
  {
    key: '2',
    day: 'Chorshanba',
    date: '02.02.2023',
    cameTime: '09:20',
  },
  {
    key: '3',
    day: 'Payshanba',
    date: '02.02.2023',
    cameTime: '09:20',
  },
  {
    key: '4',
    day: 'Shanba',
    date: '02.02.2023',
    cameTime: '09:20',
  },
];

export const areaBorderColumn = [
  {
    title: '№',
    dataIndex: 'key',
    key: 'key',
    width: '55px',
  },
  {
    title: 'Hafta kuni',
    dataIndex: 'day',
    key: 'day',
  },
  {
    title: 'Sana',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Vaqt',
    dataIndex: 'cameTime',
    key: 'cameTime',
    width: '130px',
  },
];

export const areaBorderTimeColumn = [
  {
    title: 'T/r',
    dataIndex: 'indx',
    key: 'indx',
    width: '55px',
  },
  {
    title: 'Sana',
    dataIndex: 'startDate',
    key: 'startDate',
  },
  {
    title: 'Vaqt',
    dataIndex: 'minute',
    key: 'minute',
    width: '170px',
  },
];

export const scheduleTableHeader = [
  {
    title: 'T/r',
    dataIndex: 'indx',
    key: 'indx',
  },
  {
    title: 'Viloyat',
    dataIndex: 'province',
    key: 'province',
  },
  {
    title: 'Tuman (shaxar)',
    dataIndex: 'region',
    key: 'region',
  },
  {
    title: 'Mfy',
    dataIndex: 'district',
    key: 'district',
  },
  {
    title: 'Famili ismi sharifi',
    dataIndex: 'userName',
    key: 'userName',
  },
];
