import React from 'react';
import { useSelector } from 'react-redux';
import ProLeader from './ProLeader';
import Leader from './Leader';
import RegLeader from './RegLeader';
import { Wrapper } from './style';

const Dashboard = () => {
  const { role } = useSelector((state) => state.authReducer);

  const dashboards = {
    leader: <Leader />,
    groupleader: <Leader />,
    proleader: <ProLeader />,
    regleader: <RegLeader />,
  };

  return <Wrapper>{dashboards[role]}</Wrapper>;
};

export default React.memo(Dashboard);
