import styled from 'styled-components';

export const ModalBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 35px;
  padding: 20px;
  .reason__input {
    .ant-input {
      border-radius: 10px;
    }
  }

  .range-text {
    display: flex;
    font-size: 14px;
    color: #a8a8a8;
  }
  .range-datepicker {
    width: 100%;
  }
  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    display: none;
  }
  .range-datepicker > .ant-picker,
  .ant-picker-range {
    border-radius: 10px;
    background: #f8f9fa;
    border: none;
    height: 44px;
    width: 100%;
  }
  .ant-picker-input > input {
    font-weight: 700;
    width: 80px;
    text-align: center;
  }
  .ant-picker-dropdown
    .ant-picker-dropdown-range
    .ant-picker-dropdown-placement-bottomRight {
    padding: 15px;
    background: transparent;
  }
  .ant-picker-panel-container {
    border-radius: 20px;
  }
  .ant-picker-date-panel {
    padding: 5px;
    border-radius: 20px;
  }
  .ant-picker-header {
    border: none;
    margin: 10px;
    display: flex;
    align-items: center;
  }
  .ant-picker-date-panel .ant-picker-content th {
    color: #a8a8a8;
  }
  .ant-picker-content td {
    font-family: 'Roboto';
    font-weight: 600;
  }
  .ant-picker-header > button {
    width: 24px;
    height: 24px;
    background: #E0F0ED;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    color: blue;
  }
  .ant-picker-header > button:hover {
    background-color: blue;
    color: #fff;
  }
  .ant-picker-prev-icon {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-picker-clear {
    background-color: none;
  }

  & .action-button {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    button {
      border-radius: 12px;
      display: flex;
      width: 154px;
      height: 44px;
      padding: 15px 3px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border: none;
      outline: none;
      cursor: pointer;
    }
    .submit-btn {
      background: #00826C;
      color: #fff;
    }
    .cancel-btn {
      border: 1px solid #00826C;
      color: #00826C;
      background: #fff;
    }
  }

  & .error-message {
    position: absolute;
    color: #f64e60;
  }
`;
