import types from '../../../constants/action-types';

export const setPathname = (payload) => ({
  type: types.SET_PAGE_PATHNAME,
  payload,
});
export const setClear = (payload) => ({
  type: types.CLEAR_PATHNAME,
  payload,
});
