import { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import Button from 'components/DashboardButton';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
} from './style';
import { getMonthName } from 'views/Dashboard/helper';

export default ({ options = [], handleMonth, defaultValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  if (!selectedItem) {
    setSelectedItem(() => defaultValue);
  }

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setSelectedItem(value);
    handleMonth(value);
    setIsOpen(false);
  };

  return (
    <DropDownContainer>
      <DropDownHeader onClick={toggling}>
        <Button icon={DownOutlined} text={getMonthName(+selectedItem)} />
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {options.map((item) => (
              <ListItem onClick={onOptionClicked(item?.key)} key={item?.key}>
                {item?.label}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};
